import React, {useEffect, useState} from 'react';
import { Avatar, Button, Modal } from '@salesforce/design-system-react';
import {useUser} from "../../../context/users";
import {extractUserIDFromEmail} from "../../../components/pcsk_admin/deployment/stacksets/Utils";

const ProfileCircle = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const {user } = useUser();

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    return (<>
        {/* Profile circle on the top right */}
        <Button onClick={toggleModal} className={"slds-align-middle"} variant={"icon"}>
            <strong className={"slds-m-right_xx-small slds-text-color--weak"}> {extractUserIDFromEmail(user.email)} </strong>
            <Avatar alt="User Avatar" size="small"/>
        </Button>

        {/* Modal displaying user details */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={toggleModal}
                size="x-small"
                heading={<>
                    <Avatar alt="User Avatar" size="large"/>
                    <p>{`Hi ${extractUserIDFromEmail(user.email)}`}</p>
                </>}
            >
                <div className="slds-grid slds-wrap">
                    <span
                        className="slds-m-top_x-small slds-m-bottom_small slds-m-horizontal--small slds-badge slds-box_link">
                        Admin Portal Accessible: {user.allowed_jit_admin_access}
                    </span>
                    <span
                        className="slds-m-top_x-small slds-m-bottom_small slds-m-horizontal--small slds-badge slds-box_link">
                        Account Creation Allowed: {user.allowed_account_creation}
                    </span>
                </div>

                <div className="slds-modal__content slds-p-horizontal--medium slds-box slds-m-around--small">
                    <pre>{JSON.stringify(user, null, 2)}</pre>
                </div>
            </Modal>
        </>
    );
};

export default ProfileCircle;
