import React, { useEffect, useState } from "react";
import CustomSpinnerOverlay from "../../../utils/components/spinner/custom_spinner_overlay"
import {GUSApiServiceFactory} from "../../../services/gus/factory";
import {useUser} from "../../../context/users";
import {ChangeCaseMetadata} from "../../types/gus";

interface Props {
    isValidChangeCase: boolean;
    disableInput: boolean;
    changeCaseID: string;
    setChangeCaseID: React.Dispatch<React.SetStateAction<string>>;
    setIsValidChangeCase: React.Dispatch<React.SetStateAction<boolean>>;
    deploymentType: string;
    setCCMetadata?: React.Dispatch<React.SetStateAction<ChangeCaseMetadata | null>>;
    shouldContainCommitID?: string;
    mustContainAtLeastOneCommit?: boolean;
}

class ChangeCaseMessage {
    message: string;
    class: string;

    constructor(message: string, className: string) {
        this.message = message;
        this.class = className;
    }
}

const ChangeCaseInput: React.FC<Props> = ({
                                              changeCaseID,
                                              setChangeCaseID,
                                              disableInput, isValidChangeCase,
                                              setIsValidChangeCase,
                                              setCCMetadata,
                                              shouldContainCommitID,
                                              deploymentType,
                                              mustContainAtLeastOneCommit = false,
                                          }) => {
    const [isCCValidationInProgress, setIsCCValidationInProgress] = useState<boolean>(false);
    const [changeCaseMessages, setChangeCaseMessages] = useState<ChangeCaseMessage[]>([]);
    const gusAPIService = GUSApiServiceFactory.getService();

    useEffect(() => {
        setChangeCaseID('')
        resetMessages()
        setIsValidChangeCase(false)
    }, []);

    useEffect(() => {
        resetMessages()
        setCCMetadata && setCCMetadata(null)
    }, [changeCaseID]);

    const resetMessages = () => {
        setChangeCaseMessages([])
    }
    const errorCCMessages = (messages: string[]) => {
        resetMessages()
        const newMessages = messages.map(message => new ChangeCaseMessage(message, "slds-text-color_destructive"));
        setChangeCaseMessages((prevMessages) => [...prevMessages, ...newMessages]);
        setIsValidChangeCase(false);
    }


    const successCCMessages = (messages: string[]) => {
        resetMessages()
        const newMessages = messages.map(message => new ChangeCaseMessage(message, "slds-text-color_success"));
        setChangeCaseMessages(prevMessages => [...prevMessages, ...newMessages]);
        setIsValidChangeCase(true);
    };
    const handleChangeCaseInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        // Only update state if the value is a number or an empty string
        if (/^\d*$/.test(value)) {
            setChangeCaseID(value);
            setIsValidChangeCase(false);
            resetMessages();

            // Continue validation if the value length is 8 or more
            if (value.length >= 8) {
                setIsCCValidationInProgress(true);

                gusAPIService
                    .fetchChangeCaseMetadata(value, deploymentType, shouldContainCommitID || "")
                    .then((response) => {
                        setCCMetadata && setCCMetadata(response);

                        if (response.admin_actions_data.allowed) {
                            setIsValidChangeCase(true);
                            successCCMessages([response.metadata.subject]);
                        } else {
                            if (mustContainAtLeastOneCommit && response.metadata.commit_ids.length < 1) {
                                response.admin_actions_data.errors.push(`change case does not have any associated commits.`);
                            }
                            errorCCMessages(response.admin_actions_data.errors);
                        }
                    })
                    .catch((err) => {
                        let mssg = "Error occurred. Please try again later.";
                        if (err.hasOwnProperty("message")) {
                            mssg = err.message;
                        }
                        errorCCMessages([mssg]);
                        setIsValidChangeCase(false);
                    })
                    .finally(() => {
                        setIsCCValidationInProgress(false);
                    });
            } else {
                // Do not validate yet if length is less than 8
                setIsValidChangeCase(false);
            }
        }
    };

    return (
        <>
            <div className="slds-grid slds-grid_vertical-align-center">
                <div>
                    <input
                        type="text"
                        className="slds-input"
                        onChange={(e) => handleChangeCaseInput(e)}
                        disabled={disableInput}
                        placeholder={"Change Case ID"}
                        value={changeCaseID}
                    />
                </div>
                <div className="slds-m-left_large">
                    {isCCValidationInProgress && (
                        <CustomSpinnerOverlay size="small"/>
                    )}
                </div>
            </div>
            {changeCaseMessages.length > 0 &&
                <ul className="slds-list_dotted">
                    {changeCaseMessages.map((msg, index) => (
                        <li key={index} className={msg.class}>
                            {msg.message}
                        </li>
                    ))}
                </ul>
            }
        </>
    );
}

export default ChangeCaseInput;