import { ArtifactApiService } from "./service";
import { FetchArtifactsResponse } from "../../utils/types/artifacts/artifacts";
import { BASE_URL, HandleErrorResponse } from "../common";
import {GenericDeploymentSubmissionResponse} from "../../utils/types/deployments/genericDeploymentResponse";

export class MainArtifactApiService implements ArtifactApiService {
    readonly adminBasePath = `${BASE_URL}/pcsk-admin/sam`;

    async getArtifacts(prefix: string, maxPages: number, sinceDays: number, marker: string, commitIDs: string[]): Promise<FetchArtifactsResponse> {
        const endpoint = `${this.adminBasePath}/artifacts?prefix=${encodeURIComponent(prefix)}&maxPages=${encodeURIComponent(maxPages)}&marker=${encodeURIComponent(marker)}&sinceDays=${encodeURIComponent(sinceDays)}`;

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    filter_names_containing: commitIDs,
                })
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const resp = await response.json();
            return FetchArtifactsResponse.fromJSON(resp);
        } catch (error) {
            console.error("Error fetching artifacts:", error);
            throw error;
        }
    }
    async deployArtifact(changeCase: string,  implementationStep: string, artifactName: string): Promise<GenericDeploymentSubmissionResponse> {
        const endpoint = `${this.adminBasePath}/deploy`;

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    change_case: changeCase,
                    implementation_step_id: implementationStep,
                    artifact_name: artifactName,
                })
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            const resp = await response.json();
            return GenericDeploymentSubmissionResponse.fromJSON(resp);
        } catch (error) {
            console.error("Error deploy artifact:", error);
            throw error;
        }
    }
}
