import React, {useState, useEffect, useRef} from 'react';
import { Combobox, comboboxFilterAndLimit } from '@salesforce/design-system-react';
import { ChangeCaseImplementationStep, ChangeCaseMetadata } from "../../types/gus";
import {useUser} from "../../../context/users";

interface ImplementationStepSelectorProps {
    changeCaseMetadata: ChangeCaseMetadata | null;
    setSelectedStep: React.Dispatch<React.SetStateAction<string>>;
    disable: boolean;
}

type FilteredStep = { id: string; label: string; step: ChangeCaseImplementationStep };

// Helper function to generate selection structure
const getSelection = (step: ChangeCaseImplementationStep | null): FilteredStep[] => {
    return step ? [{ id: step.id, label: step.getMinimalDetails(), step }]: [];
};

const ImplementationStepSelector: React.FC<ImplementationStepSelectorProps> = ({
                                                                                   changeCaseMetadata,
                                                                                   setSelectedStep,
                                                                                   disable,
                                                                               }) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [autoPopulate, setAutoPopulate] = useState<boolean>(true);
    const [filteredSteps, setFilteredSteps] = useState<FilteredStep[]>([]);
    const [selectedImplementationStep, setSelectedImplementationStep] = useState<ChangeCaseImplementationStep | null>(null);
    const { user} = useUser();


    // Effect to filter implementation steps whenever searchTerm or metadata changes
    useEffect(() => {
        if (!changeCaseMetadata) return;
        const steps = changeCaseMetadata.metadata.implementation_steps.map((step) => ({
            id: step.id,
            label: step.getLabel(),
            step: step,
        })) || [];
        const filtered = steps.filter((step) =>
            step.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredSteps(filtered);
    }, [searchTerm, changeCaseMetadata]);

    // Effect to reset when disabled
    useEffect(() => {
        !changeCaseMetadata ? resetFC() : setAutoPopulate(true)
    }, [changeCaseMetadata]);

    // Effect to call parents method
    useEffect(() => {
        if (!selectedImplementationStep) {
            setSelectedStep('');
            return
        }
        if (changeCaseMetadata) {
            const errors = selectedImplementationStep.validate(user.email, changeCaseMetadata.metadata.owner_email, changeCaseMetadata.metadata.shadow_implementors)
            errors.length > 0 ? setSelectedStep('') : setSelectedStep(selectedImplementationStep.id);
        }
    }, [selectedImplementationStep]);

    // Effect to auto populate the first valid implementation step
    useEffect(() => {
        if (changeCaseMetadata && filteredSteps.length > 0 && !selectedImplementationStep && autoPopulate) {
            const aStep = changeCaseMetadata.getFirstValidImplementationStep(user.email)
            aStep && selectImplementationStep({ id: aStep.id, label: aStep.getLabel(), step: aStep });
            setAutoPopulate(false)
        }
    }, [filteredSteps, selectedImplementationStep]);

    const selectImplementationStep = (step: FilteredStep | null | undefined) => {
        if (!step) {
            setSelectedImplementationStep(null);
            setSearchTerm('');
            return
        }
        setSelectedImplementationStep(step.step);
        setSearchTerm(step.label);
    }

    const resetFC = (): void => {
        setSelectedStep('');
        setSearchTerm('');
        setSelectedImplementationStep(null);
    };

    const getBottomContent = (): JSX.Element | undefined => {
        if (!selectedImplementationStep || !changeCaseMetadata) return;

        const errors = selectedImplementationStep.validate(user.email, changeCaseMetadata.metadata.owner_email, changeCaseMetadata.metadata.shadow_implementors)
        if (errors.length > 0) {
            return (
                <ul className="slds-list_dotted">
                    {errors.map((msg, index) => (
                        <li key={index} className={"slds-text-color_error"}>
                            {msg}
                        </li>
                    ))}
                </ul>
            );
        }

        const plannedStartDate = selectedImplementationStep.getLocalPlannedStartDate()
        if (plannedStartDate.isValid) {
            return (
                <ul className="slds-list_dotted">
                    <li key={0} className={"slds-text-color_success"}>
                        {`Planned Start Time: ${plannedStartDate.dateObj.toLocaleString()} [Duration: ${selectedImplementationStep.planned_duration_hours} ${selectedImplementationStep.planned_duration_hours === 1 ? 'hour' : 'hours'}]`}
                    </li>
                </ul>
            )
        }

        // unknown date formats
        return (
            <ul className="slds-list_dotted">
                <li key={0} className={"slds-text-color_weak"}>
                    {selectedImplementationStep.planned_start_time}
                </li>
            </ul>
        );
    };

    return (
        <div className="slds-size_1-of-1">
            {disable ? (
                <input
                    type="text"
                    className={`slds-input ${disable ? 'slds-is-disabled' : ''}`}
                    placeholder="Search by implementation Step ID or Name"
                    value={searchTerm}
                    disabled={disable}
                />
            ) : (
                <Combobox
                    id="implementation_step_picker_combobox"
                    events={{
                        onSelect: (event: React.ChangeEvent<HTMLInputElement>, data: { selection: FilteredStep[] }) => {
                            if (data.selection && data.selection.length > 0) {
                                selectImplementationStep(data.selection[0])
                            }
                        },
                        onChange: (event: React.ChangeEvent<HTMLInputElement>, data: { value: string }) => {
                            setSearchTerm(data.value);
                        },
                        onRequestRemoveSelectedOption: resetFC,
                    }}
                    labels={{
                        placeholder: 'Search by implementation Step ID or Name',
                        noOptionsFound: 'No matches found.',
                    }}
                    menuPosition="absolute"
                    options={comboboxFilterAndLimit({
                        inputValue: searchTerm,
                        options: filteredSteps,
                        selection: getSelection(selectedImplementationStep),
                        limit: 40000,
                    })}
                    value={searchTerm}
                    selection={getSelection(selectedImplementationStep)}
                    variant="inline-listbox"
                    menuItemVisibleLength={5}
                />
            )}
            {getBottomContent()}
        </div>
    );
};

export default ImplementationStepSelector;
