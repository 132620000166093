import React, {useEffect, useState} from 'react';
import {Modal, Button, Card, BrandBand} from '@salesforce/design-system-react';
import {StackSetsDeploymentApiServiceFactory} from "../../../../services/deployment/stacksets/factory";
import {extractCommitIDFromArtifact} from "../../../../components/pcsk_admin/deployment/stacksets/Utils";
import CustomSpinnerOverlay from "../../../../utils/components/spinner/custom_spinner_overlay";
import ChangeCaseInput from "../../../../utils/components/input/changeCaseInput";
import {GenericDeploymentSubmissionResponse} from "../../../../utils/types/deployments/genericDeploymentResponse";
import ArtifactSelector from "../../../../utils/components/input/artifactSelector";
import {ChangeCaseMetadata} from "../../../../utils/types/gus";
import ToggleWithPopover from "../../../../components/pcsk_admin/deployment/utils/toggles";
import ImplementationStepSelector from "../../../../utils/components/input/changeCaseImplementationSteps";
import ThemedBadge from "../../../../utils/components/tag/tag";

interface DeployCommitModalProps {
    isOpen: boolean;
    onClose: () => void;
    onDeploySuccess: (commitId: string, apiResponse: GenericDeploymentSubmissionResponse) => void;
}

const DeployStackSetCommitModal: React.FC<DeployCommitModalProps> = ({ isOpen, onClose, onDeploySuccess }) => {
    const [commitTitle, setCommitTitle] = useState<string>('');
    const [changeCaseID, setChangeCaseID] = useState<string>('');
    const [commitTitleInputError, setCommitTitleInputError] = useState<string>('');
    const [selectedImplStepID, setSelectedImplStepID] = useState<string>('');
    const [isValidChangeCase, setIsValidChangeCase] = useState<boolean>(false);
    const [isDeployInProgress, setIsDeployInProgress] = useState<boolean>(false);
    const [disableDeployButton, setDisableDeployButton] = useState<boolean>(false);
    const [lowerEnvDeploymentComplete, setLowerEnvDeploymentComplete] = useState<boolean>(true);
    const [dryRun, setDryRun] = useState<boolean>(false);
    const [forceDeploy, setForceDeploy] = useState<boolean>(false);
    const [deployAPIResponse, setDeployAPIResponse] = useState<GenericDeploymentSubmissionResponse | undefined>(undefined);
    const [ccMetadata, setCCMetadata] = useState<ChangeCaseMetadata | null>(null);
    const [triggerComplianceCheck, setTriggerComplianceCheck] = useState<boolean>(false);
    const [selectedArtifact, setSelectedArtifact] = useState<string>('');

    const stackSetsApiService = StackSetsDeploymentApiServiceFactory.getService();

    useEffect(() => {
        setDisableDeployButton(isDeployInProgress || !isValidChangeCase || !isValidCommitID() || !isValidCommitTitle(commitTitle) || !lowerEnvDeploymentComplete)
    }, [isValidChangeCase, selectedArtifact, isDeployInProgress, commitTitle, lowerEnvDeploymentComplete]);

    useEffect(() => {
        setTriggerComplianceCheck( isValidChangeCase && isValidCommitID() && isValidImplementationStep())
    }, [isValidChangeCase, selectedArtifact, selectedImplStepID]);

    useEffect(() => {
        disableDeployButton && setDeployAPIResponse(undefined)
    }, [disableDeployButton]);
    const handleDeploy = async () => {
        try {
            setIsDeployInProgress(true);
            const deploymentResponse = await stackSetsApiService.deployCommit(changeCaseID, selectedImplStepID, extractCommitIDFromArtifact(selectedArtifact), commitTitle, forceDeploy, false, dryRun);
            deploymentResponse.success && onDeploySuccess(extractCommitIDFromArtifact(selectedArtifact), deploymentResponse)
            setDeployAPIResponse(deploymentResponse)
        } catch (error) {
            console.log("Error submitting commit for deployment", error);
            setDeployAPIResponse(new GenericDeploymentSubmissionResponse(`${error}`, false))
        } finally {
            setIsDeployInProgress(false);
        }
    };

    const handleCommitTitleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        setCommitTitle(input);
    };

    const isValidImplementationStep = () => {
        return selectedImplStepID.length > 0
    }
    const isValidCommitID = () => {
        return extractCommitIDFromArtifact(selectedArtifact).length > 0
    }

    const isValidCommitTitle = (commitTitle: string) => {
        return commitTitle.length > 0
    }

    const resetArtifact = () => {
        return !isValidChangeCase || !isValidImplementationStep()
    }

    const disableArtifact = () => {
        return isDeployInProgress || !isValidChangeCase || !isValidImplementationStep()
    }

    const disableImplStep = () => {
        return isDeployInProgress || !isValidChangeCase
    }

    const resetImplStep = () => {
        return !isValidChangeCase
    }

    const disableComplianceChecks = () => {
        return isDeployInProgress || !isValidChangeCase || !isValidImplementationStep() || !isValidCommitID()
    }

    const resetComplianceChecks = () => {
        return !isValidChangeCase ||  !isValidImplementationStep() || !isValidCommitID()
    }

    const getBottomContent = () => {
        if (isDeployInProgress) {
            return <>
                <Card hasNoHeader={true}>
                    <div style={{minHeight: "10vh"}}>
                        <div className="slds-m-top_xx-large slds-flex slds-flex_column slds-align-items_center">
                            <CustomSpinnerOverlay size={"medium"}/>
                            <p className="slds-m-top_large slds-align_absolute-center slds-text-color--inverse-weak">
                                Submitting commit for deployment
                            </p>
                        </div>
                    </div>
                </Card>
            </>
        }

        if (deployAPIResponse) {
            return <>
                <Card hasNoHeader={true}>
                    {deployAPIResponse.success ?
                        <div className="slds-p-around_medium slds-box">
                            <h2 className="slds-text-heading_medium slds-text-align_center slds-m-bottom_medium slds-text-color_success">
                                Deployment Request Submitted Successfully!
                            </h2>
                        </div> :
                        <div className="slds-p-around_medium slds-box">
                            <div className="slds-text-wrap slds-size_1-of-1 slds-m-vertical--medium">
                                <ThemedBadge
                                    className="slds-m-right_medium"
                                    theme={"slds-theme--error"}
                                    text={"Deployment Request Could Not Be Processed!"}
                                />
                            </div>
                            {deployAPIResponse.errors.length > 0 &&
                                <ul className="slds-list_dotted">
                                    {deployAPIResponse.errors.map((msg, index) => (
                                        <li key={index} className={"slds-text-color_destructive"}>
                                            {msg}
                                        </li>
                                    ))}
                                </ul>
                            }
                        </div>
                    }
                </Card>
            </>
        }

        return <>
            <Card hasNoHeader={true}>
                <div className="slds-p-around_x-large"></div>
            </Card>
        </>
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            size="medium"
            heading="Deploy New StackSet Template Commit"
            dismissOnClickOutside={false}
            disableClose={isDeployInProgress}
            footer={
                <>
                    <Button label="Submit for Deployment" onClick={handleDeploy} variant="brand" disabled={disableDeployButton}/>
                </>
            }
        >
            <div className="slds-p-around_medium">
                <div className={"slds-box"}>
                    <div className="slds-grid slds-gutters slds-m-around_small">
                        <div className="slds-col slds-size_1-of-5">
                            <label className="slds-form-element__label"><strong>Change Case:</strong></label>
                        </div>
                        <div className="slds-col">
                            <ChangeCaseInput
                                isValidChangeCase={isValidChangeCase}
                                disableInput={isDeployInProgress}
                                changeCaseID={changeCaseID}
                                setChangeCaseID={setChangeCaseID}
                                setIsValidChangeCase={setIsValidChangeCase}
                                setCCMetadata={setCCMetadata}
                                mustContainAtLeastOneCommit={true}
                                deploymentType={"StackSetsTemplate"}
                            ></ChangeCaseInput>
                        </div>
                    </div>

                    <div className="slds-grid slds-gutters slds-m-around_small">
                        <div className="slds-col slds-size_1-of-5">
                            <label className="slds-form-element__label"><strong>Implementation Step:</strong></label>
                        </div>
                        <div className="slds-size_2-of-3">
                            <div className="slds-grid slds-grid_vertical-align-center">
                                <div className="slds-col">
                                    <ImplementationStepSelector
                                        changeCaseMetadata={isValidChangeCase ? ccMetadata : null}
                                        setSelectedStep={setSelectedImplStepID}
                                        disable={disableImplStep()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slds-grid slds-gutters slds-m-around_small">
                        <div className="slds-col slds-size_1-of-5">
                            <label className="slds-form-element__label"><strong>Artifact:</strong></label>
                        </div>
                        <div className="slds-size_2-of-3">
                            <div className="slds-grid slds-grid_vertical-align-center">
                                <div className="slds-col">
                                    <ArtifactSelector
                                        setArtifact={setSelectedArtifact}
                                        disable={disableArtifact()}
                                        prefix={"pcsk-stacksets"}
                                        removeSelection={resetArtifact()}
                                        filterContaining={ccMetadata ? ccMetadata.metadata.commit_ids : []}
                                    />
                                    {selectedArtifact && extractCommitIDFromArtifact(selectedArtifact).length > 0 && <ul className="slds-list_dotted">
                                        <li key={0} className={"slds-text-color_success"}>
                                            {"Commit ID: " + extractCommitIDFromArtifact(selectedArtifact)}
                                        </li>
                                    </ul>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className="slds-grid slds-gutters slds-m-horizontal--small slds-m-top--small slds-m-bottom--x-large">
                        <div className="slds-col slds-size_1-of-5">
                            <label className="slds-form-element__label"><strong>Commit Title:</strong></label>
                        </div>
                        <div className="slds-col slds-size_2-of-3">
                            <input
                                type="text"
                                className="slds-input"
                                onChange={(e) => handleCommitTitleInput(e)}
                                disabled={isDeployInProgress || !isValidChangeCase || !isValidCommitID() || !isValidImplementationStep()}
                                placeholder={"Enter Commit Title"}
                                value={commitTitle}
                            />
                            <div className="slds-text-color_destructive slds-m-top_small">
                                <p>{commitTitleInputError}</p>
                            </div>
                        </div>
                    </div>
                    <div className="slds-grid slds-m-right_medium">
                        <ToggleWithPopover
                            labelSuffix="Force Deploy"
                            isChecked={forceDeploy}
                            isDisabled={isDeployInProgress || !isValidChangeCase || !isValidCommitID() || !isValidImplementationStep() || !isValidCommitTitle(commitTitle)}
                            onToggle={(checked) => setForceDeploy(checked)}
                            popoverHeading="Force Deploy"
                            popoverBody="Overrides any ongoing stackset deployment."
                        />
                        <ToggleWithPopover
                            labelSuffix="Dry Run"
                            isChecked={dryRun}
                            isDisabled={isDeployInProgress || !isValidChangeCase || !isValidCommitID() || !isValidImplementationStep() || !isValidCommitTitle(commitTitle)}
                            onToggle={(checked) => setDryRun(checked)}
                            popoverHeading="Dry Run"
                            popoverBody=" Simulates the deployment process without making any actual changes to the system."
                        />
                    </div>
                </div>
                {/*<div className={"slds-m-vertical--medium"}>*/}
                {/*    <ComplianceChecks*/}
                {/*        triggerComplianceCheck={triggerComplianceCheck}*/}
                {/*        disable={disableComplianceChecks()}*/}
                {/*        reset={resetComplianceChecks()}*/}
                {/*        commitID={extractCommitIDFromArtifact(selectedArtifact)}*/}
                {/*        deploymentType={"StackSetsTemplate"}*/}
                {/*        artifactName={""}*/}
                {/*        setLowerEnvDeploymentComplete={setLowerEnvDeploymentComplete}*/}
                {/*    />*/}
                {/*</div>*/}
                {getBottomContent()}
            </div>
        </Modal>
    );
};

export default DeployStackSetCommitModal;
