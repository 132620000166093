// Mock gate | allowed only in dev environment
const isAllAPIMocksDisabled = process.env.NODE_ENV !== 'development';
const isAllAPIMocksEnabled = !isAllAPIMocksDisabled && process.env['REACT_APP_MOCK_ALL_API']?.toLowerCase() === 'true'
const isGia2hEnv = Boolean(process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT.toLowerCase().includes('gia2h'));

// Generic function to determine if a specific API mock is enabled
function isMockEnabled(envVariable: string): boolean {
    return (
        !isAllAPIMocksDisabled &&
        (process.env[envVariable]?.toLowerCase() === 'true' || isAllAPIMocksEnabled)
    );
}

// Consolidated object to check mock statuses dynamically
export const API_MOCK_STATUS = {
    isDriftDetectionMockEnabled: (): boolean => isMockEnabled('REACT_APP_MOCK_STACKSETS_DRIFT_API'),
    isAccountClosureMockEnabled: (): boolean => isMockEnabled('REACT_APP_IS_ACCOUNT_CLOSURE_API_MOCK_ENABLED'),
    isGUSMockEnabled: (): boolean => isMockEnabled('REACT_APP_IS_GUS_API_MOCK_ENABLED'),
    isUserMockEnabled: (): boolean => isMockEnabled('REACT_APP_IS_USER_API_MOCK_ENABLED'),
    isStackSetsMockEnabled: (): boolean => isMockEnabled('REACT_APP_IS_STACKSETS_DEPLOYMENTS_API_MOCK_ENABLED'),
    isArtifactsMockEnabled: (): boolean => isMockEnabled('REACT_APP_IS_ARTIFACTS_API_MOCK_ENABLED'),
    isSCPMockEnabled: (): boolean => isMockEnabled('REACT_APP_IS_SCP_API_MOCK_ENABLED'),
    isComplianceMockEnabled: (): boolean => isMockEnabled('REACT_APP_IS_COMPLIANCE_API_MOCK_ENABLED'),
    isDeploymentVerificationMockEnabled: (): boolean => isMockEnabled('REACT_APP_IS_DEPLOYMENT_VERIFICATION_API_MOCK_ENABLED')
};

export const ADMIN_SERVICE_STATUS = {
    isSCPDeploymentEnabled: (): boolean => !isGia2hEnv
}