
export const extractCommitIDFromArtifact = (artifactName: string): string => {
    const commitID = artifactName.split('_').pop()?.replace('.zip', '');
    return commitID || '';
}

export const extractUserIDFromEmail = (email: string): string => {
    if (!email || email.length < 1) return ""
    return email.split('@')[0]
}
