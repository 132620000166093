import React, {useEffect, useState} from 'react';
import {Accordion, AccordionPanel, Button, Card, Icon} from '@salesforce/design-system-react';
import {SCPDeployment} from "../../../../utils/types/deployments/scp/operations";
import {timestampStringToDate} from "../../../../utils/types/deployments/stacksets/deployment";
import SCPExpandOperationModal from "../../../../modals/pcsk_admin/deployments/scp/ExpandOperation";
import SCPRollbackOperationModal from "../../../../modals/pcsk_admin/deployments/scp/RollbackOperation";
import {AddToastFunction} from "../../../../utils/types/toast";
import ThemedBadge from "../../../../utils/components/tag/tag";
import {CONSTANT_GENERIC_DEPLOYMENTS} from "../../../../constants/themes";

interface StaggerCardProps {
    selectedDeployment: SCPDeployment | null;
    getDetailsLi: (label: string, value: string) => JSX.Element;
    setSelectedOperationIDInParent: React.Dispatch<React.SetStateAction<string>>;
    addToast: AddToastFunction;
}

const SCPOperationsCard: React.FC<StaggerCardProps> = ({
                                                           selectedDeployment,
                                                           getDetailsLi,
                                                           setSelectedOperationIDInParent,
                                                           addToast,
                                                       }) => {

    const [selectedOperationID, setSelectedOperationID] = useState<string>("");
    const [isExpandModalOpen, setIsExpandModalOpen] = useState<boolean>(false);
    const [isRevertModalOpen, setIsRevertModalOpen] = useState<boolean>(false);

    // Update parent on child updates
    useEffect(() => {
        setSelectedOperationIDInParent(selectedOperationID)
    }, [selectedOperationID]);

    // Reset operation id when selected deployment changes
    useEffect(() => {
        setSelectedOperationID("")
    }, [selectedDeployment]);

    // Ensure data is available before rendering
    if (!selectedDeployment) {
        return null;
    }

    if (selectedDeployment.operations.length == 0) {
        return <>
            <Card className="" bodyClassName="" hasNoHeader={true}>
                <p>No operations found!</p>
            </Card>
        </>
    }

    const handleExpand = () => {
        setIsExpandModalOpen(true)
    }
    const handleRevert = () => {
        setIsRevertModalOpen(true)
    }

    return (<>
        <Card className="" bodyClassName="" hasNoHeader={true}>
            <div className="slds-grid slds-wrap">
                <div className="slds-col slds-size_1-of-1">
                    <h2 className="slds-text-title_bold slds-text-color_weak slds-m-bottom_x-small">
                        Operations [{selectedDeployment.operations.length}]
                    </h2>
                    <Accordion>
                        {selectedDeployment.getUniqueOperationIDs().map((operationID, index) => (
                            <AccordionPanel
                                key={index}
                                expanded={selectedOperationID != "" && selectedOperationID === operationID}
                                onTogglePanel={() => {
                                    // If the panel is opened, set the selected operation
                                    if (selectedOperationID && selectedOperationID === operationID) {
                                        setSelectedOperationID("");  // If already selected, set it to null (close the panel)
                                    } else {
                                        setSelectedOperationID(operationID);  // If not selected, set the selected deployment
                                    }
                                }}
                                summary={
                                    <div className="slds-text-wrap slds-size_1-of-1" style={{whiteSpace: 'normal'}}>
                                        <ThemedBadge
                                            className="slds-m-right_medium"
                                            theme={CONSTANT_GENERIC_DEPLOYMENTS.GET_STATUS_THEME(selectedDeployment.getOperationStatus(operationID), true)}
                                            text={selectedDeployment.getOperationStatus(operationID)}
                                        />
                                        <span title={`${selectedDeployment.getUniqueDevelopers(operationID)}`}>
                                            {`${operationID}`}
                                        </span>
                                    </div>
                                }
                                className="slds-box slds-m-bottom_small"
                            >

                                <div className="slds-grid slds-box">
                                    <div
                                        className="slds-col slds-m-horizontal--x-small">
                                        <ul className="slds-list_dotted">
                                            {getDetailsLi("Developer", `[${selectedDeployment.getUniqueDevelopers(operationID)}]`)}
                                            {getDetailsLi("Operation type", `[${selectedDeployment.getUniqueOperationTypes(operationID)}]`)}
                                            {getDetailsLi("Created At", timestampStringToDate(selectedDeployment.getCreatedAtForOperation(operationID)))}
                                            {getDetailsLi("Updated At", timestampStringToDate(selectedDeployment.getMaxStaggerTimestampForOperation(operationID)))}
                                        </ul>
                                    </div>
                                    {selectedOperationID != "" && selectedDeployment.operationIDHasActions(selectedOperationID) &&
                                        <Card
                                            heading="Operation Actions"
                                            icon={<Icon category="standard" name="actions_and_buttons"
                                                        size="small"/>}
                                            bodyClassName={"slds-p-right_medium"}
                                        >
                                            <div className="slds-row slds-text-align--left">
                                                <div className="slds-button-group slds-vertical">
                                                    {selectedDeployment.operationIDCanExpand(selectedOperationID) && <Button
                                                        label="Expand"
                                                        variant="neutral"
                                                        className="slds-m-bottom_small slds-m-horizontal--small slds-button_outline-brand"
                                                        onClick={handleExpand}
                                                        iconName={"add"}
                                                        iconPosition="left"
                                                        iconSize={"medium"}
                                                    />}
                                                    {selectedDeployment.operationIDCanRevert(selectedOperationID) && <Button
                                                        label="Revert"
                                                        variant="text-destructive"
                                                        className="slds-m-bottom_small slds-m-horizontal--small"
                                                        onClick={handleRevert}
                                                        iconName={"undo"}
                                                        iconPosition="left"
                                                        iconSize={"medium"}
                                                    />}
                                                </div>
                                            </div>
                                        </Card>}
                                </div>
                            </AccordionPanel>
                        ))}
                    </Accordion>
                </div>
            </div>
        </Card>

            {isExpandModalOpen && selectedOperationID &&
                <SCPExpandOperationModal
                    selectedOperationID={selectedOperationID}
                    selectedCommitTitle={selectedDeployment.commit_metadata.commit_title}
                    selectedCommitID={selectedDeployment.commit_metadata.commit_id}
                    selectedCommitDeveloper={selectedDeployment.commit_metadata.author_email}
                    onExpandSuccess={() => {
                        addToast("Expand Request Submitted Successfully!", `operation: ${selectedOperationID} has been submitted for Expand.`, "success");
                        setIsExpandModalOpen(false)
                    }}
                    onClose={() => {
                        setIsExpandModalOpen(false)
                    }}
                    isOpen={isExpandModalOpen}
                />
            }
            {isRevertModalOpen && selectedOperationID &&
                <SCPRollbackOperationModal
                    selectedCommitID={selectedDeployment.commit_metadata.commit_id}
                    selectedCommitTitle={selectedDeployment.commit_metadata.commit_title}
                    selectedCommitDeveloper={selectedDeployment.commit_metadata.author_email}
                    selectedOperationID={selectedOperationID}
                    onExpandSuccess={() => {
                        addToast("Revert Request Submitted Successfully!", `operation: ${selectedOperationID} has been submitted for Revert.`, "success");
                        setIsRevertModalOpen(false)
                    }}
                    onClose={() => {
                        setIsRevertModalOpen(false)
                    }}
                    isOpen={isRevertModalOpen}
                />
            }

        </>
    );
}

export default SCPOperationsCard;
