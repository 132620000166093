import React, {useEffect, useState} from 'react';
import {Card, Icon,} from '@salesforce/design-system-react';
import {URL_PATHS} from "../../../constants/pcsk_admin/paths";
import {CustomHeightPageSpinner} from "../../../utils/components/spinner/page_spinner_overlay";
import {useNavigate} from "react-router-dom";
import {GenericServiceSummary} from "../../../utils/types/genericServiceSummaryItem";
import {DriftDetectionApiServiceFactory} from "../../../services/drift_detection/factory";
import IconSpinnerOverlay from "../../../utils/components/spinner/icon_spinner_overlay";
import BarChart, {
    BarChartRow,
    GetOptimalBarContainerSize,
} from "../../../utils/components/bar_chart/bar_chart";
import {CONSTANT_STACKSETS_DRIFTS} from "../../../constants/themes";

const StackSetDriftSummaryCard: React.FC = () => {
    const [isFetchingSummary, setIsFetchingSummary] = useState<boolean>(false);
    const [fetchingSummaryError, setFetchingSummaryError] = useState<string>("");
    const [stackSetsDriftSummary, setStackSetsDriftSummary] = useState<GenericServiceSummary | null>(null);
    const [barChartData, setBarChartData] = useState<BarChartRow[]>([])

    const navigate = useNavigate();
    const driftDetectionApiService = DriftDetectionApiServiceFactory.getService();

    useEffect(() => {
        fetchStackSetsDriftSummary()
    }, []);

    useEffect(() => {
        stackSetsDriftSummary && setBarChartData(stackSetsDriftSummary.generateBarChartData(CONSTANT_STACKSETS_DRIFTS.GET_STATUS_THEME));
    }, [stackSetsDriftSummary]);

    const fetchStackSetsDriftSummary = async () => {
        setIsFetchingSummary(true);
        setFetchingSummaryError("")
        try {
            const summary = await driftDetectionApiService.getStackSetDriftSummary();
            setStackSetsDriftSummary(summary)
        } catch (error) {
            setFetchingSummaryError(`${error}`)
        } finally {
            setIsFetchingSummary(false);
        }
    };

    const getSummaryBody = () => {
        if (fetchingSummaryError !== '') {
            return (
                <div className="slds-grid slds-wrap slds-m-around_medium slds-text-color_destructive">
                    <p>{fetchingSummaryError}&nbsp;</p>
                </div>
            );
        }

        return (
            <div className="slds-grid slds-wrap">
                <div className="slds-col slds-size_1-of-1 slds-m-right_medium">
                    <BarChart
                        heading={""}
                        data={barChartData}
                        headerClass={"slds-text-title_caps slds-m-left_small"}
                        contentText={"account"}
                    />
                </div>
            </div>
        )
    };

    return (<>
        <div className={`slds-col ${GetOptimalBarContainerSize(barChartData.length)} slds-p-around--x-small`}>
            <Card
                className="slds-card slds-card_boundary"
                heading={
                    <div className="slds-grid slds-grid_align-spread">
                        <button
                            className="slds-button slds-button_reset slds-text-heading_small slds-text-link"
                            onClick={() => navigate(URL_PATHS.PCSK_ADMIN_STACKSETS_DRIFTS_PAGE)}
                            title="goto to Stacksets Drifts"
                        >
                            StackSets Drifts
                        </button>
                        <IconSpinnerOverlay size={"x-small"} loading={isFetchingSummary} onClick={fetchStackSetsDriftSummary}/>
                    </div>
                }
                icon={<Icon
                    category="standard"
                    name="flow"
                    size="small"
                />}
                style={{minHeight: '15vh'}}
            >
                {isFetchingSummary ? <>{<CustomHeightPageSpinner size="small" minHeight="5.2vh"/>}</> :
                    <>
                        {getSummaryBody()}
                    </>
                }
            </Card>
        </div>
    </>);
};

export default StackSetDriftSummaryCard;
