import React from 'react';
import {CONSTANT_GENERIC_DEPLOYMENTS} from "../../../constants/themes";
import {debug} from "node:util";

export type BarChartRow = {
    label: string;
    value: number;
    percentage: number;
    class: string;
};

type BarChartProps = {
    heading: string;
    liClass?: string;
    headerClass?: string;
    contentText?: string;
    data: BarChartRow[];
    vertical?: boolean;
    hasNoHeading?: boolean;
};

export const NoneBarChartRow = (): BarChartRow[] => {
    return [{
        label: "None",
        value: 0,
        percentage: 0,
        class: "slds-theme_warning",
    }]
}

export const PrepareBarChartData = (statusCounts: Map<string, number>, theme: (status: string) => string = CONSTANT_GENERIC_DEPLOYMENTS.GET_STATUS_THEME) => {

    // Calculate the total sum of all counts
    const totalSum = Array.from(statusCounts.values()).reduce((sum, count) => sum + count, 0);

    // Build bar chart data
    return Array.from(statusCounts.entries()).map(([status, count]): BarChartRow => {
        const percentage = totalSum > 0 ? (count / totalSum) * 100 : 0; // Handle division by zero
        const formattedPercentage = parseFloat(percentage.toFixed(Number.isInteger(percentage) ? 0 : 2));
        return {
            label: status || "None",
            value: count,
            percentage: formattedPercentage,
            class: theme(status),
        };
    })
}
export const GetOptimalBarContainerSize = (itemCount: number, min = 2): string => {
    return `slds-size_${itemCount <= 10 ? Math.max(itemCount, min) : 1}-of-12`
}

const BarChart: React.FC<BarChartProps> = (props: BarChartProps) => {
    const getLiClasses = (): string  => {
        return `slds-p-horizontal_small ${props.liClass} slds-size_1-of-${props.data.length <= 0 ? 1 : props.data.length}`
    }
    const getHeaderClasses = (): string => {
        return `${props.headerClass}`
    }

    const sortedData: BarChartRow[] = [...props.data].sort((a, b) => b.value - a.value);

    if (props.vertical) {
        return (
            <>
                {!props.hasNoHeading && <h2 className={getHeaderClasses()}>{props.heading}</h2>}
                <ul className="slds-grid slds-wrap slds-grid_vertical slds-p-top_x-small">
                    {sortedData.map((row: BarChartRow, index: number) => (
                        <li key={index} className="slds-col slds-p-vertical_x-small">
                            <div className="slds-p-around_small">
                                <div className="slds-text-title">{row.label}</div>
                                <span>{row.percentage}%</span>
                                <div className="slds-progress-bar">
                        <span
                            className={`slds-progress-bar__value ${row.class}`}
                            style={{ width: `${row.percentage}%` }}
                        >
                            <span className="slds-assistive-text">{row.value}% Complete</span>
                        </span>
                                </div>
                                <span>{row.value} {props.contentText}{row.value !== 1 && 's'}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            </>
        )
    }

    return (
        <>
            <h2 className={getHeaderClasses()}>{props.heading}</h2>
            <ul className="slds-grid slds-wrap slds-grid_pull-padded">
                {sortedData.map((row: BarChartRow, index: number) => (
                    <li key={index} className={getLiClasses()}>
                        <div className="slds-box--small">
                            <div className="slds-text-title">{row.label}</div>
                            <span>{row.percentage}%</span>
                            <div className="slds-progress-bar">
                                <span
                                    className={`slds-progress-bar__value ${row.class}`}
                                    style={{ width: `${row.percentage}%` }}
                                >
                                    <span className="slds-assistive-text">{row.value}% Complete</span>
                                </span>
                            </div>
                            <span>{row.value} {props.contentText}{row.value !== 1 && 's'}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </>
    );
};
export default BarChart;
