import {BASE_URL, HandleErrorResponse} from "../common";
import {UserApiService} from "./service"
import {User} from "../../context/users";

export class MainUserApiService implements UserApiService {
    readonly basePath = `${BASE_URL}/users`;
    async fetchUserMetadata(email: string): Promise<User> {
        const endpoint = `${this.basePath}/${email}`;

        try {
            const response = await fetch(endpoint, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                }
            });

            if (!response.ok) {
                await HandleErrorResponse(response);
            }

            return asUserItem(await response.json())

        } catch (error) {
            console.error("Error fetching user metadata:", error);
            throw error;
        }
    }
}
const asUserItem = (response: any): User => {
    const user: User = {
        id: response.id,
        email: response.email,
        user_id: response.user_id,
        allowed_account_creation: response.allowed_account_creation,
        allowed_drift_view: response.allowed_drift_view,
        allowed_jit_admin_access: response.allowed_jit_admin_access,
        roles: response.roles,
        cloudZones: response.cloudZones
    };

    return user
};