import React from 'react';
import {Icon, Spinner} from '@salesforce/design-system-react';
import CustomSpinnerOverlay from "./custom_spinner_overlay";

interface IconSpinnerOverlayProps {
    size: 'xx-small' | 'x-small' | 'small' | 'medium' | 'large';
    loading: boolean;
    onClick: () => void;
}

// slds-button_icon-border-inverse
const IconSpinnerOverlay: React.FC<IconSpinnerOverlayProps> = ({ size,
                                                               loading,
                                                               onClick}) => {
    return (
        <button
            className={"slds-button_icon-container slds-button slds-button_outline-brand"}
            title="Refresh"
            onClick={onClick}
            disabled={loading}
        >
            <Icon
                className="slds-icon-eq"
                category="utility"
                name={loading ? "spinner" : "refresh"}
                size="x-small"
            />
            <span className="slds-assistive-text">Refresh</span>
        </button>
    );
};

export default IconSpinnerOverlay;
