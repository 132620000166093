export class FetchArtifactsResponse {
    /**
     * List of artifact names.
     */
    public artifactNames: string[];

    /**
     * Marker for pagination.
     */
    public marker: string;

    /**
     * Constructor to initialize the artifact names and marker.
     * @param artifactNames - List of artifact names.
     * @param marker - Marker for pagination.
     */
    constructor(artifactNames: string[] = [], marker: string = "") {
        this.artifactNames = artifactNames;
        this.marker = marker;
    }

    /**
     * Static function to create an instance from JSON.
     * @param json - JSON object containing artifact names and marker.
     * @returns Instance of FetchArtifactsResponse.
     */
    public static fromJSON(json: unknown): FetchArtifactsResponse {
        if (!json || typeof json !== "object") {
            return new FetchArtifactsResponse();
        }

        const data = json as Record<string, unknown>;
        const artifactNames = Array.isArray(data.artifact_names)
            ? data.artifact_names.filter((name) => typeof name === "string")
            : [];
        const marker = typeof data.marker === "string" ? data.marker : "";

        return new FetchArtifactsResponse(artifactNames, marker);
    }
}
