import { StackSetsDeploymentApiService } from "./service";
import {StackSetsDeployment} from "../../../utils/types/deployments/stacksets/deployment";
import {GenericDeploymentSubmissionResponse} from "../../../utils/types/deployments/genericDeploymentResponse";
import {GenericServiceSummary} from "../../../utils/types/genericServiceSummaryItem";

// Helper function to generate mock data
function generateMockData(): object {

    return {
        "deployments": [
            {
                "commit_id": "37a1d15cf92219665a11f386ba112ca7d62f1dcf",
                "commit_title": "Renaming role to supportupdate of path to /admin ",
                "created_at": "1731090063",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1731090266",
                "changes": [
                    {
                        "id": "f7115c16-054c-4e26-a785-5ba3cad170c7",
                        "created_at": "1731090063",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731090266",
                        "staggers": [
                            {
                                "created_at": "1731090065",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1731090266"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "aecdbe6d4bd87443d58b95169a7660df060e29b5_857050118540_b36d5bf8-70dc-11ef-8d73-7ec824c156a4",
                "commit_title": "test commit ",
                "created_at": "1726127870",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1726128072",
                "changes": [
                    {
                        "id": "af231683-6b0c-4b0b-b808-cfe7a7f6d24b",
                        "created_at": "1726127870",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1726128072",
                        "staggers": [
                            {
                                "created_at": "1726127871",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1726128072"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "93e0fd72c3f39a4a82b05e135fa6f641bd25499a",
                "commit_title": "Update CommonIAM Add DDos role ",
                "created_at": "1715076340",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1715076751",
                "changes": [
                    {
                        "id": "294234a6-5651-4949-9b90-7a1b4ed759bc",
                        "created_at": "1715076340",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715076751",
                        "staggers": [
                            {
                                "created_at": "1715076347",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715076751"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "89637b23440a3a9fe09309591647bb92064b7fd2",
                "commit_title": "e1 ",
                "created_at": "1694620587",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1694620587",
                "changes": [
                    {
                        "id": "91953d90-574b-40c6-a1d5-1a81e9b6132f",
                        "created_at": "1694620605",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694620605",
                        "staggers": [
                            {
                                "created_at": "1694620608",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694620608"
                            }
                        ]
                    },
                    {
                        "id": "cb623f85-ffff-4eb9-b7c3-064089c3cf45",
                        "created_at": "1694620603",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694620603",
                        "staggers": [
                            {
                                "created_at": "1694620605",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694620605"
                            }
                        ]
                    },
                    {
                        "id": "ff90c9e0-d5a2-45a9-ae62-44c72a14ec58",
                        "created_at": "1694620598",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694620598",
                        "staggers": [
                            {
                                "created_at": "1694620601",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694620601"
                            }
                        ]
                    },
                    {
                        "id": "e439c844-32a2-45ec-a93e-e1ca51ccf7a3",
                        "created_at": "1694620596",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694620596",
                        "staggers": [
                            {
                                "created_at": "1694620598",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694620598"
                            }
                        ]
                    },
                    {
                        "id": "50eb6989-ab4f-4e52-94a9-bea4a89fe87b",
                        "created_at": "1694620593",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694620593",
                        "staggers": [
                            {
                                "created_at": "1694620596",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694620596"
                            }
                        ]
                    },
                    {
                        "id": "080d16da-c0d4-405d-b29d-41acc20842fa",
                        "created_at": "1694620591",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694620591",
                        "staggers": [
                            {
                                "created_at": "1694620593",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694620593"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "7237a457b3e4cfb3410729a889ef89559b895e00",
                "commit_title": "Remove awsConfigRole from pcsk-stacksets repo ",
                "created_at": "1689597481",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1689597910",
                "changes": [
                    {
                        "id": "2cccf7ea-0ecb-40ba-8ddd-fe071e24794b",
                        "created_at": "1689597503",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689597708",
                        "staggers": [
                            {
                                "created_at": "1689597505",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689597708"
                            }
                        ]
                    },
                    {
                        "id": "cd62cc3d-a549-4298-9693-ca1fb48c3e4a",
                        "created_at": "1689597501",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689597708",
                        "staggers": [
                            {
                                "created_at": "1689597503",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689597708"
                            }
                        ]
                    },
                    {
                        "id": "e363b5a8-faf7-4cd3-a625-53f2982f79d6",
                        "created_at": "1689597498",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689597709",
                        "staggers": [
                            {
                                "created_at": "1689597501",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689597709"
                            }
                        ]
                    },
                    {
                        "id": "7820f9c5-4401-422b-9f88-0a9f0855f57d",
                        "created_at": "1689597495",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689597709",
                        "staggers": [
                            {
                                "created_at": "1689597498",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689597709"
                            }
                        ]
                    },
                    {
                        "id": "769070f4-411b-4e44-8b91-ae06848ba25a",
                        "created_at": "1689597491",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689597707",
                        "staggers": [
                            {
                                "created_at": "1689597495",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689597707"
                            }
                        ]
                    },
                    {
                        "id": "94c55b20-1209-4c95-9382-1f75cd7b8cd5",
                        "created_at": "1689597489",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689597910",
                        "staggers": [
                            {
                                "created_at": "1689597491",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689597910"
                            }
                        ]
                    },
                    {
                        "id": "cb837b1a-3522-485d-8f3f-be6eecad7210",
                        "created_at": "1689597487",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689597708",
                        "staggers": [
                            {
                                "created_at": "1689597489",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689597708"
                            }
                        ]
                    },
                    {
                        "id": "3d29f670-948b-4543-b90c-cffd8cb5a35a",
                        "created_at": "1689597485",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689597707",
                        "staggers": [
                            {
                                "created_at": "1689597487",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689597707"
                            }
                        ]
                    },
                    {
                        "id": "06f94308-d3df-408d-bea7-9ebbe89acea6",
                        "created_at": "1689597481",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689597708",
                        "staggers": [
                            {
                                "created_at": "1689597485",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689597708"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "19e8b8d9afe9811794f6c01cb459f9ab8c1339cc",
                "commit_title": "test commit 2 ",
                "created_at": "1726129560",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1726129763",
                "changes": [
                    {
                        "id": "c04e216c-4992-4add-843d-35f357aed8c1",
                        "created_at": "1726129560",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1726129763",
                        "staggers": [
                            {
                                "created_at": "1726129562",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1726129763"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4f8a1963fb2ed2f513e1aa963e50255e40baa953",
                "commit_title": "@W-15804687 Renaming policy names again ",
                "created_at": "1720040649",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1720041384",
                "changes": [
                    {
                        "id": "1fe69eb2-f382-4fe9-8820-04dcb5120013",
                        "created_at": "1720040651",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1720041384",
                        "staggers": [
                            {
                                "created_at": "1720041180",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1720041384"
                            },
                            {
                                "created_at": "1720040653",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1720040857"
                            }
                        ]
                    },
                    {
                        "id": "80c0396e-7cda-4138-98e7-f56878b04d4e",
                        "created_at": "1720040649",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1720040857",
                        "staggers": [
                            {
                                "created_at": "1720040651",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1720040857"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "887784b465394bf71e411055e45cf801245fb76b",
                "commit_title": "test 3 ",
                "created_at": "1716391344",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1716391754",
                "changes": [
                    {
                        "id": "07e5aacf-06cd-437f-8b6c-7dc9ea78c6c1",
                        "created_at": "1716391351",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716391754",
                        "staggers": [
                            {
                                "created_at": "1716391352",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1716391754"
                            }
                        ]
                    },
                    {
                        "id": "2b7f42de-b7b3-498c-b852-379ccfd53d5b",
                        "created_at": "1716391344",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716391754",
                        "staggers": [
                            {
                                "created_at": "1716391350",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1716391754"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "70bfb6c3c19e7001733a917f52a6d7eb14c38949",
                "commit_title": "Update CommonIAM 6 ",
                "created_at": "1713784421",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1713784830",
                "changes": [
                    {
                        "id": "a41c67a3-c5f7-4998-81d9-428971fb0042",
                        "created_at": "1713784421",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1713784830",
                        "staggers": [
                            {
                                "created_at": "1713784427",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1713784830"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e2186db6aac34dc5e8d42b194a8e97b638c3ccc6",
                "commit_title": "W 14161241 deploy wiz role to mpa account (#105) ",
                "created_at": "1705565122",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1705565327",
                "changes": [
                    {
                        "id": "d79dce89-4f86-49a4-8016-cb8113ad778c",
                        "created_at": "1705565122",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705565327",
                        "staggers": [
                            {
                                "created_at": "1705565124",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705565327"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6f2006091fa883eae775afb185726e239809edc6",
                "commit_title": "added-wizRolev2-template ",
                "created_at": "1702030788",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1702031001",
                "changes": [
                    {
                        "id": "a4fd7fab-211e-422f-ac3c-1fa6c932a7f4",
                        "created_at": "1702030803",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeWizRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702030968",
                        "staggers": [
                            {
                                "created_at": "1702030805",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "stage-mpa",
                                "updated_at": "1702030968"
                            }
                        ]
                    },
                    {
                        "id": "3e142197-23e2-4d88-8bb3-e7c7a1894ff9",
                        "created_at": "1702030800",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702030969",
                        "staggers": [
                            {
                                "created_at": "1702030803",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702030969"
                            }
                        ]
                    },
                    {
                        "id": "8e301ea0-fdb7-4f2b-be43-c687bf80ed87",
                        "created_at": "1702030798",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702030969",
                        "staggers": [
                            {
                                "created_at": "1702030800",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702030969"
                            }
                        ]
                    },
                    {
                        "id": "96a8a370-cbd9-4874-b347-616749f5f6c6",
                        "created_at": "1702030796",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702031001",
                        "staggers": [
                            {
                                "created_at": "1702030798",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702031001"
                            }
                        ]
                    },
                    {
                        "id": "2b9fbde1-c0ab-419b-b0b5-42ddfee99cbc",
                        "created_at": "1702030793",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702030969",
                        "staggers": [
                            {
                                "created_at": "1702030796",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702030969"
                            }
                        ]
                    },
                    {
                        "id": "00e7fae3-1611-4bb3-8a13-5a0a8da2b3df",
                        "created_at": "1702030788",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702030936",
                        "staggers": [
                            {
                                "created_at": "1702030793",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702030936"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f164ea2a3a81159c6716a77972775d279c1ce52c",
                "commit_title": "@W-13046968 | Enabled managed execution on stacksets ",
                "created_at": "1684138948",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1684139156",
                "changes": [
                    {
                        "id": "7705fdd8-245f-4812-a4b7-223296390b50",
                        "created_at": "1683810819",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683811027",
                        "staggers": [
                            {
                                "created_at": "1683810821",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683811027"
                            }
                        ]
                    },
                    {
                        "id": "00d2c51d-c45d-475f-965a-498c71e41635",
                        "created_at": "1683810814",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1684139156",
                        "staggers": [
                            {
                                "created_at": "1684139168",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684139168"
                            },
                            {
                                "created_at": "1684138952",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684139156"
                            },
                            {
                                "created_at": "1683811040",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683811040"
                            },
                            {
                                "created_at": "1683810819",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683811026"
                            }
                        ]
                    },
                    {
                        "id": "cba22ff8-a577-4ff8-a040-cc2577b85509",
                        "created_at": "1683810812",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683811026",
                        "staggers": [
                            {
                                "created_at": "1683810814",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683811026"
                            }
                        ]
                    },
                    {
                        "id": "c4443f8f-bc4a-4486-99ca-fd6a5479b282",
                        "created_at": "1683810811",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683811027",
                        "staggers": [
                            {
                                "created_at": "1683810812",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683811027"
                            }
                        ]
                    },
                    {
                        "id": "4bc52104-74a4-4a3e-95a6-b087c5a079c1",
                        "created_at": "1683810809",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683811025",
                        "staggers": [
                            {
                                "created_at": "1683810810",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683811025"
                            }
                        ]
                    },
                    {
                        "id": "9e7ff05d-fd88-497b-a95b-73a432683011",
                        "created_at": "1683810806",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683811026",
                        "staggers": [
                            {
                                "created_at": "1683810809",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683811026"
                            }
                        ]
                    },
                    {
                        "id": "e29208c1-5c26-4103-ad63-b3e87c5889b0",
                        "created_at": "1683810804",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683811027",
                        "staggers": [
                            {
                                "created_at": "1683810806",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683811027"
                            }
                        ]
                    },
                    {
                        "id": "01293173-b640-4824-987c-50d849e0b41d",
                        "created_at": "1683810798",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683811025",
                        "staggers": [
                            {
                                "created_at": "1683810804",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683811025"
                            }
                        ]
                    },
                    {
                        "id": "14d3ecff-11a4-4f7c-885e-5e10669dde26",
                        "created_at": "1683810757",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683811026",
                        "staggers": [
                            {
                                "created_at": "1683810798",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683811026"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "712fd723d6c3c16b1d56ee0ce90743bc969b15d8",
                "commit_title": "added-SAIRole-stackset ",
                "created_at": "1715927673",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715928509",
                "changes": [
                    {
                        "id": "a1edde98-4cf1-4cc8-997d-c6c17cfde374",
                        "created_at": "1715927684",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeSAIRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715928095",
                        "staggers": [
                            {
                                "created_at": "1715927685",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715928095"
                            }
                        ]
                    },
                    {
                        "id": "e708bd2d-af0f-44bf-a288-81dfa8a9afc3",
                        "created_at": "1715927676",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715928095",
                        "staggers": [
                            {
                                "created_at": "1715927683",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715928095"
                            }
                        ]
                    },
                    {
                        "id": "8c9810c4-2c39-4993-be01-1567d2819052",
                        "created_at": "1715927674",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715928509",
                        "staggers": [
                            {
                                "created_at": "1715928406",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715928509"
                            },
                            {
                                "created_at": "1715927676",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1715928095"
                            }
                        ]
                    },
                    {
                        "id": "acb109bb-b187-4bfb-9412-26b7b84fa527",
                        "created_at": "1715927673",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715928509",
                        "staggers": [
                            {
                                "created_at": "1715928405",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715928509"
                            },
                            {
                                "created_at": "1715927674",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1715928094"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4bd4c04a0edba16a183fce5cac383ef16eb681f7",
                "commit_title": "Code review comments1 ",
                "created_at": "1705509959",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1705510177",
                "changes": [
                    {
                        "id": "a7f0a1cf-caad-4d28-9fa7-01cc185f97cc",
                        "created_at": "1705509969",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705510176",
                        "staggers": [
                            {
                                "created_at": "1705509971",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705510176"
                            }
                        ]
                    },
                    {
                        "id": "490ae35a-1bb4-4aa6-8048-e7d33f1cfe57",
                        "created_at": "1705509967",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705510176",
                        "staggers": [
                            {
                                "created_at": "1705509969",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705510176"
                            }
                        ]
                    },
                    {
                        "id": "d8edf506-eba0-4dc2-a179-21d544db1876",
                        "created_at": "1705509964",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705510176",
                        "staggers": [
                            {
                                "created_at": "1705509967",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705510176"
                            }
                        ]
                    },
                    {
                        "id": "99a8de2e-c75b-4efd-8f22-66b51e3cc4b7",
                        "created_at": "1705509959",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705510177",
                        "staggers": [
                            {
                                "created_at": "1705509964",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705510177"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "df88022acd9ba78cfe3522f92623468d361a3c89",
                "commit_title": "test commonIAMNonFalcon stackset ",
                "created_at": "1692254855",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1692255081",
                "changes": [
                    {
                        "id": "36930d4b-2b4d-4037-a9b5-141789f6a049",
                        "created_at": "1692254873",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692255081",
                        "staggers": [
                            {
                                "created_at": "1692254875",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692255081"
                            }
                        ]
                    },
                    {
                        "id": "f5e2c705-3ba3-4bef-9285-50b689222f20",
                        "created_at": "1692254872",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692255081",
                        "staggers": [
                            {
                                "created_at": "1692254873",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692255081"
                            }
                        ]
                    },
                    {
                        "id": "8f882c88-7ec5-4adf-9083-e732637d5a07",
                        "created_at": "1692254868",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692255079",
                        "staggers": [
                            {
                                "created_at": "1692254872",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692255079"
                            }
                        ]
                    },
                    {
                        "id": "52c881d3-e2cc-4add-a3c8-743a95c4c7e5",
                        "created_at": "1692254867",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692255080",
                        "staggers": [
                            {
                                "created_at": "1692254868",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692255080"
                            }
                        ]
                    },
                    {
                        "id": "4764cb34-2865-48d6-927e-b50b4fd02fbb",
                        "created_at": "1692254864",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692255080",
                        "staggers": [
                            {
                                "created_at": "1692254867",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692255080"
                            }
                        ]
                    },
                    {
                        "id": "170ee093-4670-44fa-b24d-66fe00e07710",
                        "created_at": "1692254862",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692255080",
                        "staggers": [
                            {
                                "created_at": "1692254864",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692255080"
                            }
                        ]
                    },
                    {
                        "id": "651c6bf0-0eb6-4616-b243-a2f1ae53db26",
                        "created_at": "1692254860",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692255081",
                        "staggers": [
                            {
                                "created_at": "1692254862",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692255081"
                            }
                        ]
                    },
                    {
                        "id": "9e26fa42-ae9a-4aad-bfd9-05cd7d62a637",
                        "created_at": "1692254858",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692255080",
                        "staggers": [
                            {
                                "created_at": "1692254860",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692255080"
                            }
                        ]
                    },
                    {
                        "id": "cb95d02e-4bfa-4df7-a7df-43184b684577",
                        "created_at": "1692254855",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692255079",
                        "staggers": [
                            {
                                "created_at": "1692254858",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692255079"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "cac612c5df4ebe7c6012a3b9ec920391bec3d7be",
                "commit_title": "add dipanshu account id ",
                "created_at": "1692177402",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1692177631",
                "changes": [
                    {
                        "id": "b19922b2-54ec-465c-932b-89202462b51c",
                        "created_at": "1692177422",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692177630",
                        "staggers": [
                            {
                                "created_at": "1692177424",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692177630"
                            }
                        ]
                    },
                    {
                        "id": "18c60f9f-9652-4590-bb24-8c99b13b1b17",
                        "created_at": "1692177420",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692177628",
                        "staggers": [
                            {
                                "created_at": "1692177422",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692177628"
                            }
                        ]
                    },
                    {
                        "id": "7d68c1fe-6c4c-43fb-a5b8-e53deb20d5eb",
                        "created_at": "1692177418",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692177629",
                        "staggers": [
                            {
                                "created_at": "1692177420",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692177629"
                            }
                        ]
                    },
                    {
                        "id": "781e71c3-cbb8-40d2-ad14-9e0bc9e6292c",
                        "created_at": "1692177416",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692177631",
                        "staggers": [
                            {
                                "created_at": "1692177418",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692177631"
                            }
                        ]
                    },
                    {
                        "id": "5ee51bf2-6547-41e7-83b8-1e2171a31ae7",
                        "created_at": "1692177414",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692177630",
                        "staggers": [
                            {
                                "created_at": "1692177416",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692177630"
                            }
                        ]
                    },
                    {
                        "id": "7e679ace-ad78-45ee-bbb4-9a742800d3a2",
                        "created_at": "1692177412",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692177630",
                        "staggers": [
                            {
                                "created_at": "1692177414",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692177630"
                            }
                        ]
                    },
                    {
                        "id": "8ca6a904-8ca4-44c5-9a35-9723b9a1bfa0",
                        "created_at": "1692177409",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692177630",
                        "staggers": [
                            {
                                "created_at": "1692177412",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692177630"
                            }
                        ]
                    },
                    {
                        "id": "f8a8ae8d-8f89-43b7-92f3-29d39ee7e2c3",
                        "created_at": "1692177407",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692177629",
                        "staggers": [
                            {
                                "created_at": "1692177409",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692177629"
                            }
                        ]
                    },
                    {
                        "id": "a82307d8-cfa7-4b05-bd12-d0e546bccfc1",
                        "created_at": "1692177404",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692177631",
                        "staggers": [
                            {
                                "created_at": "1692177407",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692177631"
                            }
                        ]
                    },
                    {
                        "id": "fb3d3d9f-e765-4f55-8964-c20cfedd93c9",
                        "created_at": "1692177402",
                        "operation_type": "",
                        "stackset_id": "dummyTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692177629",
                        "staggers": [
                            {
                                "created_at": "1692177404",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "dipanshu account id",
                                "updated_at": "1692177629"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "2475006e3fe5f8bedd24e34157096982a1ace007",
                "commit_title": "Trying to use Map ",
                "created_at": "1730296681",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730296885",
                "changes": [
                    {
                        "id": "4afb1d97-b12a-4109-ae32-a658827c6648",
                        "created_at": "1730296681",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730296885",
                        "staggers": [
                            {
                                "created_at": "1730296683",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730296885"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "77b33c234a81dc27af8f8649ae24e0ad2bb71ad9",
                "commit_title": "Revamping if condition for principal ",
                "created_at": "1730217961",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730218164",
                "changes": [
                    {
                        "id": "f1f798ce-aaeb-431a-a92e-7465cb143652",
                        "created_at": "1730217961",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730218164",
                        "staggers": [
                            {
                                "created_at": "1730217963",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730218164"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "7c41684a1a883ac8b29861b1811f9b653a1484be",
                "commit_title": "fix ",
                "created_at": "1728546142",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1728546345",
                "changes": [
                    {
                        "id": "9d008696-1b3c-46da-99a3-ed91fa506513",
                        "created_at": "1728546142",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeFixPrismaRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728546345",
                        "staggers": [
                            {
                                "created_at": "1728546143",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "SG 1",
                                "updated_at": "1728546345"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "37eae24fe99934b05c7b9f9267ded8a6bd3ba5c7",
                "commit_title": "test ",
                "created_at": "1728538206",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1728538613",
                "changes": [
                    {
                        "id": "76e64251-e4b8-46db-9305-2029ee6926bb",
                        "created_at": "1728538206",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728538613",
                        "staggers": [
                            {
                                "created_at": "1728538210",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728538613"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "798723aeba157b92eb916b7ef15bcbd107e20967",
                "commit_title": "Merge pull request #167 from p-raghavan/update_MPA ",
                "created_at": "1717423794",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1717424410",
                "changes": [
                    {
                        "id": "8397a9c8-d940-44dc-a3a8-6a46c87d6ed7",
                        "created_at": "1717423800",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717424005",
                        "staggers": [
                            {
                                "created_at": "1717423802",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717424005"
                            }
                        ]
                    },
                    {
                        "id": "0bf0886a-e469-4380-a411-a40eeb441e35",
                        "created_at": "1717423794",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717424410",
                        "staggers": [
                            {
                                "created_at": "1717423800",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717424410"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "220008abca084eed2e66813d14e69abafcd59632",
                "commit_title": "updated-rn ",
                "created_at": "1716272156",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1716272360",
                "changes": [
                    {
                        "id": "676626dc-e2e6-4bff-8d8f-bdd744c19ef0",
                        "created_at": "1716272156",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSCPServiceManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716272360",
                        "staggers": [
                            {
                                "created_at": "1716272158",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1716272360"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-6efea462-732d-11ee-913d-acde48001122_38871a03-7344-11ee-8b94-2e9f149b46f3",
                "commit_title": "test commit - go back to reverted PR - create 1 stackset and change group config for other 2 stacksets",
                "created_at": "1698244736",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698244736",
                "changes": [
                    {
                        "id": "dceee567-8f56-4a75-b6eb-a29299e145b2",
                        "created_at": "1698244741",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698244774",
                        "staggers": [
                            {
                                "created_at": "1698244743",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698244774"
                            }
                        ]
                    },
                    {
                        "id": "9a3c8d3c-064c-429c-a190-b191056b08f3",
                        "created_at": "1698244740",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698244775",
                        "staggers": [
                            {
                                "created_at": "1698244741",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698244775"
                            }
                        ]
                    },
                    {
                        "id": "dd265b18-c3b8-4a19-b3d4-935230e850a5",
                        "created_at": "1698244739",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698244774",
                        "staggers": [
                            {
                                "created_at": "1698244740",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698244774"
                            }
                        ]
                    },
                    {
                        "id": "c60ede81-a140-4224-b7b4-0a12ce72b37f",
                        "created_at": "1698244737",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698244773",
                        "staggers": [
                            {
                                "created_at": "1698244739",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698244773"
                            }
                        ]
                    },
                    {
                        "id": "a04d69c4-7598-49a2-8346-0b1f578c25c4",
                        "created_at": "1698244736",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698244774",
                        "staggers": [
                            {
                                "created_at": "1698244737",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698244774"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "776937e5377d9df16080a69a002041a18b36adef",
                "commit_title": "fixed-policy-name ",
                "created_at": "1715599911",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715599911",
                "changes": null,
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3f83384d3d338bb62fb9d65e0feb2167edcae9bf",
                "commit_title": "v18 t2 ",
                "created_at": "1693830955",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1693831183",
                "changes": [
                    {
                        "id": "c83bde25-dc2b-4c8f-b75d-4c1bec164312",
                        "created_at": "1693830975",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693831182",
                        "staggers": [
                            {
                                "created_at": "1693830976",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693831182"
                            }
                        ]
                    },
                    {
                        "id": "1f178c37-900d-4a3c-bc45-4e5877a58e18",
                        "created_at": "1693830973",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693831182",
                        "staggers": [
                            {
                                "created_at": "1693830975",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693831182"
                            }
                        ]
                    },
                    {
                        "id": "6f12b144-a4fc-4fe6-964c-1c11be9fb6f6",
                        "created_at": "1693830971",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693831181",
                        "staggers": [
                            {
                                "created_at": "1693830973",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693831181"
                            }
                        ]
                    },
                    {
                        "id": "5e7b7492-71e7-4936-8ea5-a9aa86bb3076",
                        "created_at": "1693830968",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693831183",
                        "staggers": [
                            {
                                "created_at": "1693830971",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693831183"
                            }
                        ]
                    },
                    {
                        "id": "f063c6ab-4a39-4a2e-9eb8-cf6a26726ad9",
                        "created_at": "1693830966",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693831181",
                        "staggers": [
                            {
                                "created_at": "1693830968",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693831181"
                            }
                        ]
                    },
                    {
                        "id": "d8a58a92-ed0b-43e9-9660-0af901494955",
                        "created_at": "1693830964",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693831183",
                        "staggers": [
                            {
                                "created_at": "1693830966",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693831183"
                            }
                        ]
                    },
                    {
                        "id": "ef36eed9-4437-4e3d-b46b-1ac9a2009c6a",
                        "created_at": "1693830961",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693831181",
                        "staggers": [
                            {
                                "created_at": "1693830964",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693831181"
                            }
                        ]
                    },
                    {
                        "id": "f0855508-d0ac-4c56-a6f0-257de3225305",
                        "created_at": "1693830957",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693831181",
                        "staggers": [
                            {
                                "created_at": "1693830961",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693831181"
                            }
                        ]
                    },
                    {
                        "id": "e2b6a7b5-a29e-4f0a-a9f1-2a3bd615bcb0",
                        "created_at": "1693830955",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693831182",
                        "staggers": [
                            {
                                "created_at": "1693830957",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693831182"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "7f6145c1a23d6210e58284b9b5440d7c72841b1b",
                "commit_title": "test commit ",
                "created_at": "1679292596",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1679292821",
                "changes": [
                    {
                        "id": "6bd312dd-a897-4f41-b344-bcd1c05b07ad",
                        "created_at": "1679292598",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1679292821",
                        "staggers": [
                            {
                                "created_at": "1679292720",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679292821"
                            },
                            {
                                "created_at": "1679292606",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage",
                                "updated_at": "1679292708"
                            }
                        ]
                    },
                    {
                        "id": "a66061d7-c93d-4e17-a5ad-c705de178b0a",
                        "created_at": "1679292596",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1679292708",
                        "staggers": [
                            {
                                "created_at": "1679292598",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679292708"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-25e38c90-7326-11ee-bcb0-acde48001122",
                "commit_title": "test commit - delete stacksets created in last stage",
                "created_at": "1698231826",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698231895",
                "changes": [
                    {
                        "id": "6ecca781-070a-436c-a2de-1ba52be39967",
                        "created_at": "1698231829",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698231895",
                        "staggers": [
                            {
                                "created_at": "1698231864",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698231895"
                            },
                            {
                                "created_at": "1698231830",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698231861"
                            }
                        ]
                    },
                    {
                        "id": "f0d6ba4a-59c7-45c8-815a-19ebb91545f4",
                        "created_at": "1698231828",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698231862",
                        "staggers": [
                            {
                                "created_at": "1698231829",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698231861"
                            }
                        ]
                    },
                    {
                        "id": "4c3a731b-1be2-4a21-aba6-ba00907d48d2",
                        "created_at": "1698231826",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698231862",
                        "staggers": [
                            {
                                "created_at": "1698231828",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698231862"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "65bf23540ba65283e1176df66149fd6e83feafb5",
                "commit_title": "testing ",
                "created_at": "1731519762",
                "deployment_status": "FAILED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731519967",
                "changes": [
                    {
                        "id": "eba25fca-4013-46e6-a393-ee9c2ff54ac7",
                        "created_at": "1731519762",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1731519967",
                        "staggers": [
                            {
                                "created_at": "1731519766",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731519967"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "4ad39431493681876bcb1d05cfcbc1c1ff1e95d7",
                "commit_title": "Adding ch2supportaccess role as custom role ",
                "created_at": "1728330575",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1728330779",
                "changes": [
                    {
                        "id": "d64a02f6-4a51-4f81-813c-35afda421f58",
                        "created_at": "1728330575",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728330779",
                        "staggers": [
                            {
                                "created_at": "1728330577",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1728330779"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b819cb83b35558fe935e886a39392790c1def157",
                "commit_title": "@W-16186122 Updating servicename for IAM roles and KMS keys ",
                "created_at": "1724098953",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1724100090",
                "changes": [
                    {
                        "id": "c8b09cfc-acbd-400e-a7aa-8248b7f03baa",
                        "created_at": "1724098967",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724100090",
                        "staggers": [
                            {
                                "created_at": "1724099888",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724100090"
                            },
                            {
                                "created_at": "1724098970",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1724099374"
                            }
                        ]
                    },
                    {
                        "id": "5315b4e0-09dc-4913-9143-08afe909b172",
                        "created_at": "1724098965",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724099172",
                        "staggers": [
                            {
                                "created_at": "1724098967",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724099172"
                            }
                        ]
                    },
                    {
                        "id": "708dc137-0330-411e-8e5d-579b4f6731a5",
                        "created_at": "1724098962",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724099172",
                        "staggers": [
                            {
                                "created_at": "1724098965",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724099172"
                            }
                        ]
                    },
                    {
                        "id": "05cfbeb2-adfc-4c97-a68d-c1416235368b",
                        "created_at": "1724098958",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724099373",
                        "staggers": [
                            {
                                "created_at": "1724098962",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724099373"
                            }
                        ]
                    },
                    {
                        "id": "3308dfa1-341f-4ee8-9c43-f74b7a646051",
                        "created_at": "1724098953",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724099374",
                        "staggers": [
                            {
                                "created_at": "1724098958",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724099374"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "9d010add9fe3afb1627bc980e6011cb3a377d258",
                "commit_title": "add back OUs ",
                "created_at": "1720003037",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1720011250",
                "changes": [
                    {
                        "id": "aef57034-a3d6-483d-955a-25d09232ad4c",
                        "created_at": "1720003037",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1720011250",
                        "staggers": null
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "43c286b9597423238b1f647ff8c374a299005ca3",
                "commit_title": "del-svcmngtest2 ",
                "created_at": "1716200319",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1716200422",
                "changes": [
                    {
                        "id": "c1074fd2-7757-48a2-ab99-553b8732902b",
                        "created_at": "1716200319",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeSCPServiceManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716200422",
                        "staggers": [
                            {
                                "created_at": "1716200320",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1716200422"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "206315b1b5e1bfd87f01a99261d7bd053517389b",
                "commit_title": "updated-scopeEmptyOU2 ",
                "created_at": "1714401644",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714401849",
                "changes": [
                    {
                        "id": "d798db5e-6374-4b3a-b0ed-e760eb38540c",
                        "created_at": "1714401645",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeEmptyOU",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714401849",
                        "staggers": [
                            {
                                "created_at": "1714401647",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1714401849"
                            }
                        ]
                    },
                    {
                        "id": "92b25f6b-8ddb-46e4-b471-0d17b076b9e8",
                        "created_at": "1714401644",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeEmptyOU",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714401849",
                        "staggers": [
                            {
                                "created_at": "1714401645",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1714401849"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f281530d7564e18936e754565bfce2f8062d9643_857050118540_c47f5ba6-9524-11ee-85a7-5ef4f9ed1d93",
                "commit_title": "add mrt resources ",
                "created_at": "1701969565",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1701969565",
                "changes": [
                    {
                        "id": "e8b9bc53-ee14-4cd8-8c3a-3f479d8af8e3",
                        "created_at": "1701969565",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeMRTResources",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701969599",
                        "staggers": [
                            {
                                "created_at": "1701969566",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1701969599"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-a757d3e0-730c-11ee-b304-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698220876",
                "deployment_status": "FAILED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698220876",
                "changes": null,
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "666865f535039d317e279a2b3cfa42f170a5f38d",
                "commit_title": "test ",
                "created_at": "1728480834",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1728481041",
                "changes": [
                    {
                        "id": "9552cc00-9484-4c08-b497-65937c10b0e1",
                        "created_at": "1728480834",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728481041",
                        "staggers": [
                            {
                                "created_at": "1728480839",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728481041"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "0677f270cd8ed60838f8e6f347c11330d6eba4ca",
                "commit_title": "Update code ",
                "created_at": "1717507223",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1717508076",
                "changes": [
                    {
                        "id": "49b22378-4cd1-4d03-95f9-823722544078",
                        "created_at": "1717507825",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1717508076",
                        "staggers": [
                            {
                                "created_at": "1717507826",
                                "operation_status": "OVERRIDDEN",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1717508076"
                            }
                        ]
                    },
                    {
                        "id": "dc372425-0bf6-4f9f-a7e1-0f3a0c035307",
                        "created_at": "1717507235",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1717508076",
                        "staggers": null
                    },
                    {
                        "id": "4f01cee2-8587-4601-9c0d-d69b36769978",
                        "created_at": "1717507233",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1717508076",
                        "staggers": [
                            {
                                "created_at": "1717507235",
                                "operation_status": "OVERRIDDEN",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717508076"
                            }
                        ]
                    },
                    {
                        "id": "d4da894d-c072-4e4b-8745-fc40cbaa90fd",
                        "created_at": "1717507231",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1717508076",
                        "staggers": [
                            {
                                "created_at": "1717507233",
                                "operation_status": "OVERRIDDEN",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717508076"
                            }
                        ]
                    },
                    {
                        "id": "e8eea78d-6a9b-421e-a100-70f57220bed1",
                        "created_at": "1717507224",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1717508076",
                        "staggers": [
                            {
                                "created_at": "1717507231",
                                "operation_status": "OVERRIDDEN",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717508076"
                            }
                        ]
                    },
                    {
                        "id": "faef970c-06f9-411c-99fd-0f546999c632",
                        "created_at": "1717507223",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1717508076",
                        "staggers": [
                            {
                                "created_at": "1717507224",
                                "operation_status": "OVERRIDDEN",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1717508076"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "23ae79cc9d6bf16a26f7f8194f439266689875f9",
                "commit_title": "added-demo-stackset ",
                "created_at": "1698908612",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1698908864",
                "changes": [
                    {
                        "id": "8887a0c0-81ac-42c4-8075-ddcf52929e02",
                        "created_at": "1698908614",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeDemoStackset",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698908859",
                        "staggers": [
                            {
                                "created_at": "1698908617",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "ou_target",
                                "updated_at": "1698908856"
                            }
                        ]
                    },
                    {
                        "id": "2bf748f4-a070-4725-9c7b-8b5a42becad9",
                        "created_at": "1698908612",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698908843",
                        "staggers": [
                            {
                                "created_at": "1698908614",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1698908840"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-a127d814-749f-11ee-a6ab-acde48001122",
                "commit_title": "test commit - create scopeTestThird stackset and change group config and template for scopeTestFirst,scopeTestSecond stacksets",
                "created_at": "1698393959",
                "deployment_status": "REVERTED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698394463",
                "changes": [
                    {
                        "id": "eb4790b5-b235-4b3b-a8db-0b3c05a5ea52",
                        "created_at": "1698393969",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698394463",
                        "staggers": [
                            {
                                "created_at": "1698393970",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698394463"
                            }
                        ]
                    },
                    {
                        "id": "f33c11be-eb97-4663-8d21-8b8ba6d476f9",
                        "created_at": "1698393968",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698394463",
                        "staggers": [
                            {
                                "created_at": "1698393969",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698394463"
                            }
                        ]
                    },
                    {
                        "id": "656b3946-5582-4f7e-a1a1-03811eaf38ad",
                        "created_at": "1698393967",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698394463",
                        "staggers": [
                            {
                                "created_at": "1698393968",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698394463"
                            }
                        ]
                    },
                    {
                        "id": "a6a4cff6-33ee-4f3b-8951-89d6d1ee8fdc",
                        "created_at": "1698393966",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698394463",
                        "staggers": [
                            {
                                "created_at": "1698393967",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698394463"
                            }
                        ]
                    },
                    {
                        "id": "925501dd-e40a-4aac-a89e-06aecf731c4d",
                        "created_at": "1698393964",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698394463",
                        "staggers": [
                            {
                                "created_at": "1698393966",
                                "operation_status": "REVERTED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698394463"
                            }
                        ]
                    },
                    {
                        "id": "ef432748-fc4a-4415-982b-b0224ab8cedb",
                        "created_at": "1698393962",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698394463",
                        "staggers": [
                            {
                                "created_at": "1698394177",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698394463"
                            },
                            {
                                "created_at": "1698393964",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698394463"
                            }
                        ]
                    },
                    {
                        "id": "7b3cb324-7fb7-4d5e-927c-38c546146d3f",
                        "created_at": "1698393959",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698394463",
                        "staggers": [
                            {
                                "created_at": "1698393962",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698394463"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "test-75d4ac56-732c-11ee-913d-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698234537",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698234608",
                "changes": [
                    {
                        "id": "b54a82fa-7052-4fe9-a89a-77e43dedf082",
                        "created_at": "1698234539",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698234573",
                        "staggers": [
                            {
                                "created_at": "1698234542",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698234573"
                            }
                        ]
                    },
                    {
                        "id": "aa880a70-9b2f-4bfe-81c0-1e086b070946",
                        "created_at": "1698234537",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698234608",
                        "staggers": [
                            {
                                "created_at": "1698234577",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698234608"
                            },
                            {
                                "created_at": "1698234539",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698234573"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "ef63c4c77f248f59a55f743b98863a9b53c6d7ba",
                "commit_title": "added-mpa-ou-event-rule ",
                "created_at": "1695801995",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1695802418",
                "changes": [
                    {
                        "id": "27be7096-dff3-4b88-8473-c4a825f80475",
                        "created_at": "1695802008",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695802215",
                        "staggers": [
                            {
                                "created_at": "1695802010",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695802215"
                            }
                        ]
                    },
                    {
                        "id": "29f0ddd9-af2a-4447-bef6-feadad623ef2",
                        "created_at": "1695802006",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695802417",
                        "staggers": [
                            {
                                "created_at": "1695802008",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695802417"
                            }
                        ]
                    },
                    {
                        "id": "20a2a916-d2a5-48e5-8caf-52de9452be7c",
                        "created_at": "1695802004",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695802214",
                        "staggers": [
                            {
                                "created_at": "1695802006",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695802214"
                            }
                        ]
                    },
                    {
                        "id": "22276566-a49e-4c5d-8256-ca8d8f9d9b56",
                        "created_at": "1695802002",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695802417",
                        "staggers": [
                            {
                                "created_at": "1695802004",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695802417"
                            }
                        ]
                    },
                    {
                        "id": "b36a452c-8b55-42b1-b86b-f6afc6d7518f",
                        "created_at": "1695802000",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695802418",
                        "staggers": [
                            {
                                "created_at": "1695802002",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695802418"
                            }
                        ]
                    },
                    {
                        "id": "53c5f660-fcf8-48a2-8c68-d522f99505c1",
                        "created_at": "1695801995",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695802214",
                        "staggers": [
                            {
                                "created_at": "1695802000",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695802214"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-39974830-732b-11ee-860f-acde48001122",
                "commit_title": "test commit - revert to 1st stage configuration, and mark third stackset for deletion which was created by the reverted PR",
                "created_at": "1698234010",
                "deployment_status": "REVERTED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698234149",
                "changes": [
                    {
                        "id": "9c70c4a5-3ac7-4e66-93cd-088ecb7de4a3",
                        "created_at": "1698234016",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234149",
                        "staggers": [
                            {
                                "created_at": "1698234017",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234149"
                            }
                        ]
                    },
                    {
                        "id": "c97aff32-608c-42e1-9319-795b8250f2cd",
                        "created_at": "1698234014",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234149",
                        "staggers": [
                            {
                                "created_at": "1698234016",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234149"
                            }
                        ]
                    },
                    {
                        "id": "60143259-df26-4fb3-8f28-7db43267b22d",
                        "created_at": "1698234013",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234149",
                        "staggers": [
                            {
                                "created_at": "1698234014",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234149"
                            }
                        ]
                    },
                    {
                        "id": "590ba657-1929-4363-81c9-ff6449aa7eeb",
                        "created_at": "1698234012",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234149",
                        "staggers": [
                            {
                                "created_at": "1698234013",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234149"
                            }
                        ]
                    },
                    {
                        "id": "368279bb-34ac-42b4-8a28-58f2aab8692c",
                        "created_at": "1698234011",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234149",
                        "staggers": [
                            {
                                "created_at": "1698234012",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234149"
                            }
                        ]
                    },
                    {
                        "id": "a1520430-1685-4e4e-9599-bd4289680ac6",
                        "created_at": "1698234010",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234149",
                        "staggers": [
                            {
                                "created_at": "1698234010",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234149"
                            }
                        ]
                    },
                    {
                        "id": "92d71390-9c08-493d-bfb7-53440436a7d2",
                        "created_at": "1698234010",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234149",
                        "staggers": [
                            {
                                "created_at": "1698234011",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234149"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "6466d338f7ba5d27c1dd5a88da4feef472c091ff",
                "commit_title": "@W-14144277 SQS eventHandler lambda and config ",
                "created_at": "1696400115",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1696400115",
                "changes": null,
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "0d21a81e123931231f77f7d18089b3e55285be24",
                "commit_title": "Merge pull request #47 from anjani-k/W-13133352_update_groups ",
                "created_at": "1685689594",
                "deployment_status": "COMPLETED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1685689822",
                "changes": [
                    {
                        "id": "0d6c1571-824a-4c9c-bd35-2d94bc156302",
                        "created_at": "1685689614",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685689821",
                        "staggers": [
                            {
                                "created_at": "1685689616",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685689821"
                            }
                        ]
                    },
                    {
                        "id": "36d2d506-261c-4661-a9d1-d731b8e0addc",
                        "created_at": "1685689612",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685689821",
                        "staggers": [
                            {
                                "created_at": "1685689614",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685689821"
                            }
                        ]
                    },
                    {
                        "id": "0aadc9e0-924d-4944-aca9-6eb9744e807c",
                        "created_at": "1685689610",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685689820",
                        "staggers": [
                            {
                                "created_at": "1685689612",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685689820"
                            }
                        ]
                    },
                    {
                        "id": "91f962f3-ba9a-4523-91b6-b1327863b213",
                        "created_at": "1685689608",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685689822",
                        "staggers": [
                            {
                                "created_at": "1685689610",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685689822"
                            }
                        ]
                    },
                    {
                        "id": "1a451c0b-d7f1-4806-b596-cf9551219805",
                        "created_at": "1685689606",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685689822",
                        "staggers": [
                            {
                                "created_at": "1685689608",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685689822"
                            }
                        ]
                    },
                    {
                        "id": "199fb134-b1d3-4ddd-b086-3fdaa481c36f",
                        "created_at": "1685689602",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685689820",
                        "staggers": [
                            {
                                "created_at": "1685689606",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685689820"
                            }
                        ]
                    },
                    {
                        "id": "36108674-1ba1-43c6-9c8e-646083f6c705",
                        "created_at": "1685689598",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685689821",
                        "staggers": [
                            {
                                "created_at": "1685689602",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685689821"
                            }
                        ]
                    },
                    {
                        "id": "7736ea0a-d36f-4dad-b905-9494bd9b675c",
                        "created_at": "1685689596",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685689821",
                        "staggers": [
                            {
                                "created_at": "1685689598",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685689821"
                            }
                        ]
                    },
                    {
                        "id": "e96d9cf4-edaf-4e72-ae2e-1bff3dbe1abd",
                        "created_at": "1685689594",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685689820",
                        "staggers": [
                            {
                                "created_at": "1685689596",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685689820"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "0492b44192bf6d3fe62d7b469a1148c11c061b4b",
                "commit_title": "testing ",
                "created_at": "1731414233",
                "deployment_status": "COMPLETED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731415119",
                "changes": [
                    {
                        "id": "337e29ff-11b4-425c-b973-6c47774db870",
                        "created_at": "1731414239",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731414846",
                        "staggers": [
                            {
                                "created_at": "1731414241",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1731414846"
                            }
                        ]
                    },
                    {
                        "id": "2a9cca43-e3cf-4d9d-b0a1-66a25025cb48",
                        "created_at": "1731414237",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSensorsControlTelemetryRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731414845",
                        "staggers": [
                            {
                                "created_at": "1731414239",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1731414845"
                            }
                        ]
                    },
                    {
                        "id": "0a4eac38-d001-42d6-8003-82f8bd03184b",
                        "created_at": "1731414233",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731415119",
                        "staggers": [
                            {
                                "created_at": "1731414918",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731415119"
                            },
                            {
                                "created_at": "1731414237",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731414846"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b26ef7c58c2896305878707f97e011f143a20499",
                "commit_title": "test ",
                "created_at": "1728540336",
                "deployment_status": "FAILED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1728540757",
                "changes": [
                    {
                        "id": "571789ed-740f-4097-9ec9-ac719266d984",
                        "created_at": "1728540350",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728540555",
                        "staggers": [
                            {
                                "created_at": "1728540352",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728540555"
                            }
                        ]
                    },
                    {
                        "id": "bdbea1cd-c421-42a5-ab73-317e16c5c9c9",
                        "created_at": "1728540347",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728540554",
                        "staggers": [
                            {
                                "created_at": "1728540349",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728540554"
                            }
                        ]
                    },
                    {
                        "id": "18dd9565-e141-40fe-bb5e-6d4a265cc616",
                        "created_at": "1728540341",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "FAILED",
                        "updated_at": "1728540757",
                        "staggers": [
                            {
                                "created_at": "1728540345",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728540757"
                            }
                        ]
                    },
                    {
                        "id": "665b41ca-b1a7-4e9d-b5af-bb09caa4f3db",
                        "created_at": "1728540339",
                        "operation_type": "UPDATE",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728540758",
                        "staggers": [
                            {
                                "created_at": "1728540341",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728540758"
                            }
                        ]
                    },
                    {
                        "id": "c34f2e22-1aee-4c7b-930c-0337e141b5cb",
                        "created_at": "1728540336",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728540555",
                        "staggers": [
                            {
                                "created_at": "1728540339",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728540555"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "19e8b8d9afe9811794f6c01cb459f9ab8c1339cc_350292356451_f942fd45-70e4-11ef-ab92-1e00e00049db",
                "commit_title": "test commit 2 ",
                "created_at": "1726131424",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1726131627",
                "changes": [
                    {
                        "id": "c88052f5-e867-4012-9a9e-d0e16571cf42",
                        "created_at": "1726131424",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeMRTAdministratorRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1726131627",
                        "staggers": [
                            {
                                "created_at": "1726131425",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1726131627"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "15be80db2922ca44af3343e44de784af0a9d9931",
                "commit_title": "testing gd ",
                "created_at": "1717667975",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1717668178",
                "changes": [
                    {
                        "id": "0120b966-1455-4463-8b6d-9721d4126325",
                        "created_at": "1717667975",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717668178",
                        "staggers": [
                            {
                                "created_at": "1717667977",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717668178"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "23d952c942d334fb140d4a2b5b62131f63bce93a",
                "commit_title": "Added permissions and role for PCSKSCPV2Role ",
                "created_at": "1703844544",
                "deployment_status": "COMPLETED",
                "developer_id": "svaradarajan@salesforce.com",
                "updated_at": "1703844741",
                "changes": [
                    {
                        "id": "bb30250f-fd8b-4d66-b405-0a438110946a",
                        "created_at": "1703844550",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1703844710",
                        "staggers": [
                            {
                                "created_at": "1703844552",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1703844710"
                            }
                        ]
                    },
                    {
                        "id": "f9c7ae5f-ec52-4402-bae6-20d5a21a7740",
                        "created_at": "1703844548",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1703844741",
                        "staggers": [
                            {
                                "created_at": "1703844550",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1703844741"
                            }
                        ]
                    },
                    {
                        "id": "764e4b5f-50d2-4245-9c6b-b1d5740ef048",
                        "created_at": "1703844544",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1703844709",
                        "staggers": [
                            {
                                "created_at": "1703844548",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1703844709"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a959553e6043d850e0987cd969df235a19208154",
                "commit_title": "Added permissions and role for PCSKSCPV2Role ",
                "created_at": "1702987803",
                "deployment_status": "COMPLETED",
                "developer_id": "svaradarajan@salesforce.com",
                "updated_at": "1702988003",
                "changes": [
                    {
                        "id": "4cbcc9b0-3a62-4aab-9ecc-03288fe4adfe",
                        "created_at": "1702987811",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702987971",
                        "staggers": [
                            {
                                "created_at": "1702987813",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702987971"
                            }
                        ]
                    },
                    {
                        "id": "4664252f-556b-4c86-a75a-fbfbc7bfd378",
                        "created_at": "1702987808",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702988003",
                        "staggers": [
                            {
                                "created_at": "1702987811",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702988003"
                            }
                        ]
                    },
                    {
                        "id": "035ccf5b-76c5-4084-90ae-4f5b80ae30fd",
                        "created_at": "1702987803",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702987971",
                        "staggers": [
                            {
                                "created_at": "1702987808",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702987971"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-b0414352-7324-11ee-bcb0-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698231198",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698231270",
                "changes": [
                    {
                        "id": "3cd1b582-d1fa-4fa2-a5a0-bc0161c7ac08",
                        "created_at": "1698231201",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698231270",
                        "staggers": [
                            {
                                "created_at": "1698231239",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698231270"
                            },
                            {
                                "created_at": "1698231203",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698231234"
                            }
                        ]
                    },
                    {
                        "id": "be32b385-b6f2-465d-bfb8-325877d20658",
                        "created_at": "1698231198",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698231234",
                        "staggers": [
                            {
                                "created_at": "1698231201",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698231234"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "648c222c94736f6f3fbb0b445647fc3154e7a37a",
                "commit_title": "test ",
                "created_at": "1731404114",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1731404720",
                "changes": [
                    {
                        "id": "f2a8a7e8-75c0-4a2f-b959-d6751978c705",
                        "created_at": "1731404114",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSensorsControlTelemetryRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731404720",
                        "staggers": [
                            {
                                "created_at": "1731404116",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1731404720"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "1d5b02bcdb7044c5f91f59261aba4fbcf461edac",
                "commit_title": "Retain pcskpermission boundary ",
                "created_at": "1721026099",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1721026518",
                "changes": [
                    {
                        "id": "182327e5-235a-4b59-a28e-9d67af118f47",
                        "created_at": "1721026111",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721026315",
                        "staggers": [
                            {
                                "created_at": "1721026112",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721026315"
                            }
                        ]
                    },
                    {
                        "id": "996ed11e-3f02-4409-8a6d-f603885567f6",
                        "created_at": "1721026099",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "FAILED",
                        "updated_at": "1721026518",
                        "staggers": [
                            {
                                "created_at": "1721026107",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721026518"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "446359832b19f51c96e13223cd1bd864be17426f",
                "commit_title": "test-new ",
                "created_at": "1695122978",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1695123399",
                "changes": [
                    {
                        "id": "04f5df2a-46eb-4ced-8c90-e41565d8c659",
                        "created_at": "1695122980",
                        "operation_type": "",
                        "stackset_id": "ScopeTestv2",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695123399",
                        "staggers": [
                            {
                                "created_at": "1695123197",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "ou",
                                "updated_at": "1695123399"
                            },
                            {
                                "created_at": "1695122981",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "dev account",
                                "updated_at": "1695123185"
                            }
                        ]
                    },
                    {
                        "id": "5f6d15fd-437d-4ec6-89b3-a100e93566fb",
                        "created_at": "1695122978",
                        "operation_type": "",
                        "stackset_id": "ScopeTestMpav2",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695123185",
                        "staggers": [
                            {
                                "created_at": "1695122980",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "MPAs",
                                "updated_at": "1695123185"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3e40873a3c5e7c02cf6b30abd886962646a41d2e",
                "commit_title": "Testing nf stackset ",
                "created_at": "1685091325",
                "deployment_status": "FAILED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1685091732",
                "changes": [
                    {
                        "id": "fa879145-01e5-4854-92a1-1e9ef6eb0805",
                        "created_at": "1685091343",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685091551",
                        "staggers": [
                            {
                                "created_at": "1685091346",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685091551"
                            }
                        ]
                    },
                    {
                        "id": "5b9c0a86-d8da-4b44-bed1-b08a56910f55",
                        "created_at": "1685091340",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685091551",
                        "staggers": [
                            {
                                "created_at": "1685091343",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage_group_1",
                                "updated_at": "1685091551"
                            }
                        ]
                    },
                    {
                        "id": "ca6ff5c9-8b4c-46f0-bb75-78056f2a3e98",
                        "created_at": "1685091338",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685091552",
                        "staggers": [
                            {
                                "created_at": "1685091340",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage_group_1",
                                "updated_at": "1685091552"
                            }
                        ]
                    },
                    {
                        "id": "e539873d-2e3b-4034-a7fd-3df83bceb77a",
                        "created_at": "1685091336",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685091551",
                        "staggers": [
                            {
                                "created_at": "1685091338",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685091551"
                            }
                        ]
                    },
                    {
                        "id": "ddb06295-6a24-408e-b8f0-89a05b5cfc88",
                        "created_at": "1685091334",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685091550",
                        "staggers": [
                            {
                                "created_at": "1685091336",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage_group_1",
                                "updated_at": "1685091550"
                            }
                        ]
                    },
                    {
                        "id": "9ba59b9b-0ae1-4679-b160-384202f10d35",
                        "created_at": "1685091332",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685091552",
                        "staggers": [
                            {
                                "created_at": "1685091334",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685091552"
                            }
                        ]
                    },
                    {
                        "id": "0e6880a3-3917-4eee-800b-8ebde43bd52e",
                        "created_at": "1685091329",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1685091732",
                        "staggers": [
                            {
                                "created_at": "1685091332",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685091732"
                            }
                        ]
                    },
                    {
                        "id": "4f635d26-aa23-460b-ac8a-5f7fb68e9c4a",
                        "created_at": "1685091327",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685091552",
                        "staggers": [
                            {
                                "created_at": "1685091329",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage_group_1",
                                "updated_at": "1685091552"
                            }
                        ]
                    },
                    {
                        "id": "aeca4c94-e966-431d-974e-7153e9b6a976",
                        "created_at": "1685091325",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685091551",
                        "staggers": [
                            {
                                "created_at": "1685091327",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685091551"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "e603725d36e76fa0a214ccfdad6e0af33315f495",
                "commit_title": "30 sec ",
                "created_at": "1683702166",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1683702855",
                "changes": [
                    {
                        "id": "f811b883-7705-4bfc-a08a-4d3df43fcf45",
                        "created_at": "1683702226",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683702636",
                        "staggers": [
                            {
                                "created_at": "1683702228",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683702636"
                            }
                        ]
                    },
                    {
                        "id": "ac11d551-4b70-469d-9078-e52a168627a5",
                        "created_at": "1683702224",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683702636",
                        "staggers": [
                            {
                                "created_at": "1683702226",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683702636"
                            }
                        ]
                    },
                    {
                        "id": "9985629e-91a0-4fa5-af08-e4851f8a2930",
                        "created_at": "1683702222",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683702637",
                        "staggers": [
                            {
                                "created_at": "1683702224",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683702637"
                            }
                        ]
                    },
                    {
                        "id": "87b1f360-9b9b-4165-b382-839a3841981b",
                        "created_at": "1683702220",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683702636",
                        "staggers": [
                            {
                                "created_at": "1683702222",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683702636"
                            }
                        ]
                    },
                    {
                        "id": "aec37dbc-4342-4b38-afcf-e20079619cdb",
                        "created_at": "1683702218",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683702635",
                        "staggers": [
                            {
                                "created_at": "1683702220",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683702635"
                            }
                        ]
                    },
                    {
                        "id": "6999f39a-5a05-4dcf-904e-9a858a833a99",
                        "created_at": "1683702217",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683702635",
                        "staggers": [
                            {
                                "created_at": "1683702218",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683702635"
                            }
                        ]
                    },
                    {
                        "id": "9e2d8116-8048-492e-bbc4-143c18f5c8d9",
                        "created_at": "1683702214",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683702635",
                        "staggers": [
                            {
                                "created_at": "1683702216",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683702635"
                            }
                        ]
                    },
                    {
                        "id": "1e6d3b2e-3f1f-4b85-b621-fa20ca8e6a82",
                        "created_at": "1683702208",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1683702855",
                        "staggers": [
                            {
                                "created_at": "1683702868",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683702868"
                            },
                            {
                                "created_at": "1683702653",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683702855"
                            },
                            {
                                "created_at": "1683702214",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683702636"
                            }
                        ]
                    },
                    {
                        "id": "2e7b1441-e38c-4f2b-824a-eebf35b09f7c",
                        "created_at": "1683702166",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683702634",
                        "staggers": [
                            {
                                "created_at": "1683702208",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683702634"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "e14a50782961541e7affa976fdb07f4d661a3f6d",
                "commit_title": "@W-16981727 Mulesoft PCD SSM start - Creating 2 separate stacksets  (#207) ",
                "created_at": "1730739312",
                "deployment_status": "FAILED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730739519",
                "changes": [
                    {
                        "id": "d9660f6b-cbb9-4a56-93bb-c6df027c7109",
                        "created_at": "1730739314",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeMulesoftPCDProdGA",
                        "stackset_status": "FAILED",
                        "updated_at": "1730739519",
                        "staggers": [
                            {
                                "created_at": "1730739315",
                                "operation_status": "FAILED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730739519"
                            }
                        ]
                    },
                    {
                        "id": "c48d289d-4c52-4723-8b70-008404ddf248",
                        "created_at": "1730739312",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeMulesoftPCDProd",
                        "stackset_status": "FAILED",
                        "updated_at": "1730739519",
                        "staggers": [
                            {
                                "created_at": "1730739314",
                                "operation_status": "FAILED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730739519"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "8adbf8c963b61aec19d6c1bd0d59fe7fbc22a293",
                "commit_title": "@W-16822703 | Allowlisting CVW accounts in PCSKDenyHostAccessPolicy ",
                "created_at": "1728360490",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1728360917",
                "changes": [
                    {
                        "id": "df0ba344-1f0f-4e1f-bd66-5bdfcf20e777",
                        "created_at": "1728360497",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728360702",
                        "staggers": [
                            {
                                "created_at": "1728360499",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728360702"
                            }
                        ]
                    },
                    {
                        "id": "ba32b6d4-f424-40e2-abeb-2b9c2d1420b9",
                        "created_at": "1728360494",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728360917",
                        "staggers": [
                            {
                                "created_at": "1728360716",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728360917"
                            },
                            {
                                "created_at": "1728360497",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1728360701"
                            }
                        ]
                    },
                    {
                        "id": "133f060f-c482-4d04-b187-803ce328b75b",
                        "created_at": "1728360490",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728360701",
                        "staggers": [
                            {
                                "created_at": "1728360494",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728360701"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e575cd7293d51d60f20c1869992d01fb1ef73cc2",
                "commit_title": "test ",
                "created_at": "1716966413",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1716967460",
                "changes": [
                    {
                        "id": "7ce9610f-fc3b-4b19-9ad8-85244757f9bb",
                        "created_at": "1716966417",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716966827",
                        "staggers": [
                            {
                                "created_at": "1716966424",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1716966827"
                            }
                        ]
                    },
                    {
                        "id": "8dc073c7-0b1b-4166-add7-5702d2f3c5fc",
                        "created_at": "1716966415",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716967460",
                        "staggers": [
                            {
                                "created_at": "1716967259",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 3",
                                "updated_at": "1716967460"
                            },
                            {
                                "created_at": "1716966931",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1716967132"
                            },
                            {
                                "created_at": "1716966417",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1716966827"
                            }
                        ]
                    },
                    {
                        "id": "2403af5b-1bbb-4f1a-bfb6-bc3884c5c13c",
                        "created_at": "1716966413",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716966827",
                        "staggers": [
                            {
                                "created_at": "1716966415",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1716966827"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-b83ae224-71a4-11ee-9522-acde48001122",
                "commit_title": "test commit - create scopeTestThird stackset and change group config and template for scopeTestFirst,scopeTestSecond stacksets",
                "created_at": "1698066297",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698066435",
                "changes": [
                    {
                        "id": "ecda0390-cc80-4471-a707-d417ba2e805b",
                        "created_at": "1698066305",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698066400",
                        "staggers": [
                            {
                                "created_at": "1698066306",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698066400"
                            }
                        ]
                    },
                    {
                        "id": "81c97561-cc98-47f8-96e4-00d2a7b35815",
                        "created_at": "1698066304",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698066368",
                        "staggers": [
                            {
                                "created_at": "1698066305",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698066368"
                            }
                        ]
                    },
                    {
                        "id": "ea5835a8-4409-4f8b-a498-c85608492206",
                        "created_at": "1698066303",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698066337",
                        "staggers": [
                            {
                                "created_at": "1698066304",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698066337"
                            }
                        ]
                    },
                    {
                        "id": "68a8a0cf-2801-499c-a612-fc70aea4fabc",
                        "created_at": "1698066302",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698066337",
                        "staggers": [
                            {
                                "created_at": "1698066303",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698066337"
                            }
                        ]
                    },
                    {
                        "id": "11d21f33-0f0c-4b5e-b5a3-ce3b7c6010bb",
                        "created_at": "1698066301",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698066400",
                        "staggers": [
                            {
                                "created_at": "1698066302",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698066400"
                            }
                        ]
                    },
                    {
                        "id": "8acf5318-00fd-4d49-9bea-ed2b82758d96",
                        "created_at": "1698066299",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698066369",
                        "staggers": [
                            {
                                "created_at": "1698066301",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "one account id",
                                "updated_at": "1698066369"
                            }
                        ]
                    },
                    {
                        "id": "4c674a5b-016b-4862-bc5a-3538b15ae0fd",
                        "created_at": "1698066297",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698066435",
                        "staggers": [
                            {
                                "created_at": "1698066404",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "second stagger group",
                                "updated_at": "1698066435"
                            },
                            {
                                "created_at": "1698066299",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698066400"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "c1d0c8f117afdbc470b0c21a5fe774e5852b963d",
                "commit_title": "updated-template ",
                "created_at": "1691761263",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1691761493",
                "changes": [
                    {
                        "id": "a9e54470-8cef-4742-af54-13afff4fda8b",
                        "created_at": "1691761284",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691761493",
                        "staggers": [
                            {
                                "created_at": "1691761287",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691761493"
                            }
                        ]
                    },
                    {
                        "id": "a2dca874-8947-4460-bb7d-5345c565276d",
                        "created_at": "1691761282",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691761493",
                        "staggers": [
                            {
                                "created_at": "1691761284",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691761493"
                            }
                        ]
                    },
                    {
                        "id": "5c8d62e8-d9fc-48f8-a9cb-bb85edce1eb8",
                        "created_at": "1691761280",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691761492",
                        "staggers": [
                            {
                                "created_at": "1691761282",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691761492"
                            }
                        ]
                    },
                    {
                        "id": "98d7e0e4-3d5d-4aae-81cd-e9b6c5d21f18",
                        "created_at": "1691761278",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691761492",
                        "staggers": [
                            {
                                "created_at": "1691761280",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691761492"
                            }
                        ]
                    },
                    {
                        "id": "1d1d6cec-7b6b-4b02-8704-6beea18bc875",
                        "created_at": "1691761274",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691761491",
                        "staggers": [
                            {
                                "created_at": "1691761278",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691761491"
                            }
                        ]
                    },
                    {
                        "id": "c45cbee4-048c-4512-aa42-128df08c0556",
                        "created_at": "1691761272",
                        "operation_type": "",
                        "stackset_id": "bhor-test",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691761491",
                        "staggers": [
                            {
                                "created_at": "1691761274",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "scope-ou",
                                "updated_at": "1691761491"
                            }
                        ]
                    },
                    {
                        "id": "f3ec1f21-e959-4235-ab9f-ca4c44cda2fa",
                        "created_at": "1691761270",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691761493",
                        "staggers": [
                            {
                                "created_at": "1691761272",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691761493"
                            }
                        ]
                    },
                    {
                        "id": "d6de9b49-3d18-4438-9d27-97d9b6f8cdf3",
                        "created_at": "1691761268",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691761492",
                        "staggers": [
                            {
                                "created_at": "1691761270",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691761492"
                            }
                        ]
                    },
                    {
                        "id": "76da7479-5495-441c-8c62-b24d77bae5b8",
                        "created_at": "1691761266",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691761493",
                        "staggers": [
                            {
                                "created_at": "1691761268",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691761493"
                            }
                        ]
                    },
                    {
                        "id": "1b51f859-235d-4d00-89ce-25acae75b561",
                        "created_at": "1691761263",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691761492",
                        "staggers": [
                            {
                                "created_at": "1691761266",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691761492"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "07e193ad1512855d2fe1125bdbc964038e6027eb",
                "commit_title": "@W-16167395 | Account cleanup step function fix to retain permission booundary (#179) ",
                "created_at": "1721848277",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1721849129",
                "changes": [
                    {
                        "id": "dd9d1ce9-6ac3-4245-912a-feaa7d210e33",
                        "created_at": "1721848286",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721848902",
                        "staggers": [
                            {
                                "created_at": "1721848293",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721848902"
                            }
                        ]
                    },
                    {
                        "id": "32894427-7d71-4922-847f-a34b75f5be58",
                        "created_at": "1721848284",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721848497",
                        "staggers": [
                            {
                                "created_at": "1721848286",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721848497"
                            }
                        ]
                    },
                    {
                        "id": "dcae957f-251f-49e4-ba69-3c45f49eedf3",
                        "created_at": "1721848282",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721849129",
                        "staggers": [
                            {
                                "created_at": "1721848923",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721849129"
                            },
                            {
                                "created_at": "1721848284",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1721848903"
                            }
                        ]
                    },
                    {
                        "id": "fd7f0a4f-2c4d-4d61-b28d-5134e162eb92",
                        "created_at": "1721848280",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721849128",
                        "staggers": [
                            {
                                "created_at": "1721848922",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721849128"
                            },
                            {
                                "created_at": "1721848282",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1721848902"
                            }
                        ]
                    },
                    {
                        "id": "35c0b7af-cdee-4f5e-927e-bb92ace4b3d0",
                        "created_at": "1721848277",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721849129",
                        "staggers": [
                            {
                                "created_at": "1721848926",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1721849129"
                            },
                            {
                                "created_at": "1721848280",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1721848903"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "5e4fdf7ef1d08a632a02bc03b7d36c020f0c349a",
                "commit_title": "parameterised-account-ids ",
                "created_at": "1709798377",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1709798797",
                "changes": [
                    {
                        "id": "02067f37-9d5b-4e26-b689-c0d2b51fa32c",
                        "created_at": "1709798378",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709798797",
                        "staggers": [
                            {
                                "created_at": "1709798596",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1709798797"
                            },
                            {
                                "created_at": "1709798380",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1709798583"
                            }
                        ]
                    },
                    {
                        "id": "9e9aecb2-804e-4390-b3d9-33a55aff273a",
                        "created_at": "1709798377",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeOUMovementTracker",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709798583",
                        "staggers": [
                            {
                                "created_at": "1709798378",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1709798583"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-6a301af4-74a1-11ee-a6ab-acde48001122",
                "commit_title": "test commit - delete stacksets created",
                "created_at": "1698394721",
                "deployment_status": "FAILED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698394721",
                "changes": [
                    {
                        "id": "a4795f38-29b2-4eeb-80f5-1b4f2312491e",
                        "created_at": "1698394722",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "FAILED",
                        "updated_at": "1698394925",
                        "staggers": [
                            {
                                "created_at": "1698394724",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698394925"
                            }
                        ]
                    },
                    {
                        "id": "f43a5e2e-22b4-415f-9cbf-1c6678181de4",
                        "created_at": "1698394721",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698394721",
                        "staggers": [
                            {
                                "created_at": "1698394722",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698394925"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "8e45ad5864af7ddca135933ba1e76bfcc7b512a1",
                "commit_title": "added-missing-param ",
                "created_at": "1691754216",
                "deployment_status": "FAILED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1691754216",
                "changes": [
                    {
                        "id": "c3d912e0-0e28-46ec-893f-3a2eb738bd72",
                        "created_at": "1691754229",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691754229",
                        "staggers": [
                            {
                                "created_at": "1691754231",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691754231"
                            }
                        ]
                    },
                    {
                        "id": "5c9d75f0-dfdd-47b4-a518-b0273ecdb996",
                        "created_at": "1691754216",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691754216",
                        "staggers": [
                            {
                                "created_at": "1691754218",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691754218"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "03a57577fe4f0a15b67573400d3a016de6700a4c",
                "commit_title": "test ",
                "created_at": "1728545114",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1728545723",
                "changes": [
                    {
                        "id": "3d8ca3c0-a62a-4f32-9ceb-6ecb9767b530",
                        "created_at": "1728545114",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728545723",
                        "staggers": [
                            {
                                "created_at": "1728545119",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728545723"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "9143488a71f260895437feb6968a877847ae84b2",
                "commit_title": "added comments ",
                "created_at": "1728364867",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1728365074",
                "changes": [
                    {
                        "id": "698cec62-e834-40ff-b9e5-f99172377c4f",
                        "created_at": "1728364867",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728365074",
                        "staggers": [
                            {
                                "created_at": "1728364872",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728365074"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "062ca6876eb3048bf302572f43e5a6637aa6781b",
                "commit_title": "@W-16758663 Creating custom role for Mulesoft BU and policy for SSM start ",
                "created_at": "1726594588",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1726594797",
                "changes": [
                    {
                        "id": "f9267a23-3d63-4bbd-9b5d-170c7c9bfe68",
                        "created_at": "1726594590",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1726594797",
                        "staggers": [
                            {
                                "created_at": "1726594594",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1726594797"
                            }
                        ]
                    },
                    {
                        "id": "94c2b4d7-cbf2-44ab-b03c-d34e1b2c645b",
                        "created_at": "1726594588",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1726594796",
                        "staggers": [
                            {
                                "created_at": "1726594590",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1726594796"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "261b2ee7362a4060e1e9910379ef182877dbe6b9",
                "commit_title": "@W-15804687 Adding billing permissions ",
                "created_at": "1721853545",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1721854160",
                "changes": [
                    {
                        "id": "af15e2ef-8090-42ba-84a8-47cdfb9c2b55",
                        "created_at": "1721853545",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721854160",
                        "staggers": [
                            {
                                "created_at": "1721853553",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721854160"
                            }
                        ]
                    },
                    {
                        "id": "3f21a1ad-ad4c-45bd-8767-7417b9fd3471",
                        "created_at": "1721852709",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721852915",
                        "staggers": [
                            {
                                "created_at": "1721852711",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721852915"
                            }
                        ]
                    },
                    {
                        "id": "8be7efcd-975d-47eb-ba2c-886c56577657",
                        "created_at": "1721838797",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721853240",
                        "staggers": [
                            {
                                "created_at": "1721853035",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721853240"
                            },
                            {
                                "created_at": "1721838798",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1721852916"
                            }
                        ]
                    },
                    {
                        "id": "a1a62b1b-f6e3-45ea-89a4-4c41ea74b09d",
                        "created_at": "1721838786",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721853240",
                        "staggers": [
                            {
                                "created_at": "1721853034",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1721853240"
                            },
                            {
                                "created_at": "1721838789",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1721852916"
                            }
                        ]
                    },
                    {
                        "id": "1c979042-8582-4d1c-a2e6-33c8634e1cde",
                        "created_at": "1721838782",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721853240",
                        "staggers": [
                            {
                                "created_at": "1721853036",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721853240"
                            },
                            {
                                "created_at": "1721838786",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1721852917"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "65e5f05bf664fed97b568b9e60e1c0647ed389fe",
                "commit_title": "DDos role updates ",
                "created_at": "1715768464",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1715768879",
                "changes": [
                    {
                        "id": "4988518f-f83b-4218-a35b-a5ed1ee193c1",
                        "created_at": "1715768464",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715768879",
                        "staggers": [
                            {
                                "created_at": "1715768472",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715768879"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b59bfc48810cd35f9eed024d04d7ec0891226c43",
                "commit_title": "@W-16496074 Addings tags for all IAM roles ",
                "created_at": "1723730446",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1723731632",
                "changes": [
                    {
                        "id": "b7994503-7ccc-4b21-97c8-711ab5c490d9",
                        "created_at": "1723730458",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1723730661",
                        "staggers": [
                            {
                                "created_at": "1723730460",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1723730661"
                            }
                        ]
                    },
                    {
                        "id": "6dc5e583-07aa-44d9-a93d-7b16047d42fc",
                        "created_at": "1723730456",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1723731632",
                        "staggers": [
                            {
                                "created_at": "1723731430",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1723731632"
                            },
                            {
                                "created_at": "1723730458",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1723731064"
                            }
                        ]
                    },
                    {
                        "id": "cd4b0891-66ef-4bac-92e8-bba0ccec001b",
                        "created_at": "1723730451",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1723731063",
                        "staggers": [
                            {
                                "created_at": "1723730456",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1723731063"
                            }
                        ]
                    },
                    {
                        "id": "9d315650-305d-405d-875f-9be6c97f1383",
                        "created_at": "1723730449",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1723730661",
                        "staggers": [
                            {
                                "created_at": "1723730451",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1723730661"
                            }
                        ]
                    },
                    {
                        "id": "a5d3da47-be64-46e8-86fa-b3c66adabf31",
                        "created_at": "1723730446",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1723731063",
                        "staggers": [
                            {
                                "created_at": "1723730449",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1723731063"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b82242ec5e0a416a0568bb18029ba85698991405",
                "commit_title": "DDos role updates ",
                "created_at": "1715289103",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1715289103",
                "changes": [
                    {
                        "id": "87e76908-7e3b-4fe7-93bc-0d4784e1c9a7",
                        "created_at": "1715289103",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715289521",
                        "staggers": [
                            {
                                "created_at": "1715289111",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715289521"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "736623c32096196d4d58c01eda3d14d1a6c10f71",
                "commit_title": "Merge branch 'master' into IssueFixMergedMaster ",
                "created_at": "1708931687",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1708931891",
                "changes": [
                    {
                        "id": "b1e27ddb-326c-4e0d-81b7-8ced85643ce8",
                        "created_at": "1708931687",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1708931891",
                        "staggers": [
                            {
                                "created_at": "1708931688",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1708931891"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "8a9fb31ce50b841fd8b6e943ba5c93cdbc61d428",
                "commit_title": "c3 ",
                "created_at": "1689600660",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1689600890",
                "changes": [
                    {
                        "id": "4fc58234-746b-407b-be15-c5d3c2a8e3b4",
                        "created_at": "1689600682",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689600889",
                        "staggers": [
                            {
                                "created_at": "1689600684",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689600889"
                            }
                        ]
                    },
                    {
                        "id": "3b06f999-122e-490d-8a24-042ba6331cdf",
                        "created_at": "1689600680",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689600889",
                        "staggers": [
                            {
                                "created_at": "1689600682",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689600889"
                            }
                        ]
                    },
                    {
                        "id": "be54a8ba-2220-4c30-ab87-90882ee39e37",
                        "created_at": "1689600676",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689600888",
                        "staggers": [
                            {
                                "created_at": "1689600680",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689600888"
                            }
                        ]
                    },
                    {
                        "id": "6a934c9c-b5a7-4cf0-b93e-87d34b2daa65",
                        "created_at": "1689600674",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689600889",
                        "staggers": [
                            {
                                "created_at": "1689600676",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689600889"
                            }
                        ]
                    },
                    {
                        "id": "f9f9b7c2-5f3d-4e63-8142-460068cabc48",
                        "created_at": "1689600670",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689600888",
                        "staggers": [
                            {
                                "created_at": "1689600674",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689600888"
                            }
                        ]
                    },
                    {
                        "id": "0db1b2da-c26b-4851-91bf-e54e4bc3f56e",
                        "created_at": "1689600668",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689600888",
                        "staggers": [
                            {
                                "created_at": "1689600670",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689600888"
                            }
                        ]
                    },
                    {
                        "id": "48b22365-0080-48a2-ab35-8eb4e2e82dbb",
                        "created_at": "1689600666",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689600890",
                        "staggers": [
                            {
                                "created_at": "1689600668",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689600889"
                            }
                        ]
                    },
                    {
                        "id": "80828d56-2c4c-48d0-8a9b-591186816cdc",
                        "created_at": "1689600664",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689600890",
                        "staggers": [
                            {
                                "created_at": "1689600666",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689600890"
                            }
                        ]
                    },
                    {
                        "id": "f43155eb-0d38-4c2a-a802-09d9acbad9e0",
                        "created_at": "1689600660",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689600890",
                        "staggers": [
                            {
                                "created_at": "1689600664",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689600890"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "bd2902b1895fab7ea95b3dc73bf6079b78061d17",
                "commit_title": "- new test role ",
                "created_at": "1677668664",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1677668902",
                "changes": [
                    {
                        "id": "3ea715bd-d7a2-4ec1-8b6d-c7048f67a6a4",
                        "created_at": "1677668669",
                        "operation_type": "",
                        "stackset_id": "TestStacksetFalcon1",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1677668902",
                        "staggers": [
                            {
                                "created_at": "1677668674",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage",
                                "updated_at": "1677668776"
                            }
                        ]
                    },
                    {
                        "id": "6aa083db-7f5c-44b7-82bd-19c868d4cf19",
                        "created_at": "1677668664",
                        "operation_type": "",
                        "stackset_id": "TestStacksetFalcon2",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1677668902",
                        "staggers": [
                            {
                                "created_at": "1677668669",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage",
                                "updated_at": "1677668776"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "64d9800cedbbbf82d033c211e9e5f7d280ca8cee",
                "commit_title": "testing ",
                "created_at": "1731423335",
                "deployment_status": "FAILED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731423541",
                "changes": [
                    {
                        "id": "2f6293d6-baad-427c-a2b9-9da1c6c9f067",
                        "created_at": "1731423335",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1731423541",
                        "staggers": [
                            {
                                "created_at": "1731423339",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731423541"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "6336e5b38fed3ede9c8c7d4f8f5d996ad6d387cb",
                "commit_title": "Updating syntax ",
                "created_at": "1730146131",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730146335",
                "changes": [
                    {
                        "id": "be83432f-e4a0-4a52-9a59-e3777c16becc",
                        "created_at": "1730146131",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730146335",
                        "staggers": [
                            {
                                "created_at": "1730146133",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730146335"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "5f7ba86a8618048d361e925a311cbab8e63f2aa3",
                "commit_title": "Update script ",
                "created_at": "1717666682",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1717667199",
                "changes": [
                    {
                        "id": "b6c10ac1-de2c-46a5-ba9f-6a2f95cc2934",
                        "created_at": "1717666684",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717666886",
                        "staggers": [
                            {
                                "created_at": "1717666685",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717666886"
                            }
                        ]
                    },
                    {
                        "id": "3d341a01-3520-42b2-9303-fbc46b3b59e7",
                        "created_at": "1717666682",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717667199",
                        "staggers": [
                            {
                                "created_at": "1717666998",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1717667199"
                            },
                            {
                                "created_at": "1717666684",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717666886"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d9d41d0709e1c8937a63f3ee6caf2e85c9fdc843",
                "commit_title": "testing ",
                "created_at": "1731333938",
                "deployment_status": "FAILED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731334363",
                "changes": [
                    {
                        "id": "9181c6ac-98b4-4fb3-bc85-9b2fe9b17f10",
                        "created_at": "1731333958",
                        "operation_type": "UPDATE",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731334365",
                        "staggers": [
                            {
                                "created_at": "1731333959",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731334365"
                            }
                        ]
                    },
                    {
                        "id": "3d6a441e-6de5-48b8-afa1-93909a5389a4",
                        "created_at": "1731333956",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731334161",
                        "staggers": [
                            {
                                "created_at": "1731333958",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731334161"
                            }
                        ]
                    },
                    {
                        "id": "3224fcdf-5544-484d-a01d-9377340330f3",
                        "created_at": "1731333954",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731334161",
                        "staggers": [
                            {
                                "created_at": "1731333956",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1731334161"
                            }
                        ]
                    },
                    {
                        "id": "5887da3a-f0e0-4bbb-aad8-9d88f5b065bc",
                        "created_at": "1731333952",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731334364",
                        "staggers": [
                            {
                                "created_at": "1731333954",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731334364"
                            }
                        ]
                    },
                    {
                        "id": "35546398-bd71-4af4-943f-2ff3923ae0b6",
                        "created_at": "1731333950",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "IN_PROGRESS",
                        "updated_at": "1731333950",
                        "staggers": [
                            {
                                "created_at": "1731333952",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1731334363"
                            }
                        ]
                    },
                    {
                        "id": "e14897e1-f659-4b82-95a9-e2e930a0472e",
                        "created_at": "1731333948",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "IN_PROGRESS",
                        "updated_at": "1731333948",
                        "staggers": [
                            {
                                "created_at": "1731333950",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1731334363"
                            }
                        ]
                    },
                    {
                        "id": "94c79f15-1906-4a20-b48a-fd4b78941724",
                        "created_at": "1731333946",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731334365",
                        "staggers": [
                            {
                                "created_at": "1731333948",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731334365"
                            }
                        ]
                    },
                    {
                        "id": "13c7db51-8b7f-4edd-b022-ce78556e1d23",
                        "created_at": "1731333942",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731334364",
                        "staggers": [
                            {
                                "created_at": "1731333946",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731334364"
                            }
                        ]
                    },
                    {
                        "id": "1957f619-ff22-4282-a246-2d23d1fd3e3e",
                        "created_at": "1731333938",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1731334363",
                        "staggers": [
                            {
                                "created_at": "1731333942",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731334363"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "c4dd5ca3beb148c3b61473cd04c4e196808d620e",
                "commit_title": "Updating with self accountId ",
                "created_at": "1729788073",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1729788277",
                "changes": [
                    {
                        "id": "70ddfd90-0be9-4c79-acff-d390d47f7e52",
                        "created_at": "1729788073",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729788277",
                        "staggers": [
                            {
                                "created_at": "1729788075",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1729788277"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "ce5bab419ff6dede48da39c74c9be3c94dc73364",
                "commit_title": "updated-rn ",
                "created_at": "1715758437",
                "deployment_status": "FAILED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715758852",
                "changes": [
                    {
                        "id": "a8fda5e4-9561-4f19-8cfa-e9283c984574",
                        "created_at": "1715758445",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSCPSelfManagedTest",
                        "stackset_status": "FAILED",
                        "updated_at": "1715758852",
                        "staggers": [
                            {
                                "created_at": "1715758447",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715758852"
                            }
                        ]
                    },
                    {
                        "id": "2123473e-45b5-466a-b8d6-2440c6cd7930",
                        "created_at": "1715758437",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715758852",
                        "staggers": [
                            {
                                "created_at": "1715758444",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715758852"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "df5a5303c8875d33c1273241b9f0ae2ab1c65330",
                "commit_title": "DDos role update ",
                "created_at": "1715702636",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1715703048",
                "changes": [
                    {
                        "id": "5cda4d95-d72e-48a7-8fe4-d3c7e281e8fd",
                        "created_at": "1715702636",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715703048",
                        "staggers": [
                            {
                                "created_at": "1715702643",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715703048"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "c554ff73fb2881778d96bfd59f0b62ac20503c0e",
                "commit_title": "@W-14745198 | Removed Private Marketplace permissions from PCSK roles in Non-falcon accounts ",
                "created_at": "1704883020",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1704883231",
                "changes": [
                    {
                        "id": "c272c4c6-7336-4982-97cf-430fd66312ca",
                        "created_at": "1704883022",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704883231",
                        "staggers": [
                            {
                                "created_at": "1704883027",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1704883231"
                            }
                        ]
                    },
                    {
                        "id": "4ac534b6-d7c8-4e28-8b39-2d44d9af8ab0",
                        "created_at": "1704883020",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704883231",
                        "staggers": [
                            {
                                "created_at": "1704883022",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1704883231"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-1ea9b3d8-732a-11ee-860f-acde48001122",
                "commit_title": "test commit - remove all stacksets from grouping, and mark as delete in payload",
                "created_at": "1698233533",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698233598",
                "changes": [
                    {
                        "id": "f3df8b5c-70af-4149-a339-46c4e9eae124",
                        "created_at": "1698233534",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698233598",
                        "staggers": [
                            {
                                "created_at": "1698233536",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698233597"
                            }
                        ]
                    },
                    {
                        "id": "042c8bdc-f6fe-4265-a844-4fc227d3914d",
                        "created_at": "1698233533",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698233598",
                        "staggers": [
                            {
                                "created_at": "1698233534",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698233598"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f4e94648d02acf0670b50f16064f83b781678dae",
                "commit_title": "added-prisma-role-falcon ",
                "created_at": "1716447609",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1716448227",
                "changes": [
                    {
                        "id": "8962b7f1-aace-4972-b5c5-1fd0dd171508",
                        "created_at": "1716447618",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716448227",
                        "staggers": [
                            {
                                "created_at": "1716447620",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1716448227"
                            }
                        ]
                    },
                    {
                        "id": "507652f2-c0f5-411f-bb61-7c114e7ac992",
                        "created_at": "1716447609",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716448227",
                        "staggers": [
                            {
                                "created_at": "1716447618",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1716448227"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b9a9d75222909f5573c7da9c2700aeebe05955aa",
                "commit_title": "test-withouh-conditions ",
                "created_at": "1716374361",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1716374773",
                "changes": [
                    {
                        "id": "81b80cc5-746f-46e4-a404-998a803e2681",
                        "created_at": "1716374363",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716374773",
                        "staggers": [
                            {
                                "created_at": "1716374369",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1716374773"
                            }
                        ]
                    },
                    {
                        "id": "a86bdc61-4e83-4906-bb85-eb407b8da386",
                        "created_at": "1716374361",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716374572",
                        "staggers": [
                            {
                                "created_at": "1716374363",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1716374572"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "81dc1efcb6afc6f7eeee2cc8e855156d0e472183",
                "commit_title": "fix parameter key ",
                "created_at": "1709300838",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1709301142",
                "changes": [
                    {
                        "id": "278cc71d-7333-4e46-b0ef-6471228bbed7",
                        "created_at": "1709300838",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRoles",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1709301143",
                        "staggers": [
                            {
                                "created_at": "1709300840",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1709301043"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "c6160e4ef31621933964035681aa3aa6be702940",
                "commit_title": "Updated the parameter ",
                "created_at": "1679507551",
                "deployment_status": "COMPLETED",
                "developer_id": "jbhayani@salesforce.com",
                "updated_at": "1679507953",
                "changes": [
                    {
                        "id": "4f3d6eee-6b24-48e7-b252-e828ed12c36f",
                        "created_at": "1679507551",
                        "operation_type": "",
                        "stackset_id": "TestStacksetFalcon1",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1679507953",
                        "staggers": [
                            {
                                "created_at": "1679507750",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679507953"
                            },
                            {
                                "created_at": "1679507557",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "dev",
                                "updated_at": "1679507659"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "53442fe364372a905eda451d798336ad0933efab",
                "commit_title": "@W-15804687 Adding bcm data export permissions (#181) ",
                "created_at": "1725268596",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1725268596",
                "changes": [
                    {
                        "id": "2356325d-d71c-4c74-9584-9c882f9d9e69",
                        "created_at": "1725268608",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1725268816",
                        "staggers": [
                            {
                                "created_at": "1725268610",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1725268816"
                            }
                        ]
                    },
                    {
                        "id": "6d5f7834-5c88-4b06-bcfe-6179d674b1fb",
                        "created_at": "1725268604",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1725269220",
                        "staggers": [
                            {
                                "created_at": "1725268608",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1725269220"
                            }
                        ]
                    },
                    {
                        "id": "1af9244f-0018-4e21-9b67-72290ce8bedc",
                        "created_at": "1725268598",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1725269220",
                        "staggers": [
                            {
                                "created_at": "1725268604",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1725269220"
                            }
                        ]
                    },
                    {
                        "id": "e9b0f170-bdbe-4b38-9ca7-71effc792b37",
                        "created_at": "1725268596",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1725269220",
                        "staggers": [
                            {
                                "created_at": "1725268598",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1725269220"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "c40f27daa978a51a44a23f34382662147ec90304",
                "commit_title": "Added permissions and role for PCSKSCPV2Role ",
                "created_at": "1704965943",
                "deployment_status": "COMPLETED",
                "developer_id": "svaradarajan@salesforce.com",
                "updated_at": "1704966165",
                "changes": [
                    {
                        "id": "9f5e3067-bff7-4487-8a97-c01bab4d9db1",
                        "created_at": "1704965950",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704966165",
                        "staggers": [
                            {
                                "created_at": "1704965962",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1704966165"
                            }
                        ]
                    },
                    {
                        "id": "f673dab6-f087-4b36-a383-26ed05c0b660",
                        "created_at": "1704965948",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704966165",
                        "staggers": [
                            {
                                "created_at": "1704965950",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1704966165"
                            }
                        ]
                    },
                    {
                        "id": "98a7c781-391f-455b-991c-56ec8a59f386",
                        "created_at": "1704965943",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704966164",
                        "staggers": [
                            {
                                "created_at": "1704965948",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1704966164"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "fcbdb76d36cb2d5aa9447f00d6cbb3bfa247d4a4",
                "commit_title": "@W-14143629 | Update trust relationships for SAI roles ",
                "created_at": "1696839847",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1696840059",
                "changes": [
                    {
                        "id": "19e4b4ef-8c47-4751-8d34-3e6ee3cee5d1",
                        "created_at": "1696839853",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696840058",
                        "staggers": [
                            {
                                "created_at": "1696839855",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696840058"
                            }
                        ]
                    },
                    {
                        "id": "bca4cb9d-3703-4ad8-9b86-62773249aa81",
                        "created_at": "1696839851",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696840058",
                        "staggers": [
                            {
                                "created_at": "1696839853",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696840058"
                            }
                        ]
                    },
                    {
                        "id": "5251a376-38e9-48ae-b99d-343a5b52385b",
                        "created_at": "1696839847",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696840059",
                        "staggers": [
                            {
                                "created_at": "1696839851",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696840059"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4a01c1e0f5f57cc26dd58e868a1cc7197f1ab8b0",
                "commit_title": "retrying after rollback ",
                "created_at": "1730135982",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730136186",
                "changes": [
                    {
                        "id": "6f021701-1120-41f4-85b9-2adcd42c8de6",
                        "created_at": "1730135982",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730136186",
                        "staggers": [
                            {
                                "created_at": "1730135984",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730136186"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "1d782923e5195eb08fb6433baef3ee6aaaffd82f",
                "commit_title": "added-stagger-config ",
                "created_at": "1709557279",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1709557483",
                "changes": [
                    {
                        "id": "76e3d0f5-8b3e-40b2-b915-23cf7eee8822",
                        "created_at": "1709557279",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeOUMovementTracker",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709557483",
                        "staggers": [
                            {
                                "created_at": "1709557281",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1709557483"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-36d8c9c8-74b3-11ee-bed9-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698402371",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698402783",
                "changes": [
                    {
                        "id": "5a8cb478-a6a4-4bf2-b867-ef4a7d3f000a",
                        "created_at": "1698402374",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698402783",
                        "staggers": [
                            {
                                "created_at": "1698402582",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698402783"
                            },
                            {
                                "created_at": "1698402376",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698402577"
                            }
                        ]
                    },
                    {
                        "id": "f3c9285b-a95b-4fa0-bbaa-109c02f98e65",
                        "created_at": "1698402371",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698402577",
                        "staggers": [
                            {
                                "created_at": "1698402374",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698402577"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "9a371a993c74ad8c403d7695c2cb8d24365abcb4",
                "commit_title": "testing ",
                "created_at": "1731520635",
                "deployment_status": "COMPLETED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731520839",
                "changes": [
                    {
                        "id": "a7afbef7-92fa-4841-9e66-1ed2c8f4b185",
                        "created_at": "1731520635",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731520839",
                        "staggers": [
                            {
                                "created_at": "1731520638",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731520839"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "342b9a3683e98399eab3cefc763baf8232d2a47c",
                "commit_title": "remove the unused parameter ",
                "created_at": "1731099614",
                "deployment_status": "COMPLETED",
                "developer_id": "hzhao@salesforce.com",
                "updated_at": "1731619447",
                "changes": [
                    {
                        "id": "6c409e5f-13f4-43d8-80b6-44a5370b9d3b",
                        "created_at": "1731618609",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731618815",
                        "staggers": [
                            {
                                "created_at": "1731618611",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731618815"
                            }
                        ]
                    },
                    {
                        "id": "51f380d8-a18a-4f36-aaab-9e97ff854dae",
                        "created_at": "1731618607",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731618814",
                        "staggers": [
                            {
                                "created_at": "1731618609",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731618814"
                            }
                        ]
                    },
                    {
                        "id": "81e32105-b52e-437a-a980-a32305f03a8a",
                        "created_at": "1731618605",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731618814",
                        "staggers": [
                            {
                                "created_at": "1731618607",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731618814"
                            }
                        ]
                    },
                    {
                        "id": "b69f417f-9e87-4f80-a444-d8be3064c9dc",
                        "created_at": "1731618603",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731619447",
                        "staggers": [
                            {
                                "created_at": "1731619244",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731619447"
                            },
                            {
                                "created_at": "1731618605",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1731618815"
                            }
                        ]
                    },
                    {
                        "id": "8e5d5d9a-fb63-4d0d-8bb8-a6ee3afd0850",
                        "created_at": "1731618601",
                        "operation_type": "UPDATE",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731618813",
                        "staggers": [
                            {
                                "created_at": "1731618603",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731618813"
                            }
                        ]
                    },
                    {
                        "id": "47ae9354-0a3b-48c7-8a58-6b74dc8f31ab",
                        "created_at": "1731618599",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731619447",
                        "staggers": [
                            {
                                "created_at": "1731619245",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731619447"
                            },
                            {
                                "created_at": "1731618601",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1731618815"
                            }
                        ]
                    },
                    {
                        "id": "a91281ce-197d-4927-9678-06432892c459",
                        "created_at": "1731618595",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731618814",
                        "staggers": [
                            {
                                "created_at": "1731618599",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731618814"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "78f18a615e2e2cf565a15ea83ba7d10bf3be5bd8",
                "commit_title": "testing one permission at a time ",
                "created_at": "1729870146",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1729870349",
                "changes": [
                    {
                        "id": "11e3c188-ab87-4281-ac7a-15569d308313",
                        "created_at": "1729870146",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729870349",
                        "staggers": [
                            {
                                "created_at": "1729870148",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1729870349"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "457e5d58da3e2fbc0dded4b62363de0a3720d0fd",
                "commit_title": "- Added hardcoded Configs ",
                "created_at": "1727941750",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1727941956",
                "changes": [
                    {
                        "id": "794abe74-ad0b-44c4-bfdf-fb81380e5926",
                        "created_at": "1727941750",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTentacleRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727941956",
                        "staggers": [
                            {
                                "created_at": "1727941752",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1727941956"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "10dbc1173710650ac5aa7a2fadb8bf9e3f4d92b5",
                "commit_title": "fixed-template-issue ",
                "created_at": "1720161886",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1720162090",
                "changes": [
                    {
                        "id": "e661cdb5-58d7-46c6-be9c-99418b2d2993",
                        "created_at": "1720161886",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeFalconAsNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1720162090",
                        "staggers": [
                            {
                                "created_at": "1720161888",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1720162090"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3f005e7e581be517419f343c073e7cd859287cf6",
                "commit_title": "Added permissions and role for PCSKSCPV2Role ",
                "created_at": "1702918320",
                "deployment_status": "COMPLETED",
                "developer_id": "svaradarajan@salesforce.com",
                "updated_at": "1702918394",
                "changes": [
                    {
                        "id": "cbdeffd2-e8fe-451b-af59-d9f5c85df3d9",
                        "created_at": "1702918325",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702918361",
                        "staggers": [
                            {
                                "created_at": "1702918327",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702918361"
                            }
                        ]
                    },
                    {
                        "id": "d6594416-23bf-4bb0-9f5d-304150ac0243",
                        "created_at": "1702918320",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702918394",
                        "staggers": [
                            {
                                "created_at": "1702918325",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702918394"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "ea1f5ee9f415042c69839b514fc8017c9633a632",
                "commit_title": "test commit ",
                "created_at": "1677667301",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1677667793",
                "changes": [
                    {
                        "id": "582776b4-2f7c-4127-b3fd-b81ac8a1ed44",
                        "created_at": "1677667301",
                        "operation_type": "",
                        "stackset_id": "TestStacksetFalcon1",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1677667793",
                        "staggers": [
                            {
                                "created_at": "1677667489",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1677667793"
                            },
                            {
                                "created_at": "1677667306",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage",
                                "updated_at": "1677667409"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "562ce3b5dbdc6968c479486a8c2941e26f15bfdc",
                "commit_title": "Updating indent ",
                "created_at": "1730145093",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730145296",
                "changes": [
                    {
                        "id": "e37702e1-de33-4048-b6fd-e3f107296140",
                        "created_at": "1730145093",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730145296",
                        "staggers": [
                            {
                                "created_at": "1730145095",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730145296"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "9ecf7d9588fdb8d82bcc050f46b47acb8d2695e1",
                "commit_title": "updating conditions ",
                "created_at": "1730143730",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730143934",
                "changes": [
                    {
                        "id": "7482780e-606b-45d2-bc36-219a36d9676d",
                        "created_at": "1730143730",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730143934",
                        "staggers": [
                            {
                                "created_at": "1730143732",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730143934"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "10c66a6d2348eb79e3117e1afe86fb83e1c57e58",
                "commit_title": "added-wiz-role-in-mpa ",
                "created_at": "1704881010",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1704965943",
                "changes": [
                    {
                        "id": "e7c7123b-7103-4ef1-a142-9e85ff6908b5",
                        "created_at": "1704881012",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeWizRole",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1704965943",
                        "staggers": null
                    },
                    {
                        "id": "c2fb1002-f1f6-4b00-b550-a1087337c316",
                        "created_at": "1704881010",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1704965943",
                        "staggers": [
                            {
                                "created_at": "1704881012",
                                "operation_status": "OVERRIDDEN",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1704965943"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "test-b6fc6cf0-7192-11ee-a862-acde48001122",
                "commit_title": "test commit - remove all stacksets from grouping, and mark as delete in payload",
                "created_at": "1698058566",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698058635",
                "changes": [
                    {
                        "id": "a04313c6-d804-4319-aaef-560ca3b7674e",
                        "created_at": "1698058568",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698058635",
                        "staggers": [
                            {
                                "created_at": "1698058568",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698058634"
                            }
                        ]
                    },
                    {
                        "id": "8438aee8-46a1-4025-aff8-7fc9e34a4305",
                        "created_at": "1698058568",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698058634",
                        "staggers": [
                            {
                                "created_at": "1698058570",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698058633"
                            }
                        ]
                    },
                    {
                        "id": "cd3bcfcd-e4a2-4194-af6e-593a62c5f33f",
                        "created_at": "1698058566",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698058633",
                        "staggers": [
                            {
                                "created_at": "1698058568",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698058633"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a8755edcf3d1dc63308228093c6f2774197201c6",
                "commit_title": "t1 ",
                "created_at": "1689601339",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1689601567",
                "changes": [
                    {
                        "id": "a9ed500a-3c67-435a-8083-9a49256ff858",
                        "created_at": "1689601359",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689601565",
                        "staggers": [
                            {
                                "created_at": "1689601360",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689601565"
                            }
                        ]
                    },
                    {
                        "id": "a97c0c92-1185-40a5-827b-b501438403ab",
                        "created_at": "1689601357",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689601565",
                        "staggers": [
                            {
                                "created_at": "1689601359",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689601565"
                            }
                        ]
                    },
                    {
                        "id": "46844866-ebce-4bed-ad8c-1b13129a240c",
                        "created_at": "1689601353",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689601565",
                        "staggers": [
                            {
                                "created_at": "1689601357",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689601565"
                            }
                        ]
                    },
                    {
                        "id": "c0be6c65-555d-4062-818f-ce96632793c7",
                        "created_at": "1689601351",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689601566",
                        "staggers": [
                            {
                                "created_at": "1689601353",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689601566"
                            }
                        ]
                    },
                    {
                        "id": "6f202f99-6080-461d-95f7-c7d7d47162da",
                        "created_at": "1689601349",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689601564",
                        "staggers": [
                            {
                                "created_at": "1689601351",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689601564"
                            }
                        ]
                    },
                    {
                        "id": "7b40d59b-bc48-48bb-b055-02537b9fde84",
                        "created_at": "1689601347",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689601565",
                        "staggers": [
                            {
                                "created_at": "1689601349",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689601565"
                            }
                        ]
                    },
                    {
                        "id": "5bbd7adb-f8fe-44db-a919-7dd46e61992e",
                        "created_at": "1689601345",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689601566",
                        "staggers": [
                            {
                                "created_at": "1689601347",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689601566"
                            }
                        ]
                    },
                    {
                        "id": "c27be480-0cc4-46d1-96fa-7e30d1153ea9",
                        "created_at": "1689601341",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689601567",
                        "staggers": [
                            {
                                "created_at": "1689601344",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689601567"
                            }
                        ]
                    },
                    {
                        "id": "5443584e-79c8-4998-9e05-41c6825623e8",
                        "created_at": "1689601339",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689601566",
                        "staggers": [
                            {
                                "created_at": "1689601341",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689601566"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f77efade87a4764d2e0ac8a88114dd24e43ea933",
                "commit_title": "Adding / to path ",
                "created_at": "1731089170",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1731089373",
                "changes": [
                    {
                        "id": "ac031af1-9816-47e9-9112-c8f061b60448",
                        "created_at": "1731089170",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731089373",
                        "staggers": [
                            {
                                "created_at": "1731089171",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1731089373"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "0f12a9edf4f887c1f9a6df235560fc61492e0525",
                "commit_title": "removing env ",
                "created_at": "1729870624",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1729870827",
                "changes": [
                    {
                        "id": "4edd0a0e-975c-4969-aead-1205f267d1fd",
                        "created_at": "1729870624",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729870827",
                        "staggers": [
                            {
                                "created_at": "1729870625",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1729870827"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "8214cab21456dbeb1954fdfd49acfb590fcaeebc",
                "commit_title": "@W-15755612 | Added permissions for ou-automation DELETE and UPDATE APIs ",
                "created_at": "1718603492",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1718604327",
                "changes": [
                    {
                        "id": "513f6697-6e11-49df-b2e8-0b3097f1fe66",
                        "created_at": "1718603502",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1718604111",
                        "staggers": [
                            {
                                "created_at": "1718603504",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1718604111"
                            }
                        ]
                    },
                    {
                        "id": "9817eade-9412-4e0a-87e9-0a3cd46a8722",
                        "created_at": "1718603500",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1718604327",
                        "staggers": [
                            {
                                "created_at": "1718604126",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1718604327"
                            },
                            {
                                "created_at": "1718603502",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1718604110"
                            }
                        ]
                    },
                    {
                        "id": "ae045aec-d15b-43a2-bd5a-fdff8256de4e",
                        "created_at": "1718603499",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1718604111",
                        "staggers": [
                            {
                                "created_at": "1718603500",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1718604111"
                            }
                        ]
                    },
                    {
                        "id": "23224952-af7b-4370-a865-104bf231a78f",
                        "created_at": "1718603492",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1718604111",
                        "staggers": [
                            {
                                "created_at": "1718603499",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1718604111"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b9d44d093912f81beafb5d67ff40ccfa9d9d63e8",
                "commit_title": "test ",
                "created_at": "1718183882",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1718184374",
                "changes": [
                    {
                        "id": "d0c1f7e6-10ed-4b6a-957a-621903ae0436",
                        "created_at": "1718183882",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1718184374",
                        "staggers": [
                            {
                                "created_at": "1718184173",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1718184374"
                            },
                            {
                                "created_at": "1718183888",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1718184089"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a577492f34c2a115e652b4764d3da4e6a87f9eef",
                "commit_title": "fixed some conditions ",
                "created_at": "1732236170",
                "deployment_status": "COMPLETED",
                "developer_id": "hzhao@salesforce.com",
                "updated_at": "1732236856",
                "changes": [
                    {
                        "id": "290e5330-fd8e-4971-82b2-ad6aa9659772",
                        "created_at": "1732236185",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1732236591",
                        "staggers": [
                            {
                                "created_at": "1732236186",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1732236591"
                            }
                        ]
                    },
                    {
                        "id": "28224a74-75da-4c9a-a273-81ba184a2965",
                        "created_at": "1732236183",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1732236856",
                        "staggers": [
                            {
                                "created_at": "1732236654",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1732236856"
                            },
                            {
                                "created_at": "1732236185",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1732236590"
                            }
                        ]
                    },
                    {
                        "id": "5eb01646-d56e-41be-82fc-96e3e0eed7f7",
                        "created_at": "1732236178",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1732236591",
                        "staggers": [
                            {
                                "created_at": "1732236183",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1732236591"
                            }
                        ]
                    },
                    {
                        "id": "362ced93-46df-4aa4-9bc5-a502ab1202c6",
                        "created_at": "1732236177",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1732236592",
                        "staggers": [
                            {
                                "created_at": "1732236178",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1732236592"
                            }
                        ]
                    },
                    {
                        "id": "d7461da3-0864-47d6-bd1c-cf25b0afa8a5",
                        "created_at": "1732236175",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1732236856",
                        "staggers": [
                            {
                                "created_at": "1732236655",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1732236856"
                            },
                            {
                                "created_at": "1732236177",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1732236591"
                            }
                        ]
                    },
                    {
                        "id": "7f0d7b6f-11b6-4d03-aee8-aa4e3ef62233",
                        "created_at": "1732236173",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1732236591",
                        "staggers": [
                            {
                                "created_at": "1732236175",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1732236591"
                            }
                        ]
                    },
                    {
                        "id": "e413ae41-9af1-46ea-af03-2ff08b27d492",
                        "created_at": "1732236171",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSensorsControlTelemetryRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1732236590",
                        "staggers": [
                            {
                                "created_at": "1732236173",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1732236590"
                            }
                        ]
                    },
                    {
                        "id": "e969985d-00dc-4d5e-a9f5-b5f472f72ce9",
                        "created_at": "1732236170",
                        "operation_type": "UPDATE",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1732236592",
                        "staggers": [
                            {
                                "created_at": "1732236171",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1732236592"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "240e8b0904c6b52431500c1460e2066c098125b4",
                "commit_title": "test ",
                "created_at": "1729020251",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1729020658",
                "changes": [
                    {
                        "id": "4ecaccdc-235a-4673-a3b8-270ead566e5d",
                        "created_at": "1729020252",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729020658",
                        "staggers": [
                            {
                                "created_at": "1729020256",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729020658"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "600b3559dba474931ea348617ed7557fc555603b",
                "commit_title": "- Added Configs ",
                "created_at": "1727941131",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1727941336",
                "changes": [
                    {
                        "id": "04bbea18-abfa-40c8-806c-b5e82476e98e",
                        "created_at": "1727941131",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTentacleRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727941336",
                        "staggers": [
                            {
                                "created_at": "1727941132",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1727941336"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "8f315e888accc794fbb4227d8093ff427082d157",
                "commit_title": "Merge branch 'master' into IssueFixMergedMaster ",
                "created_at": "1708678926",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1708679131",
                "changes": [
                    {
                        "id": "b5e5be32-1c81-4333-8f60-4814df25ebba",
                        "created_at": "1708678926",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1708679131",
                        "staggers": [
                            {
                                "created_at": "1708678928",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1708679131"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "eee2281126890d8d9930dc712035a615d0d159e2",
                "commit_title": "updated-scopeMPA-stagger-groups ",
                "created_at": "1697095283",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1697095525",
                "changes": [
                    {
                        "id": "20e6bed3-e16d-4812-b16f-4bcbba8934b3",
                        "created_at": "1697095310",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1697095525",
                        "staggers": [
                            {
                                "created_at": "1697095312",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1697095524"
                            }
                        ]
                    },
                    {
                        "id": "c5fd5930-5820-48b4-991a-4650908f5933",
                        "created_at": "1697095291",
                        "operation_type": "",
                        "stackset_id": "scopeMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1697095525",
                        "staggers": [
                            {
                                "created_at": "1697095308",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Stage Staggered Group",
                                "updated_at": "1697095525"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "c9f4bec2f04d9ad0b90d30308b20b85a8f9b2484",
                "commit_title": "-timing to 60 sec ",
                "created_at": "1683700756",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1683701680",
                "changes": [
                    {
                        "id": "d0e21b6d-9b47-44cc-844f-e427c86c05f0",
                        "created_at": "1683700848",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683701459",
                        "staggers": [
                            {
                                "created_at": "1683700850",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683701459"
                            }
                        ]
                    },
                    {
                        "id": "8063e7dc-4fbf-42e6-9bdf-3b915071e417",
                        "created_at": "1683700846",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683701461",
                        "staggers": [
                            {
                                "created_at": "1683700848",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683701461"
                            }
                        ]
                    },
                    {
                        "id": "07610db4-69bf-4e71-8ce4-81a1a67a5566",
                        "created_at": "1683700844",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683701460",
                        "staggers": [
                            {
                                "created_at": "1683700846",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683701460"
                            }
                        ]
                    },
                    {
                        "id": "3222f9b0-a785-4bf1-aaf4-f947854b0df4",
                        "created_at": "1683700838",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1683701680",
                        "staggers": [
                            {
                                "created_at": "1683701693",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683701693"
                            },
                            {
                                "created_at": "1683701478",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683701680"
                            },
                            {
                                "created_at": "1683700844",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683701459"
                            }
                        ]
                    },
                    {
                        "id": "cb538686-8024-445e-87cf-812dbe77cbf6",
                        "created_at": "1683700836",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683701460",
                        "staggers": [
                            {
                                "created_at": "1683700838",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683701460"
                            }
                        ]
                    },
                    {
                        "id": "7a5ad73a-eacb-4804-a1c3-6f25c3232ee7",
                        "created_at": "1683700834",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683701460",
                        "staggers": [
                            {
                                "created_at": "1683700836",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683701460"
                            }
                        ]
                    },
                    {
                        "id": "5f949967-d541-4d0b-8a31-55f51295f2a9",
                        "created_at": "1683700833",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683701459",
                        "staggers": [
                            {
                                "created_at": "1683700834",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683701459"
                            }
                        ]
                    },
                    {
                        "id": "55e3da3d-f722-4a20-bb83-c7266f9b46ba",
                        "created_at": "1683700830",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683701460",
                        "staggers": [
                            {
                                "created_at": "1683700833",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683701460"
                            }
                        ]
                    },
                    {
                        "id": "a526471c-abe1-4ea4-b9dc-42ce60b8f806",
                        "created_at": "1683700756",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683701458",
                        "staggers": [
                            {
                                "created_at": "1683700830",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683701458"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "6e2b31c72d11e51e3eca38764d570f3d20309df5",
                "commit_title": "testing ",
                "created_at": "1731475023",
                "deployment_status": "FAILED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731475228",
                "changes": [
                    {
                        "id": "6a98c390-fcff-4cba-9369-044603e301fe",
                        "created_at": "1731475023",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1731475228",
                        "staggers": [
                            {
                                "created_at": "1731475027",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731475228"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "dc8ee36e1c8c3ed4be90f73da7fa4d00d4fda203",
                "commit_title": "added-scopeEmptyOU ",
                "created_at": "1714399937",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714400141",
                "changes": [
                    {
                        "id": "986cb33f-53b4-4b43-a692-d86dc8f86386",
                        "created_at": "1714399937",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeEmptyOU",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714400141",
                        "staggers": [
                            {
                                "created_at": "1714399939",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1714400141"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "06f538b43347f04cb2afdd5bab17957f8af30163",
                "commit_title": "Added permissions and role for PCSKSCPV2Role ",
                "created_at": "1702659131",
                "deployment_status": "COMPLETED",
                "developer_id": "svaradarajan@salesforce.com",
                "updated_at": "1702659205",
                "changes": [
                    {
                        "id": "9f684333-99b4-4afc-b0df-6dfd174605e7",
                        "created_at": "1702659136",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702659205",
                        "staggers": [
                            {
                                "created_at": "1702659138",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702659205"
                            }
                        ]
                    },
                    {
                        "id": "6c257584-3b72-44e9-8a4e-ff63e5a5bae2",
                        "created_at": "1702659131",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702659205",
                        "staggers": [
                            {
                                "created_at": "1702659136",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702659204"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f2737349f56ef6a091aeb1cd85c688c1c0b7c89d",
                "commit_title": "deploy-to-org-2 ",
                "created_at": "1697108817",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1697109077",
                "changes": [
                    {
                        "id": "7c7e2437-059e-42d8-8c15-5c0147acfd2d",
                        "created_at": "1697108817",
                        "operation_type": "",
                        "stackset_id": "scopeOrgTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1697109072",
                        "staggers": [
                            {
                                "created_at": "1697108821",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeNoStaggeringGroup",
                                "updated_at": "1697109067"
                            }
                        ]
                    },
                    {
                        "id": "0654ee7b-9272-4b83-9b2d-28a5c73bb7a2",
                        "created_at": "1697108817",
                        "operation_type": "",
                        "stackset_id": "scopetestv1",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1697109053",
                        "staggers": [
                            {
                                "created_at": "1697108817",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "scope-ou",
                                "updated_at": "1697109042"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "5ee5508192dbb4a78fc21ebc1010dd094c9e9f5a",
                "commit_title": "add database related actions in deny sensitive action policy ",
                "created_at": "1696626972",
                "deployment_status": "COMPLETED",
                "developer_id": "xtao@salesforce.com",
                "updated_at": "1696627184",
                "changes": [
                    {
                        "id": "fc254ec3-6e1a-4b8a-9db5-f587e06c6580",
                        "created_at": "1696626979",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696627184",
                        "staggers": [
                            {
                                "created_at": "1696626981",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696627184"
                            }
                        ]
                    },
                    {
                        "id": "fae19cb9-7629-43c0-80ec-1abb481a4409",
                        "created_at": "1696626977",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696627184",
                        "staggers": [
                            {
                                "created_at": "1696626979",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696627184"
                            }
                        ]
                    },
                    {
                        "id": "14816a47-a32e-4369-98ff-432fb4fe7a2d",
                        "created_at": "1696626972",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696627184",
                        "staggers": [
                            {
                                "created_at": "1696626977",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696627184"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "dbf487afadcb96fd838bd264d9d49907384426e1",
                "commit_title": "- commonIAM pcskpasswordpolicy function ",
                "created_at": "1695892259",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1695892470",
                "changes": [
                    {
                        "id": "fc7e007e-7fb6-4925-8c59-86267ae17936",
                        "created_at": "1695892263",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695892470",
                        "staggers": [
                            {
                                "created_at": "1695892268",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695892470"
                            }
                        ]
                    },
                    {
                        "id": "2c7ff792-dd20-4182-a47a-142d1ae87b4d",
                        "created_at": "1695892261",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695892470",
                        "staggers": [
                            {
                                "created_at": "1695892263",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695892470"
                            }
                        ]
                    },
                    {
                        "id": "5b4def39-fb19-4247-90ef-0f5112606fc3",
                        "created_at": "1695892259",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695892470",
                        "staggers": [
                            {
                                "created_at": "1695892261",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695892470"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "7ce368d5d537fe8a03c8a4dbe003554f1323bb22",
                "commit_title": "test 1 ",
                "created_at": "1728551326",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1728551735",
                "changes": [
                    {
                        "id": "2299b019-3e6a-40a9-9cd2-a5a4c5c2b825",
                        "created_at": "1728551327",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728551735",
                        "staggers": [
                            {
                                "created_at": "1728551332",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728551735"
                            }
                        ]
                    },
                    {
                        "id": "d6bd9ff8-e90d-4242-855a-60b69ae3a513",
                        "created_at": "1728551326",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeFixPrismaRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728551735",
                        "staggers": [
                            {
                                "created_at": "1728551327",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "SG 1",
                                "updated_at": "1728551735"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "26622a6c12060d7e2dbdf86a4dd882692117d9f6",
                "commit_title": "Updating ou list ",
                "created_at": "1727962861",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727963065",
                "changes": [
                    {
                        "id": "22c511e8-1e45-4794-9c46-786dfd2843c5",
                        "created_at": "1727962861",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727963065",
                        "staggers": [
                            {
                                "created_at": "1727962863",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1727963065"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b9712d4e6102391ea35bc11758076665b841fc6d",
                "commit_title": "mrt stackset cleanup ",
                "created_at": "1701959323",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1701959668",
                "changes": [
                    {
                        "id": "3578962d-b279-417e-8138-43b2f5c06aad",
                        "created_at": "1701959323",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeMRTResources",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701959668",
                        "staggers": [
                            {
                                "created_at": "1701959635",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Managed Runtime/Non-Production OU",
                                "updated_at": "1701959668"
                            },
                            {
                                "created_at": "1701959595",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Managed Runtime/Non-Production/Managers OU",
                                "updated_at": "1701959628"
                            },
                            {
                                "created_at": "1701959325",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "mrt-non-production-scope-test-2",
                                "updated_at": "1701959357"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b22d9cf96afddcc492e2e1f8d0177b2c59350dcf",
                "commit_title": "fix doc ",
                "created_at": "1700669289",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1700669506",
                "changes": [
                    {
                        "id": "f73914d6-d049-4382-ab8c-2d8a249f06b0",
                        "created_at": "1700669299",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700669505",
                        "staggers": [
                            {
                                "created_at": "1700669301",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700669505"
                            }
                        ]
                    },
                    {
                        "id": "38ce26e6-c928-4546-a59d-5dff28ca56b1",
                        "created_at": "1700669294",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700669506",
                        "staggers": [
                            {
                                "created_at": "1700669299",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700669506"
                            }
                        ]
                    },
                    {
                        "id": "b0743c04-9925-40ae-b1c3-8540549e4b01",
                        "created_at": "1700669292",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700669505",
                        "staggers": [
                            {
                                "created_at": "1700669294",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700669505"
                            }
                        ]
                    },
                    {
                        "id": "119ad31f-74c8-442f-b815-07ef3279adc6",
                        "created_at": "1700669289",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700669506",
                        "staggers": [
                            {
                                "created_at": "1700669292",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700669506"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "2802a54c911ba1e5524a8ea40174fa2802f94b47",
                "commit_title": "test1 ",
                "created_at": "1731403282",
                "deployment_status": "FAILED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1731403890",
                "changes": [
                    {
                        "id": "9a0bf46f-3a53-4f26-89d0-d80682ea9303",
                        "created_at": "1731403284",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "FAILED",
                        "updated_at": "1731403890",
                        "staggers": [
                            {
                                "created_at": "1731403286",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1731403890"
                            }
                        ]
                    },
                    {
                        "id": "1182af9b-0a0e-4b95-87ee-829f39fa4131",
                        "created_at": "1731403282",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSensorsControlTelemetryRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731403890",
                        "staggers": [
                            {
                                "created_at": "1731403284",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1731403890"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "098acc29175ecb3537f1ad03e70950173d6d365a",
                "commit_title": "@W15804687 Adding bcm data export permissions ",
                "created_at": "1719954521",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1720003037",
                "changes": [
                    {
                        "id": "1e8580ce-2eee-4972-9c8f-17ef7f0c1385",
                        "created_at": "1719954535",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1719954742",
                        "staggers": [
                            {
                                "created_at": "1719954537",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1719954742"
                            }
                        ]
                    },
                    {
                        "id": "35ddfeb0-2004-407f-9f67-327e01838313",
                        "created_at": "1719954532",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1720003037",
                        "staggers": [
                            {
                                "created_at": "1719954535",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1719954742"
                            }
                        ]
                    },
                    {
                        "id": "a37b4111-5ed9-4d9f-9bfa-98ef50d33823",
                        "created_at": "1719954530",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1720003037",
                        "staggers": [
                            {
                                "created_at": "1719954532",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1719954742"
                            }
                        ]
                    },
                    {
                        "id": "aa7c8cc6-c861-4835-bfc7-080c24975cc5",
                        "created_at": "1719954522",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1719954741",
                        "staggers": [
                            {
                                "created_at": "1719954530",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1719954741"
                            }
                        ]
                    },
                    {
                        "id": "db2e82e8-1951-4192-a4b5-8e6dda26165c",
                        "created_at": "1719954521",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1719954742",
                        "staggers": [
                            {
                                "created_at": "1719954522",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1719954742"
                            }
                        ]
                    },
                    {
                        "id": "1f358707-7bad-4776-93aa-22c74a0bcd1f",
                        "created_at": "1719954521",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1719954741",
                        "staggers": [
                            {
                                "created_at": "1719954521",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1719954741"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "ac2214afa3f00bf34a5e6c9d820b00424e0c3d6e",
                "commit_title": "testing ",
                "created_at": "1731498715",
                "deployment_status": "COMPLETED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731498920",
                "changes": [
                    {
                        "id": "214650b0-c5a2-43fe-9798-7d92ea90b5c3",
                        "created_at": "1731498715",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731498920",
                        "staggers": [
                            {
                                "created_at": "1731498719",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731498920"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "0c3343852b43942523430f365b46532acba00d85",
                "commit_title": "Code review comments fix ",
                "created_at": "1729619076",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1729619485",
                "changes": [
                    {
                        "id": "8b4c0fc4-fe4e-4cf5-9c97-ee80721b920a",
                        "created_at": "1729619076",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729619485",
                        "staggers": [
                            {
                                "created_at": "1729619082",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729619485"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "ae50c12c97bd57a4ff62075abd89daac831ec623",
                "commit_title": "test ",
                "created_at": "1728477403",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1728477812",
                "changes": [
                    {
                        "id": "b6aaf513-fc6e-4fb2-a1fb-c4daaf91fc2c",
                        "created_at": "1728477404",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728477812",
                        "staggers": [
                            {
                                "created_at": "1728477409",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728477812"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "7cf72bd1b8853f07a205b81af99ed71d09d5da44",
                "commit_title": "added-wizRolev2-template ",
                "created_at": "1702031599",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1702031825",
                "changes": [
                    {
                        "id": "3cfaf5d8-dfd4-4feb-94cf-3c8ab8830e36",
                        "created_at": "1702031599",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeWizRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702031825",
                        "staggers": [
                            {
                                "created_at": "1702031600",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage-mpa",
                                "updated_at": "1702031825"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-c8701dec-732c-11ee-913d-acde48001122",
                "commit_title": "test commit - create scopeTestThird stackset and change group config and template for scopeTestFirst,scopeTestSecond stacksets",
                "created_at": "1698234679",
                "deployment_status": "REVERTED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698234819",
                "changes": [
                    {
                        "id": "0f0ca565-f2aa-449b-b8b0-c3e078b5824d",
                        "created_at": "1698234688",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234819",
                        "staggers": [
                            {
                                "created_at": "1698234689",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698234819"
                            }
                        ]
                    },
                    {
                        "id": "2fb4c3b2-0e23-41aa-83a7-0f858c07ef19",
                        "created_at": "1698234687",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234819",
                        "staggers": [
                            {
                                "created_at": "1698234688",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698234819"
                            }
                        ]
                    },
                    {
                        "id": "388cc56d-b274-47db-9576-247e8ebd30f8",
                        "created_at": "1698234685",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234819",
                        "staggers": [
                            {
                                "created_at": "1698234685",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698234819"
                            }
                        ]
                    },
                    {
                        "id": "29a0fd86-8f31-43a7-b96e-882a6962ce22",
                        "created_at": "1698234685",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234819",
                        "staggers": [
                            {
                                "created_at": "1698234686",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698234819"
                            }
                        ]
                    },
                    {
                        "id": "4eb0b456-9964-4bc1-8768-4e1afe0cff7f",
                        "created_at": "1698234684",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234819",
                        "staggers": [
                            {
                                "created_at": "1698234685",
                                "operation_status": "REVERTED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698234819"
                            }
                        ]
                    },
                    {
                        "id": "9a5e8642-5ad0-489c-aef2-2ab59e3b4364",
                        "created_at": "1698234682",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234819",
                        "staggers": [
                            {
                                "created_at": "1698234684",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698234819"
                            }
                        ]
                    },
                    {
                        "id": "dab1ac8b-947b-4a3f-b30d-81b163b2aacb",
                        "created_at": "1698234679",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234819",
                        "staggers": [
                            {
                                "created_at": "1698234757",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698234819"
                            },
                            {
                                "created_at": "1698234682",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698234819"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "5b1d00e7e975966a6d1e9007ee03844eacad71ed",
                "commit_title": "add back ssm value ",
                "created_at": "1694698652",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1694698717",
                "changes": [
                    {
                        "id": "a830f3f5-731d-4af7-9c47-06776c20604e",
                        "created_at": "1694698652",
                        "operation_type": "",
                        "stackset_id": "ScopeTestMpa",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694698717",
                        "staggers": [
                            {
                                "created_at": "1694698654",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "MPAs",
                                "updated_at": "1694698717"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3337f4db52cb0b51cc5af98d11af074371f7456b",
                "commit_title": "test ",
                "created_at": "1728500885",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1728501293",
                "changes": [
                    {
                        "id": "9b37a7e7-7fd7-46bc-ab3b-2d9ad1e9fdcb",
                        "created_at": "1728500885",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728501293",
                        "staggers": [
                            {
                                "created_at": "1728500890",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728501293"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "894d1be5d76ad3bb8067fd56b87b59c530860387",
                "commit_title": "fix policy ",
                "created_at": "1709302127",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1709302572",
                "changes": [
                    {
                        "id": "70b9abdc-761f-4292-8137-3f3c7861f8b1",
                        "created_at": "1709302127",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709302572",
                        "staggers": [
                            {
                                "created_at": "1709302370",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1709302572"
                            },
                            {
                                "created_at": "1709302129",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1709302332"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "19ff0abce125a05f36bbe18f69f912954831870b",
                "commit_title": "skip wrapping in pagerduty error if error type is operationInProgressError ",
                "created_at": "1709019810",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1709064568",
                "changes": [
                    {
                        "id": "03ffbf16-f5bb-4c2f-b14f-4a518ee3485f",
                        "created_at": "1709019812",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709020021",
                        "staggers": [
                            {
                                "created_at": "1709019818",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709020021"
                            }
                        ]
                    },
                    {
                        "id": "5987a32e-4c31-4ce0-8af2-5be504649195",
                        "created_at": "1709019810",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1709064568",
                        "staggers": [
                            {
                                "created_at": "1709019812",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1709020021"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "f3d8fb80fa13a6379988f1c47819498f1a279634",
                "commit_title": "added-mpa-as-target ",
                "created_at": "1698909361",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1698912230",
                "changes": [
                    {
                        "id": "c75b7c1d-320d-4136-9a1a-e3dc6ffb7bc2",
                        "created_at": "1698909361",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeDemoStackset",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698909595",
                        "staggers": [
                            {
                                "created_at": "1698909362",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698909590"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "ad34fcbf22421610496a820718898184bdea4d69",
                "commit_title": "@W-16932078 | Revert retain permission boundary (#205) ",
                "created_at": "1729493943",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1729494367",
                "changes": [
                    {
                        "id": "bde16f20-7785-462f-8506-48081339fbaa",
                        "created_at": "1729493943",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729494367",
                        "staggers": [
                            {
                                "created_at": "1729494164",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729494367"
                            },
                            {
                                "created_at": "1729493945",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1729494148"
                            }
                        ]
                    },
                    {
                        "id": "c2e79b24-873c-4074-8896-3259dbe1e86d",
                        "created_at": "1728631414",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728631827",
                        "staggers": [
                            {
                                "created_at": "1728631419",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728631827"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "25d1a2444c0078899d91adf9a3c1c89b6e5ea111",
                "commit_title": "Moving code to AllMPA stackset ",
                "created_at": "1718011595",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1718013296",
                "changes": [
                    {
                        "id": "5b5503dc-4662-4d61-9eb5-23e498387a4c",
                        "created_at": "1718011595",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1718013296",
                        "staggers": [
                            {
                                "created_at": "1718013095",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1718013296"
                            },
                            {
                                "created_at": "1718011597",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1718011798"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "840d0ce9c5e7ee7b285e5cae2ee7b19a550b516e",
                "commit_title": "test ",
                "created_at": "1717666197",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1717666682",
                "changes": [
                    {
                        "id": "36729074-61f7-4fea-8e6d-7514b31d8601",
                        "created_at": "1717666199",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717666403",
                        "staggers": [
                            {
                                "created_at": "1717666201",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717666403"
                            }
                        ]
                    },
                    {
                        "id": "52ff3772-a7bd-4a5c-bb4c-8a7701d3dd27",
                        "created_at": "1717666197",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1717666682",
                        "staggers": [
                            {
                                "created_at": "1717666199",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717666402"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "test-5709d1cc-730c-11ee-b304-acde48001122",
                "commit_title": "test commit - remove all stacksets from grouping, and mark as delete in payload",
                "created_at": "1698220745",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698220814",
                "changes": [
                    {
                        "id": "a3d9ec75-76e7-46e3-ace5-5309bb035d9c",
                        "created_at": "1698220749",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698220813",
                        "staggers": [
                            {
                                "created_at": "1698220749",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698220813"
                            }
                        ]
                    },
                    {
                        "id": "51412a5f-ffad-476c-af43-c346eecee55e",
                        "created_at": "1698220747",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698220814",
                        "staggers": [
                            {
                                "created_at": "1698220749",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698220814"
                            }
                        ]
                    },
                    {
                        "id": "ef03c441-f40f-4615-8e45-7722f43ccf06",
                        "created_at": "1698220745",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698220813",
                        "staggers": [
                            {
                                "created_at": "1698220747",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698220812"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "899fc5fbe3071957e126939b402061af040f3470_799696138513_80649067-5309-11ee-bdcf-e28522d77dd8",
                "commit_title": "add back scopetest with ou ",
                "created_at": "1694701078",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1694701078",
                "changes": [
                    {
                        "id": "b549aeb1-b700-4f79-98fc-3a8f5f8472a2",
                        "created_at": "1694701078",
                        "operation_type": "",
                        "stackset_id": "ScopeTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694701143",
                        "staggers": [
                            {
                                "created_at": "1694701079",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeNoStaggeringGroup",
                                "updated_at": "1694701143"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d3bb185c41e7ab0413748a51362147d2ae9fcbf0",
                "commit_title": "- test should fail new role ",
                "created_at": "1677668902",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1677669748",
                "changes": [
                    {
                        "id": "3b8fd0cd-a61d-46ae-919c-29ed8757019e",
                        "created_at": "1677668902",
                        "operation_type": "",
                        "stackset_id": "TestStacksetFalcon2",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1677669748",
                        "staggers": [
                            {
                                "created_at": "1677669445",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1677669748"
                            },
                            {
                                "created_at": "1677668907",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage",
                                "updated_at": "1677669008"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "57b68a0edf73f519894a7410f148f97d0edba12d",
                "commit_title": "test ",
                "created_at": "1730970040",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1730970243",
                "changes": [
                    {
                        "id": "0a1b344d-0403-467f-8f97-98cb5cd60313",
                        "created_at": "1730970040",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSensorsControlTelemetryRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730970243",
                        "staggers": [
                            {
                                "created_at": "1730970041",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1730970243"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "dad7e0962f1b76f91f153bbbc14d256f3302e287",
                "commit_title": "test 2 ",
                "created_at": "1729020728",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1729021135",
                "changes": [
                    {
                        "id": "13e8cd42-f5ce-4c78-831b-797f9c983f03",
                        "created_at": "1729020728",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729021135",
                        "staggers": [
                            {
                                "created_at": "1729020733",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729021135"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "831191fe41397396e8dfaa8e9d94eecb70421937",
                "commit_title": "test 3 ",
                "created_at": "1716389502",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1716391344",
                "changes": [
                    {
                        "id": "6c02d231-e458-401d-8c00-11682cbb5104",
                        "created_at": "1716389502",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1716391344",
                        "staggers": null
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "f6027e3e6cdddc2555e9cfea76c19af0c981b93f",
                "commit_title": "merge-latest-master ",
                "created_at": "1709552185",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1709552389",
                "changes": [
                    {
                        "id": "0855bf95-6619-4a94-8b17-80c87e9dc181",
                        "created_at": "1709552185",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709552389",
                        "staggers": [
                            {
                                "created_at": "1709552187",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709552389"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-411db74e-74b4-11ee-bed9-acde48001122",
                "commit_title": "test commit - create scopeTestThird stackset and change group config and template for scopeTestFirst,scopeTestSecond stacksets",
                "created_at": "1698402816",
                "deployment_status": "REVERTED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698403260",
                "changes": [
                    {
                        "id": "7061affb-684f-41b9-9b5a-b65be6416f9d",
                        "created_at": "1698402824",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698403260",
                        "staggers": [
                            {
                                "created_at": "1698402825",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698403260"
                            }
                        ]
                    },
                    {
                        "id": "697913f9-40af-428c-8981-01840ec95ad1",
                        "created_at": "1698402824",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698403260",
                        "staggers": [
                            {
                                "created_at": "1698402824",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698403260"
                            }
                        ]
                    },
                    {
                        "id": "5b6f0172-4a12-4799-891d-f15683e4f30f",
                        "created_at": "1698402823",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698403260",
                        "staggers": [
                            {
                                "created_at": "1698402824",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698403260"
                            }
                        ]
                    },
                    {
                        "id": "56202c9f-fe41-4cf6-b07a-b6b157a32d71",
                        "created_at": "1698402822",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698403260",
                        "staggers": [
                            {
                                "created_at": "1698402823",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698403260"
                            }
                        ]
                    },
                    {
                        "id": "3b8f4553-6f0f-45cd-8524-5b9ca8405d91",
                        "created_at": "1698402821",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698403260",
                        "staggers": [
                            {
                                "created_at": "1698402822",
                                "operation_status": "REVERTED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698403260"
                            }
                        ]
                    },
                    {
                        "id": "cc460de1-597e-4940-a7e3-9ce55f9bc7ca",
                        "created_at": "1698402819",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698403260",
                        "staggers": [
                            {
                                "created_at": "1698402821",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698403260"
                            }
                        ]
                    },
                    {
                        "id": "c9aa77da-a148-4e4f-9c7f-b9a53c14246f",
                        "created_at": "1698402816",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698403260",
                        "staggers": [
                            {
                                "created_at": "1698403033",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698403260"
                            },
                            {
                                "created_at": "1698402819",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698403260"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "ea72326f0bd2d55bd13f4ac9af0929c4c046f9a7",
                "commit_title": "testing ",
                "created_at": "1731508448",
                "deployment_status": "COMPLETED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731508654",
                "changes": [
                    {
                        "id": "a79c8d60-b6f5-4be5-8d09-312596c44f8a",
                        "created_at": "1731508448",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731508654",
                        "staggers": [
                            {
                                "created_at": "1731508452",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731508654"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e1d0929c3519a401fb5e8d4fc7229ef0d65879fb",
                "commit_title": "@W-16593660 Fix cloudwatch references in Cloudtrail ",
                "created_at": "1724749123",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1724749123",
                "changes": null,
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "dbcc5f21ffc13945417f97263370c09164cf7ad1",
                "commit_title": "updated-template ",
                "created_at": "1715781564",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715781770",
                "changes": [
                    {
                        "id": "88af58dd-cfe6-4d6e-8fff-4f249d7c4b35",
                        "created_at": "1715781564",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSCPServiceManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715781770",
                        "staggers": [
                            {
                                "created_at": "1715781565",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715781770"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "cc93e75de0c4c0c96e27292dd8293268f7da37d8",
                "commit_title": "managed policy updates ",
                "created_at": "1702624906",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1702881611",
                "changes": [
                    {
                        "id": "68cee4c1-bd67-462b-977e-5194b7b6bf51",
                        "created_at": "1702881222",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702881263",
                        "staggers": [
                            {
                                "created_at": "1702881224",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702881263"
                            }
                        ]
                    },
                    {
                        "id": "1d6e37fb-891d-474d-a37b-75d72bc9b4a4",
                        "created_at": "1702624906",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "FAILED",
                        "updated_at": "1702881611",
                        "staggers": [
                            {
                                "created_at": "1702881229",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702881611"
                            },
                            {
                                "created_at": "1702624910",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702625356"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "1713c89bd8699e586db12be04051c3a1c49522ee",
                "commit_title": "Updatinh PCSKDnRFlowLogHubRole policy ",
                "created_at": "1700217426",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1700217841",
                "changes": [
                    {
                        "id": "4cc3948e-3786-43a2-92fb-4cabaecb7ca5",
                        "created_at": "1700217435",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700217840",
                        "staggers": [
                            {
                                "created_at": "1700217437",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700217840"
                            }
                        ]
                    },
                    {
                        "id": "434647bb-d2f6-4d09-9a98-b316d5944b42",
                        "created_at": "1700217432",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700217841",
                        "staggers": [
                            {
                                "created_at": "1700217434",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700217841"
                            }
                        ]
                    },
                    {
                        "id": "682ea50a-2e0c-4acf-84b1-4864e6a99db9",
                        "created_at": "1700217430",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700217841",
                        "staggers": [
                            {
                                "created_at": "1700217432",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700217841"
                            }
                        ]
                    },
                    {
                        "id": "e054e8e0-6cdb-44a0-bf00-2d679564405a",
                        "created_at": "1700217426",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700217840",
                        "staggers": [
                            {
                                "created_at": "1700217430",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700217840"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "2d9d53cdd5865007b3f0530158dedca0f6dc41d3",
                "commit_title": "- test param change ",
                "created_at": "1677665727",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1677666013",
                "changes": [
                    {
                        "id": "d213d505-5f68-4c3a-bf41-be13cb6254c6",
                        "created_at": "1677665727",
                        "operation_type": "",
                        "stackset_id": "TestStacksetFalcon1",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1677666013",
                        "staggers": [
                            {
                                "created_at": "1677665912",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1677666013"
                            },
                            {
                                "created_at": "1677665733",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage",
                                "updated_at": "1677665835"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f49769811e90cf8690f115a48298d45ffd6062ed",
                "commit_title": "testing default stage value ",
                "created_at": "1730222610",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730222813",
                "changes": [
                    {
                        "id": "c2b53c71-6d53-4f2d-946a-2f11e3963622",
                        "created_at": "1730222610",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730222813",
                        "staggers": [
                            {
                                "created_at": "1730222611",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730222813"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "8a7d991fb07c00996a2251da715a9dca4410b17b",
                "commit_title": "Merge branch 'master' of git.soma.salesforce.com:identity-access/pcsk-stacksets into separate_scope ",
                "created_at": "1729794319",
                "deployment_status": "IN_PROGRESS",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1729794319",
                "changes": [
                    {
                        "id": "61b8bb4a-2c54-4475-b4b4-ff84ee89a995",
                        "created_at": "1729794326",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729794530",
                        "staggers": [
                            {
                                "created_at": "1729794328",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729794530"
                            }
                        ]
                    },
                    {
                        "id": "b8a18d5e-2bec-4fc0-ab47-aed63d01a0ef",
                        "created_at": "1729794319",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729794530",
                        "staggers": [
                            {
                                "created_at": "1729794324",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729794530"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "c9d5297f210da27a0e19af753dc37e9626de80a5",
                "commit_title": "updated-prisma-templates ",
                "created_at": "1717134358",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1717134774",
                "changes": [
                    {
                        "id": "04f5c7de-6015-4890-8cf4-e8fbc8501df1",
                        "created_at": "1717134362",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717134774",
                        "staggers": [
                            {
                                "created_at": "1717134369",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717134774"
                            }
                        ]
                    },
                    {
                        "id": "b6940ee1-c7c3-42c2-b9ba-30242f1cccbc",
                        "created_at": "1717134360",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopePrismaRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717134774",
                        "staggers": [
                            {
                                "created_at": "1717134362",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1717134774"
                            }
                        ]
                    },
                    {
                        "id": "4c7f4ee7-7197-4678-bb47-5d7577a24c0d",
                        "created_at": "1717134358",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717134774",
                        "staggers": [
                            {
                                "created_at": "1717134360",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717134774"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f5f07c53f4920ec7086d0ef67cefef8bbfc0a72e",
                "commit_title": "removed-delete ",
                "created_at": "1715782113",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715782216",
                "changes": [
                    {
                        "id": "b0d14db8-ca43-4f94-ab65-599bb3b6da0e",
                        "created_at": "1715782113",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeSCPServiceManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715782216",
                        "staggers": [
                            {
                                "created_at": "1715782115",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715782216"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "74498dacf4434b501aa7d08a32e8e5c864dfe6f8",
                "commit_title": "updated-config ",
                "created_at": "1709804354",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1709804559",
                "changes": [
                    {
                        "id": "0fdd641c-62a2-44d9-af59-6305df10a0d8",
                        "created_at": "1709804354",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeOUMovementTracker",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709804559",
                        "staggers": [
                            {
                                "created_at": "1709804356",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1709804559"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f281530d7564e18936e754565bfce2f8062d9643",
                "commit_title": "add mrt resources ",
                "created_at": "1701969067",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1701969599",
                "changes": [
                    {
                        "id": "3dbf9ee8-1436-45ef-ba29-8b7604e5cdcc",
                        "created_at": "1701969067",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeMRTResources",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701969291",
                        "staggers": [
                            {
                                "created_at": "1701969259",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Managed Runtime/Non-Production OU",
                                "updated_at": "1701969291"
                            },
                            {
                                "created_at": "1701969217",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Managed Runtime/Non-Production/Managers OU",
                                "updated_at": "1701969250"
                            },
                            {
                                "created_at": "1701969069",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "mrt-non-production-scope-test-2",
                                "updated_at": "1701969104"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "820bf2ad33d4d20842f0cac0cbae1504c6f21ed4",
                "commit_title": "deploy-to-org ",
                "created_at": "1697108378",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1697108378",
                "changes": [
                    {
                        "id": "62759802-08a2-439f-805a-cb9cdbcf2c1c",
                        "created_at": "1697108379",
                        "operation_type": "",
                        "stackset_id": "scopeOrgTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1697108379",
                        "staggers": null
                    },
                    {
                        "id": "1deb661d-21ef-4818-afce-3f4e8383993c",
                        "created_at": "1697108378",
                        "operation_type": "",
                        "stackset_id": "scopetestv1",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1697108378",
                        "staggers": [
                            {
                                "created_at": "1697108379",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "DELETE",
                                "stagger_group_name": "scope-ou",
                                "updated_at": "1697108379"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "272c8645a1935049def0658e75323996b3eb04e5",
                "commit_title": "mrt demo stacksets ",
                "created_at": "1701931564",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1701932479",
                "changes": [
                    {
                        "id": "2703a3f9-9b84-4bfd-9b92-b161e640aa18",
                        "created_at": "1701931564",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeMRTResources",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701932479",
                        "staggers": [
                            {
                                "created_at": "1701932276",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Managed Runtime/Non-Production OU",
                                "updated_at": "1701932479"
                            },
                            {
                                "created_at": "1701932017",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Managed Runtime/Non-Production/Managers OU",
                                "updated_at": "1701932221"
                            },
                            {
                                "created_at": "1701931566",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "mrt-non-production-scope-test-2",
                                "updated_at": "1701931770"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f85a72558cfd801bd3c12cdb47e3675e2fdeb2db",
                "commit_title": "Deloying to ou template ",
                "created_at": "1727897832",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727901656",
                "changes": [
                    {
                        "id": "5010f4f2-1443-438e-ba89-18b9a8e83c01",
                        "created_at": "1727897834",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727901656",
                        "staggers": [
                            {
                                "created_at": "1727897837",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1727901656"
                            }
                        ]
                    },
                    {
                        "id": "9595b469-ccc6-40b0-a31d-9fe13a090c18",
                        "created_at": "1727897833",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727898039",
                        "staggers": [
                            {
                                "created_at": "1727897834",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1727898039"
                            }
                        ]
                    },
                    {
                        "id": "c5d2caa6-e223-47f0-9120-54c1873cac8f",
                        "created_at": "1727897832",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727901656",
                        "staggers": [
                            {
                                "created_at": "1727897833",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1727901656"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "8ae842d181cc0b887141d5985aec0d593d2b3ed5",
                "commit_title": "added listparents permission ",
                "created_at": "1717585795",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1717586431",
                "changes": [
                    {
                        "id": "66ed4464-d3d7-4bac-b990-b4b009b32644",
                        "created_at": "1717585807",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717586012",
                        "staggers": [
                            {
                                "created_at": "1717585809",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717586012"
                            }
                        ]
                    },
                    {
                        "id": "c84d7d4a-b84d-4c6f-a216-ae9c01e82de7",
                        "created_at": "1717585805",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717586431",
                        "staggers": [
                            {
                                "created_at": "1717586230",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717586431"
                            },
                            {
                                "created_at": "1717585807",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717586214"
                            }
                        ]
                    },
                    {
                        "id": "393b6b67-1aa1-452d-806d-cf98dd5f6e2d",
                        "created_at": "1717585795",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717586215",
                        "staggers": [
                            {
                                "created_at": "1717585805",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717586215"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "5f426fda13d032e6330be5fee2960bc7b6d8f3df",
                "commit_title": "updated-rolename-template ",
                "created_at": "1716202188",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1716202293",
                "changes": [
                    {
                        "id": "f9566b41-a3c8-4abe-b868-6e37e2745f5b",
                        "created_at": "1716202188",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSCPServiceManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716202293",
                        "staggers": [
                            {
                                "created_at": "1716202190",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1716202293"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "29eb34017b5d67f4af00494f5c19a3dc15c2be47",
                "commit_title": "force-update ",
                "created_at": "1715948148",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715948253",
                "changes": [
                    {
                        "id": "41de0326-5b55-4393-958d-2146337ddcb4",
                        "created_at": "1715948148",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopePrismaRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715948253",
                        "staggers": [
                            {
                                "created_at": "1715948150",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715948253"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "1ff0cdf44d30b13fd87160fc77a197179fb4f6bd",
                "commit_title": "service-managed-scp-test ",
                "created_at": "1715766957",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715767061",
                "changes": [
                    {
                        "id": "bcda0aec-9cfb-4cf2-bdaa-bd9877bdc61d",
                        "created_at": "1715766957",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeSCPServiceManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715767061",
                        "staggers": [
                            {
                                "created_at": "1715766958",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715767061"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a8dbe05eda94cf519b810280f2c414cdf7ad807e",
                "commit_title": "Merge branch 'master' into mrtprodga ",
                "created_at": "1711701653",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1711702099",
                "changes": [
                    {
                        "id": "6aefbf75-5074-4f04-83f3-1ebf08a30eca",
                        "created_at": "1711701653",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711702099",
                        "staggers": [
                            {
                                "created_at": "1711701897",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1711702099"
                            },
                            {
                                "created_at": "1711701654",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1711701857"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "2af7a522ff362d3442e00d42bf2d7144b72d7221",
                "commit_title": "add function in front of FindInMap ",
                "created_at": "1709540395",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1709540842",
                "changes": [
                    {
                        "id": "0a4c0624-1a81-4689-ab50-2e4d11525e30",
                        "created_at": "1709540395",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709540842",
                        "staggers": [
                            {
                                "created_at": "1709540641",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1709540842"
                            },
                            {
                                "created_at": "1709540397",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1709540599"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "1003b09d0fe940fc6ee9ec61c644530a70c5eca3",
                "commit_title": "Updating syntax ",
                "created_at": "1729789437",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1729789641",
                "changes": [
                    {
                        "id": "4237afab-fefe-4d1e-97d4-bcf8470838e3",
                        "created_at": "1729789437",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729789641",
                        "staggers": [
                            {
                                "created_at": "1729789439",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1729789641"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "9e1d0c559d98725f9d132314713bfb16cd221d15",
                "commit_title": "Adding stage MPA to ScopeWizRole staggering ",
                "created_at": "1728020871",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1728024710",
                "changes": [
                    {
                        "id": "d513b225-d0dd-48d1-b4df-b6f447253a2e",
                        "created_at": "1728020877",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeWizRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728024710",
                        "staggers": [
                            {
                                "created_at": "1728020878",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1728024710"
                            }
                        ]
                    },
                    {
                        "id": "cdbff220-cdb9-4e7a-8c66-001052f2ee98",
                        "created_at": "1728020875",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728021283",
                        "staggers": [
                            {
                                "created_at": "1728020877",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728021283"
                            }
                        ]
                    },
                    {
                        "id": "ba518ea7-0c0c-438b-8f58-078c31d4576e",
                        "created_at": "1728020871",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728021283",
                        "staggers": [
                            {
                                "created_at": "1728020875",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728021283"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "39ed89e6a70cf30998a2c729087960d5cd786f61",
                "commit_title": "mrt stackset deployment in RD&GA (#149) ",
                "created_at": "1712915978",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1712916183",
                "changes": [
                    {
                        "id": "71c7c654-28f0-45e8-bc46-03aaf567e0fd",
                        "created_at": "1712915978",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1712916183",
                        "staggers": [
                            {
                                "created_at": "1712915980",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1712916183"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "26548c4bd9bb0b79a9f944b89d853f88d58b42cb",
                "commit_title": "updated test account id ",
                "created_at": "1728046855",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1728047061",
                "changes": [
                    {
                        "id": "e1d9dad7-ce57-45ad-9ed1-f1e915446037",
                        "created_at": "1728046855",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728047061",
                        "staggers": [
                            {
                                "created_at": "1728046859",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728047061"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "fa93d080d44966decc255cff3666f47590babd41",
                "commit_title": "updating quotes ",
                "created_at": "1727964013",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727964217",
                "changes": [
                    {
                        "id": "51e84035-4fdd-4c54-9086-8249c32801a3",
                        "created_at": "1727964013",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727964217",
                        "staggers": [
                            {
                                "created_at": "1727964015",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1727964217"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4cf7d141fa2dd54a172b79e897618e36633e9142",
                "commit_title": "Revamping if condition for principal ",
                "created_at": "1730215934",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730216138",
                "changes": [
                    {
                        "id": "9f7329e3-3fe0-40f2-88cd-30ab7d758e47",
                        "created_at": "1730215934",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730216138",
                        "staggers": [
                            {
                                "created_at": "1730215936",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730216138"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "78469a581c33b90f9ddbb2c7b1d87ef9735e9e4b",
                "commit_title": "trying comma separated values for ou ",
                "created_at": "1727963182",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727963401",
                "changes": [
                    {
                        "id": "592709de-7e96-4fc1-a69e-051044efb048",
                        "created_at": "1727963182",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1727963401",
                        "staggers": null
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "8cd4fc4039f70db62aa531bc1ef3f21dad311eb7",
                "commit_title": "Additional actions for Read Only role ",
                "created_at": "1726464671",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1726464879",
                "changes": [
                    {
                        "id": "b6561864-4474-4e9a-af57-c5dbb4067da6",
                        "created_at": "1726464673",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1726464879",
                        "staggers": [
                            {
                                "created_at": "1726464677",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1726464879"
                            }
                        ]
                    },
                    {
                        "id": "71b155e5-c9b8-44da-a790-b7010d749cc5",
                        "created_at": "1726464671",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1726464879",
                        "staggers": [
                            {
                                "created_at": "1726464673",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1726464879"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "5bdb978244eaf8453a521961744f453540dc11d3",
                "commit_title": "updated-rolename-template3 ",
                "created_at": "1716202453",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1716202556",
                "changes": [
                    {
                        "id": "910468f6-1211-4961-b027-021666e2200b",
                        "created_at": "1716202453",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSCPServiceManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716202556",
                        "staggers": [
                            {
                                "created_at": "1716202454",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1716202556"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-cc7dcca0-7196-11ee-b088-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698060317",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698060385",
                "changes": [
                    {
                        "id": "303ef0ec-93dd-4f77-b3a4-82961556de99",
                        "created_at": "1698060319",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698060385",
                        "staggers": [
                            {
                                "created_at": "1698060354",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "second stagger group",
                                "updated_at": "1698060385"
                            },
                            {
                                "created_at": "1698060320",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698060351"
                            }
                        ]
                    },
                    {
                        "id": "b0704e63-e0de-4d51-a48d-611e40e5ee13",
                        "created_at": "1698060317",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698060351",
                        "staggers": [
                            {
                                "created_at": "1698060319",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "one account id",
                                "updated_at": "1698060351"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6bbb8288a268a778816f0c90cb6f6c4b44c294b7",
                "commit_title": "change role name ",
                "created_at": "1695206237",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1695208864",
                "changes": [
                    {
                        "id": "625fabb4-15f6-43ae-a311-4064bc3db3d0",
                        "created_at": "1695206239",
                        "operation_type": "",
                        "stackset_id": "ScopeTestMpav2",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695206446",
                        "staggers": [
                            {
                                "created_at": "1695206241",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "MPAs",
                                "updated_at": "1695206446"
                            }
                        ]
                    },
                    {
                        "id": "38b10180-9d49-4a97-8075-e729391a1543",
                        "created_at": "1695206237",
                        "operation_type": "",
                        "stackset_id": "ScopeTestv2",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695206658",
                        "staggers": [
                            {
                                "created_at": "1695206457",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "ou",
                                "updated_at": "1695206658"
                            },
                            {
                                "created_at": "1695206239",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "dev account",
                                "updated_at": "1695206446"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "71e1faefdbc089cad6a120a344a9b9133aa4318b",
                "commit_title": "clean ",
                "created_at": "1683618888",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1683619110",
                "changes": [
                    {
                        "id": "c916992f-ee06-4661-ac89-ca6ae73a61a8",
                        "created_at": "1683618905",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683619110",
                        "staggers": [
                            {
                                "created_at": "1683618906",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683619110"
                            }
                        ]
                    },
                    {
                        "id": "0ffd74f6-2c1b-48a8-bc1f-3a24ee7b1e5b",
                        "created_at": "1683618904",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683619109",
                        "staggers": [
                            {
                                "created_at": "1683618905",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683619109"
                            }
                        ]
                    },
                    {
                        "id": "dd36861c-0588-4691-b8d0-7b481c568ef1",
                        "created_at": "1683618902",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683619110",
                        "staggers": [
                            {
                                "created_at": "1683618904",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683619110"
                            }
                        ]
                    },
                    {
                        "id": "708b5575-e88a-4503-a289-fc0ed61b1f20",
                        "created_at": "1683618901",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683619109",
                        "staggers": [
                            {
                                "created_at": "1683618902",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683619109"
                            }
                        ]
                    },
                    {
                        "id": "6a617465-134b-42f4-8e08-e9248fc349d3",
                        "created_at": "1683618900",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683619109",
                        "staggers": [
                            {
                                "created_at": "1683618901",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683619109"
                            }
                        ]
                    },
                    {
                        "id": "9ca1b338-6e77-4038-9261-e215790484e5",
                        "created_at": "1683618899",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683619108",
                        "staggers": [
                            {
                                "created_at": "1683618900",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683619108"
                            }
                        ]
                    },
                    {
                        "id": "d6a7f381-a704-4753-a153-974037db454d",
                        "created_at": "1683618897",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683619110",
                        "staggers": [
                            {
                                "created_at": "1683618899",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683619110"
                            }
                        ]
                    },
                    {
                        "id": "4fa781bb-ae4e-4242-bdd8-aba29280c5eb",
                        "created_at": "1683618893",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683619110",
                        "staggers": [
                            {
                                "created_at": "1683618897",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683619110"
                            }
                        ]
                    },
                    {
                        "id": "3807a43a-3f38-4249-9ed9-68b312232c6e",
                        "created_at": "1683618888",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683619109",
                        "staggers": [
                            {
                                "created_at": "1683618893",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683619109"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a0fa8b999af934a79b6034655310437e202888ba_120881508763_2b9ca189-70db-11ef-8d73-7ec824c156a4",
                "commit_title": "Merge pull request #186 from identity-access/mulesoft_roles ",
                "created_at": "1726127213",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1726127416",
                "changes": [
                    {
                        "id": "052d6c50-c325-4043-b349-91da21d0f749",
                        "created_at": "1726127213",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1726127416",
                        "staggers": [
                            {
                                "created_at": "1726127214",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1726127416"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-6045305c-7324-11ee-bcb0-acde48001122",
                "commit_title": "test commit - remove all stacksets from grouping, and mark as delete in payload",
                "created_at": "1698231067",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698231133",
                "changes": [
                    {
                        "id": "9d9fce3c-af23-4205-8a1f-716f2639954c",
                        "created_at": "1698231068",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698231133",
                        "staggers": [
                            {
                                "created_at": "1698231070",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698231133"
                            }
                        ]
                    },
                    {
                        "id": "92a0f0b3-a0bc-4c5d-90f1-851489419778",
                        "created_at": "1698231067",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698231133",
                        "staggers": [
                            {
                                "created_at": "1698231068",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698231133"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "afcd03627d4bdacb812b26ab4b466d6a0b3cee9f",
                "commit_title": "@W-14143629 | Update trust relationships for SAI roles ",
                "created_at": "1696409790",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1696410003",
                "changes": [
                    {
                        "id": "48210854-9e97-4437-a62c-09e3f1f6a996",
                        "created_at": "1696409797",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696410002",
                        "staggers": [
                            {
                                "created_at": "1696409799",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696410002"
                            }
                        ]
                    },
                    {
                        "id": "b3b9f10d-a6f1-4e92-883d-8ac203f4bd7d",
                        "created_at": "1696409795",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696410003",
                        "staggers": [
                            {
                                "created_at": "1696409797",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696410003"
                            }
                        ]
                    },
                    {
                        "id": "21c543f8-57f1-42ed-9964-ee68c88ea466",
                        "created_at": "1696409790",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696410002",
                        "staggers": [
                            {
                                "created_at": "1696409795",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696410002"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4725913ee3e0884f039cb9134a45bf95bf56f69c",
                "commit_title": "@W-14144277 create SQS queue for Scope ",
                "created_at": "1695719520",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1695719736",
                "changes": [
                    {
                        "id": "9f48601b-16bb-4670-81ad-c7d4e613fc06",
                        "created_at": "1695719531",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695719735",
                        "staggers": [
                            {
                                "created_at": "1695719533",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695719735"
                            }
                        ]
                    },
                    {
                        "id": "1d4615f0-49a9-4c54-a21e-d8017e0807cd",
                        "created_at": "1695719529",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695719736",
                        "staggers": [
                            {
                                "created_at": "1695719531",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695719736"
                            }
                        ]
                    },
                    {
                        "id": "8ef46465-a682-4dbc-b503-4dfa89bad4d1",
                        "created_at": "1695719527",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695719735",
                        "staggers": [
                            {
                                "created_at": "1695719529",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695719735"
                            }
                        ]
                    },
                    {
                        "id": "e232db7a-e00d-4342-9e2a-ebf64274f40d",
                        "created_at": "1695719524",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695719735",
                        "staggers": [
                            {
                                "created_at": "1695719527",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695719735"
                            }
                        ]
                    },
                    {
                        "id": "d6ac4b76-961d-4a7b-bfea-749697b22e89",
                        "created_at": "1695719520",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695719736",
                        "staggers": [
                            {
                                "created_at": "1695719524",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695719736"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-8125052e-731d-11ee-8203-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698228115",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698228190",
                "changes": [
                    {
                        "id": "a6f91d20-4c5c-47a3-9fd3-0b789d1b74d1",
                        "created_at": "1698228118",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698228190",
                        "staggers": [
                            {
                                "created_at": "1698228160",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698228190"
                            },
                            {
                                "created_at": "1698228121",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698228154"
                            }
                        ]
                    },
                    {
                        "id": "25b67287-eb74-4391-ae3c-5ebefdf23ec3",
                        "created_at": "1698228115",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698228153",
                        "staggers": [
                            {
                                "created_at": "1698228118",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698228153"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "250b731691096af044eeb002e17e2fff2e95d905",
                "commit_title": "removing parameter ",
                "created_at": "1728412774",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1728412978",
                "changes": [
                    {
                        "id": "9d991170-10e7-4017-88a3-7e3599c00275",
                        "created_at": "1728412774",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728412978",
                        "staggers": [
                            {
                                "created_at": "1728412776",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1728412978"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b761e7cfdc39c1189e1878c6642fc331063c32bb",
                "commit_title": "c5 ",
                "created_at": "1714994906",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714995110",
                "changes": [
                    {
                        "id": "18391224-7053-4c9a-bafe-ff0b10c8178a",
                        "created_at": "1714994906",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeSCPTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714995110",
                        "staggers": [
                            {
                                "created_at": "1714994907",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1714995110"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d092e5a9d8280bb6e7476079a4c0d934f7b64c13",
                "commit_title": "test commit:DO NOT MERGE ",
                "created_at": "1704130854",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1704130889",
                "changes": [
                    {
                        "id": "61ef2375-1660-4b4c-a90c-92608da0e4b1",
                        "created_at": "1704130854",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704130889",
                        "staggers": [
                            {
                                "created_at": "1704130856",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1704130889"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "7518b3af1ceea5101e1c24693405255663a94e3f",
                "commit_title": "@W-13768736 Update CSIRTLimitedReadWrite and STARReadOnly roles for Commercial ",
                "created_at": "1689751937",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1689752167",
                "changes": [
                    {
                        "id": "392ac037-9603-43a0-b2d5-0519720196da",
                        "created_at": "1689751958",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689752165",
                        "staggers": [
                            {
                                "created_at": "1689751962",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689752165"
                            }
                        ]
                    },
                    {
                        "id": "d2d8b052-0678-4615-9076-7c907f48e702",
                        "created_at": "1689751956",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689752164",
                        "staggers": [
                            {
                                "created_at": "1689751958",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689752164"
                            }
                        ]
                    },
                    {
                        "id": "ae26db2d-7433-443f-87eb-6064b90073a3",
                        "created_at": "1689751954",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689752165",
                        "staggers": [
                            {
                                "created_at": "1689751956",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689752165"
                            }
                        ]
                    },
                    {
                        "id": "c73c5c39-e67b-4977-9d37-f879454fc3b9",
                        "created_at": "1689751951",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689752166",
                        "staggers": [
                            {
                                "created_at": "1689751954",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689752166"
                            }
                        ]
                    },
                    {
                        "id": "cc328824-3f98-419c-af74-bcd850432718",
                        "created_at": "1689751947",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689752164",
                        "staggers": [
                            {
                                "created_at": "1689751951",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689752164"
                            }
                        ]
                    },
                    {
                        "id": "d2f968c0-9de3-44a5-818c-693a314a6a27",
                        "created_at": "1689751945",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689752167",
                        "staggers": [
                            {
                                "created_at": "1689751947",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689752167"
                            }
                        ]
                    },
                    {
                        "id": "ed8f6729-fd67-4fec-b06c-b45bcc64b430",
                        "created_at": "1689751943",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689752165",
                        "staggers": [
                            {
                                "created_at": "1689751945",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689752165"
                            }
                        ]
                    },
                    {
                        "id": "6aa862ea-2f3a-4c80-8527-bdbd45f66e2c",
                        "created_at": "1689751941",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689752166",
                        "staggers": [
                            {
                                "created_at": "1689751943",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689752166"
                            }
                        ]
                    },
                    {
                        "id": "86992eee-580c-4009-ab87-e41f98e3f96b",
                        "created_at": "1689751937",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689752166",
                        "staggers": [
                            {
                                "created_at": "1689751941",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689752166"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "7a9d835090cc2673f6111af28d3f0a1951f092e5",
                "commit_title": "@W-12620507 : Added StacksetManagerRole (#34) ",
                "created_at": "1683563601",
                "deployment_status": "FAILED",
                "developer_id": "jbhayani@salesforce.com",
                "updated_at": "1683564776",
                "changes": [
                    {
                        "id": "7f78b3d0-f427-4e93-9931-4ec067a9354e",
                        "created_at": "1683564570",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683564777",
                        "staggers": [
                            {
                                "created_at": "1683564572",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683564777"
                            }
                        ]
                    },
                    {
                        "id": "fe4e070e-6fb1-4dcd-8435-fd217b141100",
                        "created_at": "1683564568",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683564776",
                        "staggers": [
                            {
                                "created_at": "1683564570",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683564776"
                            }
                        ]
                    },
                    {
                        "id": "632ca71f-fe2a-4219-90e3-0fa4c9a2e610",
                        "created_at": "1683564566",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683564776",
                        "staggers": [
                            {
                                "created_at": "1683564568",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683564776"
                            }
                        ]
                    },
                    {
                        "id": "d995f78f-f6a8-4cab-8b34-543b70244655",
                        "created_at": "1683563620",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683563827",
                        "staggers": [
                            {
                                "created_at": "1683563622",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683563827"
                            }
                        ]
                    },
                    {
                        "id": "841cd536-f687-40bf-a235-25ca487d7aca",
                        "created_at": "1683563618",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683563827",
                        "staggers": [
                            {
                                "created_at": "1683563620",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683563827"
                            }
                        ]
                    },
                    {
                        "id": "d4bb91e7-a513-4f99-8bbd-dead951a1045",
                        "created_at": "1683563612",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683563827",
                        "staggers": [
                            {
                                "created_at": "1683563618",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683563827"
                            }
                        ]
                    },
                    {
                        "id": "d71d030e-0ca9-4c47-9998-339d6c69c914",
                        "created_at": "1683563606",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1683564776",
                        "staggers": [
                            {
                                "created_at": "1683564566",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683564776"
                            },
                            {
                                "created_at": "1683563611",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683563826"
                            }
                        ]
                    },
                    {
                        "id": "2efcddf8-019f-4fd4-bc18-c9f995a9be6e",
                        "created_at": "1683563603",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683563826",
                        "staggers": [
                            {
                                "created_at": "1683563606",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683563826"
                            }
                        ]
                    },
                    {
                        "id": "8a440332-cf31-4efe-8418-aa1b6899d5c1",
                        "created_at": "1683563601",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683563827",
                        "staggers": [
                            {
                                "created_at": "1683563603",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683563827"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "10c2cc3590879e4fca4ff90adb8dc4c9a1431380",
                "commit_title": "Adding more required permissions and removing prod info ",
                "created_at": "1729713505",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1729713709",
                "changes": [
                    {
                        "id": "0525118b-d7c7-4d05-b92c-93f9b5138489",
                        "created_at": "1729713505",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729713709",
                        "staggers": [
                            {
                                "created_at": "1729713507",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1729713709"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-8174b3b8-74b6-11ee-bed9-acde48001122",
                "commit_title": "test commit - delete stacksets created",
                "created_at": "1698403779",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698404189",
                "changes": [
                    {
                        "id": "27703145-9c3c-448b-a9d9-7aff7ec8d623",
                        "created_at": "1698403783",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698403985",
                        "staggers": [
                            {
                                "created_at": "1698403783",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698403984"
                            }
                        ]
                    },
                    {
                        "id": "98664e70-9ce2-4dff-9fab-5cd8bbabc230",
                        "created_at": "1698403781",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698403984",
                        "staggers": [
                            {
                                "created_at": "1698403783",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698403984"
                            }
                        ]
                    },
                    {
                        "id": "3cc2feb6-15e5-449d-bdc5-a441cf0e4fff",
                        "created_at": "1698403779",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698404189",
                        "staggers": [
                            {
                                "created_at": "1698403988",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698404189"
                            },
                            {
                                "created_at": "1698403781",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698403984"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "50b3ffcd3d88f7164e67f5d8f9d9fa0ddac282db",
                "commit_title": "test ",
                "created_at": "1717665837",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1717666039",
                "changes": [
                    {
                        "id": "edfb9eb1-5edc-4182-90ee-fb5699d126ea",
                        "created_at": "1717665837",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717666039",
                        "staggers": [
                            {
                                "created_at": "1717665839",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717666039"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b61091eb70b1288c10ec36130c0c5e4009f6813e",
                "commit_title": "addressed PR comments ",
                "created_at": "1700594314",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1700594527",
                "changes": [
                    {
                        "id": "ed9b844e-6f2b-4f4c-91ed-2a3ec97acff0",
                        "created_at": "1700594319",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700594527",
                        "staggers": [
                            {
                                "created_at": "1700594322",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700594527"
                            }
                        ]
                    },
                    {
                        "id": "a312f09e-2e88-4577-8854-f895124e35b3",
                        "created_at": "1700594317",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700594526",
                        "staggers": [
                            {
                                "created_at": "1700594319",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700594526"
                            }
                        ]
                    },
                    {
                        "id": "21334eee-8a1f-4f62-9171-0eb0f0d12806",
                        "created_at": "1700594314",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700594527",
                        "staggers": [
                            {
                                "created_at": "1700594317",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700594527"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-e58ac366-74b5-11ee-bed9-acde48001122_64921d64-74b9-11ee-af51-caf16c46e0a4",
                "commit_title": "test commit - revert to 2nd stage configuration,third stackset is created back",
                "created_at": "1698405012",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698405012",
                "changes": [
                    {
                        "id": "2b919109-e241-418c-9f51-ab8d7d4495d2",
                        "created_at": "1698405017",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698405220",
                        "staggers": [
                            {
                                "created_at": "1698405018",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698405220"
                            }
                        ]
                    },
                    {
                        "id": "8689e52b-6884-4bf3-8ad5-5b40b698c7c4",
                        "created_at": "1698405016",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698405219",
                        "staggers": [
                            {
                                "created_at": "1698405017",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698405219"
                            }
                        ]
                    },
                    {
                        "id": "fae415c3-6ee3-47c9-b91b-6d57114eafa0",
                        "created_at": "1698405015",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698405220",
                        "staggers": [
                            {
                                "created_at": "1698405016",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698405220"
                            }
                        ]
                    },
                    {
                        "id": "a4eeabd3-1302-4343-b8ba-d1e5b7a24bbf",
                        "created_at": "1698405014",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698405220",
                        "staggers": [
                            {
                                "created_at": "1698405015",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698405220"
                            }
                        ]
                    },
                    {
                        "id": "2c712b31-4d8c-4e0c-826c-7514d595b073",
                        "created_at": "1698405012",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698405219",
                        "staggers": [
                            {
                                "created_at": "1698405014",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698405219"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-62ce7a58-7343-11ee-848e-acde48001122",
                "commit_title": "test commit - create scopeTestThird stackset and change group config and template for scopeTestFirst,scopeTestSecond stacksets",
                "created_at": "1698244390",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698244534",
                "changes": [
                    {
                        "id": "f276b1db-9173-4553-87f9-c87bd27eea5c",
                        "created_at": "1698244400",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698244465",
                        "staggers": [
                            {
                                "created_at": "1698244401",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698244465"
                            }
                        ]
                    },
                    {
                        "id": "69e1a8c0-ba53-4679-b248-3ba39dc92268",
                        "created_at": "1698244399",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698244465",
                        "staggers": [
                            {
                                "created_at": "1698244400",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698244465"
                            }
                        ]
                    },
                    {
                        "id": "54e2cad4-78ab-43fb-a621-8486d010245a",
                        "created_at": "1698244398",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698244464",
                        "staggers": [
                            {
                                "created_at": "1698244399",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698244464"
                            }
                        ]
                    },
                    {
                        "id": "b49085c1-134f-4bc8-8cd4-a5f983ebd4d6",
                        "created_at": "1698244397",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698244466",
                        "staggers": [
                            {
                                "created_at": "1698244398",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698244466"
                            }
                        ]
                    },
                    {
                        "id": "23a2ddff-71a1-4528-bf07-14c748054897",
                        "created_at": "1698244396",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698244465",
                        "staggers": [
                            {
                                "created_at": "1698244397",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698244465"
                            }
                        ]
                    },
                    {
                        "id": "a4d4159e-aa48-417f-8d3d-b0ef4f4e0598",
                        "created_at": "1698244393",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698244464",
                        "staggers": [
                            {
                                "created_at": "1698244396",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698244464"
                            }
                        ]
                    },
                    {
                        "id": "9430bc9d-4a46-46f5-bba2-a7cc4307e207",
                        "created_at": "1698244390",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698244534",
                        "staggers": [
                            {
                                "created_at": "1698244473",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698244534"
                            },
                            {
                                "created_at": "1698244393",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698244465"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-d4ede294-7187-11ee-a13b-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698053890",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698053960",
                "changes": [
                    {
                        "id": "3d4a92a0-9193-4b8f-b1d6-59ffa53c6dcb",
                        "created_at": "1698053892",
                        "operation_type": "",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698053960",
                        "staggers": [
                            {
                                "created_at": "1698053930",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "second stagger group",
                                "updated_at": "1698053960"
                            },
                            {
                                "created_at": "1698053894",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698053924"
                            }
                        ]
                    },
                    {
                        "id": "9d5f81e2-0fb3-496a-9908-3066b9986699",
                        "created_at": "1698053890",
                        "operation_type": "",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698053925",
                        "staggers": [
                            {
                                "created_at": "1698053892",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "one account id",
                                "updated_at": "1698053925"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e8a92b2056a644902762865bb79997f98fec0316",
                "commit_title": "deploy-to-mpa-root ",
                "created_at": "1697105452",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1697107152",
                "changes": [
                    {
                        "id": "f9265fce-2769-4f0d-b0b5-70a8f8b4b48f",
                        "created_at": "1697105452",
                        "operation_type": "",
                        "stackset_id": "scopetestv1",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1697107149",
                        "staggers": [
                            {
                                "created_at": "1697105453",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "scope-ou",
                                "updated_at": "1697107146"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f2475ffde721a22d79a8349daa8ff409dc2775d6",
                "commit_title": "test ",
                "created_at": "1728501510",
                "deployment_status": "FAILED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1728501717",
                "changes": [
                    {
                        "id": "4ace2001-2022-4e26-9eb3-24d217145373",
                        "created_at": "1728501510",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "FAILED",
                        "updated_at": "1728501717",
                        "staggers": [
                            {
                                "created_at": "1728501515",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728501717"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "3535777329053a2ff1555f54f1444fdd0d71a0eb",
                "commit_title": "test ",
                "created_at": "1728480414",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1728480620",
                "changes": [
                    {
                        "id": "44ac80cb-7db6-4ad0-af61-0f128c35eeca",
                        "created_at": "1728480414",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728480620",
                        "staggers": [
                            {
                                "created_at": "1728480418",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728480620"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "938a746265450c97f09377c9b61b3cbe4ed9b640",
                "commit_title": "add another account as target for scopetest ",
                "created_at": "1694697610",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1694697676",
                "changes": [
                    {
                        "id": "b74f7f1e-4083-42df-8077-7a50dbc308dd",
                        "created_at": "1694697612",
                        "operation_type": "",
                        "stackset_id": "ScopeTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694697676",
                        "staggers": [
                            {
                                "created_at": "1694697613",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeNoStaggeringGroup",
                                "updated_at": "1694697676"
                            }
                        ]
                    },
                    {
                        "id": "9a4606f2-5d8e-4bcd-9502-e085a6db79f7",
                        "created_at": "1694697610",
                        "operation_type": "",
                        "stackset_id": "ScopeTestMpa",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694697676",
                        "staggers": [
                            {
                                "created_at": "1694697612",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "MPAs",
                                "updated_at": "1694697676"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f6dbd0ecaeb003d350fa2f68cb22377d5cfdbc35",
                "commit_title": "scope-demo-testing ",
                "created_at": "1692949427",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1692949874",
                "changes": [
                    {
                        "id": "b0e35b4d-aa4a-428e-94e2-63ffa84a47fc",
                        "created_at": "1692949449",
                        "operation_type": "",
                        "stackset_id": "scope-demo-bhor",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692949874",
                        "staggers": [
                            {
                                "created_at": "1692949672",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "scope-ou-second",
                                "updated_at": "1692949874"
                            },
                            {
                                "created_at": "1692949451",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "scope-ou",
                                "updated_at": "1692949657"
                            }
                        ]
                    },
                    {
                        "id": "cc2a43cb-d86c-4df9-bda6-75f49805e960",
                        "created_at": "1692949447",
                        "operation_type": "",
                        "stackset_id": "bhor-test",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692949874",
                        "staggers": [
                            {
                                "created_at": "1692949671",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "scope-ou-second",
                                "updated_at": "1692949874"
                            },
                            {
                                "created_at": "1692949449",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "scope-ou",
                                "updated_at": "1692949656"
                            }
                        ]
                    },
                    {
                        "id": "010ce21b-51dc-46e8-a505-365c6a521c10",
                        "created_at": "1692949445",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692949655",
                        "staggers": [
                            {
                                "created_at": "1692949447",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692949655"
                            }
                        ]
                    },
                    {
                        "id": "ce6f8918-0426-4447-a8b1-f4103bb75993",
                        "created_at": "1692949443",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692949657",
                        "staggers": [
                            {
                                "created_at": "1692949445",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692949657"
                            }
                        ]
                    },
                    {
                        "id": "edaa2e57-ac3b-4b15-a622-9b013a588325",
                        "created_at": "1692949441",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692949657",
                        "staggers": [
                            {
                                "created_at": "1692949443",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692949657"
                            }
                        ]
                    },
                    {
                        "id": "e07ea0cf-e797-4528-90fd-6fa2c8db82ec",
                        "created_at": "1692949437",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692949655",
                        "staggers": [
                            {
                                "created_at": "1692949441",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692949655"
                            }
                        ]
                    },
                    {
                        "id": "e519a275-d169-4971-9b9d-88364be97e94",
                        "created_at": "1692949435",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692949656",
                        "staggers": [
                            {
                                "created_at": "1692949437",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692949656"
                            }
                        ]
                    },
                    {
                        "id": "5ade7833-68d3-43ce-89c8-b774e2f9f3b3",
                        "created_at": "1692949433",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692949658",
                        "staggers": [
                            {
                                "created_at": "1692949435",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692949658"
                            }
                        ]
                    },
                    {
                        "id": "5dcdf9af-08d3-44fd-8f37-f47fde0afb0a",
                        "created_at": "1692949431",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692949656",
                        "staggers": [
                            {
                                "created_at": "1692949433",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692949656"
                            }
                        ]
                    },
                    {
                        "id": "b546c6f8-f637-4767-b6b4-dbc8ce70a53f",
                        "created_at": "1692949430",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692949656",
                        "staggers": [
                            {
                                "created_at": "1692949431",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692949656"
                            }
                        ]
                    },
                    {
                        "id": "9ea17c90-7035-4db5-aee6-3b44af1a9b4f",
                        "created_at": "1692949427",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692949657",
                        "staggers": [
                            {
                                "created_at": "1692949430",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692949657"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "dbd1fd696afc909b29a95f6784481fff0275e3aa",
                "commit_title": "- region fix ",
                "created_at": "1683616796",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1683617022",
                "changes": [
                    {
                        "id": "6ce691e4-ba12-4301-aa07-1810657ff41c",
                        "created_at": "1683616815",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683617021",
                        "staggers": [
                            {
                                "created_at": "1683616816",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683617021"
                            }
                        ]
                    },
                    {
                        "id": "01b7e883-5855-4f16-b4e7-dd2f10ae8fa8",
                        "created_at": "1683616813",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683617022",
                        "staggers": [
                            {
                                "created_at": "1683616815",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683617022"
                            }
                        ]
                    },
                    {
                        "id": "356c17aa-7732-4678-af4a-92d72d49af5f",
                        "created_at": "1683616808",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683617021",
                        "staggers": [
                            {
                                "created_at": "1683616813",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683617021"
                            }
                        ]
                    },
                    {
                        "id": "d67b4e56-f385-41a0-bb6d-65dcbb98c9c7",
                        "created_at": "1683616807",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683617020",
                        "staggers": [
                            {
                                "created_at": "1683616808",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683617020"
                            }
                        ]
                    },
                    {
                        "id": "46c8fe8b-0cd7-46de-ad44-6c3821d47970",
                        "created_at": "1683616806",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683617022",
                        "staggers": [
                            {
                                "created_at": "1683616807",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683617022"
                            }
                        ]
                    },
                    {
                        "id": "9648c573-7565-4e3a-9ed1-114ab7a4eb94",
                        "created_at": "1683616800",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683617021",
                        "staggers": [
                            {
                                "created_at": "1683616806",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683617021"
                            }
                        ]
                    },
                    {
                        "id": "bef8bf25-ee80-451c-933d-0e9b205bc093",
                        "created_at": "1683616799",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683617020",
                        "staggers": [
                            {
                                "created_at": "1683616800",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683617020"
                            }
                        ]
                    },
                    {
                        "id": "2ae701f1-713a-45a3-b3be-a8cd6b466d7f",
                        "created_at": "1683616797",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683617022",
                        "staggers": [
                            {
                                "created_at": "1683616799",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683617022"
                            }
                        ]
                    },
                    {
                        "id": "4815e7c7-b2e9-49cc-b3b4-62530a92a983",
                        "created_at": "1683616796",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683617022",
                        "staggers": [
                            {
                                "created_at": "1683616797",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683617022"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "16faff2d3b6ebdf91c140ea4e5e1c2ee10f7ac82",
                "commit_title": "Merge pull request #125 from identity-access/memory ",
                "created_at": "1708666884",
                "deployment_status": "COMPLETED",
                "developer_id": "xtao@salesforce.com",
                "updated_at": "1708667098",
                "changes": [
                    {
                        "id": "6e52e3eb-84ee-4a37-bbf4-d2a7ba50faad",
                        "created_at": "1708666889",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1708667095",
                        "staggers": [
                            {
                                "created_at": "1708666891",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1708667095"
                            }
                        ]
                    },
                    {
                        "id": "03783575-8c9c-46a7-b688-5cf171de626c",
                        "created_at": "1708666884",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1708667098",
                        "staggers": [
                            {
                                "created_at": "1708666889",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1708667098"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3ba828e4eca1ebded9d0f08381399ee8e2c639c2",
                "commit_title": "updating arn ",
                "created_at": "1730303573",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730303777",
                "changes": [
                    {
                        "id": "fb5c0767-62d2-4904-84f4-daf458c6af39",
                        "created_at": "1730303573",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730303777",
                        "staggers": [
                            {
                                "created_at": "1730303575",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730303777"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4c74b84a0dd0bab23c98d9a6b38b5eccf798c038_905418466263_3db54e37-e83c-11ee-90f3-bac014bd1ee5",
                "commit_title": "create onboardingtest stackset ",
                "created_at": "1711105596",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1711105800",
                "changes": [
                    {
                        "id": "54ae8a5a-1f6f-400c-beb3-fe88985a7dae",
                        "created_at": "1711105596",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeOnboardingTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711105800",
                        "staggers": [
                            {
                                "created_at": "1711105597",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1711105800"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-0e9f87d0-7193-11ee-a862-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698058708",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698058777",
                "changes": [
                    {
                        "id": "323beb87-a311-4c0e-8f57-a1f6b37f0288",
                        "created_at": "1698058710",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698058777",
                        "staggers": [
                            {
                                "created_at": "1698058746",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "second stagger group",
                                "updated_at": "1698058777"
                            },
                            {
                                "created_at": "1698058712",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698058743"
                            }
                        ]
                    },
                    {
                        "id": "34acfdf6-67ab-4edf-aaa5-4db28bfaf5b1",
                        "created_at": "1698058708",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698058743",
                        "staggers": [
                            {
                                "created_at": "1698058710",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "one account id",
                                "updated_at": "1698058743"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "8a7dde615758ea0ae50a9d1facaa85818c8f53a3",
                "commit_title": "template change revert ",
                "created_at": "1694694516",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1694694736",
                "changes": [
                    {
                        "id": "6cecd256-913d-4de3-9376-e8d3b9dc319f",
                        "created_at": "1694694538",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694694735",
                        "staggers": [
                            {
                                "created_at": "1694694539",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694694735"
                            }
                        ]
                    },
                    {
                        "id": "0c074e48-0273-4fa5-b943-9a63ce1e13db",
                        "created_at": "1694694534",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694694604",
                        "staggers": [
                            {
                                "created_at": "1694694538",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694694604"
                            }
                        ]
                    },
                    {
                        "id": "2ef9861f-ae7b-4f84-873f-399fcb244fc8",
                        "created_at": "1694694532",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694694604",
                        "staggers": [
                            {
                                "created_at": "1694694534",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694694604"
                            }
                        ]
                    },
                    {
                        "id": "7934004a-1ede-47fc-890b-c3d3d87afc3e",
                        "created_at": "1694694530",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694694736",
                        "staggers": [
                            {
                                "created_at": "1694694532",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694694736"
                            }
                        ]
                    },
                    {
                        "id": "a164e0de-f914-4d32-8a60-13e2c6ed210f",
                        "created_at": "1694694528",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694694604",
                        "staggers": [
                            {
                                "created_at": "1694694530",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694694604"
                            }
                        ]
                    },
                    {
                        "id": "45845073-fff8-4cb6-b10d-82af1833e3ce",
                        "created_at": "1694694526",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694694736",
                        "staggers": [
                            {
                                "created_at": "1694694528",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694694736"
                            }
                        ]
                    },
                    {
                        "id": "dd10976e-c03b-48a7-90b7-235402d7b3af",
                        "created_at": "1694694522",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694694735",
                        "staggers": [
                            {
                                "created_at": "1694694526",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694694735"
                            }
                        ]
                    },
                    {
                        "id": "64e45375-35d1-4af7-ba68-5efc685fceeb",
                        "created_at": "1694694520",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694694605",
                        "staggers": [
                            {
                                "created_at": "1694694522",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694694605"
                            }
                        ]
                    },
                    {
                        "id": "e73556f9-dff0-4da9-bb69-bd54bd616626",
                        "created_at": "1694694518",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694694735",
                        "staggers": [
                            {
                                "created_at": "1694694520",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694694735"
                            }
                        ]
                    },
                    {
                        "id": "3c6a8819-f95b-48ea-a89b-c84623484596",
                        "created_at": "1694694516",
                        "operation_type": "",
                        "stackset_id": "ScopeTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694694735",
                        "staggers": [
                            {
                                "created_at": "1694694518",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "dev account",
                                "updated_at": "1694694735"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "ef732b4f0f309d8d0a710622ffdaf6e91aa066ba",
                "commit_title": "timers ",
                "created_at": "1683652982",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1683653959",
                "changes": [
                    {
                        "id": "fd10dc78-bcd8-4745-aec2-4b18b5c06674",
                        "created_at": "1683653316",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683653524",
                        "staggers": [
                            {
                                "created_at": "1683653318",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683653524"
                            }
                        ]
                    },
                    {
                        "id": "05f5eaf3-13b7-4d8e-9e78-104c77118b98",
                        "created_at": "1683653314",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683653525",
                        "staggers": [
                            {
                                "created_at": "1683653316",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683653525"
                            }
                        ]
                    },
                    {
                        "id": "f338b52d-e4dc-4bfd-88b1-0f8aa71a1eba",
                        "created_at": "1683653307",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683653523",
                        "staggers": [
                            {
                                "created_at": "1683653314",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683653523"
                            }
                        ]
                    },
                    {
                        "id": "3b39b584-9815-49f5-96d6-fb033e1399ba",
                        "created_at": "1683653302",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1683653959",
                        "staggers": [
                            {
                                "created_at": "1683653972",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683653972"
                            },
                            {
                                "created_at": "1683653757",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683653959"
                            },
                            {
                                "created_at": "1683653540",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683653743"
                            },
                            {
                                "created_at": "1683653307",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683653523"
                            }
                        ]
                    },
                    {
                        "id": "9a56ed3c-cef0-41d0-96d9-c24f9c9c4358",
                        "created_at": "1683653300",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683653524",
                        "staggers": [
                            {
                                "created_at": "1683653302",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683653524"
                            }
                        ]
                    },
                    {
                        "id": "c0337a22-fbcc-4e34-a856-e1b7c73ac037",
                        "created_at": "1683653298",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683653523",
                        "staggers": [
                            {
                                "created_at": "1683653300",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683653523"
                            }
                        ]
                    },
                    {
                        "id": "725b0d33-6084-4cb6-b7e0-3dae8daaecb4",
                        "created_at": "1683653296",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683653523",
                        "staggers": [
                            {
                                "created_at": "1683653298",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683653523"
                            }
                        ]
                    },
                    {
                        "id": "3650ef80-0780-41da-874b-957e7d1ca345",
                        "created_at": "1683653293",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683653525",
                        "staggers": [
                            {
                                "created_at": "1683653296",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683653525"
                            }
                        ]
                    },
                    {
                        "id": "4e79a386-6f37-4f8a-a872-c29b24388ab9",
                        "created_at": "1683652982",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683653524",
                        "staggers": [
                            {
                                "created_at": "1683653293",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683653524"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "8da82c09200b66241c5df5bdaa83eda78244c91c",
                "commit_title": "fix ",
                "created_at": "1729019779",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1729020187",
                "changes": [
                    {
                        "id": "44d1ce9f-3778-4768-91a1-f33ae8de10b6",
                        "created_at": "1729019779",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729020187",
                        "staggers": [
                            {
                                "created_at": "1729019784",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729020187"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "56d429077fc2c25071c953ecae27b6288e7196a9",
                "commit_title": "changes to test ",
                "created_at": "1720085181",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1720085616",
                "changes": [
                    {
                        "id": "72ffc58d-1bac-4db8-bd50-40095bf15289",
                        "created_at": "1720085188",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1720085616",
                        "staggers": [
                            {
                                "created_at": "1720085413",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1720085616"
                            },
                            {
                                "created_at": "1720085190",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1720085394"
                            }
                        ]
                    },
                    {
                        "id": "0d872242-fb9b-4015-adf7-f4244371fc7d",
                        "created_at": "1720085186",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1720085615",
                        "staggers": [
                            {
                                "created_at": "1720085409",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1720085615"
                            },
                            {
                                "created_at": "1720085188",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1720085394"
                            }
                        ]
                    },
                    {
                        "id": "7e45a100-399d-4a51-97a5-a14689653fd2",
                        "created_at": "1720085183",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1720085615",
                        "staggers": [
                            {
                                "created_at": "1720085411",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Non production",
                                "updated_at": "1720085615"
                            },
                            {
                                "created_at": "1720085186",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Non production stage",
                                "updated_at": "1720085394"
                            }
                        ]
                    },
                    {
                        "id": "48cea4ec-8e3d-4d4e-a2e4-2cda591b9349",
                        "created_at": "1720085181",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1720085393",
                        "staggers": [
                            {
                                "created_at": "1720085183",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1720085393"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e8d58e17f351224efaa7618fc8a432bf03a57d1c",
                "commit_title": "updated-template ",
                "created_at": "1715781942",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715782045",
                "changes": [
                    {
                        "id": "66ae0c81-cffc-4993-bb5c-fd5d87fa10f0",
                        "created_at": "1715781942",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeSCPServiceManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715782045",
                        "staggers": [
                            {
                                "created_at": "1715781943",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715782045"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "53bbe4e4cbeeac51dbc95c1ebd1d08d462eb65dc",
                "commit_title": "scope-sai-role-updated ",
                "created_at": "1714050429",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714050836",
                "changes": [
                    {
                        "id": "5e668d7e-1c57-40da-af59-6f916edc5e0a",
                        "created_at": "1714050429",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSAIRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714050836",
                        "staggers": [
                            {
                                "created_at": "1714050431",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1714050836"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4f721c05b4755ae06626ccd4c34d6724f594e19a",
                "commit_title": "a ",
                "created_at": "1683809796",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1683810068",
                "changes": [
                    {
                        "id": "ecf62d8f-4296-4976-9234-94e0ebcfec05",
                        "created_at": "1683809857",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683810065",
                        "staggers": [
                            {
                                "created_at": "1683809859",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683810065"
                            }
                        ]
                    },
                    {
                        "id": "538102fd-0fc1-49f9-b201-9b5f61a9749b",
                        "created_at": "1683809855",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683810067",
                        "staggers": [
                            {
                                "created_at": "1683809857",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683810067"
                            }
                        ]
                    },
                    {
                        "id": "a7e90260-04bd-4c73-8165-7b63b8360f55",
                        "created_at": "1683809853",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683810067",
                        "staggers": [
                            {
                                "created_at": "1683809855",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683810067"
                            }
                        ]
                    },
                    {
                        "id": "cae53690-a050-49bc-b066-b6cf76600129",
                        "created_at": "1683809847",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683810066",
                        "staggers": [
                            {
                                "created_at": "1683809853",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683810066"
                            }
                        ]
                    },
                    {
                        "id": "e5d68bed-d346-4cbb-839c-1b2a0bdf64b1",
                        "created_at": "1683809845",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683810066",
                        "staggers": [
                            {
                                "created_at": "1683809847",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683810066"
                            }
                        ]
                    },
                    {
                        "id": "8942b76f-afbf-459b-bdb5-ab24badd77bc",
                        "created_at": "1683809840",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1683810068",
                        "staggers": [
                            {
                                "created_at": "1683810080",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683810080"
                            },
                            {
                                "created_at": "1683809845",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683810068"
                            }
                        ]
                    },
                    {
                        "id": "99e6d7e5-fa4c-4992-8ed8-1a440dbd61f9",
                        "created_at": "1683809838",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683810065",
                        "staggers": [
                            {
                                "created_at": "1683809840",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683810065"
                            }
                        ]
                    },
                    {
                        "id": "2bf7ef29-b8d6-42ae-ab21-7eb07e92073f",
                        "created_at": "1683809836",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683810066",
                        "staggers": [
                            {
                                "created_at": "1683809838",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683810066"
                            }
                        ]
                    },
                    {
                        "id": "cf3ff818-db7e-4509-ae9b-76d6be23a634",
                        "created_at": "1683809796",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683810067",
                        "staggers": [
                            {
                                "created_at": "1683809836",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683810067"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "d9ba91f766e7d7d39986827fbe0e07bf86ad2836",
                "commit_title": "updating arn ",
                "created_at": "1730305400",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730305603",
                "changes": [
                    {
                        "id": "2ffdc71a-9ea7-4451-a0bd-b1ba777acf47",
                        "created_at": "1730305400",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730305603",
                        "staggers": [
                            {
                                "created_at": "1730305401",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730305603"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "8c3357a0cd24bb50ccd9593d4ea0b6be5202b46b",
                "commit_title": "reverse-condition ",
                "created_at": "1721036510",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1721036714",
                "changes": [
                    {
                        "id": "72cc62c9-68a4-4f74-9bd6-569e03c7b882",
                        "created_at": "1721036510",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeFalconAsNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721036714",
                        "staggers": [
                            {
                                "created_at": "1721036511",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group ProdGA 1",
                                "updated_at": "1721036714"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a0ffabc8c1450f8bd0c130db12ee9875acdbe249",
                "commit_title": "update-sai-role-name-p2 ",
                "created_at": "1715001595",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715001799",
                "changes": [
                    {
                        "id": "49399a7f-71fc-4e04-97a2-6e11892dfac0",
                        "created_at": "1715001595",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSAIRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715001799",
                        "staggers": [
                            {
                                "created_at": "1715001597",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1715001799"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "fc6cdd8451c3d1992b5ea6f7c999381d8a3c4427",
                "commit_title": "mrt role creation ",
                "created_at": "1709300067",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1709300561",
                "changes": [
                    {
                        "id": "01f29699-0372-4f5b-90c2-0a5808657312",
                        "created_at": "1709300080",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeMRTRoles",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709300561",
                        "staggers": [
                            {
                                "created_at": "1709300358",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1709300561"
                            },
                            {
                                "created_at": "1709300081",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1709300285"
                            }
                        ]
                    },
                    {
                        "id": "6dbd498e-f159-44a6-8f23-2bc12b54d308",
                        "created_at": "1709300078",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709300561",
                        "staggers": [
                            {
                                "created_at": "1709300359",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709300561"
                            },
                            {
                                "created_at": "1709300080",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1709300286"
                            }
                        ]
                    },
                    {
                        "id": "7aa0d0f2-914a-4ace-8443-06d2d90b676c",
                        "created_at": "1709300076",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709300285",
                        "staggers": [
                            {
                                "created_at": "1709300078",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709300285"
                            }
                        ]
                    },
                    {
                        "id": "e28a48e5-ac1e-4955-ad92-586a3865ba2e",
                        "created_at": "1709300071",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709300285",
                        "staggers": [
                            {
                                "created_at": "1709300076",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709300285"
                            }
                        ]
                    },
                    {
                        "id": "d28ede0b-38b5-455e-afa4-fc2206a3e257",
                        "created_at": "1709300069",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709300286",
                        "staggers": [
                            {
                                "created_at": "1709300071",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709300286"
                            }
                        ]
                    },
                    {
                        "id": "a4f70e4e-d590-49ea-834c-8fba3fb8f6e7",
                        "created_at": "1709300067",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709300285",
                        "staggers": [
                            {
                                "created_at": "1709300069",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709300285"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "755d51d780b7d62ba597da7795b7947448c6564e",
                "commit_title": "testing ",
                "created_at": "1731505299",
                "deployment_status": "COMPLETED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731505505",
                "changes": [
                    {
                        "id": "70423203-ba4a-4757-8afa-050a92c7208d",
                        "created_at": "1731505299",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731505505",
                        "staggers": [
                            {
                                "created_at": "1731505303",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731505505"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "c1053da367861e3bdb09ae06d1b1b0ed7f529717",
                "commit_title": "Renaming and updating staggers ",
                "created_at": "1729617366",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1729617771",
                "changes": [
                    {
                        "id": "282675e1-dc0d-41b2-81d8-b7c30de46785",
                        "created_at": "1729617366",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729617771",
                        "staggers": [
                            {
                                "created_at": "1729617368",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1729617771"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d5a09351e96a6e742edcfeb7378c45f1dca8116c",
                "commit_title": "service-managed-scp-test-nonfalcon ",
                "created_at": "1715767192",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715767397",
                "changes": [
                    {
                        "id": "1e9118ba-babf-4375-a794-c592824a0b79",
                        "created_at": "1715767192",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSCPServiceManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715767397",
                        "staggers": [
                            {
                                "created_at": "1715767194",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715767397"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "9fdad48c675a2bfb48f7f47ee63e76f1cb005600",
                "commit_title": "added-service-managed-stacksets ",
                "created_at": "1698920751",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1698920751",
                "changes": [
                    {
                        "id": "40809f4e-399b-4f17-a487-6828750b6e20",
                        "created_at": "1698920751",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeServiceManagedDemo",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698920751",
                        "staggers": null
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4c74b84a0dd0bab23c98d9a6b38b5eccf798c038",
                "commit_title": "create onboardingtest stackset ",
                "created_at": "1711105083",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1711107822",
                "changes": [
                    {
                        "id": "20262b93-b0c2-4512-82ef-c72de1630f18",
                        "created_at": "1711105084",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeOnboardingTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711107822",
                        "staggers": [
                            {
                                "created_at": "1711107619",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "demo ou 2",
                                "updated_at": "1711107822"
                            },
                            {
                                "created_at": "1711105087",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "internal ou",
                                "updated_at": "1711105290"
                            }
                        ]
                    },
                    {
                        "id": "8d589dd7-1d9e-4e66-af81-cf735497dbf7",
                        "created_at": "1711105083",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711105290",
                        "staggers": [
                            {
                                "created_at": "1711105084",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1711105290"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e308af39191332be22b53245dfd9f80691d56d17",
                "commit_title": "Adding non-falcon MPA staggered groups ",
                "created_at": "1684825019",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1684825854",
                "changes": [
                    {
                        "id": "ebf975cb-454f-4a98-8000-26a135902ff2",
                        "created_at": "1684825022",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684825228",
                        "staggers": [
                            {
                                "created_at": "1684825024",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684825228"
                            }
                        ]
                    },
                    {
                        "id": "8b5f3deb-5463-4d69-b18a-7e524f61bb8e",
                        "created_at": "1684825019",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684825854",
                        "staggers": [
                            {
                                "created_at": "1684825652",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684825854"
                            },
                            {
                                "created_at": "1684825472",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684825472"
                            },
                            {
                                "created_at": "1684825022",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684825228"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e5065e774001b5996a6de77135800531705f2545_799696138513_f8508ea6-674e-11ee-a2f9-e2644c3b215e",
                "commit_title": "updated-sg-3 ",
                "created_at": "1696929940",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1696929940",
                "changes": [
                    {
                        "id": "05e93ee0-541d-486b-a8c3-13a5e32fc7c2",
                        "created_at": "1696929940",
                        "operation_type": "",
                        "stackset_id": "scopetestv6",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696930146",
                        "staggers": [
                            {
                                "created_at": "1696929941",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeNoStaggeringGroup",
                                "updated_at": "1696930146"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e9d213db5a57171c6ec6b4a79965976105a15740",
                "commit_title": "updating map syntax ",
                "created_at": "1730298774",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730298978",
                "changes": [
                    {
                        "id": "e58d6562-62a4-4b2e-ab09-9a370ef505f4",
                        "created_at": "1730298774",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730298978",
                        "staggers": [
                            {
                                "created_at": "1730298776",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730298978"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "8a54f41fdc59a3106d8d9a5716fa378720e1e734",
                "commit_title": "test ",
                "created_at": "1728482640",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1728482848",
                "changes": [
                    {
                        "id": "b1ae97e8-00d1-4b25-85ff-efa1ed747cf6",
                        "created_at": "1728482640",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728482848",
                        "staggers": [
                            {
                                "created_at": "1728482646",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728482848"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "936ca08e03d9c517ffd9338b35840f5a40c2484e",
                "commit_title": "fixing indent ",
                "created_at": "1727986377",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727986581",
                "changes": [
                    {
                        "id": "1885d86f-f560-49c1-8292-17e4369fb146",
                        "created_at": "1727986377",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727986581",
                        "staggers": [
                            {
                                "created_at": "1727986379",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1727986581"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d51d0587e4c8052eb2f138402fbd11cb995b55b8",
                "commit_title": "removing array for ouids ",
                "created_at": "1727964329",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727964532",
                "changes": [
                    {
                        "id": "62a80de8-995a-4ccc-bdfd-fb2ae305493a",
                        "created_at": "1727964329",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727964532",
                        "staggers": [
                            {
                                "created_at": "1727964331",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1727964532"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "903947316d28b7e943dc77cd4e9c6629b3483417",
                "commit_title": "scope-sai-role-test ",
                "created_at": "1714049877",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714050291",
                "changes": [
                    {
                        "id": "9a63c45c-c579-43ff-829a-70f13087ff96",
                        "created_at": "1714049884",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeSAIRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714050291",
                        "staggers": [
                            {
                                "created_at": "1714049886",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1714050291"
                            }
                        ]
                    },
                    {
                        "id": "7145d304-04b4-4742-bf3f-ceb52d90b433",
                        "created_at": "1714049877",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714050291",
                        "staggers": [
                            {
                                "created_at": "1714049884",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1714050291"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "5e6256277c863f1b3e75bc81ca4884b486a56c1d",
                "commit_title": " @W-14193082 | Upgraded PCSK nodejs14 lambdas Part 3 [Magenta Release] (#72) ",
                "created_at": "1701776684",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1701776903",
                "changes": [
                    {
                        "id": "aba6bb60-4164-4d71-a015-f092cd52297d",
                        "created_at": "1701776695",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701776902",
                        "staggers": [
                            {
                                "created_at": "1701776697",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1701776902"
                            }
                        ]
                    },
                    {
                        "id": "3ff86faa-0888-4d01-93c2-a250307fad11",
                        "created_at": "1701776693",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701776901",
                        "staggers": [
                            {
                                "created_at": "1701776695",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1701776901"
                            }
                        ]
                    },
                    {
                        "id": "36d23c42-21f6-4923-b6e5-44d17383cce5",
                        "created_at": "1701776691",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701776903",
                        "staggers": [
                            {
                                "created_at": "1701776693",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1701776903"
                            }
                        ]
                    },
                    {
                        "id": "958dcb8a-e70f-46a2-87c7-dad5fec75437",
                        "created_at": "1701776689",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701776902",
                        "staggers": [
                            {
                                "created_at": "1701776691",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1701776902"
                            }
                        ]
                    },
                    {
                        "id": "2d29f477-3440-45ec-b24a-cdd5459d5f48",
                        "created_at": "1701776684",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701776902",
                        "staggers": [
                            {
                                "created_at": "1701776689",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1701776902"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-145b9b58-7320-11ee-b831-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698229220",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698229291",
                "changes": [
                    {
                        "id": "aca58580-9055-4ae3-94ee-09e8ef03c2a6",
                        "created_at": "1698229222",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698229291",
                        "staggers": [
                            {
                                "created_at": "1698229261",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698229291"
                            },
                            {
                                "created_at": "1698229225",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698229257"
                            }
                        ]
                    },
                    {
                        "id": "3d2d376e-d6bf-41c6-b645-908bb35d93bc",
                        "created_at": "1698229220",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698229256",
                        "staggers": [
                            {
                                "created_at": "1698229222",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698229256"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "2b9665867262c4a30e174e9825d839fea53d8e11",
                "commit_title": "added-test-stage ",
                "created_at": "1720161001",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1720161886",
                "changes": [
                    {
                        "id": "a9452bd2-9d51-47a6-b2a4-51f29e11beb4",
                        "created_at": "1720161004",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeFalconAsNonFalcon",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1720161886",
                        "staggers": null
                    },
                    {
                        "id": "bb4c6930-5451-4045-976b-f65f2add3dbc",
                        "created_at": "1720161001",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1720161886",
                        "staggers": [
                            {
                                "created_at": "1720161004",
                                "operation_status": "OVERRIDDEN",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1720161886"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "5b22a4f187b4ec4842aa45a3f407ac2a3ddd11e4",
                "commit_title": "c5 ",
                "created_at": "1714997954",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714998360",
                "changes": [
                    {
                        "id": "bfcc2512-553e-4ca3-ad73-bb16b69880f1",
                        "created_at": "1714997954",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSCPTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714998360",
                        "staggers": [
                            {
                                "created_at": "1714997956",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1714998360"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4a39ceefdd4dad9340f358fe5d5257b03d0086d0",
                "commit_title": "updated-scopeEmptyOU3 ",
                "created_at": "1714401872",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714402075",
                "changes": [
                    {
                        "id": "6e5c98c3-d962-497b-a385-bbb3a8eae896",
                        "created_at": "1714401872",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeEmptyOU",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714402075",
                        "staggers": [
                            {
                                "created_at": "1714401873",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1714402075"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "88cc1573db4c3b2fe32e78e4b85c7c5b9567fc2b",
                "commit_title": "ec2 ",
                "created_at": "1694620951",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1694621184",
                "changes": [
                    {
                        "id": "a4084389-56ab-48af-9a33-eb1246d0174a",
                        "created_at": "1694620976",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694621182",
                        "staggers": [
                            {
                                "created_at": "1694620978",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694621182"
                            }
                        ]
                    },
                    {
                        "id": "c63b4273-323d-4283-b64f-a26a253d2d41",
                        "created_at": "1694620974",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694621182",
                        "staggers": [
                            {
                                "created_at": "1694620976",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694621182"
                            }
                        ]
                    },
                    {
                        "id": "48c8e161-132e-403d-8969-8359d14dff53",
                        "created_at": "1694620972",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694621183",
                        "staggers": [
                            {
                                "created_at": "1694620974",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694621183"
                            }
                        ]
                    },
                    {
                        "id": "11f76575-9463-4314-a8e4-8e579452e8b3",
                        "created_at": "1694620969",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694621184",
                        "staggers": [
                            {
                                "created_at": "1694620972",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694621184"
                            }
                        ]
                    },
                    {
                        "id": "d083c4fa-7c1d-499f-9772-fc7e1b8224ce",
                        "created_at": "1694620967",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694621183",
                        "staggers": [
                            {
                                "created_at": "1694620969",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694621183"
                            }
                        ]
                    },
                    {
                        "id": "1521fdc6-de05-4d51-a5a0-9d13e7c668cd",
                        "created_at": "1694620959",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694621184",
                        "staggers": [
                            {
                                "created_at": "1694620967",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694621184"
                            }
                        ]
                    },
                    {
                        "id": "33f23844-01d3-496d-8873-f1b93a7960c0",
                        "created_at": "1694620957",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694621182",
                        "staggers": [
                            {
                                "created_at": "1694620959",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694621182"
                            }
                        ]
                    },
                    {
                        "id": "8f74c4a7-9c99-4c9f-b415-d232418acb09",
                        "created_at": "1694620955",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694621183",
                        "staggers": [
                            {
                                "created_at": "1694620957",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694621183"
                            }
                        ]
                    },
                    {
                        "id": "f90f64f0-b017-4477-bd85-bd0aef02f20d",
                        "created_at": "1694620951",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694621183",
                        "staggers": [
                            {
                                "created_at": "1694620955",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694621183"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "1dd255d86499d754ce16a50f03073c7331507a4b",
                "commit_title": "testing ",
                "created_at": "1731518960",
                "deployment_status": "FAILED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731519166",
                "changes": [
                    {
                        "id": "b518aca4-5bf1-4343-83d4-02825ec6d876",
                        "created_at": "1731518960",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1731519166",
                        "staggers": [
                            {
                                "created_at": "1731518964",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731519166"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "850a56ff7c3204845718b9be6cec1c18ba72bb76",
                "commit_title": "updatign version ",
                "created_at": "1727983693",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727983896",
                "changes": [
                    {
                        "id": "49aade06-ccd8-4a66-91e3-7a97eb33da11",
                        "created_at": "1727983693",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727983896",
                        "staggers": [
                            {
                                "created_at": "1727983694",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1727983896"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "c095fd3c18435ace5b2729dd3e07fa4c76adb60b",
                "commit_title": "test ",
                "created_at": "1716386456",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1716387109",
                "changes": [
                    {
                        "id": "7faf560e-882e-4735-b877-340c7cc31444",
                        "created_at": "1716386466",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1716387109",
                        "staggers": [
                            {
                                "created_at": "1716386468",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1716386871"
                            }
                        ]
                    },
                    {
                        "id": "7388e987-3206-4dd0-8784-0f8b78048183",
                        "created_at": "1716386458",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716386871",
                        "staggers": [
                            {
                                "created_at": "1716386466",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1716386871"
                            }
                        ]
                    },
                    {
                        "id": "d11e36f3-2a68-46af-9d73-9e76f50b6c3b",
                        "created_at": "1716386456",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716386872",
                        "staggers": [
                            {
                                "created_at": "1716386458",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1716386871"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "d603b4174214775b6a9888014164286274a42547",
                "commit_title": "fixed-paramater2 ",
                "created_at": "1715262416",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715593137",
                "changes": [
                    {
                        "id": "ca2b35ac-6ded-4fa1-b7d2-99fba5cd3b68",
                        "created_at": "1715262416",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715593137",
                        "staggers": [
                            {
                                "created_at": "1715592531",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715593136"
                            },
                            {
                                "created_at": "1715262424",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715262627"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e18e2b5e182162855f879091580988215c5fe839",
                "commit_title": "c4 ",
                "created_at": "1714994446",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714994650",
                "changes": [
                    {
                        "id": "8fac5d90-84f9-49e0-a904-8de02cfdfc3a",
                        "created_at": "1714994446",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeSCPTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714994650",
                        "staggers": [
                            {
                                "created_at": "1714994447",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1714994650"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-be921b70-7180-11ee-8716-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698050848",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698050917",
                "changes": [
                    {
                        "id": "5ed3f56a-fdcd-4194-9218-20debf0379c2",
                        "created_at": "1698050850",
                        "operation_type": "",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698050917",
                        "staggers": [
                            {
                                "created_at": "1698050886",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "second stagger group",
                                "updated_at": "1698050917"
                            },
                            {
                                "created_at": "1698050852",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698050883"
                            }
                        ]
                    },
                    {
                        "id": "e35b2606-0bbc-4504-8968-cf3839c31e90",
                        "created_at": "1698050848",
                        "operation_type": "",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698050883",
                        "staggers": [
                            {
                                "created_at": "1698050850",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "one account id",
                                "updated_at": "1698050883"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "24ae43c5053fbdb28b9444d5fa9e84dd49cbefbc",
                "commit_title": "remove mpa stackset ",
                "created_at": "1694690803",
                "deployment_status": "FAILED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1694691029",
                "changes": [
                    {
                        "id": "04ca18b6-4bf0-43ed-96c7-f1502b996c68",
                        "created_at": "1694690821",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694691028",
                        "staggers": [
                            {
                                "created_at": "1694690824",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694691028"
                            }
                        ]
                    },
                    {
                        "id": "dd6f8af4-cdd3-4ea4-80a9-a44826443a33",
                        "created_at": "1694690818",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694691029",
                        "staggers": [
                            {
                                "created_at": "1694690821",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694691029"
                            }
                        ]
                    },
                    {
                        "id": "c6d18272-0f1f-48cb-a389-ca40f81319de",
                        "created_at": "1694690816",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694691030",
                        "staggers": [
                            {
                                "created_at": "1694690818",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694691030"
                            }
                        ]
                    },
                    {
                        "id": "6e03d461-fb7b-4c0f-b502-c7fb58f8fce1",
                        "created_at": "1694690815",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694691030",
                        "staggers": [
                            {
                                "created_at": "1694690816",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694691030"
                            }
                        ]
                    },
                    {
                        "id": "da60ccef-2deb-4354-833e-95b48877f7ef",
                        "created_at": "1694690813",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694691030",
                        "staggers": [
                            {
                                "created_at": "1694690815",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694691030"
                            }
                        ]
                    },
                    {
                        "id": "37d72cf3-a6fd-4543-9f3d-975409a556ad",
                        "created_at": "1694690811",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694691031",
                        "staggers": [
                            {
                                "created_at": "1694690813",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694691031"
                            }
                        ]
                    },
                    {
                        "id": "faf13d41-6052-4e0c-8f83-9cd3e873cbc4",
                        "created_at": "1694690809",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694691029",
                        "staggers": [
                            {
                                "created_at": "1694690811",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694691029"
                            }
                        ]
                    },
                    {
                        "id": "5df9e954-aa2d-4cdd-9be0-2ac8ee88a7da",
                        "created_at": "1694690805",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "FAILED",
                        "updated_at": "1694691029",
                        "staggers": [
                            {
                                "created_at": "1694690809",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694691029"
                            }
                        ]
                    },
                    {
                        "id": "fefa31b9-4f38-42b4-a957-e9561395a37d",
                        "created_at": "1694690804",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694691029",
                        "staggers": [
                            {
                                "created_at": "1694690805",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694691029"
                            }
                        ]
                    },
                    {
                        "id": "cef5720e-a673-4d2f-8594-a9a378a5f07b",
                        "created_at": "1694690803",
                        "operation_type": "",
                        "stackset_id": "ScopeTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694691029",
                        "staggers": [
                            {
                                "created_at": "1694690804",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "dev account",
                                "updated_at": "1694691029"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "test-87e37b84-74b7-11ee-bed9-acde48001122",
                "commit_title": "test commit - create scopeTestThird stackset and change group config and template for scopeTestFirst,scopeTestSecond stacksets",
                "created_at": "1698404224",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698404638",
                "changes": [
                    {
                        "id": "5882f7af-11d3-4eb8-98c3-0532e900af1a",
                        "created_at": "1698404229",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698404431",
                        "staggers": [
                            {
                                "created_at": "1698404230",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698404431"
                            }
                        ]
                    },
                    {
                        "id": "24b0cf88-fe44-43a6-9554-0a69b792a194",
                        "created_at": "1698404227",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698404431",
                        "staggers": [
                            {
                                "created_at": "1698404229",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698404431"
                            }
                        ]
                    },
                    {
                        "id": "6342ff9f-e20d-4bc8-a3cc-40c7655cf371",
                        "created_at": "1698404224",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698404638",
                        "staggers": [
                            {
                                "created_at": "1698404437",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698404638"
                            },
                            {
                                "created_at": "1698404227",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698404431"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b1fc3af2536d1a102fd51d30cc4cb602dd807db1",
                "commit_title": "@W-17130246 Restoring rolename back to original (#221) ",
                "created_at": "1731340410",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1731340616",
                "changes": [
                    {
                        "id": "ae3f9196-e177-429a-bd1f-2ae4a3a46556",
                        "created_at": "1731340410",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731340616",
                        "staggers": [
                            {
                                "created_at": "1731340414",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731340616"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b3a9a6d69214fbf09c54d125490624cc4d963d14",
                "commit_title": "Updating indent for if ",
                "created_at": "1730144807",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730145011",
                "changes": [
                    {
                        "id": "e6ccb51c-0e92-4072-a858-5a37c570760c",
                        "created_at": "1730144807",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730145011",
                        "staggers": [
                            {
                                "created_at": "1730144809",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730145011"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f99cb6de176a5ca489908d3ef3af06da7b18e768",
                "commit_title": "Updating if condition ",
                "created_at": "1730133736",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730133940",
                "changes": [
                    {
                        "id": "b412bc6c-1dca-4d9e-87d0-253280099955",
                        "created_at": "1730133736",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730133940",
                        "staggers": [
                            {
                                "created_at": "1730133738",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730133940"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f41a2fbab3bdef7ce17bf13df67f29a1f2f36910",
                "commit_title": "Updating syntax for iam policy ",
                "created_at": "1729876240",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1729876444",
                "changes": [
                    {
                        "id": "d47226c0-cc85-4857-a189-4ad9d481719c",
                        "created_at": "1729876240",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729876444",
                        "staggers": [
                            {
                                "created_at": "1729876242",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1729876444"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a773b9009203afa8773e784c31c23b6e6b9e31ab",
                "commit_title": "W 15777475 prisma role stackset - Non Falcon (#164) ",
                "created_at": "1717519670",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1717520286",
                "changes": [
                    {
                        "id": "f90377c7-5444-489d-98f9-aab5a0b4f71b",
                        "created_at": "1717519672",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717520285",
                        "staggers": [
                            {
                                "created_at": "1717519679",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717520285"
                            }
                        ]
                    },
                    {
                        "id": "befbb1e3-d514-4c76-bcba-abc07f0829e0",
                        "created_at": "1717519670",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717520286",
                        "staggers": [
                            {
                                "created_at": "1717519672",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717520286"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "cf3e682c6cca45e504aa28bdef55ce419266476d",
                "commit_title": "delete-scopeVendingTest ",
                "created_at": "1715765337",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715765337",
                "changes": [
                    {
                        "id": "18f74fa3-22f3-4ad3-b7d8-9af7010b58f6",
                        "created_at": "1715765337",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeVendingTest",
                        "stackset_status": "FAILED",
                        "updated_at": "1715765440",
                        "staggers": [
                            {
                                "created_at": "1715765337",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715765440"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a36cdb9babbcb36c0bab20cc6ac92d31b41217f3",
                "commit_title": "managed name ",
                "created_at": "1702882062",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1702882259",
                "changes": [
                    {
                        "id": "183ab678-d219-4f76-986c-7f34752c11a0",
                        "created_at": "1702882062",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "FAILED",
                        "updated_at": "1702882259",
                        "staggers": [
                            {
                                "created_at": "1702882066",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702882259"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "5e410a93157e596516573f0b6119781cd8ac9811",
                "commit_title": "Test commit ",
                "created_at": "1684489252",
                "deployment_status": "FAILED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1684489481",
                "changes": [
                    {
                        "id": "1b550004-7b80-403f-b81d-704bffb90fe5",
                        "created_at": "1684489274",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684489480",
                        "staggers": [
                            {
                                "created_at": "1684489276",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489480"
                            }
                        ]
                    },
                    {
                        "id": "9c6ed012-1480-4a1e-9f54-a2766e9dd2c8",
                        "created_at": "1684489271",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684489481",
                        "staggers": [
                            {
                                "created_at": "1684489274",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489481"
                            }
                        ]
                    },
                    {
                        "id": "c8c23962-ca07-48da-8b68-6b50d48873df",
                        "created_at": "1684489269",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684489481",
                        "staggers": [
                            {
                                "created_at": "1684489271",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489481"
                            }
                        ]
                    },
                    {
                        "id": "5d96db0f-2e77-4bae-aa47-0f6b3e94f062",
                        "created_at": "1684489265",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1684489481",
                        "staggers": [
                            {
                                "created_at": "1684489269",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489481"
                            }
                        ]
                    },
                    {
                        "id": "a1a1ec45-4d57-45c0-8345-4e69989b7de0",
                        "created_at": "1684489263",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684489482",
                        "staggers": [
                            {
                                "created_at": "1684489265",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489482"
                            }
                        ]
                    },
                    {
                        "id": "2e3d5b8f-2bec-450b-8cfe-3bbe94ea5996",
                        "created_at": "1684489261",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684489480",
                        "staggers": [
                            {
                                "created_at": "1684489263",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489480"
                            }
                        ]
                    },
                    {
                        "id": "79b2be88-427b-45c3-9f00-bf4a8040c1e4",
                        "created_at": "1684489259",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684489481",
                        "staggers": [
                            {
                                "created_at": "1684489261",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489481"
                            }
                        ]
                    },
                    {
                        "id": "0ecd1585-f290-45f7-9f82-9657b1c2851e",
                        "created_at": "1684489256",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684489480",
                        "staggers": [
                            {
                                "created_at": "1684489259",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489480"
                            }
                        ]
                    },
                    {
                        "id": "c94a45ed-6eb3-4f50-85e6-00e6ae5a2d88",
                        "created_at": "1684489252",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684489482",
                        "staggers": [
                            {
                                "created_at": "1684489256",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489482"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "e64de0ccec8f01cd0688c94e2049c723c2d744b4",
                "commit_title": "testing ",
                "created_at": "1731520868",
                "deployment_status": "COMPLETED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731521073",
                "changes": [
                    {
                        "id": "81458dad-82d5-4f13-9111-b199bc7a71f2",
                        "created_at": "1731520868",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731521073",
                        "staggers": [
                            {
                                "created_at": "1731520872",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731521073"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "dc1fca67c7fb32d168bf7cc6d9f1b0eb0157ed3a",
                "commit_title": "fix ",
                "created_at": "1729019224",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1729019632",
                "changes": [
                    {
                        "id": "882ba7d0-7d3a-4a3e-9c70-70520c4e0e52",
                        "created_at": "1729019224",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729019632",
                        "staggers": [
                            {
                                "created_at": "1729019229",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729019632"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "fa30a6732a8a580f7ee4a8a751161c9aa1ef8e1a",
                "commit_title": "@Adding custom role path and removing comment ",
                "created_at": "1727979737",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727979940",
                "changes": [
                    {
                        "id": "69caab08-b5d3-4e0a-9847-47e542b7284b",
                        "created_at": "1727979737",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727979940",
                        "staggers": [
                            {
                                "created_at": "1727979739",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1727979940"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d94c4323bdde47175cfcc84a0fbac69d65950a4f",
                "commit_title": "added-update-param ",
                "created_at": "1715952857",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715953065",
                "changes": [
                    {
                        "id": "95790514-f385-4b46-97a9-ce15b9a1371b",
                        "created_at": "1715952857",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopePrismaRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715953065",
                        "staggers": [
                            {
                                "created_at": "1715952859",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715953065"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4c74b84a0dd0bab23c98d9a6b38b5eccf798c038_905418466263_800373ef-e83d-11ee-9a71-9a3b33b226b7",
                "commit_title": "create onboardingtest stackset ",
                "created_at": "1711106136",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1711106340",
                "changes": [
                    {
                        "id": "c40088c0-34d5-4fd5-9513-b32880a91413",
                        "created_at": "1711106136",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeOnboardingTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711106340",
                        "staggers": [
                            {
                                "created_at": "1711106137",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1711106340"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-51e20656-7325-11ee-bcb0-acde48001122",
                "commit_title": "test commit - revert to 1st stage configuration, and mark third stackset for deletion which was created by the reverted PR",
                "created_at": "1698231474",
                "deployment_status": "REVERTED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698231611",
                "changes": [
                    {
                        "id": "223dfa22-c515-413a-81b6-39779082cc76",
                        "created_at": "1698231480",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698231611",
                        "staggers": [
                            {
                                "created_at": "1698231480",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698231611"
                            }
                        ]
                    },
                    {
                        "id": "58be68a4-eb4b-4655-ba3e-828fb36a2897",
                        "created_at": "1698231479",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698231611",
                        "staggers": [
                            {
                                "created_at": "1698231479",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698231611"
                            }
                        ]
                    },
                    {
                        "id": "b1f6e172-2f8d-4387-a6b0-b178d276a10a",
                        "created_at": "1698231478",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698231611",
                        "staggers": [
                            {
                                "created_at": "1698231479",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698231611"
                            }
                        ]
                    },
                    {
                        "id": "f0cc511a-ce77-449b-8c72-d3e2b26c9e0a",
                        "created_at": "1698231477",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698231611",
                        "staggers": [
                            {
                                "created_at": "1698231478",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698231611"
                            }
                        ]
                    },
                    {
                        "id": "6c188df4-9e06-4228-9b2e-ccb059e20f20",
                        "created_at": "1698231477",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698231611",
                        "staggers": [
                            {
                                "created_at": "1698231477",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698231611"
                            }
                        ]
                    },
                    {
                        "id": "402fc21f-af98-42c2-90ab-cb8879171cfd",
                        "created_at": "1698231475",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698231611",
                        "staggers": [
                            {
                                "created_at": "1698231477",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698231611"
                            }
                        ]
                    },
                    {
                        "id": "ba01331c-fcbf-41b5-ab21-e969122dd8ef",
                        "created_at": "1698231474",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698231611",
                        "staggers": [
                            {
                                "created_at": "1698231475",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698231611"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "00541c725bcb721bca5ed49b26f6a699d91319c9",
                "commit_title": "updated-config ",
                "created_at": "1694622008",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1694622237",
                "changes": [
                    {
                        "id": "f9812dda-ae27-456a-ab96-725e3b6c5894",
                        "created_at": "1694622027",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694622237",
                        "staggers": [
                            {
                                "created_at": "1694622031",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694622237"
                            }
                        ]
                    },
                    {
                        "id": "63227e62-9a37-4238-a020-9c82d71ff4a0",
                        "created_at": "1694622025",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694622235",
                        "staggers": [
                            {
                                "created_at": "1694622027",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694622235"
                            }
                        ]
                    },
                    {
                        "id": "da651c40-2601-41de-b370-72c4c6d052e1",
                        "created_at": "1694622024",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694622237",
                        "staggers": [
                            {
                                "created_at": "1694622025",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694622237"
                            }
                        ]
                    },
                    {
                        "id": "18224226-27ea-48fc-882a-a2211cedd9a2",
                        "created_at": "1694622021",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694622236",
                        "staggers": [
                            {
                                "created_at": "1694622024",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694622236"
                            }
                        ]
                    },
                    {
                        "id": "460a406e-4c42-48f5-8d80-5ad696b8dca2",
                        "created_at": "1694622019",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694622236",
                        "staggers": [
                            {
                                "created_at": "1694622021",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694622236"
                            }
                        ]
                    },
                    {
                        "id": "8fdf9cf4-85b8-4179-9522-e1227ddf7987",
                        "created_at": "1694622015",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694622236",
                        "staggers": [
                            {
                                "created_at": "1694622019",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694622236"
                            }
                        ]
                    },
                    {
                        "id": "393d03e5-7bb3-4c48-952d-9db0bb4d39d9",
                        "created_at": "1694622013",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694622235",
                        "staggers": [
                            {
                                "created_at": "1694622015",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694622235"
                            }
                        ]
                    },
                    {
                        "id": "a5b2f9b6-10d8-4328-a026-3b7e13162aa6",
                        "created_at": "1694622011",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694622237",
                        "staggers": [
                            {
                                "created_at": "1694622013",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694622237"
                            }
                        ]
                    },
                    {
                        "id": "06d0860b-5f8a-4ae6-9243-6f566f197c12",
                        "created_at": "1694622008",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694622237",
                        "staggers": [
                            {
                                "created_at": "1694622011",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694622237"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "20f256b1a12cdc123e949f824ed9491177cb60a2",
                "commit_title": "testing ",
                "created_at": "1731478644",
                "deployment_status": "FAILED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731478850",
                "changes": [
                    {
                        "id": "5e19b769-f750-4401-b3a2-00abfffb2091",
                        "created_at": "1731478644",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1731478850",
                        "staggers": [
                            {
                                "created_at": "1731478648",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731478850"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "3c61884a06980f6bd421e1503bb54cca1767ce66",
                "commit_title": "Test custom role ",
                "created_at": "1728331235",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1728331439",
                "changes": [
                    {
                        "id": "b31a9f1f-9c7d-4d7d-a5a4-cf6cd08511d6",
                        "created_at": "1728331235",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728331439",
                        "staggers": [
                            {
                                "created_at": "1728331237",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1728331439"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "120f578125af679900501b6342ea96576621a6f1",
                "commit_title": "c2 ",
                "created_at": "1714993994",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714994197",
                "changes": [
                    {
                        "id": "deb13611-89fb-4984-a34a-8cd2a39b5e87",
                        "created_at": "1714993994",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeSCPTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714994197",
                        "staggers": [
                            {
                                "created_at": "1714993996",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1714994197"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "9da4862d2701f1fde114823764886952cc072e76",
                "commit_title": "test stagger group ",
                "created_at": "1713395616",
                "deployment_status": "COMPLETED",
                "developer_id": "xtao@salesforce.com",
                "updated_at": "1713396703",
                "changes": [
                    {
                        "id": "e5332aef-c2b9-4866-ac90-8af74de736e7",
                        "created_at": "1713395622",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1713396703",
                        "staggers": [
                            {
                                "created_at": "1713396501",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1713396703"
                            },
                            {
                                "created_at": "1713395625",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1713396028"
                            }
                        ]
                    },
                    {
                        "id": "b574a497-c0ce-4090-a912-1db0f24ef206",
                        "created_at": "1713395616",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1713396027",
                        "staggers": [
                            {
                                "created_at": "1713395622",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1713396027"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d847a3dc964a1a08a9000fb3339def9a83b066bf",
                "commit_title": "add database related actions in deny sensitive action policy ",
                "created_at": "1709064568",
                "deployment_status": "COMPLETED",
                "developer_id": "xtao@salesforce.com",
                "updated_at": "1709064568",
                "changes": [
                    {
                        "id": "876d5fde-ad19-45eb-b231-5e756f8f8567",
                        "created_at": "1709064574",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709064778",
                        "staggers": [
                            {
                                "created_at": "1709064576",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709064778"
                            }
                        ]
                    },
                    {
                        "id": "49588d7c-b976-4c05-9e1d-dfa3602478d2",
                        "created_at": "1708985750",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1709019810",
                        "staggers": [
                            {
                                "created_at": "1708985752",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1709064980"
                            }
                        ]
                    },
                    {
                        "id": "4468d8f5-27e6-44b3-90a3-c795d3a0c2ff",
                        "created_at": "1708985745",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709064980",
                        "staggers": [
                            {
                                "created_at": "1709064574",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709064980"
                            },
                            {
                                "created_at": "1708985750",
                                "operation_status": "OVERRIDDEN",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709019810"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6ce2f3cd4e9cbf82a75987fbd6d89725b2292b4f",
                "commit_title": "add stage mpa in wiz template ",
                "created_at": "1704445835",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1704447987",
                "changes": [
                    {
                        "id": "7272b56f-08f0-437e-8fb9-413c3caf6ffd",
                        "created_at": "1704445835",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeWizRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704447987",
                        "staggers": [
                            {
                                "created_at": "1704445836",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1704447987"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "8b559190e355974bdca475c5ad09cc9066d44ed0",
                "commit_title": "test ",
                "created_at": "1684826331",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1684826539",
                "changes": [
                    {
                        "id": "9746e979-8f52-4c5b-9d34-21eaaa476447",
                        "created_at": "1684826331",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684826539",
                        "staggers": [
                            {
                                "created_at": "1684826334",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684826539"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "0035ad6f3c6e2a6691f89a90dd9344a70b1500bd",
                "commit_title": "keeping just one ou for testing ",
                "created_at": "1728401693",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1728401897",
                "changes": [
                    {
                        "id": "e733a7e0-8e91-49e4-8d89-aa664557b5ae",
                        "created_at": "1728401693",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728401897",
                        "staggers": [
                            {
                                "created_at": "1728401695",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1728401897"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "61a9c9eaaac20b695c0c50438ffd5b5a51924d09",
                "commit_title": "test 12 ",
                "created_at": "1716387109",
                "deployment_status": "FAILED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1716387312",
                "changes": [
                    {
                        "id": "9cab86be-0c82-4dda-9b18-ed8ccd611d42",
                        "created_at": "1716387109",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "FAILED",
                        "updated_at": "1716387312",
                        "staggers": [
                            {
                                "created_at": "1716387110",
                                "operation_status": "FAILED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1716387312"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "test-3f2be0ce-7184-11ee-89dc-acde48001122",
                "commit_title": "test commit - remove all stacksets from grouping, and mark as delete in payload",
                "created_at": "1698052353",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698052387",
                "changes": [
                    {
                        "id": "b81e6ffa-19bd-412d-b027-462390249ab7",
                        "created_at": "1698052354",
                        "operation_type": "",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698052387",
                        "staggers": [
                            {
                                "created_at": "1698052354",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698052386"
                            }
                        ]
                    },
                    {
                        "id": "3c3f48a8-4c4e-4162-ac24-cf5b84e2b01e",
                        "created_at": "1698052353",
                        "operation_type": "",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698052387",
                        "staggers": [
                            {
                                "created_at": "1698052353",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698052387"
                            }
                        ]
                    },
                    {
                        "id": "12d2bd03-1251-426e-857c-e0c502af7f1c",
                        "created_at": "1698052353",
                        "operation_type": "",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698052386",
                        "staggers": [
                            {
                                "created_at": "1698052354",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698052386"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "bd164c347e54b4c0a2f6fa479ff21798ba8a1cef",
                "commit_title": "@W-15755612 | Added permissions for ou-automation DELETE and UPDATE APIs ",
                "created_at": "1718798939",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1718799357",
                "changes": [
                    {
                        "id": "5213a4c2-09c7-4677-97f4-e41b6b43184f",
                        "created_at": "1718798939",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1718799357",
                        "staggers": [
                            {
                                "created_at": "1718799156",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1718799357"
                            },
                            {
                                "created_at": "1718798941",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1718799143"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "c96dfd7eebf7722616c0e5d025b6d878dd381262",
                "commit_title": "test 4 ",
                "created_at": "1716391907",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1716392132",
                "changes": [
                    {
                        "id": "8fd4d10c-c821-4e95-bd64-13794749cad0",
                        "created_at": "1716391907",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1716392132",
                        "staggers": [
                            {
                                "created_at": "1716391909",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1716392110"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "971df284ba4f6b4d0f76d7acd4de572fe6624f43",
                "commit_title": "added-missing-param ",
                "created_at": "1716363851",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1716364063",
                "changes": [
                    {
                        "id": "beaaaf40-881c-4769-b69c-f88c918469b4",
                        "created_at": "1716363858",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716364062",
                        "staggers": [
                            {
                                "created_at": "1716363860",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1716364062"
                            }
                        ]
                    },
                    {
                        "id": "2433da5b-b890-4c79-93d9-5d9ef0a12535",
                        "created_at": "1716363851",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716364063",
                        "staggers": [
                            {
                                "created_at": "1716363858",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1716364063"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-49bfb7d4-74b5-11ee-bed9-acde48001122",
                "commit_title": "test commit - revert to 1st stage configuration, and mark third stackset for deletion which was created by the reverted PR",
                "created_at": "1698403260",
                "deployment_status": "REVERTED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698403520",
                "changes": [
                    {
                        "id": "069dfa19-b26d-4389-9518-4940758b9268",
                        "created_at": "1698403266",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698403520",
                        "staggers": [
                            {
                                "created_at": "1698403267",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698403520"
                            }
                        ]
                    },
                    {
                        "id": "b3332b35-0aeb-4275-b0c4-c8f49f7f1ee0",
                        "created_at": "1698403265",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698403520",
                        "staggers": [
                            {
                                "created_at": "1698403266",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698403520"
                            }
                        ]
                    },
                    {
                        "id": "db3f69a5-ea00-4577-b86e-6f817674d3a6",
                        "created_at": "1698403264",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698403520",
                        "staggers": [
                            {
                                "created_at": "1698403265",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698403520"
                            }
                        ]
                    },
                    {
                        "id": "db9183a9-7b99-419d-8b98-ec79b9936285",
                        "created_at": "1698403264",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698403520",
                        "staggers": [
                            {
                                "created_at": "1698403264",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698403520"
                            }
                        ]
                    },
                    {
                        "id": "17377f0b-c56c-4e6e-a0ec-02d903619c84",
                        "created_at": "1698403263",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698403520",
                        "staggers": [
                            {
                                "created_at": "1698403264",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698403520"
                            }
                        ]
                    },
                    {
                        "id": "ce14d8d0-a7ae-44fe-b480-1e7ca659708c",
                        "created_at": "1698403262",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698403520",
                        "staggers": [
                            {
                                "created_at": "1698403263",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698403520"
                            }
                        ]
                    },
                    {
                        "id": "d6943b7d-8923-4bb1-b190-928734539d37",
                        "created_at": "1698403260",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698403520",
                        "staggers": [
                            {
                                "created_at": "1698403262",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698403520"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "79af32d375dc8f0b9a05192e5e13b80b7df43359",
                "commit_title": "dummy stackset creation on stage account ",
                "created_at": "1692172435",
                "deployment_status": "FAILED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1692172435",
                "changes": [
                    {
                        "id": "159a7894-7b95-41f9-af7e-25e6bc97acd2",
                        "created_at": "1692173392",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692173598",
                        "staggers": [
                            {
                                "created_at": "1692173394",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692173598"
                            }
                        ]
                    },
                    {
                        "id": "61b54e1d-5933-4061-9095-23d5fe6b04ce",
                        "created_at": "1692173390",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692173599",
                        "staggers": [
                            {
                                "created_at": "1692173392",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692173599"
                            }
                        ]
                    },
                    {
                        "id": "5551a27e-916e-4aeb-b0ac-9177906cbdb3",
                        "created_at": "1692173389",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692173598",
                        "staggers": [
                            {
                                "created_at": "1692173390",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692173598"
                            }
                        ]
                    },
                    {
                        "id": "28fccc93-e33a-4cb0-9e41-2b11a2422aea",
                        "created_at": "1692173386",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692173599",
                        "staggers": [
                            {
                                "created_at": "1692173389",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692173599"
                            }
                        ]
                    },
                    {
                        "id": "bdb0a2b2-106b-4d18-942a-ea223d68c68c",
                        "created_at": "1692173384",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692173597",
                        "staggers": [
                            {
                                "created_at": "1692173386",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692173597"
                            }
                        ]
                    },
                    {
                        "id": "75fd48f3-b318-42c4-8349-bb1d36321d83",
                        "created_at": "1692173382",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692173598",
                        "staggers": [
                            {
                                "created_at": "1692173384",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692173598"
                            }
                        ]
                    },
                    {
                        "id": "42b078f6-a2f8-4cd2-88ea-f9eac1ce8f59",
                        "created_at": "1692173380",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692173599",
                        "staggers": [
                            {
                                "created_at": "1692173382",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692173599"
                            }
                        ]
                    },
                    {
                        "id": "da80065e-b6a0-44a0-9443-76be70b8df36",
                        "created_at": "1692173378",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692173599",
                        "staggers": [
                            {
                                "created_at": "1692173380",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692173599"
                            }
                        ]
                    },
                    {
                        "id": "5aa9285a-8ffd-4145-9190-0704a400433f",
                        "created_at": "1692173374",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692173597",
                        "staggers": [
                            {
                                "created_at": "1692173378",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692173597"
                            }
                        ]
                    },
                    {
                        "id": "7705fcc9-f0c9-48dd-b6e6-2ec54569d068",
                        "created_at": "1692173372",
                        "operation_type": "",
                        "stackset_id": "dummyTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692173598",
                        "staggers": [
                            {
                                "created_at": "1692173374",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "pcsk account id",
                                "updated_at": "1692173598"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "a6066b3c6f9abe920feafd4699ddbfdc2ef88193",
                "commit_title": "@W-13046968 | Enabled managed execution on stacksets ",
                "created_at": "1683796372",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1683796639",
                "changes": [
                    {
                        "id": "cd4e482c-0aea-4a27-aaca-ac7985385682",
                        "created_at": "1683796432",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683796639",
                        "staggers": [
                            {
                                "created_at": "1683796433",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683796639"
                            }
                        ]
                    },
                    {
                        "id": "69191064-bc82-4a98-b412-df039a218793",
                        "created_at": "1683796430",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683796640",
                        "staggers": [
                            {
                                "created_at": "1683796432",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683796639"
                            }
                        ]
                    },
                    {
                        "id": "b7a12150-6d49-4833-8aa7-977e4ed6cf2d",
                        "created_at": "1683796428",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683796639",
                        "staggers": [
                            {
                                "created_at": "1683796430",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683796639"
                            }
                        ]
                    },
                    {
                        "id": "b746b572-8189-46ef-b3fb-1cba57a769a7",
                        "created_at": "1683796423",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683796638",
                        "staggers": [
                            {
                                "created_at": "1683796428",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683796638"
                            }
                        ]
                    },
                    {
                        "id": "97349f8f-3003-4065-9ed4-06fcc75f0483",
                        "created_at": "1683796421",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683796637",
                        "staggers": [
                            {
                                "created_at": "1683796423",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683796637"
                            }
                        ]
                    },
                    {
                        "id": "a6b8f48f-7c00-4311-8d50-df5a7ecaa6ab",
                        "created_at": "1683796419",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683796638",
                        "staggers": [
                            {
                                "created_at": "1683796421",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683796638"
                            }
                        ]
                    },
                    {
                        "id": "0147463d-2d86-4e39-99ce-b12939f14f58",
                        "created_at": "1683796417",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683796638",
                        "staggers": [
                            {
                                "created_at": "1683796419",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683796638"
                            }
                        ]
                    },
                    {
                        "id": "ae731243-b4f0-4f02-9cbe-4a199dace92f",
                        "created_at": "1683796415",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683796639",
                        "staggers": [
                            {
                                "created_at": "1683796417",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683796639"
                            }
                        ]
                    },
                    {
                        "id": "d6a5d4db-9161-44e3-bec4-3c470711fc60",
                        "created_at": "1683796372",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1683796639",
                        "staggers": [
                            {
                                "created_at": "1683796654",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683796654"
                            },
                            {
                                "created_at": "1683796415",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683796638"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "28f69c32f99a2390e2d957eab4df214ff0231c42",
                "commit_title": "fix ",
                "created_at": "1728547129",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1728547332",
                "changes": [
                    {
                        "id": "ba969f13-f5a9-45a6-8fbf-2f6e3608c6ba",
                        "created_at": "1728547129",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeFixPrismaRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728547332",
                        "staggers": [
                            {
                                "created_at": "1728547130",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "SG 1",
                                "updated_at": "1728547332"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "aecdbe6d4bd87443d58b95169a7660df060e29b5",
                "commit_title": "test commit ",
                "created_at": "1726127614",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1726127821",
                "changes": [
                    {
                        "id": "36aa761c-cbed-4b6c-8e7e-fe0d20baf8d0",
                        "created_at": "1726127614",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1726127821",
                        "staggers": [
                            {
                                "created_at": "1726127619",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1726127821"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "57c03f434536116aa84b9d732b36f027cf04172e",
                "commit_title": "@W-15804687 Adding bcm data export permissions ",
                "created_at": "1721854822",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1721855700",
                "changes": [
                    {
                        "id": "ea2391fd-3aab-41cf-b557-c46b2ca2627c",
                        "created_at": "1721854833",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721855700",
                        "staggers": [
                            {
                                "created_at": "1721855498",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721855700"
                            },
                            {
                                "created_at": "1721854835",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1721855443"
                            }
                        ]
                    },
                    {
                        "id": "1d46d6d1-699e-456f-a5ce-2001b9d667d0",
                        "created_at": "1721854831",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721855443",
                        "staggers": [
                            {
                                "created_at": "1721854833",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721855443"
                            }
                        ]
                    },
                    {
                        "id": "1c8a9845-c1ee-4430-84a0-8405eba21890",
                        "created_at": "1721854823",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721855443",
                        "staggers": [
                            {
                                "created_at": "1721854831",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721855443"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-2e852df0-71a4-11ee-9522-acde48001122",
                "commit_title": "test commit - remove all stacksets from grouping, and mark as delete in payload",
                "created_at": "1698066067",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698066104",
                "changes": [
                    {
                        "id": "a6583230-89fd-4350-b510-92a14bf11922",
                        "created_at": "1698066070",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698066103",
                        "staggers": [
                            {
                                "created_at": "1698066070",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698066102"
                            }
                        ]
                    },
                    {
                        "id": "5cad0e85-04e9-40cd-9c4c-7bbdababe9ab",
                        "created_at": "1698066068",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698066102",
                        "staggers": [
                            {
                                "created_at": "1698066070",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698066102"
                            }
                        ]
                    },
                    {
                        "id": "4f67fd02-c875-4832-b92a-b8960b017b38",
                        "created_at": "1698066067",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698066104",
                        "staggers": [
                            {
                                "created_at": "1698066068",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698066103"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6bbb8288a268a778816f0c90cb6f6c4b44c294b7_4e93ea7c-57a7-11ee-83e9-96b47427f83f",
                "commit_title": "change role name ",
                "created_at": "1695208660",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1695208660",
                "changes": [
                    {
                        "id": "041c750e-1467-41b8-8a1e-238b14e9ec31",
                        "created_at": "1695208660",
                        "operation_type": "",
                        "stackset_id": "ScopeTestv2",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695208864",
                        "staggers": [
                            {
                                "created_at": "1695208661",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeNoStaggeringGroup",
                                "updated_at": "1695208864"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3e2df4c2374da711cd1e8fb4720d419a1870042d",
                "commit_title": "@@W-12620500 Added TempStackset for demo ",
                "created_at": "1679503663",
                "deployment_status": "COMPLETED",
                "developer_id": "jbhayani@salesforce.com",
                "updated_at": "1679504112",
                "changes": [
                    {
                        "id": "53c91351-ef27-4ef9-a2e0-2d8b80bf0033",
                        "created_at": "1679503677",
                        "operation_type": "",
                        "stackset_id": "TestStacksetFalcon1",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1679503909",
                        "staggers": [
                            {
                                "created_at": "1679503799",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679503909"
                            },
                            {
                                "created_at": "1679503683",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage",
                                "updated_at": "1679503785"
                            }
                        ]
                    },
                    {
                        "id": "1a247187-4082-49db-9724-23043a470832",
                        "created_at": "1679503675",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1679504111",
                        "staggers": [
                            {
                                "created_at": "1679503806",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679504111"
                            },
                            {
                                "created_at": "1679503677",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Account Test",
                                "updated_at": "1679503786"
                            }
                        ]
                    },
                    {
                        "id": "3694bfa8-a103-4620-bf46-ef3983ecbc37",
                        "created_at": "1679503673",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1679503785",
                        "staggers": [
                            {
                                "created_at": "1679503675",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679503785"
                            }
                        ]
                    },
                    {
                        "id": "3f1e138d-2ef7-47d6-8dfa-a33ac9b0a84a",
                        "created_at": "1679503666",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1679504111",
                        "staggers": [
                            {
                                "created_at": "1679503805",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679504111"
                            },
                            {
                                "created_at": "1679503673",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage",
                                "updated_at": "1679503786"
                            }
                        ]
                    },
                    {
                        "id": "102373e2-b594-474d-89b8-73ff10326bad",
                        "created_at": "1679503663",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1679504112",
                        "staggers": [
                            {
                                "created_at": "1679503808",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679504112"
                            },
                            {
                                "created_at": "1679503666",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Account Test",
                                "updated_at": "1679503786"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "7abf37e81a12dfc74e7d235a75a7abe1089706de",
                "commit_title": "@W-16167395 | Account cleanup step function fix ",
                "created_at": "1721027409",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1721028051",
                "changes": [
                    {
                        "id": "266e3323-a887-4682-a5eb-42a858c91134",
                        "created_at": "1721027420",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721028051",
                        "staggers": [
                            {
                                "created_at": "1721027849",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721028051"
                            },
                            {
                                "created_at": "1721027422",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1721027829"
                            }
                        ]
                    },
                    {
                        "id": "446894d5-1285-41c5-b80b-ff1e8dbda228",
                        "created_at": "1721027418",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721027830",
                        "staggers": [
                            {
                                "created_at": "1721027420",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721027830"
                            }
                        ]
                    },
                    {
                        "id": "448bb688-d643-450f-8482-50bed501abe5",
                        "created_at": "1721027411",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721027827",
                        "staggers": [
                            {
                                "created_at": "1721027418",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721027827"
                            }
                        ]
                    },
                    {
                        "id": "8235ce95-948f-473e-960d-4c8ce1143f85",
                        "created_at": "1721027409",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721027830",
                        "staggers": [
                            {
                                "created_at": "1721027411",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721027830"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "5ed4664671397768a2b2a0db5cb6b6cab4a05a06",
                "commit_title": "remove language transforms ",
                "created_at": "1719304766",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1719305276",
                "changes": [
                    {
                        "id": "f096948f-c018-4497-9867-c89c3b2cdaac",
                        "created_at": "1719304774",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1719304978",
                        "staggers": [
                            {
                                "created_at": "1719304775",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1719304978"
                            }
                        ]
                    },
                    {
                        "id": "6fd40a95-67eb-4723-9406-5d441eae830e",
                        "created_at": "1719304772",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1719304979",
                        "staggers": [
                            {
                                "created_at": "1719304774",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1719304979"
                            }
                        ]
                    },
                    {
                        "id": "056e767d-095b-4481-ad81-e65e7a9c23e4",
                        "created_at": "1719304770",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1719305276",
                        "staggers": [
                            {
                                "created_at": "1719305074",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1719305276"
                            },
                            {
                                "created_at": "1719304772",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1719304979"
                            }
                        ]
                    },
                    {
                        "id": "9b3eabaf-6f23-4b3d-aadb-8241e6b193cb",
                        "created_at": "1719304767",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1719305275",
                        "staggers": [
                            {
                                "created_at": "1719305072",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1719305275"
                            },
                            {
                                "created_at": "1719304770",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1719304978"
                            }
                        ]
                    },
                    {
                        "id": "5a1f3d33-4308-484c-9259-592bd43ad917",
                        "created_at": "1719304766",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1719304979",
                        "staggers": [
                            {
                                "created_at": "1719304767",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1719304979"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "1cbe82db0645b2925f26218cb661a56748763e46",
                "commit_title": "@W-12555581 - Added Tests Stacksets (#24) ",
                "created_at": "1677671429",
                "deployment_status": "COMPLETED",
                "developer_id": "jbhayani@salesforce.com",
                "updated_at": "1677671770",
                "changes": [
                    {
                        "id": "28e59864-c05c-4b48-9260-ce238ad20d64",
                        "created_at": "1677671435",
                        "operation_type": "",
                        "stackset_id": "TestStacksetFalcon2",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1677671770",
                        "staggers": [
                            {
                                "created_at": "1677671565",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1677671770"
                            },
                            {
                                "created_at": "1677671440",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage",
                                "updated_at": "1677671545"
                            }
                        ]
                    },
                    {
                        "id": "69a1edda-128e-4978-95a6-43852c71b50c",
                        "created_at": "1677671429",
                        "operation_type": "",
                        "stackset_id": "TestStacksetFalcon1",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1677671770",
                        "staggers": [
                            {
                                "created_at": "1677671559",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1677671770"
                            },
                            {
                                "created_at": "1677671434",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage",
                                "updated_at": "1677671545"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "c6703b7138aa3f82998969647928c1ac2e7af360",
                "commit_title": "Update version ",
                "created_at": "1730964702",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1730965107",
                "changes": [
                    {
                        "id": "56e7644c-4a21-40e2-bdc1-b1f9c3b6ef1f",
                        "created_at": "1730964702",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSensorsControlTelemetryRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730965107",
                        "staggers": [
                            {
                                "created_at": "1730964704",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1730965107"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "7881874e25b2d8048c53fff5d4634ca0e60d5dbd",
                "commit_title": "fix ",
                "created_at": "1728546621",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1728546825",
                "changes": [
                    {
                        "id": "f1e81a51-311c-4683-8931-59ebc2b2084e",
                        "created_at": "1728546621",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeFixPrismaRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728546825",
                        "staggers": [
                            {
                                "created_at": "1728546623",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "SG 1",
                                "updated_at": "1728546825"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d1ad1d4a8aaadb8c59236a79b509aa74614472ab",
                "commit_title": "@W-16186122 Adding servicename tag to PCSK passworkPolicy lambda ",
                "created_at": "1724083637",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1724084049",
                "changes": [
                    {
                        "id": "f057235f-ed27-4acd-bd0a-e89ae91f37ec",
                        "created_at": "1724083644",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724084049",
                        "staggers": [
                            {
                                "created_at": "1724083646",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724084049"
                            }
                        ]
                    },
                    {
                        "id": "99cc0a99-4333-441c-9ac3-aafcb0379409",
                        "created_at": "1724083642",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724084049",
                        "staggers": [
                            {
                                "created_at": "1724083644",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724084049"
                            }
                        ]
                    },
                    {
                        "id": "a070e9f8-ed6e-4f97-930e-23d31ae3de30",
                        "created_at": "1724083637",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724084049",
                        "staggers": [
                            {
                                "created_at": "1724083642",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724084049"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b419e73bb8d51d314a208fef1b96ea84beee039b",
                "commit_title": "@W-16241569 adding another commit ",
                "created_at": "1721758777",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1721760313",
                "changes": [
                    {
                        "id": "b9d31aec-e32d-4aeb-8892-332a1d27bc3d",
                        "created_at": "1721758783",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721759399",
                        "staggers": [
                            {
                                "created_at": "1721758791",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721759399"
                            }
                        ]
                    },
                    {
                        "id": "a27f5361-1e13-401b-bbfd-614bc44f4d20",
                        "created_at": "1721758779",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721760313",
                        "staggers": [
                            {
                                "created_at": "1721760109",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1721760313"
                            },
                            {
                                "created_at": "1721758783",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1721759399"
                            }
                        ]
                    },
                    {
                        "id": "69308e6e-9f31-4479-8fb1-2c92f25888a9",
                        "created_at": "1721758777",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721760312",
                        "staggers": [
                            {
                                "created_at": "1721760107",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721760312"
                            },
                            {
                                "created_at": "1721758779",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1721759398"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "fc8b113b06524a616980e9da774a3fe20da6b1d1",
                "commit_title": "add stackset execution roles ",
                "created_at": "1720012288",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1720012722",
                "changes": [
                    {
                        "id": "9063f25b-350c-4f91-8c6d-56d4e0e4e1fc",
                        "created_at": "1720012292",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1720012496",
                        "staggers": [
                            {
                                "created_at": "1720012293",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1720012496"
                            }
                        ]
                    },
                    {
                        "id": "284363aa-483c-4afe-bbc0-932f335eef1d",
                        "created_at": "1720012288",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1720012722",
                        "staggers": [
                            {
                                "created_at": "1720012521",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Non production",
                                "updated_at": "1720012722"
                            },
                            {
                                "created_at": "1720012292",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Non production stage",
                                "updated_at": "1720012496"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "dc7c50a49a6d3c6179a4317b6db4a69fc4ffd0ed",
                "commit_title": "update version ",
                "created_at": "1729873095",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1729873299",
                "changes": [
                    {
                        "id": "5340ef34-bd3b-4d84-ad71-5cffd079bae5",
                        "created_at": "1729873095",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729873299",
                        "staggers": [
                            {
                                "created_at": "1729873097",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1729873299"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "59fc7bb037426cf48f4b05624872cedd986a453d",
                "commit_title": "add back line break to test common IAM change deployment ",
                "created_at": "1709024029",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1709024099",
                "changes": [
                    {
                        "id": "d4f9c9e3-8211-48e2-a2c0-72682e14c82b",
                        "created_at": "1709024029",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709024099",
                        "staggers": [
                            {
                                "created_at": "1709024034",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709024099"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "1c7bc487bfc5a4eec7c031484310649a8a66d191",
                "commit_title": "added-pcsk-in-role-name ",
                "created_at": "1704814672",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1704814914",
                "changes": [
                    {
                        "id": "5b8e229f-a32e-4500-8941-ed269246a84a",
                        "created_at": "1704814672",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeWizRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704814909",
                        "staggers": [
                            {
                                "created_at": "1704814674",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1704814903"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "32b15b190167df1f6ec5f641fdc935f01d9edd4d",
                "commit_title": "updated-wiz-role-name ",
                "created_at": "1704806319",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1704806557",
                "changes": [
                    {
                        "id": "ff0c09d7-77b3-4469-9e30-fe5b42be03ab",
                        "created_at": "1704806319",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704806552",
                        "staggers": [
                            {
                                "created_at": "1704806321",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1704806548"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-2307254e-732c-11ee-913d-acde48001122",
                "commit_title": "test commit - remove all stacksets from grouping, and mark as delete in payload",
                "created_at": "1698234401",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698234468",
                "changes": [
                    {
                        "id": "81fc8c4b-3db5-4410-bc37-4ef4754d6c9f",
                        "created_at": "1698234404",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698234468",
                        "staggers": [
                            {
                                "created_at": "1698234405",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698234468"
                            }
                        ]
                    },
                    {
                        "id": "be3c68d0-6a21-4155-b3fa-bcf36fe323fd",
                        "created_at": "1698234402",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698234468",
                        "staggers": [
                            {
                                "created_at": "1698234404",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698234467"
                            }
                        ]
                    },
                    {
                        "id": "0d396b40-94a9-4b26-894b-f60a37f6374a",
                        "created_at": "1698234401",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698234467",
                        "staggers": [
                            {
                                "created_at": "1698234402",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698234467"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-6123e04e-74b0-11ee-911d-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698401150",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698401562",
                "changes": [
                    {
                        "id": "eaae9747-3faf-4659-b4d8-e125dae073f3",
                        "created_at": "1698401153",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698401562",
                        "staggers": [
                            {
                                "created_at": "1698401362",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698401562"
                            },
                            {
                                "created_at": "1698401155",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698401356"
                            }
                        ]
                    },
                    {
                        "id": "341656dc-c5f4-40ac-8876-230ebc3afab0",
                        "created_at": "1698401150",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698401356",
                        "staggers": [
                            {
                                "created_at": "1698401153",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698401356"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "ebb4acd3f160653357bb346622d639fc1c340c6b",
                "commit_title": "testing ",
                "created_at": "1731506376",
                "deployment_status": "FAILED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731506983",
                "changes": [
                    {
                        "id": "8a4e45a6-5ce9-46e4-a35a-9023f6a06537",
                        "created_at": "1731506376",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1731506983",
                        "staggers": [
                            {
                                "created_at": "1731506380",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731506983"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "aa7c805ee82eda1d05341fa9b159b861fe751cd4",
                "commit_title": "adding wildcard for principal ",
                "created_at": "1730219492",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730219696",
                "changes": [
                    {
                        "id": "c834edb2-faea-4a8d-92cf-db753cfd4ac8",
                        "created_at": "1730219492",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730219696",
                        "staggers": [
                            {
                                "created_at": "1730219494",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730219696"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "bdd4e498333e05095eabcef200a27f0cfc80d96b",
                "commit_title": "Freed perm boundary for testing ",
                "created_at": "1729490846",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1729491271",
                "changes": [
                    {
                        "id": "0e8c52c9-9840-4195-bb43-652da8a35506",
                        "created_at": "1729490848",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729491057",
                        "staggers": [
                            {
                                "created_at": "1729490853",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729491057"
                            }
                        ]
                    },
                    {
                        "id": "faa04535-2661-48db-af61-06838fad4d3b",
                        "created_at": "1729490846",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729491271",
                        "staggers": [
                            {
                                "created_at": "1729491070",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729491271"
                            },
                            {
                                "created_at": "1729490848",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1729491056"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "09674cda4a6053a2ab0a4c2ed5860f44fe7ed6be",
                "commit_title": "@W-16593660 Fix cloudwatch references in Cloudtrail- null fix ",
                "created_at": "1724751236",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1724751443",
                "changes": [
                    {
                        "id": "b2860263-d592-4b08-83f2-4723d5d0fa16",
                        "created_at": "1724751236",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724751443",
                        "staggers": [
                            {
                                "created_at": "1724751240",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724751443"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a577aacb59deacb64b8db144070e8dbedf13d86a",
                "commit_title": "del-svcmngtest ",
                "created_at": "1716200021",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1716200021",
                "changes": null,
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b79e8d5aa5f4c418b46e49c7ab9e3167dad57ab4",
                "commit_title": "fixed-typo-in-file-name ",
                "created_at": "1715947527",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715947735",
                "changes": [
                    {
                        "id": "b6530fa9-494c-47fc-97a4-285d1c0cda5c",
                        "created_at": "1715947528",
                        "operation_type": "CREATE",
                        "stackset_id": "scopePrismaRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715947735",
                        "staggers": [
                            {
                                "created_at": "1715947529",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715947735"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "52f40cd79e4e4b479876aad8890b44d89d21ac9d",
                "commit_title": "removed-prisma-cloud-role ",
                "created_at": "1715597429",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715597839",
                "changes": [
                    {
                        "id": "10b4ed52-8138-4115-a75e-a0b57fda8c21",
                        "created_at": "1715597429",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715597839",
                        "staggers": [
                            {
                                "created_at": "1715597435",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715597839"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-9bc4eece-7329-11ee-8874-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698233315",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698233389",
                "changes": [
                    {
                        "id": "fcfcbf3a-d0e7-41d1-b16f-032c8a9a6b2c",
                        "created_at": "1698233317",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698233389",
                        "staggers": [
                            {
                                "created_at": "1698233358",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698233389"
                            },
                            {
                                "created_at": "1698233320",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698233352"
                            }
                        ]
                    },
                    {
                        "id": "17af2327-5b7d-449b-a9e0-15fe6d75895f",
                        "created_at": "1698233315",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698233352",
                        "staggers": [
                            {
                                "created_at": "1698233317",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698233352"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "dd54abd7771868081e7c7a1ee4bc8316533799e3",
                "commit_title": "adding prodga acocunt to trust ",
                "created_at": "1729538449",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1729539445",
                "changes": [
                    {
                        "id": "ae92ee21-16c4-489f-ad12-94bb86eb39f0",
                        "created_at": "1729538463",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729539072",
                        "staggers": [
                            {
                                "created_at": "1729538465",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729539072"
                            }
                        ]
                    },
                    {
                        "id": "ff7d820e-33d0-4e0e-8d7f-eb90e5e779dd",
                        "created_at": "1729538458",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729539071",
                        "staggers": [
                            {
                                "created_at": "1729538463",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729539071"
                            }
                        ]
                    },
                    {
                        "id": "707c4685-a0c2-42f8-ac01-c71b71cbbc36",
                        "created_at": "1729538457",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729538667",
                        "staggers": [
                            {
                                "created_at": "1729538458",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729538667"
                            }
                        ]
                    },
                    {
                        "id": "288751d0-ffb5-4dcd-8813-c251e5b0184b",
                        "created_at": "1729538455",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729539444",
                        "staggers": [
                            {
                                "created_at": "1729539241",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729539444"
                            },
                            {
                                "created_at": "1729538457",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1729539070"
                            }
                        ]
                    },
                    {
                        "id": "faa19eb3-e399-4764-a2ae-939e530b70c9",
                        "created_at": "1729538453",
                        "operation_type": "UPDATE",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729539072",
                        "staggers": [
                            {
                                "created_at": "1729538455",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729539072"
                            }
                        ]
                    },
                    {
                        "id": "6709cbbd-fc99-49a5-8e36-028d4816f785",
                        "created_at": "1729538452",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729539072",
                        "staggers": [
                            {
                                "created_at": "1729538453",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729539072"
                            }
                        ]
                    },
                    {
                        "id": "0b67ed28-d9ec-45ce-ae27-c2fc12d1aec5",
                        "created_at": "1729538449",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729539445",
                        "staggers": [
                            {
                                "created_at": "1729539243",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729539445"
                            },
                            {
                                "created_at": "1729538452",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1729539071"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "996d2291307b3577d7e993bdc33b4f202b0aa525",
                "commit_title": "@W-16593660 Empty values for cloudwatch resources ",
                "created_at": "1724835598",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1724835805",
                "changes": [
                    {
                        "id": "5c729049-28c2-48e2-aa1e-3ff51e9e0b63",
                        "created_at": "1724835598",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724835805",
                        "staggers": [
                            {
                                "created_at": "1724835603",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724835805"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "9bc19d3173f9ad092074f0ac66b2157177a2965a",
                "commit_title": "Update CommonIAM change ",
                "created_at": "1714135233",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1714135644",
                "changes": [
                    {
                        "id": "1cbe9b02-b97d-467f-a4a5-b681cd882dae",
                        "created_at": "1714135234",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714135644",
                        "staggers": [
                            {
                                "created_at": "1714135240",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1714135644"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b2e2838ba53e4e2042442e8e4a54664ad576153e",
                "commit_title": "renaming role name ",
                "created_at": "1709033903",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1709034107",
                "changes": [
                    {
                        "id": "2810a742-6931-44f4-8795-84ab015588b4",
                        "created_at": "1709033903",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709034107",
                        "staggers": [
                            {
                                "created_at": "1709033904",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709034107"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f3d8fb80fa13a6379988f1c47819498f1a279634_060736148499_c2a3d886-7955-11ee-aab2-56d7cf10e70b",
                "commit_title": "added-mpa-as-target ",
                "created_at": "1698911980",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1698911980",
                "changes": [
                    {
                        "id": "9d171021-ff88-4dc7-8293-6acefdcff414",
                        "created_at": "1698911980",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeDemoStackset",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698912225",
                        "staggers": [
                            {
                                "created_at": "1698911981",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698912218"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f8241e1752f1f4f1f05e956fe31d599cd4774840",
                "commit_title": "new stackset for mrt admin role ",
                "created_at": "1720011250",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1720011900",
                "changes": [
                    {
                        "id": "e0e44c69-04eb-4ed3-b90d-158cdebe0cd7",
                        "created_at": "1720011256",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1720011460",
                        "staggers": [
                            {
                                "created_at": "1720011257",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1720011460"
                            }
                        ]
                    },
                    {
                        "id": "ce9f9b53-b848-4193-a5ca-b9563e6260a3",
                        "created_at": "1720011255",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeMRTAdministratorRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1720011900",
                        "staggers": [
                            {
                                "created_at": "1720011693",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Non production root",
                                "updated_at": "1720011900"
                            },
                            {
                                "created_at": "1720011256",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Non production stage root",
                                "updated_at": "1720011460"
                            }
                        ]
                    },
                    {
                        "id": "08972214-457a-4e9d-8573-63b66a5be3f2",
                        "created_at": "1720011253",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1720011900",
                        "staggers": [
                            {
                                "created_at": "1720011694",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1720011900"
                            },
                            {
                                "created_at": "1720011255",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1720011461"
                            }
                        ]
                    },
                    {
                        "id": "bbcd9d5a-b2c9-4821-8235-7867c2d56f5e",
                        "created_at": "1720011250",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1720011900",
                        "staggers": [
                            {
                                "created_at": "1720011697",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Non production",
                                "updated_at": "1720011900"
                            },
                            {
                                "created_at": "1720011253",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Non production stage",
                                "updated_at": "1720011461"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-a9d70f02-731e-11ee-a516-acde48001122",
                "commit_title": "test commit - remove all stacksets from grouping, and mark as delete in payload",
                "created_at": "1698228613",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698228678",
                "changes": [
                    {
                        "id": "8fd08d62-1faf-4cbf-82a5-d0ccd4358ef5",
                        "created_at": "1698228614",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698228677",
                        "staggers": [
                            {
                                "created_at": "1698228615",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698228677"
                            }
                        ]
                    },
                    {
                        "id": "ad799f72-46bf-442f-963e-1b4828c4c246",
                        "created_at": "1698228613",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698228678",
                        "staggers": [
                            {
                                "created_at": "1698228614",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698228678"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d29b4c2b701981a922322c509a8841abec35e835",
                "commit_title": "@W-14143629 | Update trust relationships for SAI roles ",
                "created_at": "1696852066",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1696852278",
                "changes": [
                    {
                        "id": "abb207a8-f55d-4280-a42b-333014e2c7d1",
                        "created_at": "1696852073",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696852277",
                        "staggers": [
                            {
                                "created_at": "1696852075",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696852277"
                            }
                        ]
                    },
                    {
                        "id": "067e6794-1dac-42d4-89df-95d425dbd88a",
                        "created_at": "1696852071",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696852278",
                        "staggers": [
                            {
                                "created_at": "1696852073",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696852278"
                            }
                        ]
                    },
                    {
                        "id": "7412d461-1614-471a-9a7c-2f7582a6fece",
                        "created_at": "1696852066",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696852278",
                        "staggers": [
                            {
                                "created_at": "1696852071",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696852278"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "fb60024caea05ac2c3429813ce57182dc0b51721",
                "commit_title": "testing ",
                "created_at": "1731473964",
                "deployment_status": "COMPLETED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731474170",
                "changes": [
                    {
                        "id": "ec5a0515-0e03-421b-8b03-21e8e04a688a",
                        "created_at": "1731473964",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731474170",
                        "staggers": [
                            {
                                "created_at": "1731473968",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731474170"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f7dbeb0ae661794b9d7919d6091d479faf75c5ad",
                "commit_title": "updating parameter ",
                "created_at": "1730224293",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730224496",
                "changes": [
                    {
                        "id": "2975293c-a858-4a87-a5b5-290b431fb153",
                        "created_at": "1730224293",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730224496",
                        "staggers": [
                            {
                                "created_at": "1730224294",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730224496"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "eec364695c449559a6baf3445922a726ae98a192",
                "commit_title": "Updating versions to match ",
                "created_at": "1728327759",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1728328171",
                "changes": [
                    {
                        "id": "4d325299-a15b-440e-866a-c99c29992899",
                        "created_at": "1728327765",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728327969",
                        "staggers": [
                            {
                                "created_at": "1728327767",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1728327969"
                            }
                        ]
                    },
                    {
                        "id": "50f47acb-8ed6-41d7-9053-cc4412fe50be",
                        "created_at": "1728327764",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728328171",
                        "staggers": [
                            {
                                "created_at": "1728327765",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728328171"
                            }
                        ]
                    },
                    {
                        "id": "6ac9a179-552c-42e7-a7aa-9d7b3f76e1a7",
                        "created_at": "1728327759",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728328171",
                        "staggers": [
                            {
                                "created_at": "1728327764",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728328171"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "7d85a5e4f247c76a38e88ead162c117e3852e458",
                "commit_title": "Merge pull request #94 from identity-access/sensitive-policy ",
                "created_at": "1715286644",
                "deployment_status": "COMPLETED",
                "developer_id": "xtao@salesforce.com",
                "updated_at": "1715287462",
                "changes": [
                    {
                        "id": "aa999770-62da-4a0e-a6eb-5d50a36f4874",
                        "created_at": "1715286653",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715287462",
                        "staggers": [
                            {
                                "created_at": "1715287257",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715287462"
                            },
                            {
                                "created_at": "1715286655",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1715287061"
                            }
                        ]
                    },
                    {
                        "id": "240b7a91-b11e-4075-b36d-bc22a4631422",
                        "created_at": "1715286645",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715287062",
                        "staggers": [
                            {
                                "created_at": "1715286653",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715287062"
                            }
                        ]
                    },
                    {
                        "id": "7a192cb9-7004-4f3e-aef0-a7c4576533d6",
                        "created_at": "1715286644",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715287462",
                        "staggers": [
                            {
                                "created_at": "1715287258",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715287462"
                            },
                            {
                                "created_at": "1715286645",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1715287061"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "eb52b74dcc53a377d93a3c79344bcea6b071e085",
                "commit_title": "testing ",
                "created_at": "1731475385",
                "deployment_status": "FAILED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731475592",
                "changes": [
                    {
                        "id": "076e9c1b-072c-4265-87bf-c93bab38a1d3",
                        "created_at": "1731475385",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1731475592",
                        "staggers": [
                            {
                                "created_at": "1731475389",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731475592"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "80366bd8665bbbc15224c4500771eb66731085c8",
                "commit_title": "@W-16186122 Updating servicename as per service registry ",
                "created_at": "1724089924",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1724096773",
                "changes": [
                    {
                        "id": "4f993704-f5b2-4575-adf8-bcf3a11369ed",
                        "created_at": "1724089927",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724090133",
                        "staggers": [
                            {
                                "created_at": "1724089931",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724090133"
                            }
                        ]
                    },
                    {
                        "id": "b35a7009-36e2-4dd8-8c1d-3b1cac811d94",
                        "created_at": "1724089925",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724096773",
                        "staggers": [
                            {
                                "created_at": "1724096571",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724096773"
                            },
                            {
                                "created_at": "1724089927",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1724090133"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "fcb0f7f4d6e065d5631014c01a480b6283ee3abe",
                "commit_title": "@W-16241569 Adding deny SSM start session policy for V1 session policies ",
                "created_at": "1721338578",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1721339650",
                "changes": [
                    {
                        "id": "cea1c5d1-a8a7-4183-af33-bfc352e3058a",
                        "created_at": "1721338588",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721339197",
                        "staggers": [
                            {
                                "created_at": "1721338590",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721339197"
                            }
                        ]
                    },
                    {
                        "id": "aef3e83b-436d-4933-b6dc-8b369a861751",
                        "created_at": "1721338586",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721339650",
                        "staggers": [
                            {
                                "created_at": "1721339447",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721339650"
                            },
                            {
                                "created_at": "1721338588",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1721339197"
                            }
                        ]
                    },
                    {
                        "id": "3e07cbc5-c67b-4a5b-ab76-974ce0924ecc",
                        "created_at": "1721338578",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721339197",
                        "staggers": [
                            {
                                "created_at": "1721338586",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721339196"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "287d6813a171d480ad54578f1c194de01ddc7510",
                "commit_title": "@W-14745198 | Removed Private Marketplace permissions from PCSK roles in Non-falcon accounts ",
                "created_at": "1704710281",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1704710490",
                "changes": [
                    {
                        "id": "88f9bd5e-4e54-4ed1-938d-c9fdd8389579",
                        "created_at": "1704710284",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704710490",
                        "staggers": [
                            {
                                "created_at": "1704710286",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1704710490"
                            }
                        ]
                    },
                    {
                        "id": "7139dd94-d470-4fd7-95f7-645e4fb472e4",
                        "created_at": "1704710281",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704710490",
                        "staggers": [
                            {
                                "created_at": "1704710284",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1704710490"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-61902ca4-71a4-11ee-9522-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698066150",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698066219",
                "changes": [
                    {
                        "id": "79626ab1-cf08-4f14-9ea9-19e475936bd7",
                        "created_at": "1698066152",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698066219",
                        "staggers": [
                            {
                                "created_at": "1698066189",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "second stagger group",
                                "updated_at": "1698066219"
                            },
                            {
                                "created_at": "1698066154",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698066184"
                            }
                        ]
                    },
                    {
                        "id": "3df9f7fc-6bfb-4c1b-a709-e59fdccf6559",
                        "created_at": "1698066150",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698066185",
                        "staggers": [
                            {
                                "created_at": "1698066152",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "one account id",
                                "updated_at": "1698066185"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "1237f8dc897f09bc31c4b330a5bda7083e059484",
                "commit_title": "@W-13738330 | Cleanup of CloudHealth stackset on MPAs ",
                "created_at": "1690535192",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1690535418",
                "changes": [
                    {
                        "id": "95acb58d-da2f-4d10-a87d-975029040d30",
                        "created_at": "1690535209",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535416",
                        "staggers": [
                            {
                                "created_at": "1690535211",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535416"
                            }
                        ]
                    },
                    {
                        "id": "b4bab145-fc85-4292-b43e-9765b2b27823",
                        "created_at": "1690535206",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535417",
                        "staggers": [
                            {
                                "created_at": "1690535209",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535417"
                            }
                        ]
                    },
                    {
                        "id": "f6bffe62-7ab1-4a25-a0a5-8e684a23d930",
                        "created_at": "1690535203",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535418",
                        "staggers": [
                            {
                                "created_at": "1690535206",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535418"
                            }
                        ]
                    },
                    {
                        "id": "6a1ebf3e-baf9-4068-92fe-87659b13bd96",
                        "created_at": "1690535202",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535415",
                        "staggers": [
                            {
                                "created_at": "1690535203",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535415"
                            }
                        ]
                    },
                    {
                        "id": "02215b9b-49ea-42ac-80c2-30de9e410f5d",
                        "created_at": "1690535200",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535417",
                        "staggers": [
                            {
                                "created_at": "1690535202",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535417"
                            }
                        ]
                    },
                    {
                        "id": "0cb03ef2-9cc2-45e6-bec1-d233b2dc727d",
                        "created_at": "1690535198",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535416",
                        "staggers": [
                            {
                                "created_at": "1690535200",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535416"
                            }
                        ]
                    },
                    {
                        "id": "06d43297-ee9c-4ad9-ac33-37db48b5046d",
                        "created_at": "1690535196",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535417",
                        "staggers": [
                            {
                                "created_at": "1690535198",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535417"
                            }
                        ]
                    },
                    {
                        "id": "d328779b-cdb5-481e-a4a0-1cb84b37a00a",
                        "created_at": "1690535194",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535416",
                        "staggers": [
                            {
                                "created_at": "1690535196",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535416"
                            }
                        ]
                    },
                    {
                        "id": "12073ea9-e43b-4bcd-a158-5591ef36b7ab",
                        "created_at": "1690535192",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535417",
                        "staggers": [
                            {
                                "created_at": "1690535194",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535417"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-99766a42-7196-11ee-b088-acde48001122",
                "commit_title": "test commit - remove all stacksets from grouping, and mark as delete in payload",
                "created_at": "1698060233",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698060270",
                "changes": [
                    {
                        "id": "dd6324f5-2be3-40ee-bd99-4e8a4ddccd43",
                        "created_at": "1698060236",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698060269",
                        "staggers": [
                            {
                                "created_at": "1698060237",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698060269"
                            }
                        ]
                    },
                    {
                        "id": "dce4fce8-6de3-45e4-8965-ede62b8ad0a6",
                        "created_at": "1698060235",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698060269",
                        "staggers": [
                            {
                                "created_at": "1698060236",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698060269"
                            }
                        ]
                    },
                    {
                        "id": "08610892-a308-46ee-acfe-4c8605f323dc",
                        "created_at": "1698060233",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698060270",
                        "staggers": [
                            {
                                "created_at": "1698060235",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698060270"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "bbee36d6691d9a7c01d92b3a65907ddc8edf93fb",
                "commit_title": "@W-14193082 | Upgraded PCSK nodejs14 lambdas Part 3 [Magenta Release] ",
                "created_at": "1695966188",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1695966188",
                "changes": [
                    {
                        "id": "d1f426cd-12f8-4f02-8ac0-404c65aeebf2",
                        "created_at": "1697299028",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1697299234",
                        "staggers": [
                            {
                                "created_at": "1697299030",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1697299234"
                            }
                        ]
                    },
                    {
                        "id": "f5d3f845-3367-4224-8a07-9e233f9f5fd3",
                        "created_at": "1695967040",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695967247",
                        "staggers": [
                            {
                                "created_at": "1695967042",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695967247"
                            }
                        ]
                    },
                    {
                        "id": "c2908348-e73e-4ee3-b02e-2726a586935c",
                        "created_at": "1695967038",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695967247",
                        "staggers": [
                            {
                                "created_at": "1695967040",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695967247"
                            }
                        ]
                    },
                    {
                        "id": "1553f94c-1620-46ec-9bb4-4d965c5bef0a",
                        "created_at": "1695967036",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695967247",
                        "staggers": [
                            {
                                "created_at": "1695967038",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695967247"
                            }
                        ]
                    },
                    {
                        "id": "0bcff4da-ed12-4e9c-9ddf-cdf7345e8aec",
                        "created_at": "1695967031",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695967246",
                        "staggers": [
                            {
                                "created_at": "1695967036",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695967246"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "7aceeccdb3fbce2c3a7fe15b7ed6ac36b0215234",
                "commit_title": "Adding 3rd element ",
                "created_at": "1730132671",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730132874",
                "changes": [
                    {
                        "id": "a1643e80-6996-47f8-959c-2a0105343993",
                        "created_at": "1730132671",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730132874",
                        "staggers": [
                            {
                                "created_at": "1730132673",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730132874"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6464aa3abef13c9fe59bb72727053209e4744f76",
                "commit_title": "@W-16758663 updating filename ",
                "created_at": "1726683458",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1726686477",
                "changes": [
                    {
                        "id": "29f6389f-45af-4a02-b216-7790ca3e5570",
                        "created_at": "1726683458",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeMulesoftPcdSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1726686477",
                        "staggers": [
                            {
                                "created_at": "1726683460",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1726686477"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a0f04b3ad68dd26bc7ab77e2aceae9c3be70067c",
                "commit_title": "new commit ",
                "created_at": "1717666424",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1717666626",
                "changes": [
                    {
                        "id": "777753a8-ca3f-46fb-921e-88c865eee9fe",
                        "created_at": "1717666424",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717666626",
                        "staggers": [
                            {
                                "created_at": "1717666425",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717666626"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "29fe868bd813226fee6b4c833bc39bb1d1e37252",
                "commit_title": "scope test delete ",
                "created_at": "1694699106",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1694699169",
                "changes": [
                    {
                        "id": "0f1f2cf8-df9a-4c83-a769-97bb9351d6c3",
                        "created_at": "1694699106",
                        "operation_type": "",
                        "stackset_id": "ScopeTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694699169",
                        "staggers": [
                            {
                                "created_at": "1694699107",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "dev account",
                                "updated_at": "1694699169"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "19e8b8d9afe9811794f6c01cb459f9ab8c1339cc_015062504835_ea2e4745-70e5-11ef-ab92-1e00e00049db",
                "commit_title": "test commit 2 ",
                "created_at": "1726131828",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1726132031",
                "changes": [
                    {
                        "id": "9d3d3f0f-d37e-496b-9b07-5743da08149e",
                        "created_at": "1726131828",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeMRTAdministratorRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1726132031",
                        "staggers": [
                            {
                                "created_at": "1726131829",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1726132031"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "91117627df2efddb8c61fa556f2711be69af03e7",
                "commit_title": "@W-16607522 Adding tags to SSM doc and Loggroup ",
                "created_at": "1724938872",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1724940096",
                "changes": [
                    {
                        "id": "bd105e50-b5f5-46dd-af4a-931ffcb8de13",
                        "created_at": "1724938878",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724939487",
                        "staggers": [
                            {
                                "created_at": "1724938883",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724939487"
                            }
                        ]
                    },
                    {
                        "id": "86a63612-6cd3-422c-b75e-3f64eb91205f",
                        "created_at": "1724938876",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724940096",
                        "staggers": [
                            {
                                "created_at": "1724939894",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724940096"
                            },
                            {
                                "created_at": "1724938878",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1724939487"
                            }
                        ]
                    },
                    {
                        "id": "7c64fd52-9ba7-4ee4-a0d5-4513ae928a1c",
                        "created_at": "1724938872",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724939285",
                        "staggers": [
                            {
                                "created_at": "1724938876",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724939285"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "45a508986f18c3ed9f761b8fd4f4fa36bfe9db29",
                "commit_title": "forced-update ",
                "created_at": "1715596677",
                "deployment_status": "FAILED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715597090",
                "changes": [
                    {
                        "id": "d736f9a1-8efe-4980-aa7f-322db95e7401",
                        "created_at": "1715596677",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "FAILED",
                        "updated_at": "1715597090",
                        "staggers": [
                            {
                                "created_at": "1715596684",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715597090"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "89daaf255b47ec7a447c3efedca984fda82714f5",
                "commit_title": "c1 ",
                "created_at": "1714993745",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714993949",
                "changes": [
                    {
                        "id": "dd9a943a-6a24-4781-a414-d7275ec6ad75",
                        "created_at": "1714993745",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeSCPTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714993949",
                        "staggers": [
                            {
                                "created_at": "1714993746",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1714993949"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6d17e890d32b8d1b38c08e337374271811c6d01f",
                "commit_title": "edit template to test mrt role updation ",
                "created_at": "1709311897",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1709312341",
                "changes": [
                    {
                        "id": "dee51aee-6a31-49af-a071-86a46e1a1521",
                        "created_at": "1709311897",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709312341",
                        "staggers": [
                            {
                                "created_at": "1709312139",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1709312341"
                            },
                            {
                                "created_at": "1709311899",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1709312102"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-008f7ef0-7325-11ee-bcb0-acde48001122",
                "commit_title": "test commit - create scopeTestThird stackset and change group config and template for scopeTestFirst,scopeTestSecond stacksets",
                "created_at": "1698231337",
                "deployment_status": "REVERTED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698231474",
                "changes": [
                    {
                        "id": "2be2adc3-aef0-40f8-a544-fbfc098eb9e1",
                        "created_at": "1698231346",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698231474",
                        "staggers": [
                            {
                                "created_at": "1698231347",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698231474"
                            }
                        ]
                    },
                    {
                        "id": "b7e01238-24cc-4bee-8933-82588ed4fde8",
                        "created_at": "1698231345",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698231474",
                        "staggers": [
                            {
                                "created_at": "1698231346",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698231474"
                            }
                        ]
                    },
                    {
                        "id": "55ee7ea9-3021-4107-bb99-8f0e06ad5656",
                        "created_at": "1698231344",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698231474",
                        "staggers": [
                            {
                                "created_at": "1698231345",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698231474"
                            }
                        ]
                    },
                    {
                        "id": "c0a64f30-055c-40ed-865a-d8aa209c04a7",
                        "created_at": "1698231343",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698231474",
                        "staggers": [
                            {
                                "created_at": "1698231344",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698231474"
                            }
                        ]
                    },
                    {
                        "id": "b0dd3440-fe4a-4dbc-a34b-7315d82eb9ef",
                        "created_at": "1698231342",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698231474",
                        "staggers": [
                            {
                                "created_at": "1698231343",
                                "operation_status": "REVERTED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698231474"
                            }
                        ]
                    },
                    {
                        "id": "8d00915e-22d8-49d9-833f-77de7477f879",
                        "created_at": "1698231340",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698231474",
                        "staggers": [
                            {
                                "created_at": "1698231342",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698231474"
                            }
                        ]
                    },
                    {
                        "id": "01613f78-42f1-43cf-86da-c4a3ec5c73c7",
                        "created_at": "1698231337",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698231474",
                        "staggers": [
                            {
                                "created_at": "1698231415",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698231474"
                            },
                            {
                                "created_at": "1698231340",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698231474"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "a5f71081eb913b87631fba6f01671b663a07edb4",
                "commit_title": "renamed ",
                "created_at": "1695902317",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1695902727",
                "changes": [
                    {
                        "id": "454e359c-021b-4a24-bf98-f170881fa5c4",
                        "created_at": "1695902322",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695902525",
                        "staggers": [
                            {
                                "created_at": "1695902323",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695902525"
                            }
                        ]
                    },
                    {
                        "id": "d9fa05da-8ad6-4403-a034-0856cbeee7fd",
                        "created_at": "1695902317",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695902727",
                        "staggers": [
                            {
                                "created_at": "1695902322",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695902727"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "c144bc16000754d50fe19c6066be2fda8561d696",
                "commit_title": "add empty param in mpa config ",
                "created_at": "1694698468",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1694698533",
                "changes": [
                    {
                        "id": "94117e1e-6587-45ed-a189-ca29f9a04340",
                        "created_at": "1694698468",
                        "operation_type": "",
                        "stackset_id": "ScopeTestMpa",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694698533",
                        "staggers": [
                            {
                                "created_at": "1694698470",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "MPAs",
                                "updated_at": "1694698533"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f38e3198b5782b6a1e1450811dc80fd6907387ca",
                "commit_title": "mark stackset as scope resource ",
                "created_at": "1694696580",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1694696644",
                "changes": [
                    {
                        "id": "61a7e80c-aa93-46cc-908e-d2939dc6cdd4",
                        "created_at": "1694696580",
                        "operation_type": "",
                        "stackset_id": "ScopeTestMpa",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694696644",
                        "staggers": [
                            {
                                "created_at": "1694696582",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "MPAs",
                                "updated_at": "1694696644"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "91da44a5f43d4ed12ca853f6da80b15685ad2179",
                "commit_title": "Merge pull request #196 from dnagpal/additional-readOnly-role ",
                "created_at": "1727161954",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1727162568",
                "changes": [
                    {
                        "id": "5c2310c5-1e9f-49b7-b49e-be622d32795e",
                        "created_at": "1727161954",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727162568",
                        "staggers": [
                            {
                                "created_at": "1727161957",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1727162568"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3d5049a08f921089bcd6521acd5f88781cd55356",
                "commit_title": "delete-prisma-stack ",
                "created_at": "1717140223",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1717140638",
                "changes": [
                    {
                        "id": "4eb05789-56ea-4710-acd8-6133708b7430",
                        "created_at": "1717140232",
                        "operation_type": "DELETE",
                        "stackset_id": "scopePrismaRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717140638",
                        "staggers": [
                            {
                                "created_at": "1717140233",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1717140637"
                            }
                        ]
                    },
                    {
                        "id": "dd1bee79-440d-4b34-bd21-f892550bf4f3",
                        "created_at": "1717140225",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717140638",
                        "staggers": [
                            {
                                "created_at": "1717140232",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717140638"
                            }
                        ]
                    },
                    {
                        "id": "e6870a08-4f24-482b-98cc-ef5afb110271",
                        "created_at": "1717140223",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717140638",
                        "staggers": [
                            {
                                "created_at": "1717140225",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717140638"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "74833b50bfc183e6b476b7518000cf57c62dd8d1",
                "commit_title": "testing ",
                "created_at": "1731521148",
                "deployment_status": "COMPLETED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731521353",
                "changes": [
                    {
                        "id": "bb690e64-ee2a-4aea-a044-2980b629daa4",
                        "created_at": "1731521148",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731521353",
                        "staggers": [
                            {
                                "created_at": "1731521152",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731521353"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e8973729dd9c045c5a7ff17421c5efc6286cf73b",
                "commit_title": "adding wildcard for principal ",
                "created_at": "1730220060",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730220263",
                "changes": [
                    {
                        "id": "cbcd34c8-e6cb-484b-aa92-f5e205fbaf5e",
                        "created_at": "1730220060",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730220263",
                        "staggers": [
                            {
                                "created_at": "1730220062",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730220263"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "275f04bd9a09482a108cbbda7f407e1d62e5938e",
                "commit_title": "Updating versions ",
                "created_at": "1729698713",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1729698917",
                "changes": [
                    {
                        "id": "3a5f0104-9b6c-40e0-8e2f-4d7822c97c7e",
                        "created_at": "1729698713",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729698917",
                        "staggers": [
                            {
                                "created_at": "1729698715",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1729698917"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "def899aa2871b2d7bca46afbef9caeaa1478769b",
                "commit_title": "added-update-to-child ",
                "created_at": "1715953618",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715953722",
                "changes": [
                    {
                        "id": "ea183292-3fa0-4525-971b-48d514b0facb",
                        "created_at": "1715953618",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopePrismaRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715953722",
                        "staggers": [
                            {
                                "created_at": "1715953620",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715953722"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d2ccef1d4ab1b9603c8032604c61a19223cc2a79",
                "commit_title": "added-prisma-stackset ",
                "created_at": "1715946666",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715946873",
                "changes": [
                    {
                        "id": "a12e1bfe-6d37-45dd-9027-efb03b34ad4d",
                        "created_at": "1715946666",
                        "operation_type": "CREATE",
                        "stackset_id": "scopePrismaRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715946873",
                        "staggers": [
                            {
                                "created_at": "1715946668",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715946873"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "8656bf10adf82aa05bcd9782bf2878788003dce4",
                "commit_title": "Update CommonIAM ",
                "created_at": "1713783439",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1713783915",
                "changes": [
                    {
                        "id": "209eab86-b763-4072-9e34-e77144d88639",
                        "created_at": "1713783439",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1713783915",
                        "staggers": [
                            {
                                "created_at": "1713783713",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1713783915"
                            },
                            {
                                "created_at": "1713783440",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1713783643"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e1e4897913d14d26f0afdb35efb6d81699d8fa37",
                "commit_title": "vendingtest stackset creation ",
                "created_at": "1711107888",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1711109135",
                "changes": [
                    {
                        "id": "d62f1d04-a2cd-4f96-984f-21e2882c150f",
                        "created_at": "1711107888",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeVendingTest",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1711109135",
                        "staggers": [
                            {
                                "created_at": "1711107891",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "internal ou",
                                "updated_at": "1711108093"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "d5607de79d6520da98d629728d2286ad65eb14ee",
                "commit_title": "test 1 ",
                "created_at": "1728501774",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1728502181",
                "changes": [
                    {
                        "id": "df636cfe-81fd-43ff-bfb0-7c0c81f9e85a",
                        "created_at": "1728501774",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728502181",
                        "staggers": [
                            {
                                "created_at": "1728501779",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728502181"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d1dff54270861fe52eae4f615acb160b22af28d2",
                "commit_title": "Scope SCP protection changes ",
                "created_at": "1718794632",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1718794836",
                "changes": [
                    {
                        "id": "14150e77-7cf8-42a3-a9c2-97425461a8fb",
                        "created_at": "1718794632",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeSCPprotectionV2",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1718794836",
                        "staggers": [
                            {
                                "created_at": "1718794633",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1718794836"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "2dfccc17ee613f271cffb627e0ce3b052f1721f1",
                "commit_title": "add permission for mrt role ",
                "created_at": "1709737399",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1709737816",
                "changes": [
                    {
                        "id": "83c137f5-012d-48d8-bc39-9dea64bba48e",
                        "created_at": "1709737399",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709737816",
                        "staggers": [
                            {
                                "created_at": "1709737615",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1709737816"
                            },
                            {
                                "created_at": "1709737401",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1709737604"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "eceb08d6f0ab1725af8a2ed3994709251f6fe314",
                "commit_title": "added-second-scope-test ",
                "created_at": "1692877490",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1692877936",
                "changes": [
                    {
                        "id": "72a46fb4-2471-434b-b9b7-0c3babdaf3b9",
                        "created_at": "1692877512",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692877720",
                        "staggers": [
                            {
                                "created_at": "1692877514",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692877720"
                            }
                        ]
                    },
                    {
                        "id": "8e205cb1-84e4-481f-b4d7-64138f7999cc",
                        "created_at": "1692877510",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692877720",
                        "staggers": [
                            {
                                "created_at": "1692877512",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692877720"
                            }
                        ]
                    },
                    {
                        "id": "33f10f0a-15c5-4e38-95ba-cd747afbb3d3",
                        "created_at": "1692877508",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692877719",
                        "staggers": [
                            {
                                "created_at": "1692877510",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692877719"
                            }
                        ]
                    },
                    {
                        "id": "c322e0c8-0830-4721-8f0a-f727e87cb6db",
                        "created_at": "1692877504",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692877719",
                        "staggers": [
                            {
                                "created_at": "1692877508",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692877719"
                            }
                        ]
                    },
                    {
                        "id": "97ba6bec-635b-4942-8d5b-2321a75415d1",
                        "created_at": "1692877501",
                        "operation_type": "",
                        "stackset_id": "bhor-test",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692877936",
                        "staggers": [
                            {
                                "created_at": "1692877733",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "scope-ou-second",
                                "updated_at": "1692877936"
                            },
                            {
                                "created_at": "1692877504",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "scope-ou",
                                "updated_at": "1692877719"
                            }
                        ]
                    },
                    {
                        "id": "0606716e-b7e5-4eae-a43c-9ab79d78ed03",
                        "created_at": "1692877499",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692877719",
                        "staggers": [
                            {
                                "created_at": "1692877501",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692877719"
                            }
                        ]
                    },
                    {
                        "id": "ae8b727a-2e4b-4dac-9546-6c8a70862a68",
                        "created_at": "1692877497",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692877718",
                        "staggers": [
                            {
                                "created_at": "1692877499",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692877718"
                            }
                        ]
                    },
                    {
                        "id": "b6943a03-b7fb-4b2f-ac56-ffc5e086c332",
                        "created_at": "1692877495",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692877720",
                        "staggers": [
                            {
                                "created_at": "1692877497",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692877720"
                            }
                        ]
                    },
                    {
                        "id": "f8134717-acf0-4034-8aa5-a873f6c49662",
                        "created_at": "1692877493",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692877721",
                        "staggers": [
                            {
                                "created_at": "1692877495",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692877721"
                            }
                        ]
                    },
                    {
                        "id": "3aaa8273-4f1f-4ce9-9f1a-0d2cebfcd5d4",
                        "created_at": "1692877490",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692877718",
                        "staggers": [
                            {
                                "created_at": "1692877493",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692877718"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "37ce3b9476138c7b8782202496fe2510a437d964",
                "commit_title": "add another dev account as deployment target ",
                "created_at": "1692193820",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1692194048",
                "changes": [
                    {
                        "id": "38fa9d00-6d45-4991-8bce-f39234b17ed3",
                        "created_at": "1692193840",
                        "operation_type": "",
                        "stackset_id": "dummyTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692194047",
                        "staggers": [
                            {
                                "created_at": "1692193842",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "dipanshu account id",
                                "updated_at": "1692194047"
                            }
                        ]
                    },
                    {
                        "id": "a54c4cf1-74ae-4c98-9acb-c00c4774fd16",
                        "created_at": "1692193838",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692194046",
                        "staggers": [
                            {
                                "created_at": "1692193840",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692194046"
                            }
                        ]
                    },
                    {
                        "id": "11b1e54f-1d3b-4e46-af07-c7233bad941e",
                        "created_at": "1692193836",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692194048",
                        "staggers": [
                            {
                                "created_at": "1692193838",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692194048"
                            }
                        ]
                    },
                    {
                        "id": "086cd5ed-5455-4332-98f1-5af360b41f11",
                        "created_at": "1692193832",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692194045",
                        "staggers": [
                            {
                                "created_at": "1692193836",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692194045"
                            }
                        ]
                    },
                    {
                        "id": "6f9f7861-24d4-4f44-8607-3f7d579b5b65",
                        "created_at": "1692193829",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692194046",
                        "staggers": [
                            {
                                "created_at": "1692193832",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692194045"
                            }
                        ]
                    },
                    {
                        "id": "d45bf511-8c79-4dcd-8063-a953b1817253",
                        "created_at": "1692193827",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692194046",
                        "staggers": [
                            {
                                "created_at": "1692193829",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692194046"
                            }
                        ]
                    },
                    {
                        "id": "f988eb3c-5634-4dfb-957a-9c20e47864a1",
                        "created_at": "1692193826",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692194046",
                        "staggers": [
                            {
                                "created_at": "1692193827",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692194046"
                            }
                        ]
                    },
                    {
                        "id": "6a84aa54-a1ea-4e3f-8af5-e8d5c787d653",
                        "created_at": "1692193824",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692194047",
                        "staggers": [
                            {
                                "created_at": "1692193826",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692194047"
                            }
                        ]
                    },
                    {
                        "id": "26558c3f-da4a-4839-9410-de5aad2d726e",
                        "created_at": "1692193822",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692194047",
                        "staggers": [
                            {
                                "created_at": "1692193824",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692194047"
                            }
                        ]
                    },
                    {
                        "id": "c07db604-4747-4251-b9b9-e4edd775903f",
                        "created_at": "1692193820",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692194047",
                        "staggers": [
                            {
                                "created_at": "1692193822",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692194047"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a083243ccf9c2e365a0885cc951157584aa6458e",
                "commit_title": "stackset change ",
                "created_at": "1677667934",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1677668365",
                "changes": [
                    {
                        "id": "d1c825cc-0c0b-4051-87be-819f6d022c4e",
                        "created_at": "1677667940",
                        "operation_type": "",
                        "stackset_id": "TestStacksetFalcon2",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1677668365",
                        "staggers": [
                            {
                                "created_at": "1677668058",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1677668365"
                            },
                            {
                                "created_at": "1677667945",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage",
                                "updated_at": "1677668046"
                            }
                        ]
                    },
                    {
                        "id": "c6731e43-a33e-4b8d-ad4a-8bf10a57951a",
                        "created_at": "1677667934",
                        "operation_type": "",
                        "stackset_id": "TestStacksetFalcon1",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1677668365",
                        "staggers": [
                            {
                                "created_at": "1677668062",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1677668365"
                            },
                            {
                                "created_at": "1677667940",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage",
                                "updated_at": "1677668047"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "2b8285aae7e9a6bc2f04fb83f24835b1c7a504e4",
                "commit_title": "Merge branch 'master' into resiliency_branch ",
                "created_at": "1731793596",
                "deployment_status": "COMPLETED",
                "developer_id": "hzhao@salesforce.com",
                "updated_at": "1731794342",
                "changes": [
                    {
                        "id": "0e979856-17c6-431e-87e8-db0372172ab8",
                        "created_at": "1731793609",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731794342",
                        "staggers": [
                            {
                                "created_at": "1731794140",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731794342"
                            },
                            {
                                "created_at": "1731793611",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1731793814"
                            }
                        ]
                    },
                    {
                        "id": "395fae22-8001-40e9-8e5f-fede96956453",
                        "created_at": "1731793607",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731793812",
                        "staggers": [
                            {
                                "created_at": "1731793609",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731793812"
                            }
                        ]
                    },
                    {
                        "id": "6123e564-4104-43af-9322-cd0ec02b2caf",
                        "created_at": "1731793602",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731793814",
                        "staggers": [
                            {
                                "created_at": "1731793607",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731793814"
                            }
                        ]
                    },
                    {
                        "id": "4d60ebf0-24f4-4367-b40c-881926128004",
                        "created_at": "1731793601",
                        "operation_type": "UPDATE",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731793813",
                        "staggers": [
                            {
                                "created_at": "1731793602",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731793813"
                            }
                        ]
                    },
                    {
                        "id": "aae4c888-ecaa-49c0-a249-af295a29491a",
                        "created_at": "1731793599",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731793813",
                        "staggers": [
                            {
                                "created_at": "1731793601",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731793813"
                            }
                        ]
                    },
                    {
                        "id": "9a44a31e-aece-44b8-9bba-166f288bfe3c",
                        "created_at": "1731793597",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731793812",
                        "staggers": [
                            {
                                "created_at": "1731793599",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731793812"
                            }
                        ]
                    },
                    {
                        "id": "00bf4a54-8ea0-421b-94de-7d6512e7870c",
                        "created_at": "1731793596",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731794342",
                        "staggers": [
                            {
                                "created_at": "1731794139",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731794342"
                            },
                            {
                                "created_at": "1731793597",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1731793813"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a2d2afb2d7af4f284686c9eb0b678f382ef8885c",
                "commit_title": "testing ",
                "created_at": "1731505596",
                "deployment_status": "COMPLETED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731505801",
                "changes": [
                    {
                        "id": "a46c42ff-33e3-4988-b25e-620ba93ec8e7",
                        "created_at": "1731505596",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731505801",
                        "staggers": [
                            {
                                "created_at": "1731505600",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731505801"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a34b801b2e005197a50d994032afee93e40efbd6",
                "commit_title": "refactoring file name ",
                "created_at": "1731014785",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1731014989",
                "changes": [
                    {
                        "id": "d681477b-7485-455f-87fd-ca79b03702cb",
                        "created_at": "1731014785",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731014989",
                        "staggers": [
                            {
                                "created_at": "1731014786",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1731014989"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "292102fa8299a698bef9e18db2e02cf93e7e6031",
                "commit_title": "@W-15842798 adding new permissions for falcon MPA ",
                "created_at": "1716998711",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1716999328",
                "changes": [
                    {
                        "id": "dad1cef8-030f-43da-878e-abc0ab2fe8c0",
                        "created_at": "1716998719",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716998924",
                        "staggers": [
                            {
                                "created_at": "1716998721",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1716998924"
                            }
                        ]
                    },
                    {
                        "id": "bd6759cb-a077-44b5-8476-8c255259db9b",
                        "created_at": "1716998711",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716999328",
                        "staggers": [
                            {
                                "created_at": "1716998719",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1716999328"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3122512f00be990b2d525ca7f5199e340317495b",
                "commit_title": "update-sai-role-name ",
                "created_at": "1715000921",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715001529",
                "changes": [
                    {
                        "id": "31277424-5d1f-4ac9-b989-e00f16e6c1ad",
                        "created_at": "1715000921",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSAIRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715001529",
                        "staggers": [
                            {
                                "created_at": "1715000923",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1715001529"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "1865f1f7ff742f9b788b98afbbeac2871fb94263",
                "commit_title": "change permission for odcr role ",
                "created_at": "1709034466",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1709034670",
                "changes": [
                    {
                        "id": "14848d58-a5ec-41b6-82d0-db84ff22874d",
                        "created_at": "1709034466",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709034670",
                        "staggers": [
                            {
                                "created_at": "1709034467",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709034670"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6e6c0826f39d6b37caac10682170b11aa0c2b614_857050118540_2e3a26ca-950c-11ee-947e-3680d7cb0cd2",
                "commit_title": "add MRT resources stackset ",
                "created_at": "1701959006",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1701959006",
                "changes": [
                    {
                        "id": "73ffc499-7269-4592-8c41-677e1cc1de78",
                        "created_at": "1701959006",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeMRTResources",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701959042",
                        "staggers": [
                            {
                                "created_at": "1701959007",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1701959042"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "8ede7fd9537bd8881577c8dafd8b860cdaa577ae",
                "commit_title": "scope-mpa-test ",
                "created_at": "1694616516",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1694616745",
                "changes": [
                    {
                        "id": "35d609ae-a16a-4932-88d5-04e41f13c6c9",
                        "created_at": "1694616536",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694616745",
                        "staggers": [
                            {
                                "created_at": "1694616539",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694616745"
                            }
                        ]
                    },
                    {
                        "id": "4bc7d794-189b-4f01-8624-d71a24717326",
                        "created_at": "1694616534",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694616744",
                        "staggers": [
                            {
                                "created_at": "1694616536",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694616744"
                            }
                        ]
                    },
                    {
                        "id": "89020d0e-cec5-4ae0-9f90-750af9c27f58",
                        "created_at": "1694616530",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694616745",
                        "staggers": [
                            {
                                "created_at": "1694616534",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694616745"
                            }
                        ]
                    },
                    {
                        "id": "e92d23c1-874a-4e26-bc0a-30629c5b033a",
                        "created_at": "1694616529",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694616745",
                        "staggers": [
                            {
                                "created_at": "1694616530",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694616745"
                            }
                        ]
                    },
                    {
                        "id": "0437ef2e-a609-4b53-8f39-95877f5f51b9",
                        "created_at": "1694616527",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694616743",
                        "staggers": [
                            {
                                "created_at": "1694616529",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694616743"
                            }
                        ]
                    },
                    {
                        "id": "a5133b7d-580e-45eb-bfa7-adc0e99c2a3c",
                        "created_at": "1694616525",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694616743",
                        "staggers": [
                            {
                                "created_at": "1694616527",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694616743"
                            }
                        ]
                    },
                    {
                        "id": "b2ae5542-7c64-494a-996c-f812f88c9d3d",
                        "created_at": "1694616523",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694616743",
                        "staggers": [
                            {
                                "created_at": "1694616525",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694616743"
                            }
                        ]
                    },
                    {
                        "id": "f25e2de2-35cd-4551-a9bf-a8fb9435ac32",
                        "created_at": "1694616521",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694616744",
                        "staggers": [
                            {
                                "created_at": "1694616523",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694616744"
                            }
                        ]
                    },
                    {
                        "id": "8f4cbfa8-94cf-401e-b90d-8ae3d3e8b277",
                        "created_at": "1694616516",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694616744",
                        "staggers": [
                            {
                                "created_at": "1694616521",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694616744"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "1238fa20fb778af5862aabcfe8590b1b3e58d1b7",
                "commit_title": "@W-17130246 creating separate templates and stacks based on MPAs 1 and 2 ",
                "created_at": "1731001185",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1731001390",
                "changes": [
                    {
                        "id": "e04343fb-ad3c-4b1a-bad9-c7ebf67a4ae1",
                        "created_at": "1731001186",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSensorsControlTelemetryRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731001390",
                        "staggers": [
                            {
                                "created_at": "1731001188",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1731001390"
                            }
                        ]
                    },
                    {
                        "id": "61a7f457-cde6-472e-9d7c-1ac67b39475a",
                        "created_at": "1731001185",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731001390",
                        "staggers": [
                            {
                                "created_at": "1731001186",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1731001390"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "dd9c0cf06a12abb4e50775410f6054b4c7084b1b",
                "commit_title": "Updatin version ",
                "created_at": "1730148202",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730148405",
                "changes": [
                    {
                        "id": "365e84f0-0902-48d2-a601-2bc25ab7fcb2",
                        "created_at": "1730148202",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730148405",
                        "staggers": [
                            {
                                "created_at": "1730148203",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730148405"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "83205ca523bbe0d5d38e41dd5e0e219fe1323868",
                "commit_title": "@W-16989479 | [TD-0205604] New Role for Tentacle functionality CC#67839082 (#208) ",
                "created_at": "1729666101",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1729666529",
                "changes": [
                    {
                        "id": "0c986582-c3cb-4610-870d-ac4b9261661e",
                        "created_at": "1729666104",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729666312",
                        "staggers": [
                            {
                                "created_at": "1729666109",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729666312"
                            }
                        ]
                    },
                    {
                        "id": "8a878aca-0e08-49f0-a70e-4f1e208f02b9",
                        "created_at": "1729666103",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729666313",
                        "staggers": [
                            {
                                "created_at": "1729666104",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729666313"
                            }
                        ]
                    },
                    {
                        "id": "e62a8e9a-c920-4105-99cd-2f81e8c4094f",
                        "created_at": "1729666101",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729666529",
                        "staggers": [
                            {
                                "created_at": "1729666326",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729666529"
                            },
                            {
                                "created_at": "1729666103",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1729666313"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3eca0fb5da4b3e6362afaa403e1b7c7ea2476a81",
                "commit_title": "test-1 ",
                "created_at": "1709805146",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1709805350",
                "changes": [
                    {
                        "id": "19360546-f49c-4231-a871-685dc050885f",
                        "created_at": "1709805146",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeOUMovementTracker",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709805350",
                        "staggers": [
                            {
                                "created_at": "1709805147",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1709805350"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3ab66de0302a05862a9a48de137530b8b7fed0a4",
                "commit_title": "fix policy name ",
                "created_at": "1709303570",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1709311251",
                "changes": [
                    {
                        "id": "03c44fc0-ef3d-486a-ab90-15604095660e",
                        "created_at": "1709303570",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1709311251",
                        "staggers": [
                            {
                                "created_at": "1709303572",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1709303775"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "f3d8fb80fa13a6379988f1c47819498f1a279634_719063279660_8d8cb9e0-7953-11ee-aab2-56d7cf10e70b",
                "commit_title": "added-mpa-as-target ",
                "created_at": "1698911032",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1698911032",
                "changes": [
                    {
                        "id": "db8db4b8-4b38-4bac-b45b-c1c8627e1f6d",
                        "created_at": "1698911032",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeDemoStackset",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698911255",
                        "staggers": [
                            {
                                "created_at": "1698911033",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698911252"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "cf79d7ee73acf6884d89ca049328b7e302c8ff52",
                "commit_title": "Merge pull request #65 from identity-access/@W-14025010-update-eksAccessRole-permissions ",
                "created_at": "1696849080",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1696852066",
                "changes": [
                    {
                        "id": "1ef21c80-ddfd-400d-a70d-cbdb47d1b80b",
                        "created_at": "1696849087",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1696852066",
                        "staggers": [
                            {
                                "created_at": "1696849089",
                                "operation_status": "OVERRIDDEN",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696852066"
                            }
                        ]
                    },
                    {
                        "id": "26934711-d1e2-491b-bf7e-2519b23ce893",
                        "created_at": "1696849085",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696849308",
                        "staggers": [
                            {
                                "created_at": "1696849087",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696849308"
                            }
                        ]
                    },
                    {
                        "id": "5b2c36c7-1a1a-4e07-9593-db6a14d1805c",
                        "created_at": "1696849080",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1696852066",
                        "staggers": [
                            {
                                "created_at": "1696849085",
                                "operation_status": "OVERRIDDEN",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696852066"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "f14963d05d8f8a4adcac47bb76ffa58a3c4dd50c",
                "commit_title": "@W-16167395 | Account cleanup step function fix to retain permission booundary ",
                "created_at": "1721289760",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1721289980",
                "changes": [
                    {
                        "id": "78f71ea7-78ae-460e-8006-be61f5086b91",
                        "created_at": "1721289773",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721289980",
                        "staggers": [
                            {
                                "created_at": "1721289775",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721289980"
                            }
                        ]
                    },
                    {
                        "id": "91333b73-4161-4b78-a74b-c59dc53c3f19",
                        "created_at": "1721289765",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "FAILED",
                        "updated_at": "1721289980",
                        "staggers": [
                            {
                                "created_at": "1721289773",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721289980"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "ffb867f14d9c63039f7cce0f77a9a6082f7d1071",
                "commit_title": "updated-rd ",
                "created_at": "1715758897",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715759001",
                "changes": [
                    {
                        "id": "554cb0de-164d-4ad8-b4e4-fd6088998309",
                        "created_at": "1715758897",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSCPSelfManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715759001",
                        "staggers": [
                            {
                                "created_at": "1715758899",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715759001"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "998cf2b1ab2cbf2b1dafa8ddebb08ae3a55c7ddc",
                "commit_title": "t1 ",
                "created_at": "1700650944",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1700651161",
                "changes": [
                    {
                        "id": "3d92965f-7740-4a5a-bfdf-946fabfe2d9b",
                        "created_at": "1700650953",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700651161",
                        "staggers": [
                            {
                                "created_at": "1700650955",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700651161"
                            }
                        ]
                    },
                    {
                        "id": "5bfbc0e9-d822-4d19-affa-848c5b27d146",
                        "created_at": "1700650951",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700651160",
                        "staggers": [
                            {
                                "created_at": "1700650953",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700651160"
                            }
                        ]
                    },
                    {
                        "id": "7b07b709-f28a-4bbc-8fed-cc9e924ab384",
                        "created_at": "1700650949",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700651160",
                        "staggers": [
                            {
                                "created_at": "1700650951",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700651160"
                            }
                        ]
                    },
                    {
                        "id": "73f7d382-0f2b-4d5b-9bb8-9816f136025d",
                        "created_at": "1700650944",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700651160",
                        "staggers": [
                            {
                                "created_at": "1700650949",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700651160"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f021a6783294367a93bcd4ef9219c9872ff80cb8",
                "commit_title": "@W-13211761 | [Phase 2] Changes to AWS Billing, Cost Management and Account Consoles Permissions ",
                "created_at": "1689583802",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1689584029",
                "changes": [
                    {
                        "id": "af05d83c-0a5e-4b2e-a9f5-6a2da6a48cf2",
                        "created_at": "1689583821",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689584028",
                        "staggers": [
                            {
                                "created_at": "1689583823",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689584028"
                            }
                        ]
                    },
                    {
                        "id": "08b83bf5-4468-4501-97fb-8e52fdac888a",
                        "created_at": "1689583819",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689584027",
                        "staggers": [
                            {
                                "created_at": "1689583821",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689584027"
                            }
                        ]
                    },
                    {
                        "id": "103d648e-4f8e-46a0-a0d5-8e32d795829e",
                        "created_at": "1689583816",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689584028",
                        "staggers": [
                            {
                                "created_at": "1689583819",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689584028"
                            }
                        ]
                    },
                    {
                        "id": "d52c59ab-2273-4df8-96fd-bb1a8c692545",
                        "created_at": "1689583815",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689584029",
                        "staggers": [
                            {
                                "created_at": "1689583816",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689584029"
                            }
                        ]
                    },
                    {
                        "id": "c34bb8fd-a44f-43ef-bd3a-4a07701441a0",
                        "created_at": "1689583813",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689584028",
                        "staggers": [
                            {
                                "created_at": "1689583815",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689584028"
                            }
                        ]
                    },
                    {
                        "id": "73b5e988-a5b7-45ca-9c5d-ffdfa6c7ab7e",
                        "created_at": "1689583811",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689584027",
                        "staggers": [
                            {
                                "created_at": "1689583813",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689584027"
                            }
                        ]
                    },
                    {
                        "id": "ce18bc63-4713-472e-a86e-e8d7134d20be",
                        "created_at": "1689583809",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689584028",
                        "staggers": [
                            {
                                "created_at": "1689583811",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689584028"
                            }
                        ]
                    },
                    {
                        "id": "a884f1c5-eb65-43ea-b12a-5b1b1f837918",
                        "created_at": "1689583806",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689584029",
                        "staggers": [
                            {
                                "created_at": "1689583809",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689584029"
                            }
                        ]
                    },
                    {
                        "id": "f343822d-b8a9-470e-8013-5e1e63c941c6",
                        "created_at": "1689583802",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689584029",
                        "staggers": [
                            {
                                "created_at": "1689583806",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689584029"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "1aa8568487cdea9a6ca94158eab8cf8304850603",
                "commit_title": "testing ",
                "created_at": "1731477428",
                "deployment_status": "COMPLETED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731477834",
                "changes": [
                    {
                        "id": "b4368158-a1f4-4f79-a1fe-60bdc25e28b1",
                        "created_at": "1731477428",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731477834",
                        "staggers": [
                            {
                                "created_at": "1731477432",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731477834"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6486a879fc3000e4ec9f9b15be809ef26815ce8e",
                "commit_title": "@W-16167395 | Account cleanup step function fix to retain permission booundary ",
                "created_at": "1721290142",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1721290782",
                "changes": [
                    {
                        "id": "4622b350-987f-4c66-a086-1f12b63773a6",
                        "created_at": "1721290153",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721290562",
                        "staggers": [
                            {
                                "created_at": "1721290155",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721290562"
                            }
                        ]
                    },
                    {
                        "id": "867b39ac-328e-456b-be27-3b25ce634eaf",
                        "created_at": "1721290151",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721290782",
                        "staggers": [
                            {
                                "created_at": "1721290580",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721290782"
                            },
                            {
                                "created_at": "1721290153",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1721290562"
                            }
                        ]
                    },
                    {
                        "id": "20a7b3a5-82e6-44b2-8662-f835ad30c0a2",
                        "created_at": "1721290142",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721290561",
                        "staggers": [
                            {
                                "created_at": "1721290150",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721290561"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "2bab11e3b46f75a281147bfac709613c0fafe202",
                "commit_title": "added permission for list parent ",
                "created_at": "1717503797",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1717504217",
                "changes": [
                    {
                        "id": "1ea895bf-05e9-4578-9347-2bb1c2c496db",
                        "created_at": "1717503797",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717504217",
                        "staggers": [
                            {
                                "created_at": "1717504016",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717504217"
                            },
                            {
                                "created_at": "1717503799",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717504001"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "5d466108025df5d68c8cd88e8df2098bcd334f9b",
                "commit_title": "Merge pull request #89 from identity-access/stagger ",
                "created_at": "1701874344",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1701874549",
                "changes": [
                    {
                        "id": "351c5e98-863c-44da-92b2-d75874fa5c9a",
                        "created_at": "1701874345",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701874549",
                        "staggers": [
                            {
                                "created_at": "1701874346",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1701874549"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "5bc353258375801eb33f80c09c8f34760b239570",
                "commit_title": " @W-14193082 | Upgraded PCSK nodejs14 lambdas Part 3 [Magenta Release] ",
                "created_at": "1700635478",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1700635694",
                "changes": [
                    {
                        "id": "499ef279-2333-43cd-be2c-0e5e83e56dc0",
                        "created_at": "1700635486",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700635692",
                        "staggers": [
                            {
                                "created_at": "1700635489",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700635692"
                            }
                        ]
                    },
                    {
                        "id": "169dca95-ed7a-465a-80e3-c59fc95f605e",
                        "created_at": "1700635484",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700635694",
                        "staggers": [
                            {
                                "created_at": "1700635486",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700635694"
                            }
                        ]
                    },
                    {
                        "id": "599c5509-3a18-4202-b3c5-f53051dddb58",
                        "created_at": "1700635482",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700635693",
                        "staggers": [
                            {
                                "created_at": "1700635484",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700635693"
                            }
                        ]
                    },
                    {
                        "id": "c87136b3-7995-486e-b6f5-aea67216d336",
                        "created_at": "1700635478",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700635693",
                        "staggers": [
                            {
                                "created_at": "1700635482",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700635693"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6b78d710714379b1bd90f8350519355f7f910fef",
                "commit_title": "stage v ",
                "created_at": "1689599484",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1689599713",
                "changes": [
                    {
                        "id": "89724fdd-45cf-4b7b-b31b-7e7a1f0ea20c",
                        "created_at": "1689599504",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689599713",
                        "staggers": [
                            {
                                "created_at": "1689599506",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689599713"
                            }
                        ]
                    },
                    {
                        "id": "5b68e54a-2f06-4913-9270-43e42d3c0e2c",
                        "created_at": "1689599501",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689599711",
                        "staggers": [
                            {
                                "created_at": "1689599504",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689599711"
                            }
                        ]
                    },
                    {
                        "id": "30f5eb5f-5c09-42f5-bae8-8a0ab98e404b",
                        "created_at": "1689599499",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689599712",
                        "staggers": [
                            {
                                "created_at": "1689599501",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689599712"
                            }
                        ]
                    },
                    {
                        "id": "6bed5c5f-4cab-4a7a-bd1d-b205bf3463d9",
                        "created_at": "1689599497",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689599713",
                        "staggers": [
                            {
                                "created_at": "1689599499",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689599713"
                            }
                        ]
                    },
                    {
                        "id": "2eb7db12-d895-414f-b9af-5bba61966d8b",
                        "created_at": "1689599494",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689599712",
                        "staggers": [
                            {
                                "created_at": "1689599497",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689599712"
                            }
                        ]
                    },
                    {
                        "id": "2ea701b6-5cf9-4bee-92ce-09b90fa04dc5",
                        "created_at": "1689599491",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689599711",
                        "staggers": [
                            {
                                "created_at": "1689599494",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689599711"
                            }
                        ]
                    },
                    {
                        "id": "0ac24871-1364-4d0b-8d1d-61d448996fa1",
                        "created_at": "1689599489",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689599711",
                        "staggers": [
                            {
                                "created_at": "1689599491",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689599711"
                            }
                        ]
                    },
                    {
                        "id": "7251e582-2603-48af-b00f-fc38afc97763",
                        "created_at": "1689599487",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689599712",
                        "staggers": [
                            {
                                "created_at": "1689599489",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689599712"
                            }
                        ]
                    },
                    {
                        "id": "7a49dd37-39c3-41ed-9578-078ab652a85f",
                        "created_at": "1689599484",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689599713",
                        "staggers": [
                            {
                                "created_at": "1689599487",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689599713"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "67fd69b8d0719995e75f0656ed1460fc5e333d3a",
                "commit_title": "Updating IAM policy with required permissions ",
                "created_at": "1729697722",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1729698352",
                "changes": [
                    {
                        "id": "8919f447-886d-4884-b102-3ace3009f808",
                        "created_at": "1729697726",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729698135",
                        "staggers": [
                            {
                                "created_at": "1729697731",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729698135"
                            }
                        ]
                    },
                    {
                        "id": "9d29aa1e-54b5-4bb6-9e20-20745089db66",
                        "created_at": "1729697724",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729697933",
                        "staggers": [
                            {
                                "created_at": "1729697726",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729697933"
                            }
                        ]
                    },
                    {
                        "id": "9f39c27e-75e4-481f-935b-aa626052dd45",
                        "created_at": "1729697722",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729698352",
                        "staggers": [
                            {
                                "created_at": "1729698151",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729698352"
                            },
                            {
                                "created_at": "1729697724",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1729698135"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3ec7799652d0a0987cab673a8101fe417b4d1c56",
                "commit_title": "Update code ",
                "created_at": "1717509613",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1717509816",
                "changes": [
                    {
                        "id": "ed1c5469-165c-4a34-86ef-82ffc3348b76",
                        "created_at": "1717509613",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717509816",
                        "staggers": [
                            {
                                "created_at": "1717509614",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717509816"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "ab56bb6437eac04d66bf2819f9545ca23a7fa348",
                "commit_title": "scope-sai-role-deleted ",
                "created_at": "1714051051",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714051256",
                "changes": [
                    {
                        "id": "ccb1b886-873c-4c75-addf-b958535dbe15",
                        "created_at": "1714051051",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeSAIRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714051256",
                        "staggers": [
                            {
                                "created_at": "1714051053",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1714051256"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "c9255d6bb5e8646349b8f5af1896c35ff71685bb",
                "commit_title": "add permissions as requested from mrt ",
                "created_at": "1709897199",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1709897616",
                "changes": [
                    {
                        "id": "92d6cfb6-9f9a-4338-a0e9-3f2fe60ac2b7",
                        "created_at": "1709897199",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709897616",
                        "staggers": [
                            {
                                "created_at": "1709897414",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1709897616"
                            },
                            {
                                "created_at": "1709897201",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1709897404"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "0c5695976f99e8e82d85a416f3705732d16a9a69",
                "commit_title": "Adding stage mpa stagger group ",
                "created_at": "1705466659",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1705467172",
                "changes": [
                    {
                        "id": "6b6be41d-ed20-4111-bf7e-a484f4ad1318",
                        "created_at": "1705466667",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705466873",
                        "staggers": [
                            {
                                "created_at": "1705466669",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705466873"
                            }
                        ]
                    },
                    {
                        "id": "46da3a1b-79ba-43b5-bf5c-f703e45249a2",
                        "created_at": "1705466665",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705467172",
                        "staggers": [
                            {
                                "created_at": "1705466971",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705467172"
                            },
                            {
                                "created_at": "1705466667",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1705466873"
                            }
                        ]
                    },
                    {
                        "id": "d29a38e5-6283-4446-9887-c6bb14d8bde5",
                        "created_at": "1705466663",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705466873",
                        "staggers": [
                            {
                                "created_at": "1705466665",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705466873"
                            }
                        ]
                    },
                    {
                        "id": "f1db8689-ece7-4d54-9a04-97bc01dbeb69",
                        "created_at": "1705466659",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705466872",
                        "staggers": [
                            {
                                "created_at": "1705466663",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705466872"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "c299676074b7fd0624b50f21e10d9b39672c3ff8",
                "commit_title": "added UpdateStaggerAPILambda and additional permissions ",
                "created_at": "1701072183",
                "deployment_status": "COMPLETED",
                "developer_id": "svaradarajan@salesforce.com",
                "updated_at": "1701072402",
                "changes": [
                    {
                        "id": "f5869446-94d4-4d41-8904-932ab0c8c8b4",
                        "created_at": "1701072195",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701072402",
                        "staggers": [
                            {
                                "created_at": "1701072197",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1701072402"
                            }
                        ]
                    },
                    {
                        "id": "0f52c822-6562-43e8-a87b-872f6c357f1a",
                        "created_at": "1701072193",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701072401",
                        "staggers": [
                            {
                                "created_at": "1701072195",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1701072401"
                            }
                        ]
                    },
                    {
                        "id": "1bdda3f0-dbe1-43b6-a73f-c74f276f181c",
                        "created_at": "1701072191",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701072401",
                        "staggers": [
                            {
                                "created_at": "1701072193",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1701072401"
                            }
                        ]
                    },
                    {
                        "id": "df8acfe7-90f3-41b2-a1fb-9e415a2d1afe",
                        "created_at": "1701072188",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701072401",
                        "staggers": [
                            {
                                "created_at": "1701072191",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1701072401"
                            }
                        ]
                    },
                    {
                        "id": "817e1703-1bfa-42dd-9f03-f4225b1ef514",
                        "created_at": "1701072183",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701072402",
                        "staggers": [
                            {
                                "created_at": "1701072188",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1701072402"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "1f43feb0d3305b677639122628c6ee5355a0d552",
                "commit_title": "scptestscope-fix-te ",
                "created_at": "1715697789",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715697994",
                "changes": [
                    {
                        "id": "a7a4d810-b0bf-42e6-87b3-10d199affabe",
                        "created_at": "1715697789",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeSCPSelfManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715697994",
                        "staggers": [
                            {
                                "created_at": "1715697791",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715697994"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "5c97a19c5e9b1d5d17d3a168c621ec04bf05a930",
                "commit_title": "commonIAM add extra line for testing ",
                "created_at": "1709020995",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1709024029",
                "changes": [
                    {
                        "id": "3fe27d8a-3ab7-433d-940a-4f9dc25a777d",
                        "created_at": "1709020995",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1709024029",
                        "staggers": [
                            {
                                "created_at": "1709021000",
                                "operation_status": "OVERRIDDEN",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709024029"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "8b326ac3a15536874dc88215aa7c37bee03a3c51",
                "commit_title": "scope-test-creation ",
                "created_at": "1692876085",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1692876315",
                "changes": [
                    {
                        "id": "70d42f58-9480-449b-a58d-b05134b9db96",
                        "created_at": "1692876106",
                        "operation_type": "",
                        "stackset_id": "bhor-test",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692876315",
                        "staggers": [
                            {
                                "created_at": "1692876109",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "scope-ou",
                                "updated_at": "1692876315"
                            }
                        ]
                    },
                    {
                        "id": "b8e559f3-103e-469e-9100-00d449a11f05",
                        "created_at": "1692876104",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692876314",
                        "staggers": [
                            {
                                "created_at": "1692876106",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692876314"
                            }
                        ]
                    },
                    {
                        "id": "34ffe37b-4433-48bd-86d1-7bce1f7017a3",
                        "created_at": "1692876103",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692876313",
                        "staggers": [
                            {
                                "created_at": "1692876104",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692876313"
                            }
                        ]
                    },
                    {
                        "id": "fcb679db-5f03-4a5b-aa69-63949ce3f78a",
                        "created_at": "1692876101",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692876314",
                        "staggers": [
                            {
                                "created_at": "1692876102",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692876314"
                            }
                        ]
                    },
                    {
                        "id": "ceb141bf-24b9-419a-83df-4aa70751bed3",
                        "created_at": "1692876099",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692876313",
                        "staggers": [
                            {
                                "created_at": "1692876101",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692876313"
                            }
                        ]
                    },
                    {
                        "id": "38771de8-f847-44e5-b848-f9208049acd8",
                        "created_at": "1692876097",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692876314",
                        "staggers": [
                            {
                                "created_at": "1692876099",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692876314"
                            }
                        ]
                    },
                    {
                        "id": "9e5f8954-7f74-4ddd-9300-cba0021b9681",
                        "created_at": "1692876095",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692876313",
                        "staggers": [
                            {
                                "created_at": "1692876097",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692876313"
                            }
                        ]
                    },
                    {
                        "id": "2a177c1b-3530-4442-8b5e-646905b73acf",
                        "created_at": "1692876092",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692876314",
                        "staggers": [
                            {
                                "created_at": "1692876095",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692876314"
                            }
                        ]
                    },
                    {
                        "id": "70151c1e-51b4-4a2c-ba69-740e66759275",
                        "created_at": "1692876089",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692876313",
                        "staggers": [
                            {
                                "created_at": "1692876092",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692876313"
                            }
                        ]
                    },
                    {
                        "id": "5c86a591-9232-4a35-898d-9ecf1241f1dc",
                        "created_at": "1692876085",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692876312",
                        "staggers": [
                            {
                                "created_at": "1692876089",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692876312"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "09e97d8082a0f6a61116ec85c624b11f271571f8",
                "commit_title": "added-svcmgtest ",
                "created_at": "1716201008",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1716201008",
                "changes": null,
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4e4d85352d05fabc6b528c1adbfccdbed6c51937",
                "commit_title": "scptestscope ",
                "created_at": "1715697355",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715697355",
                "changes": null,
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "899fc5fbe3071957e126939b402061af040f3470_799696138513_820b6121-5308-11ee-9e95-963d29dacc5e",
                "commit_title": "add back scopetest with ou ",
                "created_at": "1694700652",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1694700652",
                "changes": [
                    {
                        "id": "5d7e3b8b-ebd7-401f-884d-060b3a237603",
                        "created_at": "1694700652",
                        "operation_type": "",
                        "stackset_id": "ScopeTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694700716",
                        "staggers": [
                            {
                                "created_at": "1694700652",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeNoStaggeringGroup",
                                "updated_at": "1694700716"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4c9f10adfa6c721d00bea5448299faaeb669b569",
                "commit_title": "updated-svcmgtest ",
                "created_at": "1716201381",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1716201486",
                "changes": [
                    {
                        "id": "766ab906-6dd7-478f-b3b0-d7bae9219585",
                        "created_at": "1716201381",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeSCPServiceManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716201486",
                        "staggers": [
                            {
                                "created_at": "1716201383",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1716201486"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "91a85952a1725f301df2bb3017908ebdd3dcf3b1",
                "commit_title": "test not push ",
                "created_at": "1684138616",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1684138881",
                "changes": [
                    {
                        "id": "83f9db56-3af4-4376-9ea3-b6664723928d",
                        "created_at": "1684138668",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684138881",
                        "staggers": [
                            {
                                "created_at": "1684138670",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684138881"
                            }
                        ]
                    },
                    {
                        "id": "d9e12047-1c2a-4399-bb40-1858ce5da49b",
                        "created_at": "1684138663",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1684138881",
                        "staggers": [
                            {
                                "created_at": "1684138668",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684138881"
                            }
                        ]
                    },
                    {
                        "id": "b67e8c98-dbc8-44ee-b9bc-42ecb140610f",
                        "created_at": "1684138662",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684138881",
                        "staggers": [
                            {
                                "created_at": "1684138663",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684138881"
                            }
                        ]
                    },
                    {
                        "id": "4d77f5ee-204a-4f77-8388-510cfd058b4d",
                        "created_at": "1684138660",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684138881",
                        "staggers": [
                            {
                                "created_at": "1684138662",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684138881"
                            }
                        ]
                    },
                    {
                        "id": "d1537b07-c0ff-40a1-b978-b6bb78d8accb",
                        "created_at": "1684138658",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684138882",
                        "staggers": [
                            {
                                "created_at": "1684138660",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684138882"
                            }
                        ]
                    },
                    {
                        "id": "9f6c25e3-38f9-4ac8-99c6-0a1d3f4ebe12",
                        "created_at": "1684138655",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684138882",
                        "staggers": [
                            {
                                "created_at": "1684138658",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684138882"
                            }
                        ]
                    },
                    {
                        "id": "7ca6c9a5-d1ef-462a-918f-626848f85bfd",
                        "created_at": "1684138616",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684138882",
                        "staggers": [
                            {
                                "created_at": "1684138655",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684138882"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "b457dfe5ecb08918b5e8d1e95740a2edfd71ca5a",
                "commit_title": "updating conditions in principal ",
                "created_at": "1730140374",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730140577",
                "changes": [
                    {
                        "id": "849ce84f-d8fb-4248-8161-9a8dd783e2ef",
                        "created_at": "1730140374",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730140577",
                        "staggers": [
                            {
                                "created_at": "1730140376",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730140577"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6bd81c46ae92df1a973550b627abc7337709d7e5",
                "commit_title": "updated-prisma-role ",
                "created_at": "1714150874",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714151490",
                "changes": [
                    {
                        "id": "c6ae26a0-4eff-42cf-89ec-a1f416162dba",
                        "created_at": "1714150881",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopePrismaRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714151490",
                        "staggers": [
                            {
                                "created_at": "1714150883",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1714151490"
                            }
                        ]
                    },
                    {
                        "id": "7cf383d0-c98f-4b64-9a3b-04a51e39519f",
                        "created_at": "1714150874",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714151288",
                        "staggers": [
                            {
                                "created_at": "1714150881",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1714151288"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "eb726cc83b75a1090ec6a34a4096ade31df9f5d1",
                "commit_title": "added new role changes ",
                "created_at": "1704491704",
                "deployment_status": "COMPLETED",
                "developer_id": "svaradarajan@salesforce.com",
                "updated_at": "1704491911",
                "changes": [
                    {
                        "id": "b380d995-ff71-4ac6-8704-a69a394592b9",
                        "created_at": "1704491704",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704491911",
                        "staggers": [
                            {
                                "created_at": "1704491707",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1704491911"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-6efea462-732d-11ee-913d-acde48001122_236941421163,126343934955_f4c5373e-7343-11ee-8b94-2e9f149b46f3",
                "commit_title": "test commit - go back to reverted PR - create 1 stackset and change group config for other 2 stacksets",
                "created_at": "1698244621",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698244621",
                "changes": [
                    {
                        "id": "87f43180-3c95-4d42-a304-912f578e0d84",
                        "created_at": "1698244625",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698244658",
                        "staggers": [
                            {
                                "created_at": "1698244626",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698244658"
                            }
                        ]
                    },
                    {
                        "id": "befdee29-573d-44ee-9782-d68782f80509",
                        "created_at": "1698244624",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698244658",
                        "staggers": [
                            {
                                "created_at": "1698244625",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698244658"
                            }
                        ]
                    },
                    {
                        "id": "fc8788a2-9114-4ad3-a979-b1f789a71d2c",
                        "created_at": "1698244623",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698244659",
                        "staggers": [
                            {
                                "created_at": "1698244624",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698244659"
                            }
                        ]
                    },
                    {
                        "id": "89a385b1-de7e-40ab-afc6-454da1f90527",
                        "created_at": "1698244622",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698244657",
                        "staggers": [
                            {
                                "created_at": "1698244623",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698244657"
                            }
                        ]
                    },
                    {
                        "id": "aa0af956-08d3-4362-bc74-d5a3b469f85e",
                        "created_at": "1698244621",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698244658",
                        "staggers": [
                            {
                                "created_at": "1698244622",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698244658"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-cd281b2c-7184-11ee-89dc-acde48001122",
                "commit_title": "test commit - create scopeTestThird stackset and change group config and template for scopeTestFirst,scopeTestSecond stacksets",
                "created_at": "1698052591",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698052695",
                "changes": [
                    {
                        "id": "7dd5b489-c112-4623-8dae-6c074d11fc43",
                        "created_at": "1698052595",
                        "operation_type": "",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698052660",
                        "staggers": [
                            {
                                "created_at": "1698052596",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698052660"
                            }
                        ]
                    },
                    {
                        "id": "fea3e4a9-e27f-47bb-aa42-7cdc33caa621",
                        "created_at": "1698052593",
                        "operation_type": "",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698052660",
                        "staggers": [
                            {
                                "created_at": "1698052597",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698052660"
                            },
                            {
                                "created_at": "1698052595",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "one account id",
                                "updated_at": "1698052659"
                            }
                        ]
                    },
                    {
                        "id": "bd8fd2b9-8bd7-48f9-9c17-68728524b2f3",
                        "created_at": "1698052591",
                        "operation_type": "",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698052695",
                        "staggers": [
                            {
                                "created_at": "1698052664",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "second stagger group",
                                "updated_at": "1698052695"
                            },
                            {
                                "created_at": "1698052598",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698052660"
                            },
                            {
                                "created_at": "1698052593",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698052660"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "01358d706c2805a1270bef8d072fa29de06d5676",
                "commit_title": "delete-v2-test ",
                "created_at": "1695123524",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1695123942",
                "changes": [
                    {
                        "id": "e49beffe-6fb9-4e49-93f3-12289289e6c6",
                        "created_at": "1695123525",
                        "operation_type": "",
                        "stackset_id": "ScopeTestMpav2",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695123729",
                        "staggers": [
                            {
                                "created_at": "1695123526",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "MPAs",
                                "updated_at": "1695123729"
                            }
                        ]
                    },
                    {
                        "id": "4bb3d3ef-30be-4efc-9e26-5f22708e5ea4",
                        "created_at": "1695123524",
                        "operation_type": "",
                        "stackset_id": "ScopeTestv2",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695123942",
                        "staggers": [
                            {
                                "created_at": "1695123740",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ou",
                                "updated_at": "1695123942"
                            },
                            {
                                "created_at": "1695123525",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "dev account",
                                "updated_at": "1695123729"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "9ceb153f522c1f02e273a0a6ef208509f2947127",
                "commit_title": "Updating no value condition ",
                "created_at": "1730134355",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730134559",
                "changes": [
                    {
                        "id": "7050cec7-4247-451b-8d6d-098afd33fb84",
                        "created_at": "1730134355",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730134559",
                        "staggers": [
                            {
                                "created_at": "1730134357",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730134559"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "610325630ff2cefcbfae3ae5f70f2171794060e3",
                "commit_title": "Removing test role and testing after scp change ",
                "created_at": "1728400324",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1728400737",
                "changes": [
                    {
                        "id": "d2ffa872-4954-450c-8944-e462c7013dfa",
                        "created_at": "1728400330",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728400535",
                        "staggers": [
                            {
                                "created_at": "1728400333",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728400535"
                            }
                        ]
                    },
                    {
                        "id": "214d03ab-28ed-4242-b561-e3dd8b682032",
                        "created_at": "1728400326",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728400737",
                        "staggers": [
                            {
                                "created_at": "1728400330",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728400737"
                            }
                        ]
                    },
                    {
                        "id": "07570324-2009-4f2e-8909-baad1d232cd3",
                        "created_at": "1728400324",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728400535",
                        "staggers": [
                            {
                                "created_at": "1728400326",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1728400535"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e2c69d03c0906d333e384a55ff8dd89a24d4a6d8",
                "commit_title": "test 5 ",
                "created_at": "1716392132",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1716392132",
                "changes": null,
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "8263c55383ebfbfa0e651aa2362001427c6b820d",
                "commit_title": "removed-nonfalcon-from-sg ",
                "created_at": "1715764216",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715764321",
                "changes": [
                    {
                        "id": "c830dcac-4983-474b-8ea5-a827af03a78c",
                        "created_at": "1715764217",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeSCPSelfManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715764321",
                        "staggers": [
                            {
                                "created_at": "1715764218",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1715764321"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "ad3062d583a245730f44d7d45ec1e7f45b5e8ee5",
                "commit_title": "updated-scopeEmptyOU ",
                "created_at": "1714401332",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714401538",
                "changes": [
                    {
                        "id": "cb753194-8012-42e9-81b3-5bb42a813e02",
                        "created_at": "1714401334",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeEmptyOU",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714401538",
                        "staggers": [
                            {
                                "created_at": "1714401335",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1714401538"
                            }
                        ]
                    },
                    {
                        "id": "08770580-a739-4be2-b6f7-e66fb00e6903",
                        "created_at": "1714401332",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeEmptyOU",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714401538",
                        "staggers": [
                            {
                                "created_at": "1714401334",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1714401538"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-fa2e909c-731e-11ee-a516-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698228747",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698228818",
                "changes": [
                    {
                        "id": "c740baa8-2efb-4f53-b267-fd1d05257ebb",
                        "created_at": "1698228749",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698228818",
                        "staggers": [
                            {
                                "created_at": "1698228787",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698228818"
                            },
                            {
                                "created_at": "1698228752",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698228783"
                            }
                        ]
                    },
                    {
                        "id": "e03bb894-8638-4766-a187-d38db08ca3b4",
                        "created_at": "1698228747",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698228783",
                        "staggers": [
                            {
                                "created_at": "1698228749",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698228783"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b3fb2ef876920184fdd05392dcfa6d65769045eb",
                "commit_title": "test policy ",
                "created_at": "1695901570",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1695901980",
                "changes": [
                    {
                        "id": "06a53876-3597-416d-bb17-fc9d70802c9b",
                        "created_at": "1695901572",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "FAILED",
                        "updated_at": "1695901980",
                        "staggers": [
                            {
                                "created_at": "1695901992",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695901992"
                            },
                            {
                                "created_at": "1695901576",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695901980"
                            }
                        ]
                    },
                    {
                        "id": "9e5737cb-bb79-4c91-b98a-251fef5cf3b7",
                        "created_at": "1695901570",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695901980",
                        "staggers": [
                            {
                                "created_at": "1695901572",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1695901980"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "3bc23eebbfed93e643be60ae80bb4aa3efdb9e29",
                "commit_title": " @W-13738330 | Cleanup of CloudHealth stackset on MPAs ",
                "created_at": "1690535492",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1690535720",
                "changes": [
                    {
                        "id": "59a3c215-831b-4407-a6f5-2ca464f3cbaf",
                        "created_at": "1690535514",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535719",
                        "staggers": [
                            {
                                "created_at": "1690535515",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535719"
                            }
                        ]
                    },
                    {
                        "id": "85f56425-6468-4aef-acc7-c20725e39c16",
                        "created_at": "1690535512",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535719",
                        "staggers": [
                            {
                                "created_at": "1690535514",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535719"
                            }
                        ]
                    },
                    {
                        "id": "501864e5-7f9f-4c74-9e30-9bb114dc4d6f",
                        "created_at": "1690535510",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535720",
                        "staggers": [
                            {
                                "created_at": "1690535512",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535720"
                            }
                        ]
                    },
                    {
                        "id": "65a78bac-f10d-4eb0-add5-54ec1b9a3678",
                        "created_at": "1690535507",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535719",
                        "staggers": [
                            {
                                "created_at": "1690535510",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535719"
                            }
                        ]
                    },
                    {
                        "id": "bb8ade67-531f-4875-97f2-f059ee4a8bdf",
                        "created_at": "1690535506",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535718",
                        "staggers": [
                            {
                                "created_at": "1690535507",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535718"
                            }
                        ]
                    },
                    {
                        "id": "45e04057-b9b3-4dc7-a4bd-24638bd47105",
                        "created_at": "1690535504",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535718",
                        "staggers": [
                            {
                                "created_at": "1690535506",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535718"
                            }
                        ]
                    },
                    {
                        "id": "fdda73a3-f96c-4f40-a1a5-adf6624f77a7",
                        "created_at": "1690535501",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535718",
                        "staggers": [
                            {
                                "created_at": "1690535504",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535718"
                            }
                        ]
                    },
                    {
                        "id": "6c57d6e8-713a-45a7-bf5d-226782ebb249",
                        "created_at": "1690535496",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535720",
                        "staggers": [
                            {
                                "created_at": "1690535501",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535720"
                            }
                        ]
                    },
                    {
                        "id": "14475231-0176-4a0d-a5e6-d5a70315926c",
                        "created_at": "1690535492",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690535719",
                        "staggers": [
                            {
                                "created_at": "1690535496",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690535719"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "ed807bd61b2194f64f401d3e8abf332d70cb1bee",
                "commit_title": "added-conditional-role ",
                "created_at": "1715857607",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715857607",
                "changes": null,
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a42c0e477211621e2d6f46a28cf3605555f6a968",
                "commit_title": "added-flaconMPA-sg ",
                "created_at": "1715845812",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715845812",
                "changes": [
                    {
                        "id": "61119ab5-a55d-42e6-9711-afefb8c60400",
                        "created_at": "1715845812",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715846334",
                        "staggers": [
                            {
                                "created_at": "1715845819",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715846334"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "2703541fab1c3d0a6a1642d0b3adeacaf68193f5",
                "commit_title": "updated-condition ",
                "created_at": "1710241760",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1710242194",
                "changes": [
                    {
                        "id": "2b1a28b8-4f66-4c08-8045-831f447a1cdc",
                        "created_at": "1710241774",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeOUMovementTracker",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1710241980",
                        "staggers": [
                            {
                                "created_at": "1710241775",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1710241980"
                            }
                        ]
                    },
                    {
                        "id": "c7f3460c-632e-48eb-bf3b-e43fdf2d1f7f",
                        "created_at": "1710241772",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1710241979",
                        "staggers": [
                            {
                                "created_at": "1710241774",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1710241979"
                            }
                        ]
                    },
                    {
                        "id": "f0d67546-10d2-4ea3-a0cc-449adefc0d35",
                        "created_at": "1710241770",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1710241979",
                        "staggers": [
                            {
                                "created_at": "1710241772",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1710241979"
                            }
                        ]
                    },
                    {
                        "id": "5ad0064f-54e7-4465-b9dd-aa0226f19e72",
                        "created_at": "1710241768",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1710242193",
                        "staggers": [
                            {
                                "created_at": "1710241992",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1710242193"
                            },
                            {
                                "created_at": "1710241770",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1710241979"
                            }
                        ]
                    },
                    {
                        "id": "40757fe9-3a76-4b9d-88f6-9e37e3921166",
                        "created_at": "1710241766",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1710241979",
                        "staggers": [
                            {
                                "created_at": "1710241768",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1710241979"
                            }
                        ]
                    },
                    {
                        "id": "666e537c-2909-4b99-8bb4-fc34927599bd",
                        "created_at": "1710241760",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1710241980",
                        "staggers": [
                            {
                                "created_at": "1710241766",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1710241980"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "1d88330815cc4c39cbca87064af4537c71d088d6",
                "commit_title": "test mrt role creation ",
                "created_at": "1709311251",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1709311711",
                "changes": [
                    {
                        "id": "a97d42d2-78c9-478b-a8a1-53bacd6c0db5",
                        "created_at": "1709311251",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709311711",
                        "staggers": [
                            {
                                "created_at": "1709311509",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1709311711"
                            },
                            {
                                "created_at": "1709311253",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1709311455"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e5eaea54691918eb988970ed7e7c31bf1b480efa",
                "commit_title": "- sample changes ",
                "created_at": "1682505591",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1683563365",
                "changes": [
                    {
                        "id": "737a60a6-b6d1-467f-b9ac-02363f28cbf2",
                        "created_at": "1683562753",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683563365",
                        "staggers": [
                            {
                                "created_at": "1683562755",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683563365"
                            }
                        ]
                    },
                    {
                        "id": "8be8b424-f8eb-4c48-8a52-eec3bf7c90d0",
                        "created_at": "1683562751",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683563363",
                        "staggers": [
                            {
                                "created_at": "1683562753",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683563363"
                            }
                        ]
                    },
                    {
                        "id": "ea039960-ab4d-4459-b53c-797ecb93cae2",
                        "created_at": "1683562749",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683563364",
                        "staggers": [
                            {
                                "created_at": "1683562751",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683563364"
                            }
                        ]
                    },
                    {
                        "id": "d17fedd9-b249-4d4d-95ce-98ff51d41566",
                        "created_at": "1683562746",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683563366",
                        "staggers": [
                            {
                                "created_at": "1683562749",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683563366"
                            }
                        ]
                    },
                    {
                        "id": "c71b7a4d-741f-4385-8574-44e0e86110c1",
                        "created_at": "1683562738",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683563364",
                        "staggers": [
                            {
                                "created_at": "1683562740",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683563364"
                            }
                        ]
                    },
                    {
                        "id": "067f7e01-da50-47a9-88e1-b1a6715b2ed0",
                        "created_at": "1683562361",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683563363",
                        "staggers": [
                            {
                                "created_at": "1683562738",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683563363"
                            }
                        ]
                    },
                    {
                        "id": "87be4656-cc61-4f26-9302-03455b63e864",
                        "created_at": "1683562359",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683563365",
                        "staggers": [
                            {
                                "created_at": "1683562361",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683563365"
                            }
                        ]
                    },
                    {
                        "id": "e2eb3444-91e8-45dd-af8e-9703fdaca7b5",
                        "created_at": "1682505591",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1683563365",
                        "staggers": [
                            {
                                "created_at": "1683562746",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683563365"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "275f5f03ca505ef07c2522ddd39266181c754f15",
                "commit_title": "test-withouh-conditions ",
                "created_at": "1716375035",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1716375850",
                "changes": [
                    {
                        "id": "3eb7e148-7fe6-422c-8617-04142cda21b2",
                        "created_at": "1716375037",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716375850",
                        "staggers": [
                            {
                                "created_at": "1716375043",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1716375850"
                            }
                        ]
                    },
                    {
                        "id": "7437ae31-63f5-457c-ac9f-5dc515c7cb61",
                        "created_at": "1716375035",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716375245",
                        "staggers": [
                            {
                                "created_at": "1716375037",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1716375245"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "77c997f15114cae6b4313ca1251cf08e4a2a5312",
                "commit_title": "added-sai-role-falcon ",
                "created_at": "1716363569",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1716363569",
                "changes": null,
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "08dfe117fbf6ba3f5e9a40dd097f8a620a51a1e2",
                "commit_title": "updated-sg-2 ",
                "created_at": "1696923864",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1696924086",
                "changes": [
                    {
                        "id": "ffe65748-57fc-4f6e-ad29-6e076e806c9b",
                        "created_at": "1696923875",
                        "operation_type": "",
                        "stackset_id": "scopetestv6",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696924084",
                        "staggers": [
                            {
                                "created_at": "1696923880",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "scope-ou",
                                "updated_at": "1696924084"
                            }
                        ]
                    },
                    {
                        "id": "c34caf9d-4e0c-415b-bffb-c9c3c0758d2f",
                        "created_at": "1696923873",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696924086",
                        "staggers": [
                            {
                                "created_at": "1696923875",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696924086"
                            }
                        ]
                    },
                    {
                        "id": "5722167d-541c-46d3-945d-7b672bde9e65",
                        "created_at": "1696923871",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696924085",
                        "staggers": [
                            {
                                "created_at": "1696923873",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696924085"
                            }
                        ]
                    },
                    {
                        "id": "2efb058b-eff1-403f-8726-e671f387574f",
                        "created_at": "1696923869",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696924085",
                        "staggers": [
                            {
                                "created_at": "1696923871",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696924085"
                            }
                        ]
                    },
                    {
                        "id": "4ff5bda5-8d51-4330-9858-a44d9e935b07",
                        "created_at": "1696923864",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696924085",
                        "staggers": [
                            {
                                "created_at": "1696923869",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696924085"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "7bf37b7d464486858059103e027a1cf7c102405e",
                "commit_title": "testing ",
                "created_at": "1685528187",
                "deployment_status": "FAILED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1685530640",
                "changes": [
                    {
                        "id": "1e0c1604-b2c3-4191-a45b-ecc0f693fadc",
                        "created_at": "1685528206",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685528412",
                        "staggers": [
                            {
                                "created_at": "1685528208",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685528412"
                            }
                        ]
                    },
                    {
                        "id": "f262ca27-dd72-4e5b-8cc2-d4bd6c83fb4b",
                        "created_at": "1685528204",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685528413",
                        "staggers": [
                            {
                                "created_at": "1685528206",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685528413"
                            }
                        ]
                    },
                    {
                        "id": "5a51bfd4-840a-4ef9-b758-20cfbd307bd6",
                        "created_at": "1685528202",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685528413",
                        "staggers": [
                            {
                                "created_at": "1685528204",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685528413"
                            }
                        ]
                    },
                    {
                        "id": "44e10fa4-4278-4741-9541-095f6f32cec9",
                        "created_at": "1685528199",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1685530640",
                        "staggers": [
                            {
                                "created_at": "1685528202",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685530640"
                            }
                        ]
                    },
                    {
                        "id": "819b69b3-c644-4860-840a-ebb04a05bbcd",
                        "created_at": "1685528197",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685528413",
                        "staggers": [
                            {
                                "created_at": "1685528199",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685528413"
                            }
                        ]
                    },
                    {
                        "id": "b48ac81d-7502-4b4e-84dd-91f97cd4a501",
                        "created_at": "1685528191",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685530859",
                        "staggers": [
                            {
                                "created_at": "1685528195",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685530859"
                            }
                        ]
                    },
                    {
                        "id": "072afdb6-f8fd-4dad-95c7-a6e571dedb47",
                        "created_at": "1685528189",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685530859",
                        "staggers": [
                            {
                                "created_at": "1685528191",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685530859"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "856719ff607c3e87c6ca31fd3af0fda9864ffed9",
                "commit_title": "Merge pull request #32 from identity-access/fix ",
                "created_at": "1679377946",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1679382837",
                "changes": [
                    {
                        "id": "8994d928-7644-4ba5-931a-488a0e401d37",
                        "created_at": "1679377954",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1679382635",
                        "staggers": [
                            {
                                "created_at": "1679382525",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679382635"
                            },
                            {
                                "created_at": "1679377956",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Account Test",
                                "updated_at": "1679378367"
                            }
                        ]
                    },
                    {
                        "id": "b95afc11-d752-42ad-a6fe-562ceea08022",
                        "created_at": "1679377948",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1679382837",
                        "staggers": [
                            {
                                "created_at": "1679382533",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679382837"
                            },
                            {
                                "created_at": "1679377954",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage",
                                "updated_at": "1679378367"
                            }
                        ]
                    },
                    {
                        "id": "28654f49-777c-40da-81b7-78b4d4557135",
                        "created_at": "1679377946",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1679378265",
                        "staggers": [
                            {
                                "created_at": "1679377948",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679378265"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "0d7be29f15e167a9ae6da10b2cd739a491af7663",
                "commit_title": "updating sts syntax ",
                "created_at": "1730299355",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730299558",
                "changes": [
                    {
                        "id": "be4f28be-f174-4f1d-9914-225f0a15ad99",
                        "created_at": "1730299355",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730299558",
                        "staggers": [
                            {
                                "created_at": "1730299356",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730299558"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "94c3a2c3f53064ea4807e0ef5601c48cd9ffb7e8",
                "commit_title": "Updating to older syntax ",
                "created_at": "1730134895",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730135098",
                "changes": [
                    {
                        "id": "d2c0219e-711b-4a8a-bfbb-c72906c59ec1",
                        "created_at": "1730134895",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730135098",
                        "staggers": [
                            {
                                "created_at": "1730134896",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730135098"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "7ba46423428af3bd59eb7764db91e22ab4ab77fa",
                "commit_title": "@W-16822763 Adding additional permissions to ODCROperationsAccessPolicy for TD-0216778 ",
                "created_at": "1727206112",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727206316",
                "changes": [
                    {
                        "id": "8f5ba7e1-bbfe-47ee-9c86-21936bfc8953",
                        "created_at": "1727206112",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727206316",
                        "staggers": [
                            {
                                "created_at": "1727206114",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1727206316"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "2cb35ebd228b0c169a5a9065c38ef99af8835889",
                "commit_title": "testing-true-condition ",
                "created_at": "1715858923",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715858923",
                "changes": null,
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-9b7bdb6e-749e-11ee-a6ab-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698393513",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698393926",
                "changes": [
                    {
                        "id": "d91693bc-53a3-4b8b-9905-f2b55b2f167e",
                        "created_at": "1698393516",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698393926",
                        "staggers": [
                            {
                                "created_at": "1698393725",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698393926"
                            },
                            {
                                "created_at": "1698393518",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698393719"
                            }
                        ]
                    },
                    {
                        "id": "9856351d-c69c-4871-b021-c4d008eb45ff",
                        "created_at": "1698393513",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698393719",
                        "staggers": [
                            {
                                "created_at": "1698393516",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698393719"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-b63dc70c-7316-11ee-8b18-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698225196",
                "deployment_status": "FAILED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698225196",
                "changes": null,
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "4505b6758a03f6931e151c253bca5af5250ea526",
                "commit_title": "- ssm process ",
                "created_at": "1683620173",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1683621904",
                "changes": [
                    {
                        "id": "314f28a9-7941-4c7c-95ae-7c6a1135003a",
                        "created_at": "1683620208",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683620417",
                        "staggers": [
                            {
                                "created_at": "1683620211",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683620417"
                            }
                        ]
                    },
                    {
                        "id": "7ee9524f-95a2-4614-9e0b-d146aeffbf6e",
                        "created_at": "1683620205",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683620417",
                        "staggers": [
                            {
                                "created_at": "1683620208",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683620417"
                            }
                        ]
                    },
                    {
                        "id": "3f48659c-8db5-405a-a9a8-9763c33ec64f",
                        "created_at": "1683620201",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683620417",
                        "staggers": [
                            {
                                "created_at": "1683620205",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683620417"
                            }
                        ]
                    },
                    {
                        "id": "1273a452-fa62-414c-802d-ed004662c019",
                        "created_at": "1683620196",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683620417",
                        "staggers": [
                            {
                                "created_at": "1683620201",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683620417"
                            }
                        ]
                    },
                    {
                        "id": "41025581-46f7-4810-8cec-811eef7afc5a",
                        "created_at": "1683620189",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683620415",
                        "staggers": [
                            {
                                "created_at": "1683620196",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683620415"
                            }
                        ]
                    },
                    {
                        "id": "3ca65ad7-e7de-4a53-9584-133a8ad8471f",
                        "created_at": "1683620186",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683620416",
                        "staggers": [
                            {
                                "created_at": "1683620189",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683620416"
                            }
                        ]
                    },
                    {
                        "id": "fee01274-7ab5-48f1-a3cb-001589a56194",
                        "created_at": "1683620183",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683620416",
                        "staggers": [
                            {
                                "created_at": "1683620186",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683620416"
                            }
                        ]
                    },
                    {
                        "id": "cd04dc5c-f943-4fbf-9f6d-117989e5d3a2",
                        "created_at": "1683620180",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683620416",
                        "staggers": [
                            {
                                "created_at": "1683620183",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683620416"
                            }
                        ]
                    },
                    {
                        "id": "5faba70a-c519-4112-94e1-e6e25b6e1639",
                        "created_at": "1683620173",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1683621904",
                        "staggers": [
                            {
                                "created_at": "1683621920",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683621920"
                            },
                            {
                                "created_at": "1683621701",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683621904"
                            },
                            {
                                "created_at": "1683621490",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683621692"
                            },
                            {
                                "created_at": "1683621278",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683621481"
                            },
                            {
                                "created_at": "1683621067",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683621269"
                            },
                            {
                                "created_at": "1683620855",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683621057"
                            },
                            {
                                "created_at": "1683620643",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683620845"
                            },
                            {
                                "created_at": "1683620429",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683620631"
                            },
                            {
                                "created_at": "1683620180",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683620415"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "1ef08ac7fa5832d71c064098918596cb0b7cf4a3",
                "commit_title": "test ",
                "created_at": "1717572425",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1717572628",
                "changes": [
                    {
                        "id": "84908de3-2921-46dc-bd36-1d35192f14ef",
                        "created_at": "1717572425",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717572628",
                        "staggers": [
                            {
                                "created_at": "1717572427",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717572628"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "8dd1b07bd0054ce40d690a69ab0658a45e76644a",
                "commit_title": "added-prisma-role-s ",
                "created_at": "1715255479",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715255479",
                "changes": null,
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-65616ad4-7193-11ee-a862-acde48001122",
                "commit_title": "test commit - create scopeTestThird stackset and change group config and template for scopeTestFirst,scopeTestSecond stacksets",
                "created_at": "1698058856",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698058965",
                "changes": [
                    {
                        "id": "2d42ffaa-f0ad-49de-8749-7aa145e8a62c",
                        "created_at": "1698058864",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698058930",
                        "staggers": [
                            {
                                "created_at": "1698058865",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698058930"
                            }
                        ]
                    },
                    {
                        "id": "2922678b-f631-44c5-9567-3f6e13f8b90d",
                        "created_at": "1698058863",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698058930",
                        "staggers": [
                            {
                                "created_at": "1698058864",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698058930"
                            }
                        ]
                    },
                    {
                        "id": "8d4d3d29-1c23-45b8-9ff1-d5c416d344de",
                        "created_at": "1698058862",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698058929",
                        "staggers": [
                            {
                                "created_at": "1698058863",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698058929"
                            }
                        ]
                    },
                    {
                        "id": "68f40555-a2ef-4392-b00b-97b591350187",
                        "created_at": "1698058861",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698058929",
                        "staggers": [
                            {
                                "created_at": "1698058862",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698058929"
                            }
                        ]
                    },
                    {
                        "id": "bd05e2a4-da1f-46d5-b08b-a4d62b64bbf6",
                        "created_at": "1698058860",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698058898",
                        "staggers": [
                            {
                                "created_at": "1698058861",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698058898"
                            }
                        ]
                    },
                    {
                        "id": "0df9c1f4-7373-4a4a-9f86-f4a53ebe2fa9",
                        "created_at": "1698058858",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698058929",
                        "staggers": [
                            {
                                "created_at": "1698058860",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "one account id",
                                "updated_at": "1698058929"
                            }
                        ]
                    },
                    {
                        "id": "99605cdb-d101-4b3f-8397-acbca7cf1d19",
                        "created_at": "1698058856",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698058965",
                        "staggers": [
                            {
                                "created_at": "1698058934",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "second stagger group",
                                "updated_at": "1698058965"
                            },
                            {
                                "created_at": "1698058858",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698058929"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d85f3407957bc83e4c46224180ad81f4c2b1167b",
                "commit_title": "@W-13879570 | Fixed dependencies of PCSKPermissionsBoundary in stacksets ",
                "created_at": "1692781896",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1692782121",
                "changes": [
                    {
                        "id": "d8b67036-a9c7-4513-ba2d-e9e4033f4827",
                        "created_at": "1692781914",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692782119",
                        "staggers": [
                            {
                                "created_at": "1692781916",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692782119"
                            }
                        ]
                    },
                    {
                        "id": "182afb79-45ea-4459-854f-f32c7369f46b",
                        "created_at": "1692781912",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692782119",
                        "staggers": [
                            {
                                "created_at": "1692781914",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692782119"
                            }
                        ]
                    },
                    {
                        "id": "41ae3b15-124e-4b75-a507-476d8d5ddb69",
                        "created_at": "1692781911",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692782118",
                        "staggers": [
                            {
                                "created_at": "1692781912",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692782118"
                            }
                        ]
                    },
                    {
                        "id": "d300d824-a0a9-42e5-bfff-6fbb1065d897",
                        "created_at": "1692781909",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692782120",
                        "staggers": [
                            {
                                "created_at": "1692781911",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692782120"
                            }
                        ]
                    },
                    {
                        "id": "4cd78975-2c6e-4477-9891-5f86a3f2f33e",
                        "created_at": "1692781906",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692782120",
                        "staggers": [
                            {
                                "created_at": "1692781909",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692782120"
                            }
                        ]
                    },
                    {
                        "id": "c2373cfe-cea0-4299-b1e4-da086895e149",
                        "created_at": "1692781905",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692782119",
                        "staggers": [
                            {
                                "created_at": "1692781906",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692782119"
                            }
                        ]
                    },
                    {
                        "id": "4ca8d494-7b3d-46a2-99e4-32df4fdda9ea",
                        "created_at": "1692781903",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692782120",
                        "staggers": [
                            {
                                "created_at": "1692781904",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692782120"
                            }
                        ]
                    },
                    {
                        "id": "7da63800-0d44-42c2-9e2f-daa82e6b2899",
                        "created_at": "1692781899",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692782120",
                        "staggers": [
                            {
                                "created_at": "1692781903",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692782120"
                            }
                        ]
                    },
                    {
                        "id": "fb1e2753-5af1-48bb-9238-4ed9e3af438d",
                        "created_at": "1692781896",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692782121",
                        "staggers": [
                            {
                                "created_at": "1692781899",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692782121"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "499d3c044cba12f7b5fe8b3a98f2e0d3f4bc0318",
                "commit_title": "Merge branch 'master' into resiliency_branch ",
                "created_at": "1731083944",
                "deployment_status": "IN_PROGRESS",
                "developer_id": "hzhao@salesforce.com",
                "updated_at": "1731083944",
                "changes": null,
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "637b20ee5b5813144a9ddde0837a45d4848ef141",
                "commit_title": "Consolidating in one template with conditions ",
                "created_at": "1730131743",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730131947",
                "changes": [
                    {
                        "id": "8251493a-f422-48a8-8544-805f85aed35f",
                        "created_at": "1730131743",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730131947",
                        "staggers": [
                            {
                                "created_at": "1730131745",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730131947"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "bbca465fe5854eecb2e5a0cf3480e391b519c318",
                "commit_title": "updated-go-runtime (#127) ",
                "created_at": "1708679298",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1708679503",
                "changes": [
                    {
                        "id": "d9c87ea7-4910-4f0d-a814-096abf5ed0b1",
                        "created_at": "1708679298",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1708679503",
                        "staggers": [
                            {
                                "created_at": "1708679300",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1708679503"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-1c9e8e66-7180-11ee-8716-acde48001122",
                "commit_title": "test commit - remove all stacksets from grouping, and mark as delete in payload",
                "created_at": "1698050580",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698050786",
                "changes": [
                    {
                        "id": "852db001-bbec-4bcb-b150-576e5db8599c",
                        "created_at": "1698050582",
                        "operation_type": "",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698050786",
                        "staggers": [
                            {
                                "created_at": "1698050583",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698050785"
                            }
                        ]
                    },
                    {
                        "id": "966528e9-35b6-4a14-8234-fe572a908a4d",
                        "created_at": "1698050581",
                        "operation_type": "",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698050786",
                        "staggers": [
                            {
                                "created_at": "1698050582",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698050786"
                            }
                        ]
                    },
                    {
                        "id": "474d284b-ba01-42a6-b589-8d3b3c63ce54",
                        "created_at": "1698050580",
                        "operation_type": "",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698050785",
                        "staggers": [
                            {
                                "created_at": "1698050581",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698050785"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e3f6a28f2307f8f23e646e269d290c68be713d6f",
                "commit_title": "testing ",
                "created_at": "1731652845",
                "deployment_status": "FAILED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731653063",
                "changes": [
                    {
                        "id": "7994462e-f015-4324-9d0b-87b61f160a0c",
                        "created_at": "1731652859",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731653063",
                        "staggers": [
                            {
                                "created_at": "1731652861",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731653063"
                            }
                        ]
                    },
                    {
                        "id": "63183da7-e196-4553-8d6e-f828eadfa2ea",
                        "created_at": "1731652855",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731653062",
                        "staggers": [
                            {
                                "created_at": "1731652859",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731653062"
                            }
                        ]
                    },
                    {
                        "id": "a9870de1-d04e-40f8-8a99-6f528e6f0ee4",
                        "created_at": "1731652851",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1731653063",
                        "staggers": [
                            {
                                "created_at": "1731652855",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731653063"
                            }
                        ]
                    },
                    {
                        "id": "647d044c-4130-47d3-8e96-cbb8f0c225eb",
                        "created_at": "1731652850",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731653063",
                        "staggers": [
                            {
                                "created_at": "1731652851",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1731653063"
                            }
                        ]
                    },
                    {
                        "id": "63eb9f32-b8e7-4a29-b106-f4d46830afbd",
                        "created_at": "1731652847",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731653063",
                        "staggers": [
                            {
                                "created_at": "1731652850",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731653063"
                            }
                        ]
                    },
                    {
                        "id": "ba572ee6-cdbe-4c1e-98d4-9c287be1f8d6",
                        "created_at": "1731652845",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "IN_PROGRESS",
                        "updated_at": "1731652845",
                        "staggers": [
                            {
                                "created_at": "1731652847",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1731653064"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "56b190e7cb61e2205cc645c147535eaae5b9d753",
                "commit_title": "@W-17130246 Updating custom path and role name due to replacement ",
                "created_at": "1731088699",
                "deployment_status": "FAILED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1731089119",
                "changes": [
                    {
                        "id": "7b70f8c2-113e-4424-9d95-599e4332617a",
                        "created_at": "1731088713",
                        "operation_type": "UPDATE",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731089121",
                        "staggers": [
                            {
                                "created_at": "1731088715",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731089121"
                            }
                        ]
                    },
                    {
                        "id": "e55352ec-e689-44f5-9a8b-5b90e0b1e4e6",
                        "created_at": "1731088712",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "IN_PROGRESS",
                        "updated_at": "1731088712",
                        "staggers": [
                            {
                                "created_at": "1731088713",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1731089121"
                            }
                        ]
                    },
                    {
                        "id": "493f180c-2159-4de8-8189-88548a27685f",
                        "created_at": "1731088710",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731089121",
                        "staggers": [
                            {
                                "created_at": "1731088712",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731089121"
                            }
                        ]
                    },
                    {
                        "id": "c0076ebf-945d-4549-9ace-10e1440e8c97",
                        "created_at": "1731088708",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731088917",
                        "staggers": [
                            {
                                "created_at": "1731088710",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731088917"
                            }
                        ]
                    },
                    {
                        "id": "8c3f1acc-470a-44c5-963f-9cafcc70bd87",
                        "created_at": "1731088707",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "IN_PROGRESS",
                        "updated_at": "1731088707",
                        "staggers": [
                            {
                                "created_at": "1731088708",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1731089120"
                            }
                        ]
                    },
                    {
                        "id": "88c116d6-31f0-408e-bee6-0d72b22373ab",
                        "created_at": "1731088705",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "FAILED",
                        "updated_at": "1731089119",
                        "staggers": [
                            {
                                "created_at": "1731088707",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1731089119"
                            }
                        ]
                    },
                    {
                        "id": "271e1d67-bc32-424a-b04e-a5be0cbdb7b4",
                        "created_at": "1731088703",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731088917",
                        "staggers": [
                            {
                                "created_at": "1731088705",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731088917"
                            }
                        ]
                    },
                    {
                        "id": "81a6433f-2b81-4e6d-93a1-a1046bf48715",
                        "created_at": "1731088699",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731089120",
                        "staggers": [
                            {
                                "created_at": "1731088703",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731089120"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "e5065e774001b5996a6de77135800531705f2545_780246501965_caf5b888-6756-11ee-a60c-528d00e7cc86",
                "commit_title": "updated-sg-3 ",
                "created_at": "1696933300",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1696933300",
                "changes": [
                    {
                        "id": "fc2e4bfd-a173-4323-8788-73f02d2d4127",
                        "created_at": "1696933300",
                        "operation_type": "",
                        "stackset_id": "scopetestv6",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696933506",
                        "staggers": [
                            {
                                "created_at": "1696933301",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeNoStaggeringGroup",
                                "updated_at": "1696933506"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "035a9bb9c9f230790dbc2ff8909dd228d02cdfc4",
                "commit_title": "testing ",
                "created_at": "1731519284",
                "deployment_status": "FAILED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731519489",
                "changes": [
                    {
                        "id": "0d045149-fd3e-4dcd-bf73-9331e50f853c",
                        "created_at": "1731519284",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1731519489",
                        "staggers": [
                            {
                                "created_at": "1731519288",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731519489"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "test-005bb884-749e-11ee-a6ab-acde48001122",
                "commit_title": "test commit - remove all stacksets from grouping, and mark as delete in payload",
                "created_at": "1698393257",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698393465",
                "changes": [
                    {
                        "id": "da68d636-598d-4f61-8169-010480fc455a",
                        "created_at": "1698393260",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698393464",
                        "staggers": [
                            {
                                "created_at": "1698393261",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698393463"
                            }
                        ]
                    },
                    {
                        "id": "9be01b56-d769-44a8-9139-466521326198",
                        "created_at": "1698393259",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698393463",
                        "staggers": [
                            {
                                "created_at": "1698393260",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698393463"
                            }
                        ]
                    },
                    {
                        "id": "47122a95-2353-417d-af38-264248a2837a",
                        "created_at": "1698393257",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698393465",
                        "staggers": [
                            {
                                "created_at": "1698393259",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698393465"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4c45d4c4ac010e5c1ec6828829f7eacfa32edd4c",
                "commit_title": "latest stackset ",
                "created_at": "1694693755",
                "deployment_status": "FAILED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1694693982",
                "changes": [
                    {
                        "id": "38e436d1-9de7-4a5d-9a6a-087ee0e327fc",
                        "created_at": "1694693776",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694693983",
                        "staggers": [
                            {
                                "created_at": "1694693778",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694693983"
                            }
                        ]
                    },
                    {
                        "id": "d750992c-ba92-4252-8c1c-7f29088f81d6",
                        "created_at": "1694693774",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694693984",
                        "staggers": [
                            {
                                "created_at": "1694693776",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694693984"
                            }
                        ]
                    },
                    {
                        "id": "6f76d45a-5071-41be-bf99-e18de832c11b",
                        "created_at": "1694693772",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694693982",
                        "staggers": [
                            {
                                "created_at": "1694693774",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694693982"
                            }
                        ]
                    },
                    {
                        "id": "0012e5af-ce1e-4af2-b923-c51162459c15",
                        "created_at": "1694693771",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694693984",
                        "staggers": [
                            {
                                "created_at": "1694693772",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694693984"
                            }
                        ]
                    },
                    {
                        "id": "23c47a63-e0d3-4078-a80f-0353cab4a405",
                        "created_at": "1694693769",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694693983",
                        "staggers": [
                            {
                                "created_at": "1694693771",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694693983"
                            }
                        ]
                    },
                    {
                        "id": "433fd2eb-8bb4-4307-9e86-aa8b1de0600b",
                        "created_at": "1694693766",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694693982",
                        "staggers": [
                            {
                                "created_at": "1694693769",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694693982"
                            }
                        ]
                    },
                    {
                        "id": "7efde4f9-30e2-46b8-bc29-ef5b606b9d50",
                        "created_at": "1694693762",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "FAILED",
                        "updated_at": "1694693982",
                        "staggers": [
                            {
                                "created_at": "1694693766",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694693982"
                            }
                        ]
                    },
                    {
                        "id": "afbe41c8-311b-4ffe-99b4-3bbee8b5e585",
                        "created_at": "1694693760",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694693982",
                        "staggers": [
                            {
                                "created_at": "1694693762",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694693982"
                            }
                        ]
                    },
                    {
                        "id": "64e6a69b-ae1a-4ab7-817f-439f3d6eff75",
                        "created_at": "1694693756",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694693983",
                        "staggers": [
                            {
                                "created_at": "1694693760",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694693983"
                            }
                        ]
                    },
                    {
                        "id": "8a07f46f-e318-47f9-8d37-0ee62a6f4605",
                        "created_at": "1694693755",
                        "operation_type": "",
                        "stackset_id": "ScopeTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694693984",
                        "staggers": [
                            {
                                "created_at": "1694693756",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "dev account",
                                "updated_at": "1694693984"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "f8369c54d7757f4800751ef427dd1af8d01904ce",
                "commit_title": "@W-13497026 | Assumable role within all Falcon MPA accounts that only has ability to enable and disable IPAM service ",
                "created_at": "1692595681",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1692595911",
                "changes": [
                    {
                        "id": "c579599d-a681-45f5-8da1-acaf3cde30a7",
                        "created_at": "1692595705",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692595909",
                        "staggers": [
                            {
                                "created_at": "1692595707",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692595909"
                            }
                        ]
                    },
                    {
                        "id": "5b74f740-3aea-4656-bffb-27e0f5d25943",
                        "created_at": "1692595703",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692595910",
                        "staggers": [
                            {
                                "created_at": "1692595705",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692595910"
                            }
                        ]
                    },
                    {
                        "id": "57c1d0f8-48cd-4a91-98c2-f7d9697c5403",
                        "created_at": "1692595701",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692595911",
                        "staggers": [
                            {
                                "created_at": "1692595703",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692595911"
                            }
                        ]
                    },
                    {
                        "id": "721e61d8-0117-46f5-83b2-574bec518592",
                        "created_at": "1692595699",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692595910",
                        "staggers": [
                            {
                                "created_at": "1692595701",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692595910"
                            }
                        ]
                    },
                    {
                        "id": "c5f27329-65b2-4ec2-894c-eb47f949df01",
                        "created_at": "1692595698",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692595911",
                        "staggers": [
                            {
                                "created_at": "1692595699",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692595911"
                            }
                        ]
                    },
                    {
                        "id": "173a5675-9b7e-4bf2-937f-7a875fa138cd",
                        "created_at": "1692595695",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692595911",
                        "staggers": [
                            {
                                "created_at": "1692595698",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692595911"
                            }
                        ]
                    },
                    {
                        "id": "bf706d57-4c8c-4950-a923-cd5106403390",
                        "created_at": "1692595686",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692595909",
                        "staggers": [
                            {
                                "created_at": "1692595695",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692595909"
                            }
                        ]
                    },
                    {
                        "id": "500292b1-de27-48fa-af37-dda1666396bb",
                        "created_at": "1692595683",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692595910",
                        "staggers": [
                            {
                                "created_at": "1692595686",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692595910"
                            }
                        ]
                    },
                    {
                        "id": "3cf3391b-ffc8-4ea5-bd9b-aeab1843f696",
                        "created_at": "1692595681",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692595910",
                        "staggers": [
                            {
                                "created_at": "1692595683",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692595910"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a21bff4d638d574486b093de441fe0dc29bb0b01",
                "commit_title": "Updating role name since update to custom path errors out ",
                "created_at": "1731081880",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1731082084",
                "changes": [
                    {
                        "id": "0d79ce31-6326-4b59-838f-ae513a24b73d",
                        "created_at": "1731081880",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731082084",
                        "staggers": [
                            {
                                "created_at": "1731081882",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1731082084"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b9d55f7f4fed53392a2ed4a2920e9498b532ebe1",
                "commit_title": "prisma-role-test ",
                "created_at": "1715599337",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715599337",
                "changes": null,
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f0b0043f13c81d485bc4b1c8f04ab53f303a4be1_211125539547_d100a677-e848-11ee-8a42-6a57e1fa6fd0",
                "commit_title": "create vendingtest stackset ",
                "created_at": "1711110998",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1711111204",
                "changes": [
                    {
                        "id": "88aa30d2-9bf1-4e3c-b39b-2f1b34d1294f",
                        "created_at": "1711110999",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeVendingTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711111203",
                        "staggers": [
                            {
                                "created_at": "1711111000",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1711111203"
                            }
                        ]
                    },
                    {
                        "id": "72fcf6ad-70c6-4f69-85dd-2e7e4ff84c36",
                        "created_at": "1711110998",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeOnboardingTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711111204",
                        "staggers": [
                            {
                                "created_at": "1711110999",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1711111204"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "004c368ff1793c8356749c6ccc3a4ebb57667289",
                "commit_title": "Added permissions and role for PCSKSCPV2Role ",
                "created_at": "1702409099",
                "deployment_status": "COMPLETED",
                "developer_id": "svaradarajan@salesforce.com",
                "updated_at": "1702409135",
                "changes": [
                    {
                        "id": "7b5c8b42-2b31-4f24-8577-f78a2b9d7492",
                        "created_at": "1702409099",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702409135",
                        "staggers": [
                            {
                                "created_at": "1702409101",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702409135"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "68bf8a84c1815f55f8c677cbd785ce3372e4e24a",
                "commit_title": "testing ",
                "created_at": "1731476166",
                "deployment_status": "COMPLETED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731476372",
                "changes": [
                    {
                        "id": "bfc9fb83-485f-47c0-b450-691161709ec1",
                        "created_at": "1731476166",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731476372",
                        "staggers": [
                            {
                                "created_at": "1731476170",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731476372"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "0cbb97040173d88850ae6ca4dd805f279d275d7d",
                "commit_title": "renamed resources and param from ssm ",
                "created_at": "1729067676",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1729078790",
                "changes": [
                    {
                        "id": "cc0f367b-8d8e-48a3-8024-270e989fbf83",
                        "created_at": "1729067676",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTentacleRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729078790",
                        "staggers": [
                            {
                                "created_at": "1729067678",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1729078790"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3296b883e40fe5ea7368e53cae520ab44a02a231",
                "commit_title": "Update CommonIAM.yml ",
                "created_at": "1718268359",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1718268771",
                "changes": [
                    {
                        "id": "d1000e01-0a1c-4f96-9b36-5d4718db8145",
                        "created_at": "1718268359",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1718268771",
                        "staggers": [
                            {
                                "created_at": "1718268366",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1718268771"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "9a03444068b01d891ff29b7b610dcce2b2e3be58",
                "commit_title": "Revert \"test\" ",
                "created_at": "1718185142",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1718267954",
                "changes": [
                    {
                        "id": "639eeed4-a9cc-4be5-b2fe-06f22daa1ac8",
                        "created_at": "1718185142",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1718267954",
                        "staggers": [
                            {
                                "created_at": "1718185144",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1718185346"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "57d70425d8571817c856c2da20e477af942fa29a",
                "commit_title": "modified-test-stacksets ",
                "created_at": "1698223785",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1698224164",
                "changes": [
                    {
                        "id": "dd93e545-7827-4d01-a63a-c22c1b939fab",
                        "created_at": "1698223797",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698224156",
                        "staggers": [
                            {
                                "created_at": "1698223799",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1698224150"
                            }
                        ]
                    },
                    {
                        "id": "b8c11ce8-e7bd-43b6-99ba-29721043a4b4",
                        "created_at": "1698223795",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698224102",
                        "staggers": [
                            {
                                "created_at": "1698223797",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1698224096"
                            }
                        ]
                    },
                    {
                        "id": "b174d5a1-728e-4c2d-975c-821f6729a20b",
                        "created_at": "1698223793",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698224163",
                        "staggers": [
                            {
                                "created_at": "1698223795",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1698224163"
                            }
                        ]
                    },
                    {
                        "id": "54c8602d-30ba-4879-b89e-69ba703bca65",
                        "created_at": "1698223791",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698224130",
                        "staggers": [
                            {
                                "created_at": "1698223793",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1698224122"
                            }
                        ]
                    },
                    {
                        "id": "7050e4e0-3458-4ebe-98fe-4c249a91245d",
                        "created_at": "1698223787",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698224077",
                        "staggers": [
                            {
                                "created_at": "1698223791",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1698224070"
                            }
                        ]
                    },
                    {
                        "id": "98678953-299a-4287-9e26-8d26d4a7e4d1",
                        "created_at": "1698223785",
                        "operation_type": "CREATE",
                        "stackset_id": "scopetestv1",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698224164",
                        "staggers": [
                            {
                                "created_at": "1698223787",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "scope-ou",
                                "updated_at": "1698224164"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-731c1e76-7184-11ee-89dc-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698052440",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698052508",
                "changes": [
                    {
                        "id": "92140ae2-549e-449e-a4a6-792ebff0019c",
                        "created_at": "1698052442",
                        "operation_type": "",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698052508",
                        "staggers": [
                            {
                                "created_at": "1698052478",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "second stagger group",
                                "updated_at": "1698052508"
                            },
                            {
                                "created_at": "1698052444",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698052475"
                            }
                        ]
                    },
                    {
                        "id": "d3737e1c-a84c-482f-9b97-3ed0dc475952",
                        "created_at": "1698052440",
                        "operation_type": "",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698052474",
                        "staggers": [
                            {
                                "created_at": "1698052442",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "one account id",
                                "updated_at": "1698052474"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4ddeccf6a9ad6e96e90d44d0184e9c6a446bac5c",
                "commit_title": "updated-template ",
                "created_at": "1691758536",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1691758765",
                "changes": [
                    {
                        "id": "9fc08428-f3d5-40b8-a3f6-1db002445ac9",
                        "created_at": "1691758556",
                        "operation_type": "",
                        "stackset_id": "bhor-test",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691758763",
                        "staggers": [
                            {
                                "created_at": "1691758559",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "scope-ou",
                                "updated_at": "1691758763"
                            }
                        ]
                    },
                    {
                        "id": "937a6ba3-fbc8-4831-a049-2fed9dc1be68",
                        "created_at": "1691758555",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691758763",
                        "staggers": [
                            {
                                "created_at": "1691758556",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691758763"
                            }
                        ]
                    },
                    {
                        "id": "0d2d4b14-8475-4ffc-ad59-7720a1acb1bb",
                        "created_at": "1691758553",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691758764",
                        "staggers": [
                            {
                                "created_at": "1691758555",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691758764"
                            }
                        ]
                    },
                    {
                        "id": "92e0db69-8016-454b-9122-95017a0fedde",
                        "created_at": "1691758551",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691758765",
                        "staggers": [
                            {
                                "created_at": "1691758553",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691758765"
                            }
                        ]
                    },
                    {
                        "id": "9eba674b-4bb2-43fa-877c-8859a3131a7e",
                        "created_at": "1691758549",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691758764",
                        "staggers": [
                            {
                                "created_at": "1691758551",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691758764"
                            }
                        ]
                    },
                    {
                        "id": "c41685e1-b0d2-425a-9dc2-7b8474455015",
                        "created_at": "1691758546",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691758765",
                        "staggers": [
                            {
                                "created_at": "1691758549",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691758765"
                            }
                        ]
                    },
                    {
                        "id": "a8155f10-fa26-49c9-a2ed-25ffd75b8da4",
                        "created_at": "1691758544",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691758764",
                        "staggers": [
                            {
                                "created_at": "1691758546",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691758764"
                            }
                        ]
                    },
                    {
                        "id": "0e4465fa-01f6-42ea-a948-af5671e4e565",
                        "created_at": "1691758542",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691758765",
                        "staggers": [
                            {
                                "created_at": "1691758544",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691758765"
                            }
                        ]
                    },
                    {
                        "id": "d84ef603-a3b6-4fa3-a0e8-a690d246587a",
                        "created_at": "1691758538",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691758764",
                        "staggers": [
                            {
                                "created_at": "1691758542",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691758764"
                            }
                        ]
                    },
                    {
                        "id": "9355501d-3e43-4ada-907c-2da96b65681b",
                        "created_at": "1691758536",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691758763",
                        "staggers": [
                            {
                                "created_at": "1691758538",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691758763"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "5547e46786594eb1e572ea955b75a6efc80c6cb0",
                "commit_title": "test 1 ",
                "created_at": "1687432031",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1687432663",
                "changes": [
                    {
                        "id": "b1dcc717-9a06-420e-977e-2c3a4d02c36f",
                        "created_at": "1687432051",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1687432663",
                        "staggers": [
                            {
                                "created_at": "1687432053",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1687432663"
                            }
                        ]
                    },
                    {
                        "id": "d9ad7956-be9b-4a60-bc5e-e09db8f30fa4",
                        "created_at": "1687432049",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1687432259",
                        "staggers": [
                            {
                                "created_at": "1687432051",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1687432259"
                            }
                        ]
                    },
                    {
                        "id": "76da17d1-f82c-4845-99ba-ac005aa422f7",
                        "created_at": "1687432047",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1687432257",
                        "staggers": [
                            {
                                "created_at": "1687432049",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1687432257"
                            }
                        ]
                    },
                    {
                        "id": "69286c76-d0a7-4e86-8698-f7785cd9836d",
                        "created_at": "1687432045",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1687432257",
                        "staggers": [
                            {
                                "created_at": "1687432047",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1687432257"
                            }
                        ]
                    },
                    {
                        "id": "9af02968-57de-4375-9b44-0b98e4ff3024",
                        "created_at": "1687432041",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1687432258",
                        "staggers": [
                            {
                                "created_at": "1687432045",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1687432258"
                            }
                        ]
                    },
                    {
                        "id": "7491e586-edeb-4089-a5cf-42cfad846911",
                        "created_at": "1687432039",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1687432258",
                        "staggers": [
                            {
                                "created_at": "1687432041",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1687432258"
                            }
                        ]
                    },
                    {
                        "id": "5a327ccf-7311-4f3d-b0d5-48a6e35503f7",
                        "created_at": "1687432035",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1687432258",
                        "staggers": [
                            {
                                "created_at": "1687432039",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1687432258"
                            }
                        ]
                    },
                    {
                        "id": "bead3364-739a-4da5-85cd-03e66c9b6943",
                        "created_at": "1687432034",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1687432258",
                        "staggers": [
                            {
                                "created_at": "1687432035",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1687432258"
                            }
                        ]
                    },
                    {
                        "id": "08be313d-7c4b-4245-b16f-7261d53e65ee",
                        "created_at": "1687432031",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1687432259",
                        "staggers": [
                            {
                                "created_at": "1687432034",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1687432259"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "aebd3ae2e39e0a191f9892f2bb8d51b1f756a936",
                "commit_title": "Revamping if condition for principal ",
                "created_at": "1730214068",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730214272",
                "changes": [
                    {
                        "id": "d44b2664-9b7a-41d2-8596-e2c818833790",
                        "created_at": "1730214068",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730214272",
                        "staggers": [
                            {
                                "created_at": "1730214069",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730214272"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "25b963ea05a7bd57501aba34759f19fed5ac9c56",
                "commit_title": "readding after rollback update ",
                "created_at": "1728405983",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1728406187",
                "changes": [
                    {
                        "id": "de261fdf-cfc4-42aa-872d-7c93b70c4888",
                        "created_at": "1728405983",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728406187",
                        "staggers": [
                            {
                                "created_at": "1728405985",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1728406187"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "1369530430590aab4a1d440a9fe6b9556824eeb7",
                "commit_title": "Merge branch 'master' into mrtroleupdate ",
                "created_at": "1719303937",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1719304766",
                "changes": [
                    {
                        "id": "e4d3e908-2901-4d2d-b7f2-50bb9cab55f4",
                        "created_at": "1719303945",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1719304766",
                        "staggers": null
                    },
                    {
                        "id": "2d10d19a-ea87-4ca3-aa2c-3552d3102f8d",
                        "created_at": "1719303937",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1719304766",
                        "staggers": [
                            {
                                "created_at": "1719303945",
                                "operation_status": "OVERRIDDEN",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1719304766"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "e0862ddf5cbfe5f9b167ae4726c0cabc32fa4c9a",
                "commit_title": "test-commit ",
                "created_at": "1704808122",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1704808348",
                "changes": [
                    {
                        "id": "cf6b68a5-84f5-4e60-857f-7594a3f22fc3",
                        "created_at": "1704808122",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeWizRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704808345",
                        "staggers": [
                            {
                                "created_at": "1704808124",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1704808342"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-6efea462-732d-11ee-913d-acde48001122",
                "commit_title": "test commit - go back to reverted PR - create 1 stackset and change group config for other 2 stacksets",
                "created_at": "1698234958",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698244775",
                "changes": [
                    {
                        "id": "8c7dae9c-476b-4637-bce8-84fc42c115cd",
                        "created_at": "1698234963",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698234995",
                        "staggers": [
                            {
                                "created_at": "1698234963",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234995"
                            }
                        ]
                    },
                    {
                        "id": "164f4764-7327-4dda-a0fa-7a75ace073fe",
                        "created_at": "1698234962",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698234994",
                        "staggers": [
                            {
                                "created_at": "1698234963",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234994"
                            }
                        ]
                    },
                    {
                        "id": "026c5387-c627-4dda-b1bb-c212f56da76a",
                        "created_at": "1698234960",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698234995",
                        "staggers": [
                            {
                                "created_at": "1698234962",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234995"
                            }
                        ]
                    },
                    {
                        "id": "ea43e3cd-a2a5-42e8-bd7a-b1e80498cceb",
                        "created_at": "1698234959",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698234995",
                        "staggers": [
                            {
                                "created_at": "1698234960",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234995"
                            }
                        ]
                    },
                    {
                        "id": "06f16aeb-8772-486e-8dab-54076cfdee6f",
                        "created_at": "1698234958",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698234994",
                        "staggers": [
                            {
                                "created_at": "1698234959",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234994"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-2337f6ba-7197-11ee-b088-acde48001122",
                "commit_title": "test commit - create scopeTestThird stackset and change group config and template for scopeTestFirst,scopeTestSecond stacksets",
                "created_at": "1698060463",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698060570",
                "changes": [
                    {
                        "id": "54956e0e-887f-40a6-9953-77ddc8599501",
                        "created_at": "1698060471",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698060535",
                        "staggers": [
                            {
                                "created_at": "1698060472",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698060535"
                            }
                        ]
                    },
                    {
                        "id": "e7f4b6b6-6288-4cac-b423-a82bbc95fec0",
                        "created_at": "1698060470",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698060534",
                        "staggers": [
                            {
                                "created_at": "1698060471",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698060534"
                            }
                        ]
                    },
                    {
                        "id": "518d9bc5-b5a7-42cc-970d-0788b26bb034",
                        "created_at": "1698060469",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698060503",
                        "staggers": [
                            {
                                "created_at": "1698060470",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698060503"
                            }
                        ]
                    },
                    {
                        "id": "d8e21d28-fe20-4a47-9487-a1f2c0f198be",
                        "created_at": "1698060468",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698060503",
                        "staggers": [
                            {
                                "created_at": "1698060469",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698060503"
                            }
                        ]
                    },
                    {
                        "id": "9c0f6a81-236d-4e01-ac6d-f8e495a86c8f",
                        "created_at": "1698060467",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698060502",
                        "staggers": [
                            {
                                "created_at": "1698060468",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698060502"
                            }
                        ]
                    },
                    {
                        "id": "e5a038c7-0283-4ffc-b0b0-b146e149b999",
                        "created_at": "1698060465",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698060535",
                        "staggers": [
                            {
                                "created_at": "1698060467",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "one account id",
                                "updated_at": "1698060535"
                            }
                        ]
                    },
                    {
                        "id": "bd419823-c35a-4678-b087-58198cb4e5e0",
                        "created_at": "1698060463",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698060570",
                        "staggers": [
                            {
                                "created_at": "1698060539",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "second stagger group",
                                "updated_at": "1698060570"
                            },
                            {
                                "created_at": "1698060465",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698060535"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-1764c04a-7181-11ee-8716-acde48001122",
                "commit_title": "test commit - create scopeTestThird stackset and change group config and template for scopeTestFirst,scopeTestSecond stacksets",
                "created_at": "1698051000",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698051073",
                "changes": [
                    {
                        "id": "cc236b78-4360-4210-aabd-126e73140a9b",
                        "created_at": "1698051006",
                        "operation_type": "",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698051038",
                        "staggers": [
                            {
                                "created_at": "1698051007",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698051038"
                            }
                        ]
                    },
                    {
                        "id": "980b2357-4f5f-4d01-afe3-51f0f23c5bfd",
                        "created_at": "1698051001",
                        "operation_type": "",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698051038",
                        "staggers": [
                            {
                                "created_at": "1698051006",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "one account id",
                                "updated_at": "1698051038"
                            },
                            {
                                "created_at": "1698051002",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698051038"
                            }
                        ]
                    },
                    {
                        "id": "da07dcbc-dcc8-4579-9d16-eb8a9422c14a",
                        "created_at": "1698051000",
                        "operation_type": "",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698051073",
                        "staggers": [
                            {
                                "created_at": "1698051042",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "second stagger group",
                                "updated_at": "1698051073"
                            },
                            {
                                "created_at": "1698051004",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698051038"
                            },
                            {
                                "created_at": "1698051001",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698051038"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "198957fb5e00d705719eca70da9e8182c166599b",
                "commit_title": "@@W-16758663 test custom role in comminianm ",
                "created_at": "1727984296",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727984709",
                "changes": [
                    {
                        "id": "0ecf0ec1-975d-43c6-ac13-30e3ee145df9",
                        "created_at": "1727984298",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727984709",
                        "staggers": [
                            {
                                "created_at": "1727984306",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1727984709"
                            }
                        ]
                    },
                    {
                        "id": "0879fb71-28a6-4fae-8557-742639e4beb5",
                        "created_at": "1727984296",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727984709",
                        "staggers": [
                            {
                                "created_at": "1727984298",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1727984709"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "afa282e75d00568fab3136bf9776da640482b0df",
                "commit_title": "empty-commit-nodejs-runtime-fix ",
                "created_at": "1694174831",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1694175058",
                "changes": [
                    {
                        "id": "c8e3cec4-2678-48c5-83f8-ae2296c7237f",
                        "created_at": "1694174848",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694175058",
                        "staggers": [
                            {
                                "created_at": "1694174851",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694175058"
                            }
                        ]
                    },
                    {
                        "id": "06348ed3-2dce-4587-acda-7ba7b6eec482",
                        "created_at": "1694174845",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694175057",
                        "staggers": [
                            {
                                "created_at": "1694174848",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694175057"
                            }
                        ]
                    },
                    {
                        "id": "6d452e98-ab62-4532-9ef6-08c1248a4ce8",
                        "created_at": "1694174843",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694175057",
                        "staggers": [
                            {
                                "created_at": "1694174845",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694175057"
                            }
                        ]
                    },
                    {
                        "id": "8bcdb860-bb15-499a-bc75-df73343c2c89",
                        "created_at": "1694174841",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694175056",
                        "staggers": [
                            {
                                "created_at": "1694174843",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694175056"
                            }
                        ]
                    },
                    {
                        "id": "8731d7d2-c295-4cb3-8a4a-8de6cfcd0953",
                        "created_at": "1694174839",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694175056",
                        "staggers": [
                            {
                                "created_at": "1694174841",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694175056"
                            }
                        ]
                    },
                    {
                        "id": "9a684242-bab0-48fb-bd40-a43bce6ae748",
                        "created_at": "1694174837",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694175056",
                        "staggers": [
                            {
                                "created_at": "1694174839",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694175056"
                            }
                        ]
                    },
                    {
                        "id": "0fc282c6-3ff5-4820-9a10-5fceb90ae68d",
                        "created_at": "1694174835",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694175057",
                        "staggers": [
                            {
                                "created_at": "1694174837",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694175057"
                            }
                        ]
                    },
                    {
                        "id": "05e77ada-a434-4762-b30f-35a3a11e8b06",
                        "created_at": "1694174833",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694175057",
                        "staggers": [
                            {
                                "created_at": "1694174835",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694175057"
                            }
                        ]
                    },
                    {
                        "id": "9154e4d4-2a29-4060-9b92-cd7dca97548b",
                        "created_at": "1694174831",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694175056",
                        "staggers": [
                            {
                                "created_at": "1694174833",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694175056"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "ec4d3975ceb270d533ec155bf365d8a08992bbcd",
                "commit_title": "@W-15804687 Adding bcm data export permissions ",
                "created_at": "1721062228",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1721065568",
                "changes": [
                    {
                        "id": "a922ebb6-e35d-4406-a3ab-a0eea4ff26dc",
                        "created_at": "1720715454",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1721065568",
                        "staggers": [
                            {
                                "created_at": "1721065365",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1721065568"
                            },
                            {
                                "created_at": "1720715456",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1721062431"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4e2accafe6d54a5a3d131002d47453245d4ffcd2",
                "commit_title": "Added permissions and role for PCSKSCPV2Role ",
                "created_at": "1704833861",
                "deployment_status": "COMPLETED",
                "developer_id": "svaradarajan@salesforce.com",
                "updated_at": "1704834068",
                "changes": [
                    {
                        "id": "81be5fad-18af-4506-8b56-1faab283e2a0",
                        "created_at": "1704833861",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704834068",
                        "staggers": [
                            {
                                "created_at": "1704833863",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1704834068"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6e6c0826f39d6b37caac10682170b11aa0c2b614_857050118540_c887488b-950a-11ee-947e-3680d7cb0cd2",
                "commit_title": "add MRT resources stackset ",
                "created_at": "1701958406",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1701958406",
                "changes": [
                    {
                        "id": "6b3b690e-fcd6-4abe-8f4e-6ec7eadc9914",
                        "created_at": "1701958406",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeMRTResources",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701958441",
                        "staggers": [
                            {
                                "created_at": "1701958407",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1701958441"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-e58ac366-74b5-11ee-bed9-acde48001122_236941421163,126343934955_b079e6f1-74b8-11ee-891c-d60d254457f7",
                "commit_title": "test commit - revert to 2nd stage configuration,third stackset is created back",
                "created_at": "1698404709",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698404709",
                "changes": [
                    {
                        "id": "d91aa3f7-e2f5-40b3-b1b0-06a61d433c00",
                        "created_at": "1698404713",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698404915",
                        "staggers": [
                            {
                                "created_at": "1698404714",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698404915"
                            }
                        ]
                    },
                    {
                        "id": "8fd95bfc-fb40-402b-9303-803ec9c24a0c",
                        "created_at": "1698404712",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698404915",
                        "staggers": [
                            {
                                "created_at": "1698404713",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698404915"
                            }
                        ]
                    },
                    {
                        "id": "96d933a0-2a52-4f03-a57c-f852ecd6280d",
                        "created_at": "1698404711",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698404915",
                        "staggers": [
                            {
                                "created_at": "1698404712",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698404915"
                            }
                        ]
                    },
                    {
                        "id": "93fc5856-fdbf-4968-8ab8-7d1efe06332e",
                        "created_at": "1698404710",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698404915",
                        "staggers": [
                            {
                                "created_at": "1698404711",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698404915"
                            }
                        ]
                    },
                    {
                        "id": "cc183a0b-acdc-4737-af3d-c597ce295b20",
                        "created_at": "1698404709",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698404916",
                        "staggers": [
                            {
                                "created_at": "1698404710",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698404916"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-3ffe2748-717d-11ee-aefe-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698049359",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698049771",
                "changes": [
                    {
                        "id": "edbbf793-bd49-4433-ae9a-83fa2b0f5e86",
                        "created_at": "1698049361",
                        "operation_type": "",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698049565",
                        "staggers": [
                            {
                                "created_at": "1698049363",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "one account id",
                                "updated_at": "1698049565"
                            }
                        ]
                    },
                    {
                        "id": "5c31bb25-d56a-444a-8ddb-a881b89c13db",
                        "created_at": "1698049360",
                        "operation_type": "",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698049771",
                        "staggers": [
                            {
                                "created_at": "1698049570",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "second stagger group",
                                "updated_at": "1698049771"
                            },
                            {
                                "created_at": "1698049361",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698049565"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "394448e4a4f7cf89efac76a07b5421499a9cc0c8",
                "commit_title": "added list roots permission ",
                "created_at": "1717673444",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1717673866",
                "changes": [
                    {
                        "id": "ad92c67e-b57b-4946-9f69-62be2ed37b17",
                        "created_at": "1717673445",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717673865",
                        "staggers": [
                            {
                                "created_at": "1717673663",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1717673865"
                            },
                            {
                                "created_at": "1717673447",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717673650"
                            }
                        ]
                    },
                    {
                        "id": "e12ecaa3-39c1-4eff-ac0f-1d1f999e9e48",
                        "created_at": "1717673444",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717673866",
                        "staggers": [
                            {
                                "created_at": "1717673664",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717673866"
                            },
                            {
                                "created_at": "1717673445",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717673651"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "75bb71dc3bfdbfe333630940c0213403472005c4",
                "commit_title": "added-prisma-role-falcon ",
                "created_at": "1715239341",
                "deployment_status": "FAILED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715239551",
                "changes": [
                    {
                        "id": "fc54b033-fb4d-464c-8407-d7ee9986e16b",
                        "created_at": "1715239341",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "FAILED",
                        "updated_at": "1715239551",
                        "staggers": [
                            {
                                "created_at": "1715239348",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715239551"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "test-ce163e14-74a0-11ee-a6ab-acde48001122",
                "commit_title": "test commit - revert to 1st stage configuration, and mark third stackset for deletion which was created by the reverted PR",
                "created_at": "1698394463",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698394673",
                "changes": [
                    {
                        "id": "7bb523f7-0655-4317-985e-38f9808d2dea",
                        "created_at": "1698394469",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698394672",
                        "staggers": [
                            {
                                "created_at": "1698394470",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698394672"
                            }
                        ]
                    },
                    {
                        "id": "c4d3f1fb-53d4-47d1-9b79-40d21d2493b1",
                        "created_at": "1698394468",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698394672",
                        "staggers": [
                            {
                                "created_at": "1698394469",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698394672"
                            }
                        ]
                    },
                    {
                        "id": "71d718ac-2105-4e75-93c7-51e046292a10",
                        "created_at": "1698394467",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698394673",
                        "staggers": [
                            {
                                "created_at": "1698394468",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698394673"
                            }
                        ]
                    },
                    {
                        "id": "71e0e9c8-68c1-4499-8c59-591b36e18a04",
                        "created_at": "1698394466",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698394671",
                        "staggers": [
                            {
                                "created_at": "1698394467",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698394671"
                            }
                        ]
                    },
                    {
                        "id": "f143f61e-2882-4ab5-8163-12c55237060e",
                        "created_at": "1698394466",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698394671",
                        "staggers": [
                            {
                                "created_at": "1698394466",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698394671"
                            }
                        ]
                    },
                    {
                        "id": "b1f735c8-325e-4d76-8e98-75dad4e1a2b0",
                        "created_at": "1698394465",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698394672",
                        "staggers": [
                            {
                                "created_at": "1698394466",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698394672"
                            }
                        ]
                    },
                    {
                        "id": "e96e6e41-d17c-4b57-9e40-0f23d27b764b",
                        "created_at": "1698394463",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698394672",
                        "staggers": [
                            {
                                "created_at": "1698394465",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698394672"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-1b8d819a-732d-11ee-913d-acde48001122",
                "commit_title": "test commit - revert to 1st stage configuration, and mark third stackset for deletion which was created by the reverted PR",
                "created_at": "1698234819",
                "deployment_status": "REVERTED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698234958",
                "changes": [
                    {
                        "id": "282671b7-4435-4954-8cfb-f5e883568a8e",
                        "created_at": "1698234824",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234958",
                        "staggers": [
                            {
                                "created_at": "1698234825",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234958"
                            }
                        ]
                    },
                    {
                        "id": "94ec68a9-79d0-48ba-a9e8-0cc51cbe60f1",
                        "created_at": "1698234823",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234958",
                        "staggers": [
                            {
                                "created_at": "1698234824",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234958"
                            }
                        ]
                    },
                    {
                        "id": "2682f6c6-661e-44d4-a310-bd7e17cd5d85",
                        "created_at": "1698234822",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234958",
                        "staggers": [
                            {
                                "created_at": "1698234823",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234958"
                            }
                        ]
                    },
                    {
                        "id": "e892587b-f80f-4313-9ec0-887be6ea9eb7",
                        "created_at": "1698234821",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234958",
                        "staggers": [
                            {
                                "created_at": "1698234822",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234958"
                            }
                        ]
                    },
                    {
                        "id": "38a85f4c-c7ea-4e73-95fe-efa79440ec7c",
                        "created_at": "1698234820",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234958",
                        "staggers": [
                            {
                                "created_at": "1698234821",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234958"
                            }
                        ]
                    },
                    {
                        "id": "d4d3e0d9-0676-4d1b-9b19-32475ae1410d",
                        "created_at": "1698234819",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234958",
                        "staggers": [
                            {
                                "created_at": "1698234820",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234958"
                            }
                        ]
                    },
                    {
                        "id": "10cce128-bba5-401e-98b0-3e34eca0f6a2",
                        "created_at": "1698234819",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234958",
                        "staggers": [
                            {
                                "created_at": "1698234819",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234958"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "f783ef4158f7217f7753d784e8803b1f89937d0f",
                "commit_title": "- commonIAMNonFalcon ",
                "created_at": "1684310633",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1684311079",
                "changes": [
                    {
                        "id": "2c072e22-c6fe-4ee8-8562-efc27ed4017d",
                        "created_at": "1684310654",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684310861",
                        "staggers": [
                            {
                                "created_at": "1684310656",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684310861"
                            }
                        ]
                    },
                    {
                        "id": "6d192a01-3f08-4dd2-93d5-b8ff09db4b93",
                        "created_at": "1684310652",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684310861",
                        "staggers": [
                            {
                                "created_at": "1684310654",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684310861"
                            }
                        ]
                    },
                    {
                        "id": "07bf6854-502a-499e-b6b8-96343ae9710d",
                        "created_at": "1684310650",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684310861",
                        "staggers": [
                            {
                                "created_at": "1684310652",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684310861"
                            }
                        ]
                    },
                    {
                        "id": "4345ce28-b8d4-4fa7-a6f4-3fef3d23633a",
                        "created_at": "1684310648",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684310862",
                        "staggers": [
                            {
                                "created_at": "1684310650",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684310862"
                            }
                        ]
                    },
                    {
                        "id": "cb2a73a0-7d72-4afc-9e30-69244073f3ef",
                        "created_at": "1684310644",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684310644",
                        "staggers": [
                            {
                                "created_at": "1684310648",
                                "operation_status": "QUEUED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684311078"
                            }
                        ]
                    },
                    {
                        "id": "007a71a9-5639-4585-b1e6-ba2e3d13d417",
                        "created_at": "1684310641",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684310860",
                        "staggers": [
                            {
                                "created_at": "1684310644",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684310860"
                            }
                        ]
                    },
                    {
                        "id": "f08a6b2f-5463-4715-a026-ad868cb35bf3",
                        "created_at": "1684310639",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684310861",
                        "staggers": [
                            {
                                "created_at": "1684310641",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684310861"
                            }
                        ]
                    },
                    {
                        "id": "5b25856f-38f3-4aa1-894e-9273af6ecc7e",
                        "created_at": "1684310637",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684310862",
                        "staggers": [
                            {
                                "created_at": "1684310639",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684310862"
                            }
                        ]
                    },
                    {
                        "id": "2db7d817-f2cf-4b9f-a4c7-2c685afd9aa5",
                        "created_at": "1684310633",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1684311078",
                        "staggers": [
                            {
                                "created_at": "1684311091",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684311091"
                            },
                            {
                                "created_at": "1684310876",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684311078"
                            },
                            {
                                "created_at": "1684310637",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684310862"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "a90e47f4584212133a191d340610f8f2b621b31b",
                "commit_title": "Adding Glue perm to CSIRT role in Falcon ",
                "created_at": "1729242810",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1729243218",
                "changes": [
                    {
                        "id": "b905d1ee-b5c9-4717-8aff-1840ca4993cf",
                        "created_at": "1729242810",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729243218",
                        "staggers": [
                            {
                                "created_at": "1729242815",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729243218"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "ee344fbf1cb7086ab2ee3450ccc766bd563fa7ac",
                "commit_title": "wizrole creation (#101) ",
                "created_at": "1704441613",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1704441689",
                "changes": [
                    {
                        "id": "e0ea6db3-54e6-4ab3-99bf-e1a13240bcc9",
                        "created_at": "1704441619",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704441689",
                        "staggers": [
                            {
                                "created_at": "1704441621",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1704441689"
                            }
                        ]
                    },
                    {
                        "id": "fa61b0ac-3c37-410f-b6ed-a15fc5f2e6d5",
                        "created_at": "1704441615",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704441688",
                        "staggers": [
                            {
                                "created_at": "1704441619",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1704441688"
                            }
                        ]
                    },
                    {
                        "id": "d5ff8a4a-e14c-454a-9db9-72f6d32d40e9",
                        "created_at": "1704441613",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704441656",
                        "staggers": [
                            {
                                "created_at": "1704441615",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1704441656"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-707ffef6-732a-11ee-860f-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698233669",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698233740",
                "changes": [
                    {
                        "id": "059f23a8-806f-49b1-a22e-e31d59804165",
                        "created_at": "1698233671",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698233740",
                        "staggers": [
                            {
                                "created_at": "1698233709",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698233740"
                            },
                            {
                                "created_at": "1698233674",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698233704"
                            }
                        ]
                    },
                    {
                        "id": "f080ab93-5fa5-4b72-a815-bb40bae7ec83",
                        "created_at": "1698233669",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698233705",
                        "staggers": [
                            {
                                "created_at": "1698233671",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698233705"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-7c3a1118-7187-11ee-a13b-acde48001122",
                "commit_title": "test commit - remove all stacksets from grouping, and mark as delete in payload",
                "created_at": "1698053745",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698053812",
                "changes": [
                    {
                        "id": "f61fc840-9c4a-4965-9c4b-7ff350254424",
                        "created_at": "1698053747",
                        "operation_type": "",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698053812",
                        "staggers": [
                            {
                                "created_at": "1698053748",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698053812"
                            }
                        ]
                    },
                    {
                        "id": "4cf72eed-0994-41d2-ad95-31e6ab1bdb25",
                        "created_at": "1698053746",
                        "operation_type": "",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698053812",
                        "staggers": [
                            {
                                "created_at": "1698053747",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698053811"
                            }
                        ]
                    },
                    {
                        "id": "06012e1f-bf00-417f-a946-76f77dc8ae4e",
                        "created_at": "1698053745",
                        "operation_type": "",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698053811",
                        "staggers": [
                            {
                                "created_at": "1698053746",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698053811"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "557b3250b616c81909ce3e7db90002ce82daef04",
                "commit_title": "Update template ",
                "created_at": "1731405060",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1731405264",
                "changes": [
                    {
                        "id": "b62768dd-67da-45c1-902f-a479d0a2075c",
                        "created_at": "1731405060",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSensorsControlTelemetryRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731405264",
                        "staggers": [
                            {
                                "created_at": "1731405062",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1731405264"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "baf871ecb1d348310c63842d36330905e11c7276",
                "commit_title": "@W-16758663 updating stackset name to include scope ",
                "created_at": "1726689132",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1726692952",
                "changes": [
                    {
                        "id": "3ce08365-4823-43c6-9cf3-21e463cdd044",
                        "created_at": "1726689132",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPcdSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1726692952",
                        "staggers": [
                            {
                                "created_at": "1726689134",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1726692952"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "cf2c1bc79101b9c3c5d0178f577209c5a85624c6",
                "commit_title": "force-update2 ",
                "created_at": "1715949106",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715949211",
                "changes": [
                    {
                        "id": "29741a51-e865-4038-ae1b-6efaca4395ed",
                        "created_at": "1715949106",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopePrismaRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715949211",
                        "staggers": [
                            {
                                "created_at": "1715949108",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715949211"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f0b0043f13c81d485bc4b1c8f04ab53f303a4be1",
                "commit_title": "create vendingtest stackset ",
                "created_at": "1711109135",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1711109341",
                "changes": [
                    {
                        "id": "2e4292e1-cbf3-476a-b20e-c3fb33d0bfde",
                        "created_at": "1711109135",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeVendingTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711109341",
                        "staggers": [
                            {
                                "created_at": "1711109138",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "internal ou",
                                "updated_at": "1711109341"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a7d89f6c357d7a08ca5187ff5ffbc00cdc129531",
                "commit_title": "v18 ",
                "created_at": "1693819595",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1693819827",
                "changes": [
                    {
                        "id": "f4f8ef7f-9ccb-4cdc-b6d7-0cac8c6e95eb",
                        "created_at": "1693819619",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693819827",
                        "staggers": [
                            {
                                "created_at": "1693819621",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693819827"
                            }
                        ]
                    },
                    {
                        "id": "fd90a510-2b08-49d0-825d-fbf2d0ff8305",
                        "created_at": "1693819617",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693819826",
                        "staggers": [
                            {
                                "created_at": "1693819619",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693819826"
                            }
                        ]
                    },
                    {
                        "id": "2905fda8-d9c1-4f66-8abc-1c489e6e84f0",
                        "created_at": "1693819615",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693819826",
                        "staggers": [
                            {
                                "created_at": "1693819617",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693819826"
                            }
                        ]
                    },
                    {
                        "id": "66ea6df4-82e1-4652-8bcd-48c6a9a63a55",
                        "created_at": "1693819611",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693819826",
                        "staggers": [
                            {
                                "created_at": "1693819615",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693819826"
                            }
                        ]
                    },
                    {
                        "id": "1c85a5d0-a8c2-4fa0-a0cc-52a103766a2c",
                        "created_at": "1693819604",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693819827",
                        "staggers": [
                            {
                                "created_at": "1693819611",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693819827"
                            }
                        ]
                    },
                    {
                        "id": "9a4554bb-5e0d-4217-8ce7-067a22e8285a",
                        "created_at": "1693819601",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693819826",
                        "staggers": [
                            {
                                "created_at": "1693819604",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693819826"
                            }
                        ]
                    },
                    {
                        "id": "cb1d6e8c-a913-4c6f-975c-e2dcdf4bb879",
                        "created_at": "1693819599",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693819825",
                        "staggers": [
                            {
                                "created_at": "1693819601",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693819825"
                            }
                        ]
                    },
                    {
                        "id": "6808e838-dec4-47b7-8780-f676827132d5",
                        "created_at": "1693819597",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693819825",
                        "staggers": [
                            {
                                "created_at": "1693819599",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693819825"
                            }
                        ]
                    },
                    {
                        "id": "dd6e8844-12a3-4998-b34f-a12eb2f01a9c",
                        "created_at": "1693819595",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693819825",
                        "staggers": [
                            {
                                "created_at": "1693819597",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693819825"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "c00783114c70edc31de46bac8daed9acc62d77ec",
                "commit_title": "remove recently added dummy role ",
                "created_at": "1692256634",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1692257194",
                "changes": [
                    {
                        "id": "555e42f1-1184-4b76-8f2e-9397bf9b45a2",
                        "created_at": "1692256656",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692257193",
                        "staggers": [
                            {
                                "created_at": "1692256989",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692257193"
                            },
                            {
                                "created_at": "1692256658",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "pcsk approver accounts",
                                "updated_at": "1692256864"
                            }
                        ]
                    },
                    {
                        "id": "68d10f91-d805-4e84-bcd6-4c28c1a3b0a9",
                        "created_at": "1692256650",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692256863",
                        "staggers": [
                            {
                                "created_at": "1692256656",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692256863"
                            }
                        ]
                    },
                    {
                        "id": "18160bb2-6eac-40c3-b40b-b81571d52341",
                        "created_at": "1692256648",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692257194",
                        "staggers": [
                            {
                                "created_at": "1692256990",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692257194"
                            },
                            {
                                "created_at": "1692256650",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "pcsk approver accounts",
                                "updated_at": "1692256864"
                            }
                        ]
                    },
                    {
                        "id": "7675b9c9-ca1c-4e59-8b11-d04aa912ac77",
                        "created_at": "1692256647",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692256862",
                        "staggers": [
                            {
                                "created_at": "1692256648",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692256862"
                            }
                        ]
                    },
                    {
                        "id": "b873404f-a6d2-4941-8efe-336560d4e158",
                        "created_at": "1692256645",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692257193",
                        "staggers": [
                            {
                                "created_at": "1692256988",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692257193"
                            },
                            {
                                "created_at": "1692256647",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "pcsk approver accounts",
                                "updated_at": "1692256864"
                            }
                        ]
                    },
                    {
                        "id": "df54a6a0-bd35-4f97-a595-5e55fe0bcfbc",
                        "created_at": "1692256643",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692256862",
                        "staggers": [
                            {
                                "created_at": "1692256645",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692256862"
                            }
                        ]
                    },
                    {
                        "id": "dd1adfe5-002c-4cc2-94af-c32ae6230cf1",
                        "created_at": "1692256639",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692256863",
                        "staggers": [
                            {
                                "created_at": "1692256643",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692256863"
                            }
                        ]
                    },
                    {
                        "id": "4f77db01-a2f2-48aa-9f1f-d1535e0a892e",
                        "created_at": "1692256637",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692257193",
                        "staggers": [
                            {
                                "created_at": "1692256987",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692257193"
                            },
                            {
                                "created_at": "1692256639",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "pcsk approver accounts",
                                "updated_at": "1692256863"
                            }
                        ]
                    },
                    {
                        "id": "697da3fa-1f9c-4951-9949-e3734ad39e5f",
                        "created_at": "1692256634",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692256862",
                        "staggers": [
                            {
                                "created_at": "1692256637",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692256862"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "dae92402db4ab6504d420eda67650e2b33b9c676",
                "commit_title": "stackset template syntax ",
                "created_at": "1724231454",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1724231658",
                "changes": [
                    {
                        "id": "83cd7942-1e61-480a-b61b-0a5fdf65899f",
                        "created_at": "1724231454",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSensorsControlTelemetryRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724231658",
                        "staggers": [
                            {
                                "created_at": "1724231456",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1724231658"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "086e98f0fea1f5e14eba89913f68b2de30420079",
                "commit_title": "scope-sai-role-added ",
                "created_at": "1714051431",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714051637",
                "changes": [
                    {
                        "id": "7fca786d-139b-475d-a0d1-deb255c28556",
                        "created_at": "1714051432",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeSAIRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714051637",
                        "staggers": [
                            {
                                "created_at": "1714051433",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1714051637"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "eebb2a2c8f71522a40420d11c23f5454e1af16c5",
                "commit_title": "delete scope mpa stackset ",
                "created_at": "1694699301",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1694699365",
                "changes": [
                    {
                        "id": "f554873d-d256-4960-aa5a-b69699a2d38e",
                        "created_at": "1694699301",
                        "operation_type": "",
                        "stackset_id": "ScopeTestMpa",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694699365",
                        "staggers": [
                            {
                                "created_at": "1694699303",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "MPAs",
                                "updated_at": "1694699364"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3e4d1dd2695d1d7fa775eaadabc0be2aeb173d5e",
                "commit_title": "test ",
                "created_at": "1728481131",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1728481337",
                "changes": [
                    {
                        "id": "e23181de-240f-45f4-b350-6cb823582b14",
                        "created_at": "1728481131",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728481337",
                        "staggers": [
                            {
                                "created_at": "1728481136",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728481337"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "47d19f08c9918b121142af951071291d03d6f863",
                "commit_title": "test on stage ",
                "created_at": "1728363934",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1728364341",
                "changes": [
                    {
                        "id": "698160b7-6d91-480e-8943-537f3b2b008f",
                        "created_at": "1728363934",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728364341",
                        "staggers": [
                            {
                                "created_at": "1728363938",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728364341"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3993ba703e41336caee4f814b0adde2aa14dbfbb",
                "commit_title": "added-update-param2 ",
                "created_at": "1715953419",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715953524",
                "changes": [
                    {
                        "id": "821395cd-e139-40f3-9210-bc82e13f28e2",
                        "created_at": "1715953419",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopePrismaRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715953524",
                        "staggers": [
                            {
                                "created_at": "1715953421",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715953524"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "0581536733c4f08667bcbbeed8964d6279ce4842",
                "commit_title": "removed-falcon-from-sg ",
                "created_at": "1715759908",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715760012",
                "changes": [
                    {
                        "id": "f51bd5db-8e87-45e4-b324-2a56d87e276e",
                        "created_at": "1715759908",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeSCPSelfManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715760012",
                        "staggers": [
                            {
                                "created_at": "1715759909",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1715760012"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f4ddc3c1a76a6cc7eddedf70b3f31f8a10ff19cd",
                "commit_title": "nre role test ",
                "created_at": "1707304355",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1707304567",
                "changes": [
                    {
                        "id": "6eb3337b-938f-4edf-82e1-1c388afd6cd4",
                        "created_at": "1707304360",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1707304567",
                        "staggers": [
                            {
                                "created_at": "1707304362",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1707304567"
                            }
                        ]
                    },
                    {
                        "id": "428a5d59-b657-4e9d-a60e-4095566d656e",
                        "created_at": "1707304355",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1707304567",
                        "staggers": [
                            {
                                "created_at": "1707304360",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1707304567"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "910030a68871a16a557adccc6d693217a220c7d0",
                "commit_title": "added-test-stacksets ",
                "created_at": "1705067317",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1705067558",
                "changes": [
                    {
                        "id": "340bf41e-dead-4189-b372-466ebf719743",
                        "created_at": "1705067319",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705067542",
                        "staggers": [
                            {
                                "created_at": "1705067321",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705067539"
                            }
                        ]
                    },
                    {
                        "id": "c5b28b26-2397-494b-a0da-8ccebc7befd0",
                        "created_at": "1705067317",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeWizRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705067555",
                        "staggers": [
                            {
                                "created_at": "1705067319",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1705067553"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-1138b746-731e-11ee-b87f-acde48001122",
                "commit_title": "test commit - create 2 stacksets",
                "created_at": "1698228356",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698228428",
                "changes": [
                    {
                        "id": "2d590459-f314-40a2-884b-790a95273d16",
                        "created_at": "1698228358",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698228428",
                        "staggers": [
                            {
                                "created_at": "1698228397",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698228427"
                            },
                            {
                                "created_at": "1698228361",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698228392"
                            }
                        ]
                    },
                    {
                        "id": "ebafaef3-5003-42c2-86c4-2af5a11059d9",
                        "created_at": "1698228356",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698228392",
                        "staggers": [
                            {
                                "created_at": "1698228358",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698228392"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "862e8569bcb4071171cf8bb49d46467a1c26a542",
                "commit_title": "removing resources for cleanup ",
                "created_at": "1729533602",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1729534007",
                "changes": [
                    {
                        "id": "1dc428b5-d1e8-419b-9a90-de518b60b1c8",
                        "created_at": "1729533602",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729534007",
                        "staggers": [
                            {
                                "created_at": "1729533604",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1729534007"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "ea73ad5fea94d54209fec22236f010cea4c7268b",
                "commit_title": "removing the scope roles ",
                "created_at": "1727986033",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727986237",
                "changes": [
                    {
                        "id": "065c4455-44fe-47c4-a2d0-de867d669321",
                        "created_at": "1727986033",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727986237",
                        "staggers": [
                            {
                                "created_at": "1727986035",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1727986237"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "08df0e9bbb520a0ccc65d7aa5e8c369c0a76292f",
                "commit_title": "updated-env-var ",
                "created_at": "1715780988",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715781194",
                "changes": [
                    {
                        "id": "312c66db-0034-4272-8e8c-eb322acd61ad",
                        "created_at": "1715780988",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSCPServiceManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715781194",
                        "staggers": [
                            {
                                "created_at": "1715780990",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715781194"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "735471cac761ec87603c1c6329ca613b883c3484",
                "commit_title": "added-wiz-role-in-mpa ",
                "created_at": "1704881354",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1704881585",
                "changes": [
                    {
                        "id": "76ab0eae-bb90-4590-ab3f-a3c51797ddc6",
                        "created_at": "1704881354",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeWizRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704881581",
                        "staggers": [
                            {
                                "created_at": "1704881356",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1704881578"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "299d5ed5e701b69726ffe4052fffc57439b1efd7",
                "commit_title": "fix-syntax-issue ",
                "created_at": "1704806868",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1704807105",
                "changes": [
                    {
                        "id": "8a23af24-55f9-4cca-8541-a86feb8c274d",
                        "created_at": "1704806868",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeWizRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704807100",
                        "staggers": [
                            {
                                "created_at": "1704806869",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1704807096"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3ff1f65409c29d57a243e02d22709d90a8c479d1",
                "commit_title": "Revert \"Merge pull request #89 from identity-access/stagger\" ",
                "created_at": "1702272248",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1702272449",
                "changes": [
                    {
                        "id": "315eb210-a1c2-42b7-ab79-113fa0babfd5",
                        "created_at": "1702272256",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702272449",
                        "staggers": [
                            {
                                "created_at": "1702272259",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702272449"
                            }
                        ]
                    },
                    {
                        "id": "1984db2d-48ec-4f23-8538-956e3ce7a0d8",
                        "created_at": "1702272252",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702272385",
                        "staggers": [
                            {
                                "created_at": "1702272256",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702272385"
                            }
                        ]
                    },
                    {
                        "id": "5fbb359b-3449-47f6-9319-936678fce050",
                        "created_at": "1702272250",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702272448",
                        "staggers": [
                            {
                                "created_at": "1702272252",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702272448"
                            }
                        ]
                    },
                    {
                        "id": "d386ede4-6168-4d15-a028-3966ec599434",
                        "created_at": "1702272248",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702272386",
                        "staggers": [
                            {
                                "created_at": "1702272250",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702272386"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "481b559e27abdc651039a4833bd60c4353467f48",
                "commit_title": "added-logs ",
                "created_at": "1694628063",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1694628307",
                "changes": [
                    {
                        "id": "439d9e8c-e7da-4bb1-ad76-901aeffe5d87",
                        "created_at": "1694628095",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694628306",
                        "staggers": [
                            {
                                "created_at": "1694628100",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694628306"
                            }
                        ]
                    },
                    {
                        "id": "3e18252c-709b-427f-9254-04014058fa51",
                        "created_at": "1694628088",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694628305",
                        "staggers": [
                            {
                                "created_at": "1694628095",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694628305"
                            }
                        ]
                    },
                    {
                        "id": "14fc8fcb-7441-43ff-8451-10c3b906076f",
                        "created_at": "1694628082",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694628304",
                        "staggers": [
                            {
                                "created_at": "1694628088",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694628304"
                            }
                        ]
                    },
                    {
                        "id": "ea4f72ec-e545-46ae-8026-8cdcc48f29d3",
                        "created_at": "1694628080",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694628306",
                        "staggers": [
                            {
                                "created_at": "1694628082",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694628306"
                            }
                        ]
                    },
                    {
                        "id": "9d5afddf-003e-4411-9495-6d32843cef15",
                        "created_at": "1694628078",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694628307",
                        "staggers": [
                            {
                                "created_at": "1694628080",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694628307"
                            }
                        ]
                    },
                    {
                        "id": "305fbcc5-241b-4378-baf5-e18d7acd80e3",
                        "created_at": "1694628076",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694628306",
                        "staggers": [
                            {
                                "created_at": "1694628078",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694628306"
                            }
                        ]
                    },
                    {
                        "id": "463bd20d-2c51-4340-9fae-f2917dae4ed4",
                        "created_at": "1694628070",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694628305",
                        "staggers": [
                            {
                                "created_at": "1694628076",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694628305"
                            }
                        ]
                    },
                    {
                        "id": "acff8dc9-60db-4978-9ab6-58e0867d6671",
                        "created_at": "1694628066",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694628306",
                        "staggers": [
                            {
                                "created_at": "1694628070",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694628306"
                            }
                        ]
                    },
                    {
                        "id": "43506624-9d67-483f-a51f-dac99d09decf",
                        "created_at": "1694628063",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694628305",
                        "staggers": [
                            {
                                "created_at": "1694628066",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694628305"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3bf160c0146ec041f62b1e5f24e2077e6f5c2cdf",
                "commit_title": "testing ",
                "created_at": "1731519994",
                "deployment_status": "COMPLETED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731520551",
                "changes": [
                    {
                        "id": "3c9a4055-7fbe-4bdd-845f-19af85c5aa81",
                        "created_at": "1731519994",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731520551",
                        "staggers": [
                            {
                                "created_at": "1731520350",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731520551"
                            },
                            {
                                "created_at": "1731519998",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731520198"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-c16677da-731d-11ee-b87f-acde48001122",
                "commit_title": "test commit - remove all stacksets from grouping, and mark as delete in payload",
                "created_at": "1698228222",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698228288",
                "changes": [
                    {
                        "id": "fa54291a-e466-4708-9e02-c50376f6db5e",
                        "created_at": "1698228224",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698228288",
                        "staggers": [
                            {
                                "created_at": "1698228225",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698228287"
                            }
                        ]
                    },
                    {
                        "id": "9f14d366-6940-4a0e-8305-d06a79e85195",
                        "created_at": "1698228222",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698228287",
                        "staggers": [
                            {
                                "created_at": "1698228224",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698228287"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "8a96a7a1f9f53490c16cd820f406d2d6390daf4e",
                "commit_title": "updated-config-scopeMPA ",
                "created_at": "1697094230",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1697094230",
                "changes": null,
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "c600a4beb88ed399bd48e52ccb09772d0ff8c401",
                "commit_title": "Test commit ",
                "created_at": "1684489759",
                "deployment_status": "FAILED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1684489988",
                "changes": [
                    {
                        "id": "1889f63d-775b-4082-85a9-c4baf646c3e8",
                        "created_at": "1684489779",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684489988",
                        "staggers": [
                            {
                                "created_at": "1684489781",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489988"
                            }
                        ]
                    },
                    {
                        "id": "2fa48822-30a3-47ee-a8b7-4f467343b868",
                        "created_at": "1684489777",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684489987",
                        "staggers": [
                            {
                                "created_at": "1684489779",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489987"
                            }
                        ]
                    },
                    {
                        "id": "52466bc2-50e9-4bb1-ad46-eb6810df190c",
                        "created_at": "1684489772",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "FAILED",
                        "updated_at": "1684489988",
                        "staggers": [
                            {
                                "created_at": "1684489777",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489988"
                            }
                        ]
                    },
                    {
                        "id": "531470d8-21a5-49f7-9d24-e24254759626",
                        "created_at": "1684489770",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684489986",
                        "staggers": [
                            {
                                "created_at": "1684489772",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489986"
                            }
                        ]
                    },
                    {
                        "id": "a3bbd43c-0f23-4d90-92a9-85f60bb2d9f5",
                        "created_at": "1684489767",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1684489987",
                        "staggers": [
                            {
                                "created_at": "1684489770",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489987"
                            }
                        ]
                    },
                    {
                        "id": "7da63587-0081-4a9e-9f54-8c320acd1c57",
                        "created_at": "1684489765",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684489987",
                        "staggers": [
                            {
                                "created_at": "1684489767",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489987"
                            }
                        ]
                    },
                    {
                        "id": "1a021163-f567-4d56-b4a5-a92da228cdd9",
                        "created_at": "1684489763",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684489988",
                        "staggers": [
                            {
                                "created_at": "1684489765",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489988"
                            }
                        ]
                    },
                    {
                        "id": "f728fb71-7eb7-40cb-8998-f03d9aafed94",
                        "created_at": "1684489761",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684489987",
                        "staggers": [
                            {
                                "created_at": "1684489763",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489987"
                            }
                        ]
                    },
                    {
                        "id": "cdda2142-d8a5-4d19-b731-da22e6cef020",
                        "created_at": "1684489759",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684489986",
                        "staggers": [
                            {
                                "created_at": "1684489761",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684489986"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "2ee051fb094387bea0303c294f90efe9d91c47a9",
                "commit_title": "@W-16496074 Addings tags for IAM Roles ",
                "created_at": "1723658770",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1723730369",
                "changes": [
                    {
                        "id": "6b7d4093-a386-4b03-8abd-aa7fd46b4571",
                        "created_at": "1723658777",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1723659185",
                        "staggers": [
                            {
                                "created_at": "1723658781",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1723659185"
                            }
                        ]
                    },
                    {
                        "id": "302f6dcc-a318-4f3e-9df2-cae376f11d4c",
                        "created_at": "1723658775",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1723658984",
                        "staggers": [
                            {
                                "created_at": "1723658777",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1723658984"
                            }
                        ]
                    },
                    {
                        "id": "df88b44e-e4b2-489e-aadb-29fb2d1b92d7",
                        "created_at": "1723658772",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1723658983",
                        "staggers": [
                            {
                                "created_at": "1723658775",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1723658983"
                            }
                        ]
                    },
                    {
                        "id": "369f8101-28f6-4ac6-8cc2-924935310d31",
                        "created_at": "1723658770",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1723730369",
                        "staggers": [
                            {
                                "created_at": "1723730167",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1723730369"
                            },
                            {
                                "created_at": "1723658772",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1723659185"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d6f0b9f2607d97df21ffe67917d067dcb147af41",
                "commit_title": "test-commit ",
                "created_at": "1717141213",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1717144242",
                "changes": [
                    {
                        "id": "de709759-6ec2-4f8e-83d8-a8cc12272661",
                        "created_at": "1717141213",
                        "operation_type": "CREATE",
                        "stackset_id": "scopePrismaRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717144242",
                        "staggers": [
                            {
                                "created_at": "1717141215",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1717144242"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "5ed9f531cccf7d7074a840af1012f75e3c085394",
                "commit_title": "Minor fixes for actions ",
                "created_at": "1710427403",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1711024656",
                "changes": [
                    {
                        "id": "6e827c42-eafa-4c20-83bb-5bb192a46c4c",
                        "created_at": "1710427415",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1710427621",
                        "staggers": [
                            {
                                "created_at": "1710427417",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1710427621"
                            }
                        ]
                    },
                    {
                        "id": "41c064b0-1d74-423d-a1df-5142fcaadb04",
                        "created_at": "1710427413",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1710427620",
                        "staggers": [
                            {
                                "created_at": "1710427415",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1710427620"
                            }
                        ]
                    },
                    {
                        "id": "f14c13db-2489-4fb7-a667-81baa0776614",
                        "created_at": "1710427408",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1710427823",
                        "staggers": [
                            {
                                "created_at": "1710427413",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1710427823"
                            }
                        ]
                    },
                    {
                        "id": "0a3d28a6-20fe-4fba-bfd2-6161abe0567b",
                        "created_at": "1710427405",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1710427620",
                        "staggers": [
                            {
                                "created_at": "1710427408",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1710427620"
                            }
                        ]
                    },
                    {
                        "id": "57b2c31a-20fd-4607-a12e-20a9aa570191",
                        "created_at": "1710427403",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1711024656",
                        "staggers": [
                            {
                                "created_at": "1710427405",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1710427822"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "fccbcc8f517479ab60eab88fec4be13e7a9fb590",
                "commit_title": "added-perm-boundary ",
                "created_at": "1709557958",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1709558162",
                "changes": [
                    {
                        "id": "6d00e746-d122-4fb0-8d95-6ba991a510ab",
                        "created_at": "1709557958",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeOUMovementTracker",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709558162",
                        "staggers": [
                            {
                                "created_at": "1709557959",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1709558162"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "00219513602eddec462ef8d76d85ffdb1f28a28b",
                "commit_title": "test of go runtime upgrade ",
                "created_at": "1707248092",
                "deployment_status": "COMPLETED",
                "developer_id": "xtao@salesforce.com",
                "updated_at": "1707248303",
                "changes": [
                    {
                        "id": "6e03de74-fef6-4723-abab-611f2a41f2a8",
                        "created_at": "1707248095",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1707248303",
                        "staggers": [
                            {
                                "created_at": "1707248100",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1707248303"
                            }
                        ]
                    },
                    {
                        "id": "02403b89-2fa7-4e21-b4ce-ad28a675d9cc",
                        "created_at": "1707248092",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1707248303",
                        "staggers": [
                            {
                                "created_at": "1707248095",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1707248303"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b5619ad02df7760eb2ca396812a1da9ba94163b3",
                "commit_title": "added-stage-mpa ",
                "created_at": "1697095945",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1697096167",
                "changes": [
                    {
                        "id": "2655b061-7b99-4dfe-bcb0-a7c81af80ff3",
                        "created_at": "1697095946",
                        "operation_type": "",
                        "stackset_id": "scopeMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1697096167",
                        "staggers": [
                            {
                                "created_at": "1697095953",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeNoStaggeringGroup",
                                "updated_at": "1697096167"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e5065e774001b5996a6de77135800531705f2545",
                "commit_title": "updated-sg-3 ",
                "created_at": "1696925588",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1696933506",
                "changes": [
                    {
                        "id": "af84c316-808f-405f-b146-760da890e7e6",
                        "created_at": "1696925588",
                        "operation_type": "",
                        "stackset_id": "scopetestv6",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696925793",
                        "staggers": [
                            {
                                "created_at": "1696925589",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeNoStaggeringGroup",
                                "updated_at": "1696925793"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "5a91f5ab2c8fb4dad6cadf52dbadb7fad92081b4",
                "commit_title": "delete-test-stacksets ",
                "created_at": "1715765557",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715765557",
                "changes": [
                    {
                        "id": "0d6655f1-66fd-43d0-b966-5f4fe0cf62ed",
                        "created_at": "1715765562",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeMPA",
                        "stackset_status": "FAILED",
                        "updated_at": "1715765665",
                        "staggers": [
                            {
                                "created_at": "1715765563",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715765664"
                            }
                        ]
                    },
                    {
                        "id": "d38a4acc-6ba9-44c7-a1ff-ababd75b9c5f",
                        "created_at": "1715765562",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestRole",
                        "stackset_status": "FAILED",
                        "updated_at": "1715765665",
                        "staggers": [
                            {
                                "created_at": "1715765562",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715765665"
                            }
                        ]
                    },
                    {
                        "id": "bbc017c9-b537-4335-aab3-846b4bbdf6e5",
                        "created_at": "1715765561",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeOnboardingTest",
                        "stackset_status": "FAILED",
                        "updated_at": "1715765666",
                        "staggers": [
                            {
                                "created_at": "1715765562",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715765665"
                            }
                        ]
                    },
                    {
                        "id": "ab906199-9869-4406-8567-1cb6e6a6b820",
                        "created_at": "1715765557",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeDemoStackset",
                        "stackset_status": "FAILED",
                        "updated_at": "1715765666",
                        "staggers": [
                            {
                                "created_at": "1715765561",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715765666"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "9d8a7effb9fb9fc23a7ffdda6ddd65988c22c8fa_905418466263_af0b7615-e828-11ee-a01d-868f13430be0",
                "commit_title": "scopeOnboardingTest stackset ",
                "created_at": "1711097197",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1711097197",
                "changes": [
                    {
                        "id": "f1d57cd7-b1c8-4227-865e-6f47895d1cc3",
                        "created_at": "1711097197",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeOnboardingTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711097401",
                        "staggers": [
                            {
                                "created_at": "1711097198",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1711097401"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "09cdbacae4ad78b637199bbe93dee2587fe527b6",
                "commit_title": "@W-14421107 | Add trust relationship for SCP Stagger codebuild role ",
                "created_at": "1699007314",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1699007519",
                "changes": [
                    {
                        "id": "62237f87-9523-46fc-8c00-adf8e54fe3ec",
                        "created_at": "1699007314",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1699007519",
                        "staggers": [
                            {
                                "created_at": "1699007317",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1699007519"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-963c55a2-74b2-11ee-bed9-acde48001122",
                "commit_title": "test commit - remove all stacksets from grouping, and mark as delete in payload",
                "created_at": "1698402103",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698402309",
                "changes": [
                    {
                        "id": "64af4a93-ce29-4341-bba6-e56879ea1688",
                        "created_at": "1698402104",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698402309",
                        "staggers": [
                            {
                                "created_at": "1698402106",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698402309"
                            }
                        ]
                    },
                    {
                        "id": "d59c270e-7b26-43a8-a21b-1009b610a51a",
                        "created_at": "1698402103",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698402309",
                        "staggers": [
                            {
                                "created_at": "1698402104",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698402308"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "10ef4cd4ddd2cd4cf6dc994ea6ce81a183f6de55",
                "commit_title": "updated-name ",
                "created_at": "1694617374",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1694617815",
                "changes": [
                    {
                        "id": "b2c3a425-6400-4939-82c0-644649598ce9",
                        "created_at": "1694617404",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694617815",
                        "staggers": [
                            {
                                "created_at": "1694617406",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694617815"
                            }
                        ]
                    },
                    {
                        "id": "559dd2e4-75fe-4958-a403-ffb87dfd363c",
                        "created_at": "1694617399",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694617814",
                        "staggers": [
                            {
                                "created_at": "1694617404",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694617814"
                            }
                        ]
                    },
                    {
                        "id": "8b53e10b-b04f-479e-a32c-308edd281a26",
                        "created_at": "1694617388",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694617813",
                        "staggers": [
                            {
                                "created_at": "1694617399",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694617813"
                            }
                        ]
                    },
                    {
                        "id": "8a45c20d-25fd-4136-a8c7-951c0a3d466f",
                        "created_at": "1694617386",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694617610",
                        "staggers": [
                            {
                                "created_at": "1694617388",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694617610"
                            }
                        ]
                    },
                    {
                        "id": "e7592525-9abd-4184-9659-47db4fadd19a",
                        "created_at": "1694617382",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694617814",
                        "staggers": [
                            {
                                "created_at": "1694617386",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694617814"
                            }
                        ]
                    },
                    {
                        "id": "d9ae8067-e9d2-46c6-8358-bdb97cece945",
                        "created_at": "1694617380",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694617814",
                        "staggers": [
                            {
                                "created_at": "1694617382",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694617814"
                            }
                        ]
                    },
                    {
                        "id": "313afe8a-502f-401d-a926-8d11ba0c400b",
                        "created_at": "1694617378",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694617815",
                        "staggers": [
                            {
                                "created_at": "1694617380",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694617815"
                            }
                        ]
                    },
                    {
                        "id": "07c41e54-25a6-4e04-b502-9e4b705777b1",
                        "created_at": "1694617376",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694617815",
                        "staggers": [
                            {
                                "created_at": "1694617378",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694617815"
                            }
                        ]
                    },
                    {
                        "id": "f13c8992-3bdd-4aa5-8f12-4b327b6e15be",
                        "created_at": "1694617374",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694617610",
                        "staggers": [
                            {
                                "created_at": "1694617376",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694617610"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "2c13cbe36ba810ccf5331e5485957ee089412d7c",
                "commit_title": "set false ",
                "created_at": "1683618630",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1683618855",
                "changes": [
                    {
                        "id": "1d5d0c56-9f88-4765-bd8a-57e8638d15c0",
                        "created_at": "1683618648",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683618853",
                        "staggers": [
                            {
                                "created_at": "1683618649",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683618853"
                            }
                        ]
                    },
                    {
                        "id": "bc401948-468a-48d1-b6f1-571583d1249e",
                        "created_at": "1683618647",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683618854",
                        "staggers": [
                            {
                                "created_at": "1683618648",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683618854"
                            }
                        ]
                    },
                    {
                        "id": "552e3d57-5604-47d4-994e-e0f7a3b2a1f9",
                        "created_at": "1683618646",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683618853",
                        "staggers": [
                            {
                                "created_at": "1683618647",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683618853"
                            }
                        ]
                    },
                    {
                        "id": "8dcf33de-ccfa-40d7-b13c-3c35febe135c",
                        "created_at": "1683618644",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683618853",
                        "staggers": [
                            {
                                "created_at": "1683618646",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683618853"
                            }
                        ]
                    },
                    {
                        "id": "4365a273-d3a7-4a7f-85d2-65b091bfaf51",
                        "created_at": "1683618639",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683618854",
                        "staggers": [
                            {
                                "created_at": "1683618644",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683618854"
                            }
                        ]
                    },
                    {
                        "id": "5ea58029-f8d3-4a82-8f24-82d298f05fd0",
                        "created_at": "1683618638",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683618854",
                        "staggers": [
                            {
                                "created_at": "1683618639",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683618854"
                            }
                        ]
                    },
                    {
                        "id": "f648de9f-a0e5-4998-a615-08b1460da112",
                        "created_at": "1683618637",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683618853",
                        "staggers": [
                            {
                                "created_at": "1683618638",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683618853"
                            }
                        ]
                    },
                    {
                        "id": "fa4afc52-fbec-4e65-ba5e-105018b2e637",
                        "created_at": "1683618631",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683618855",
                        "staggers": [
                            {
                                "created_at": "1683618637",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683618855"
                            }
                        ]
                    },
                    {
                        "id": "826ddd4b-dba1-4763-b94c-8ccc2d50e899",
                        "created_at": "1683618630",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683618854",
                        "staggers": [
                            {
                                "created_at": "1683618631",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683618854"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6f9d9cb5f50c0383a28517e463a64e5421ba7283",
                "commit_title": "@W-12697388 | Added new cost savings AWS services to EAI inventory role ",
                "created_at": "1679313205",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1679383369",
                "changes": [
                    {
                        "id": "72a9ddb6-29ba-4e62-8c2f-a039b8f82e27",
                        "created_at": "1679382945",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1679383369",
                        "staggers": [
                            {
                                "created_at": "1679383065",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679383369"
                            },
                            {
                                "created_at": "1679382948",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Account Test",
                                "updated_at": "1679383052"
                            }
                        ]
                    },
                    {
                        "id": "f575f13a-98aa-4815-bf58-5da1f8aa4a9b",
                        "created_at": "1679313216",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1679383369",
                        "staggers": [
                            {
                                "created_at": "1679383066",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679383369"
                            },
                            {
                                "created_at": "1679382950",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Account Test",
                                "updated_at": "1679383052"
                            }
                        ]
                    },
                    {
                        "id": "54323cbe-5696-4ada-863e-8d0e1ba2a18c",
                        "created_at": "1679313210",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1679383369",
                        "staggers": [
                            {
                                "created_at": "1679383063",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679383369"
                            },
                            {
                                "created_at": "1679382943",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage",
                                "updated_at": "1679383051"
                            },
                            {
                                "created_at": "1679313308",
                                "operation_status": "OVERRIDDEN",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679377946"
                            },
                            {
                                "created_at": "1679313216",
                                "operation_status": "OVERRIDDEN",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage",
                                "updated_at": "1679377946"
                            }
                        ]
                    },
                    {
                        "id": "9891f5b3-f937-4933-bc19-cc0d79a03b63",
                        "created_at": "1679313207",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1679383052",
                        "staggers": [
                            {
                                "created_at": "1679313210",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679383052"
                            }
                        ]
                    },
                    {
                        "id": "bf2e01e7-96a8-4f92-8a90-174b92c2ec27",
                        "created_at": "1679313205",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1679383051",
                        "staggers": [
                            {
                                "created_at": "1679382945",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679383051"
                            },
                            {
                                "created_at": "1679313207",
                                "operation_status": "OVERRIDDEN",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1679377946"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "c647969e73f854eaa1c495d55bb64ada3a1987f2",
                "commit_title": "Adding single quotes ",
                "created_at": "1727963401",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727963864",
                "changes": [
                    {
                        "id": "e463f12d-482d-4269-8ada-d132b6f98c19",
                        "created_at": "1727963401",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1727963864",
                        "staggers": null
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "899fc5fbe3071957e126939b402061af040f3470",
                "commit_title": "add back scopetest with ou ",
                "created_at": "1694700031",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1694701143",
                "changes": [
                    {
                        "id": "ba607d95-806e-4a95-8795-a2566177550e",
                        "created_at": "1694700032",
                        "operation_type": "",
                        "stackset_id": "ScopeTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694700172",
                        "staggers": [
                            {
                                "created_at": "1694700108",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "ou",
                                "updated_at": "1694700172"
                            },
                            {
                                "created_at": "1694700033",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "dev account",
                                "updated_at": "1694700097"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "94952aa6452e4181fe9b988e9f98120706b198c8",
                "commit_title": "@W-16989479 | [TD-0205604] New Role for Tentacle functionality ",
                "created_at": "1729080128",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1729084203",
                "changes": [
                    {
                        "id": "edb51069-c8a8-48ad-9fc8-7ab2c56514d8",
                        "created_at": "1729080131",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTentacleRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729083981",
                        "staggers": [
                            {
                                "created_at": "1729080132",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1729083981"
                            }
                        ]
                    },
                    {
                        "id": "3dce9796-87d9-4f55-b406-9a397480a2d1",
                        "created_at": "1729080128",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729084203",
                        "staggers": [
                            {
                                "created_at": "1729084001",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729084203"
                            },
                            {
                                "created_at": "1729080130",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1729083981"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "bd6ff0003ca8e5b73ffe215f978132d33f168655",
                "commit_title": "@W-16989479 | [TD-0205604] New Role for Tentacle functionality ",
                "created_at": "1729064019",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1729064225",
                "changes": [
                    {
                        "id": "e345546a-a7b5-484e-9214-06016c1cf0a8",
                        "created_at": "1729064019",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTentacleRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729064225",
                        "staggers": [
                            {
                                "created_at": "1729064021",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1729064225"
                            }
                        ]
                    },
                    {
                        "id": "52388173-6878-4446-b831-d84eeef4e4bf",
                        "created_at": "1729060640",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729061050",
                        "staggers": [
                            {
                                "created_at": "1729060645",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729061050"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f6202d5b844b88680f9746dade889e4c36194aef",
                "commit_title": "@W-16186122 Adding servicename tag to PCSK resources - lambda, roles, cloudtrail (#191) ",
                "created_at": "1724829243",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1724829445",
                "changes": [
                    {
                        "id": "14455381-c4b1-4c4b-aa7a-7ed38a507d89",
                        "created_at": "1724263935",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724264547",
                        "staggers": [
                            {
                                "created_at": "1724263937",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724264547"
                            }
                        ]
                    },
                    {
                        "id": "e66e2566-9162-4a00-8de9-7cc5d2dc90d3",
                        "created_at": "1724263933",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724266080",
                        "staggers": [
                            {
                                "created_at": "1724265876",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724266080"
                            },
                            {
                                "created_at": "1724263935",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1724264546"
                            }
                        ]
                    },
                    {
                        "id": "bcba064c-cbe6-4f53-92d4-2432ca4c537c",
                        "created_at": "1724263928",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724264546",
                        "staggers": [
                            {
                                "created_at": "1724263933",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724264546"
                            }
                        ]
                    },
                    {
                        "id": "3b33d9b1-2da4-4861-969c-6028e395aee1",
                        "created_at": "1724263926",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724264547",
                        "staggers": [
                            {
                                "created_at": "1724263928",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724264547"
                            }
                        ]
                    },
                    {
                        "id": "6fca0d68-73d8-4b8b-a720-6c0186d595f0",
                        "created_at": "1724263922",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724264547",
                        "staggers": [
                            {
                                "created_at": "1724263926",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1724264547"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e785e820b703659fec95d5197d97449b4534d481",
                "commit_title": "added-test-scope-role ",
                "created_at": "1714391432",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714391432",
                "changes": null,
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6a009b8354b5540770e3a209062a0a14fd37f7ae",
                "commit_title": "odcr role falconmpa.yml ",
                "created_at": "1709032618",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1709032823",
                "changes": [
                    {
                        "id": "bbe1f16f-8e5c-42ea-9e71-6f2680c08cab",
                        "created_at": "1709032618",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709032823",
                        "staggers": [
                            {
                                "created_at": "1709032620",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709032823"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d8bd097f849a492453ef981c1690341a83501901",
                "commit_title": "Adding test appgroup tag ",
                "created_at": "1728408836",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1728409040",
                "changes": [
                    {
                        "id": "01820f76-7992-4037-9b44-cd182c23bd7b",
                        "created_at": "1728408836",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728409040",
                        "staggers": [
                            {
                                "created_at": "1728408838",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1728409040"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "8a961037cdbf1ec996deff195b1ffd183c305ed6",
                "commit_title": "added assume role permissions ",
                "created_at": "1717496038",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1717496457",
                "changes": [
                    {
                        "id": "9c1dc144-95f4-4a75-b473-5ea333ede3d2",
                        "created_at": "1717496038",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717496457",
                        "staggers": [
                            {
                                "created_at": "1717496255",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717496457"
                            },
                            {
                                "created_at": "1717496040",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717496242"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "45e7945ccf1f4533aadad7c118088582eaec848e",
                "commit_title": "remove-prisma-role-spike ",
                "created_at": "1715871594",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715871594",
                "changes": [
                    {
                        "id": "cf9fcf0c-7ecf-4c7b-bb8b-8ac68f966737",
                        "created_at": "1715871594",
                        "operation_type": "DELETE",
                        "stackset_id": "scopePrismaRole",
                        "stackset_status": "FAILED",
                        "updated_at": "1715871705",
                        "staggers": [
                            {
                                "created_at": "1715871595",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1715871705"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4f110b9eb488daacd87570d658ab8a551b481581",
                "commit_title": "added-nf-acc ",
                "created_at": "1715698887",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715699092",
                "changes": [
                    {
                        "id": "fb8061bf-984e-43a8-a82d-992342db6fc9",
                        "created_at": "1715698888",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeSCPSelfManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715699092",
                        "staggers": [
                            {
                                "created_at": "1715698889",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1715699092"
                            }
                        ]
                    },
                    {
                        "id": "edc45e2a-10d3-4b54-8092-e7c925631f06",
                        "created_at": "1715698887",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeSCPSelfManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715699092",
                        "staggers": [
                            {
                                "created_at": "1715698888",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1715699092"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6a68f84ef580510a3db4bc424b374f3b50ccca90",
                "commit_title": "Merge pull request #111 from identity-access/staggerallmpa ",
                "created_at": "1705551647",
                "deployment_status": "COMPLETED",
                "developer_id": "svaradarajan@salesforce.com",
                "updated_at": "1705552076",
                "changes": [
                    {
                        "id": "841eab2f-fa84-4bc7-9aad-97effa533595",
                        "created_at": "1705551654",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705551864",
                        "staggers": [
                            {
                                "created_at": "1705551658",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705551864"
                            }
                        ]
                    },
                    {
                        "id": "10b820d4-43b7-4898-97c5-463cc2d8e6de",
                        "created_at": "1705551651",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705551863",
                        "staggers": [
                            {
                                "created_at": "1705551654",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705551863"
                            }
                        ]
                    },
                    {
                        "id": "a89cbc7b-da10-4ba6-b111-fe690c2a575d",
                        "created_at": "1705551649",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705552076",
                        "staggers": [
                            {
                                "created_at": "1705551873",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705552076"
                            },
                            {
                                "created_at": "1705551651",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1705551864"
                            }
                        ]
                    },
                    {
                        "id": "506b1e6c-4ba5-41d7-8e55-abf1b2924eb0",
                        "created_at": "1705551647",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705551863",
                        "staggers": [
                            {
                                "created_at": "1705551649",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705551863"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "5621135e1c653a1c7bfbc06bc0bff87e8ce37c1a",
                "commit_title": "added new role changes ",
                "created_at": "1704736689",
                "deployment_status": "COMPLETED",
                "developer_id": "svaradarajan@salesforce.com",
                "updated_at": "1704736898",
                "changes": [
                    {
                        "id": "8f4644f1-1519-46af-894e-728822abbf76",
                        "created_at": "1704736691",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704736897",
                        "staggers": [
                            {
                                "created_at": "1704736693",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1704736897"
                            }
                        ]
                    },
                    {
                        "id": "dcf3588a-7118-470d-9a23-0d9d6239dcb2",
                        "created_at": "1704736689",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1704736898",
                        "staggers": [
                            {
                                "created_at": "1704736691",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1704736898"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e08521e2c88e6a6f136505fff04b223a531e653b",
                "commit_title": "@W-13879570 | Fix dependency of PCSKPermissionsBoundary on stacksets ",
                "created_at": "1692879428",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1692879428",
                "changes": [
                    {
                        "id": "a938b130-8784-412a-977d-71247a6462a4",
                        "created_at": "1692879446",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692880274",
                        "staggers": [
                            {
                                "created_at": "1692879449",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692880274"
                            }
                        ]
                    },
                    {
                        "id": "c9395687-647d-4690-bafe-7f666cad883d",
                        "created_at": "1692879442",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692880274",
                        "staggers": [
                            {
                                "created_at": "1692879446",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692880274"
                            }
                        ]
                    },
                    {
                        "id": "d2eea544-0480-442b-bbd8-1f118070bc63",
                        "created_at": "1692879441",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692880273",
                        "staggers": [
                            {
                                "created_at": "1692879442",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692880273"
                            }
                        ]
                    },
                    {
                        "id": "7d3f277a-91a5-4536-9131-dfe7d5ce4db6",
                        "created_at": "1692879439",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692880273",
                        "staggers": [
                            {
                                "created_at": "1692879441",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692880273"
                            }
                        ]
                    },
                    {
                        "id": "801c251f-3294-43ed-bf03-b1c40619b088",
                        "created_at": "1692879437",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692880273",
                        "staggers": [
                            {
                                "created_at": "1692879439",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692880273"
                            }
                        ]
                    },
                    {
                        "id": "5e537270-1a7a-4574-a48c-c0e488a8ab14",
                        "created_at": "1692879434",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692880272",
                        "staggers": [
                            {
                                "created_at": "1692879437",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692880272"
                            }
                        ]
                    },
                    {
                        "id": "a67db9a0-ac14-45a1-bfd1-d4c6ac85542f",
                        "created_at": "1692879433",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692880274",
                        "staggers": [
                            {
                                "created_at": "1692879434",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692880274"
                            }
                        ]
                    },
                    {
                        "id": "e0e9dee3-8b5a-4878-8e20-89db5a46bb2d",
                        "created_at": "1692879431",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692880272",
                        "staggers": [
                            {
                                "created_at": "1692879433",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692880272"
                            }
                        ]
                    },
                    {
                        "id": "9c6b0af8-b394-446c-bee9-a3418f7e0f72",
                        "created_at": "1692879428",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692880272",
                        "staggers": [
                            {
                                "created_at": "1692879431",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692880272"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "c89e7c22ac4765b678c2baba0a9610bcad654201",
                "commit_title": "testing ",
                "created_at": "1731474677",
                "deployment_status": "FAILED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731474883",
                "changes": [
                    {
                        "id": "e2b0724c-c268-4f5d-a1ac-2ea2366ebfdd",
                        "created_at": "1731474677",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1731474883",
                        "staggers": [
                            {
                                "created_at": "1731474681",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731474883"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "f2e22640c12e2cf05fa627de4d93b7f896c58e44",
                "commit_title": "removed params ",
                "created_at": "1729065591",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1729065795",
                "changes": [
                    {
                        "id": "82151ffe-6e31-4cc1-8ef5-692ce6fec0e4",
                        "created_at": "1729065591",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTentacleRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729065795",
                        "staggers": [
                            {
                                "created_at": "1729065593",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1729065795"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d34885eec63cef110d13afd5019b9e10b52c8ccd",
                "commit_title": "@W-16758663 updating ouids ",
                "created_at": "1727823413",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727823621",
                "changes": [
                    {
                        "id": "ea80b8c1-fcd6-4cf7-8c94-90be29eee644",
                        "created_at": "1727823417",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727823621",
                        "staggers": [
                            {
                                "created_at": "1727823419",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1727823621"
                            }
                        ]
                    },
                    {
                        "id": "1341f35d-4b74-4a87-a8dc-f57839506270",
                        "created_at": "1727823413",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727823621",
                        "staggers": [
                            {
                                "created_at": "1727823417",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1727823621"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "0511e83bc1869399a280c98ca8282254e4c2bc06",
                "commit_title": "test 2 ",
                "created_at": "1717572145",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1717572348",
                "changes": [
                    {
                        "id": "e9e9efd5-c333-4eb6-8a24-32f2b1c7bc50",
                        "created_at": "1717572145",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717572348",
                        "staggers": [
                            {
                                "created_at": "1717572147",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717572348"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a373101ebbcc8298f6f70ca7e5eb9cb37f142068",
                "commit_title": "Added permissions ",
                "created_at": "1717493741",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1717494159",
                "changes": [
                    {
                        "id": "bff37221-8c2a-4d6b-b9b1-89af0462bddc",
                        "created_at": "1717493741",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717494159",
                        "staggers": [
                            {
                                "created_at": "1717493958",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717494159"
                            },
                            {
                                "created_at": "1717493742",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717493945"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-8c754886-732b-11ee-860f-acde48001122",
                "commit_title": "test commit - go back to reverted PR - create 1 stackset and change group config for other 2 stacksets",
                "created_at": "1698234149",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698234186",
                "changes": [
                    {
                        "id": "dc395b08-0898-4bc1-9bce-96f40e40dd9d",
                        "created_at": "1698234153",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698234186",
                        "staggers": [
                            {
                                "created_at": "1698234154",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234186"
                            }
                        ]
                    },
                    {
                        "id": "b73eb0a2-c9a7-44be-9a98-1c24686f8908",
                        "created_at": "1698234152",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698234185",
                        "staggers": [
                            {
                                "created_at": "1698234153",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234185"
                            }
                        ]
                    },
                    {
                        "id": "afe00089-3d1f-4f42-8e61-baa0b05b902c",
                        "created_at": "1698234151",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698234185",
                        "staggers": [
                            {
                                "created_at": "1698234152",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234185"
                            }
                        ]
                    },
                    {
                        "id": "dcea2de1-6800-4a04-a122-1453deb77a62",
                        "created_at": "1698234150",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698234185",
                        "staggers": [
                            {
                                "created_at": "1698234151",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234185"
                            }
                        ]
                    },
                    {
                        "id": "2a208522-8ef6-4540-9cf0-1a0bd1b23110",
                        "created_at": "1698234149",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698234185",
                        "staggers": [
                            {
                                "created_at": "1698234150",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698234185"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a24c5df6d14675d97a3e6a8b2c2b1913d9eac799",
                "commit_title": "ec3 ",
                "created_at": "1694623204",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1694623427",
                "changes": [
                    {
                        "id": "9360438d-2d1b-47d8-9890-9c38160e519e",
                        "created_at": "1694623217",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694623426",
                        "staggers": [
                            {
                                "created_at": "1694623224",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694623426"
                            },
                            {
                                "created_at": "1694623221",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694623221"
                            }
                        ]
                    },
                    {
                        "id": "59c28045-5fd8-487c-89c9-00ed410e160a",
                        "created_at": "1694623217",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694623425",
                        "staggers": [
                            {
                                "created_at": "1694623220",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694623425"
                            }
                        ]
                    },
                    {
                        "id": "398ef9fd-033b-47fe-8f02-6a8ab1b2a86e",
                        "created_at": "1694623215",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694623426",
                        "staggers": [
                            {
                                "created_at": "1694623217",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694623426"
                            }
                        ]
                    },
                    {
                        "id": "dac7754e-edbe-49b1-b0a1-cefd20b9d18c",
                        "created_at": "1694623215",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694623427",
                        "staggers": [
                            {
                                "created_at": "1694623217",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694623427"
                            }
                        ]
                    },
                    {
                        "id": "a3287c1c-4bb5-4198-bf3d-1db069868063",
                        "created_at": "1694623213",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694623425",
                        "staggers": [
                            {
                                "created_at": "1694623215",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694623425"
                            }
                        ]
                    },
                    {
                        "id": "10db51a3-e28d-4247-80d7-82085b89bcdf",
                        "created_at": "1694623211",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694623426",
                        "staggers": [
                            {
                                "created_at": "1694623213",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694623426"
                            }
                        ]
                    },
                    {
                        "id": "d5552c96-9f1b-4b95-a9c5-07fe03db678d",
                        "created_at": "1694623209",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694623427",
                        "staggers": [
                            {
                                "created_at": "1694623211",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694623427"
                            }
                        ]
                    },
                    {
                        "id": "742a2543-5a82-4724-b931-a897c681f249",
                        "created_at": "1694623206",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694623425",
                        "staggers": [
                            {
                                "created_at": "1694623209",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694623425"
                            }
                        ]
                    },
                    {
                        "id": "559fd918-38da-4436-bcd6-0ab8daa12aac",
                        "created_at": "1694623204",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694623427",
                        "staggers": [
                            {
                                "created_at": "1694623206",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1694623427"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3a4177044faa592f0160833244bfa3992e54ccab",
                "commit_title": "Next empy commit ",
                "created_at": "1731427667",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1731427873",
                "changes": [
                    {
                        "id": "5aa573a4-5ba6-4277-9229-d7290233a0b6",
                        "created_at": "1731427667",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731427873",
                        "staggers": [
                            {
                                "created_at": "1731427671",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731427873"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6aea5b9b056937b3b093169a78fd71b353636e79",
                "commit_title": "Adding more required permissions for stage ",
                "created_at": "1729714747",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1729714951",
                "changes": [
                    {
                        "id": "1f7c6d00-2c8d-4801-b4de-5cb98b666698",
                        "created_at": "1729714747",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729714951",
                        "staggers": [
                            {
                                "created_at": "1729714749",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1729714951"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3a8f4ed761a7cdbd2dc2bf14471629b52e954336",
                "commit_title": "removed-del ",
                "created_at": "1714992919",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714993124",
                "changes": [
                    {
                        "id": "56c1fdb1-3af8-4152-9e41-5b1c8db583c3",
                        "created_at": "1714992919",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeSCPTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714993124",
                        "staggers": [
                            {
                                "created_at": "1714992921",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1714993124"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "68be1ca0a48c377141c61acd1fa64400c0f1cebf",
                "commit_title": "Update CommonIAM ",
                "created_at": "1712922930",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1712923340",
                "changes": [
                    {
                        "id": "df2e0638-621e-444a-87c3-c02e6c7501b9",
                        "created_at": "1712922930",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1712923340",
                        "staggers": [
                            {
                                "created_at": "1712922937",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1712923340"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "9d8a7effb9fb9fc23a7ffdda6ddd65988c22c8fa",
                "commit_title": "scopeOnboardingTest stackset ",
                "created_at": "1711024656",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1711097921",
                "changes": [
                    {
                        "id": "b96d9cf7-7bf5-445f-b0b6-9b0c1dd0c7a1",
                        "created_at": "1711024667",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711025076",
                        "staggers": [
                            {
                                "created_at": "1711024669",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1711025076"
                            }
                        ]
                    },
                    {
                        "id": "8d285702-7ce9-4a6e-be83-8d698b0ae2ac",
                        "created_at": "1711024658",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711025075",
                        "staggers": [
                            {
                                "created_at": "1711024665",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1711025075"
                            }
                        ]
                    },
                    {
                        "id": "9170bf61-1dc7-41e5-9087-1b4b15da56a1",
                        "created_at": "1711024656",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711025076",
                        "staggers": [
                            {
                                "created_at": "1711024658",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1711025076"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "2c01f80a4096ca9517c622b3bbec2167696892c4",
                "commit_title": "add database related actions in deny sensitive action policy ",
                "created_at": "1699659908",
                "deployment_status": "COMPLETED",
                "developer_id": "xtao@salesforce.com",
                "updated_at": "1699660321",
                "changes": [
                    {
                        "id": "03fd1166-a5d7-4010-8a14-efe334944746",
                        "created_at": "1699659914",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1699660320",
                        "staggers": [
                            {
                                "created_at": "1699659916",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1699660320"
                            }
                        ]
                    },
                    {
                        "id": "04b4a309-2247-4069-8847-f9980330db68",
                        "created_at": "1699659912",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1699660321",
                        "staggers": [
                            {
                                "created_at": "1699659914",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1699660321"
                            }
                        ]
                    },
                    {
                        "id": "16cf6e0b-14b6-4c68-946f-27c8ccca42df",
                        "created_at": "1699659908",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1699660320",
                        "staggers": [
                            {
                                "created_at": "1699659912",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1699660320"
                            }
                        ]
                    },
                    {
                        "id": "b495143b-0fa0-4471-9706-6bd68d7fa393",
                        "created_at": "1696629180",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696629387",
                        "staggers": [
                            {
                                "created_at": "1696629185",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696629387"
                            }
                        ]
                    },
                    {
                        "id": "515e0185-4573-4144-9e35-b87f8fc97854",
                        "created_at": "1696629178",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1696629387",
                        "staggers": [
                            {
                                "created_at": "1696629180",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1696629387"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f6654130641a78e7a11dc9376fd41f6a2206eb02",
                "commit_title": "bring back hardcode ",
                "created_at": "1683626909",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1683629626",
                "changes": [
                    {
                        "id": "03ca13b1-a63d-4e46-a583-4cae90683610",
                        "created_at": "1683626937",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1683629626",
                        "staggers": [
                            {
                                "created_at": "1683629639",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683629639"
                            },
                            {
                                "created_at": "1683629424",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683629626"
                            },
                            {
                                "created_at": "1683629209",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683629411"
                            },
                            {
                                "created_at": "1683628994",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683629196"
                            },
                            {
                                "created_at": "1683628780",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683628982"
                            },
                            {
                                "created_at": "1683628565",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683628767"
                            },
                            {
                                "created_at": "1683628350",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683628552"
                            },
                            {
                                "created_at": "1683628134",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683628336"
                            },
                            {
                                "created_at": "1683627919",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683628121"
                            },
                            {
                                "created_at": "1683627701",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683627904"
                            },
                            {
                                "created_at": "1683627483",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683627687"
                            },
                            {
                                "created_at": "1683627379",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683627379"
                            },
                            {
                                "created_at": "1683627163",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683627365"
                            },
                            {
                                "created_at": "1683626944",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683627150"
                            }
                        ]
                    },
                    {
                        "id": "612b51ec-a740-4941-824c-7c33eda27fb0",
                        "created_at": "1683626936",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683627149",
                        "staggers": [
                            {
                                "created_at": "1683626937",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683627149"
                            }
                        ]
                    },
                    {
                        "id": "4e01ed2f-7bbf-4c2f-92ab-1691bad10fc9",
                        "created_at": "1683626934",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683627148",
                        "staggers": [
                            {
                                "created_at": "1683626936",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683627148"
                            }
                        ]
                    },
                    {
                        "id": "aaf77498-83a3-4224-8431-a10e4682db98",
                        "created_at": "1683626932",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683627150",
                        "staggers": [
                            {
                                "created_at": "1683626934",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683627150"
                            }
                        ]
                    },
                    {
                        "id": "07631823-1ed7-43c4-811a-2390bcf51267",
                        "created_at": "1683626926",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683627149",
                        "staggers": [
                            {
                                "created_at": "1683626932",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683627149"
                            }
                        ]
                    },
                    {
                        "id": "cd31cf73-6e40-445e-81de-8a024baf4edf",
                        "created_at": "1683626924",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683627150",
                        "staggers": [
                            {
                                "created_at": "1683626926",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683627150"
                            }
                        ]
                    },
                    {
                        "id": "2c74a77d-0d3d-4e65-a69f-8292070e9b04",
                        "created_at": "1683626922",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683627148",
                        "staggers": [
                            {
                                "created_at": "1683626924",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683627148"
                            }
                        ]
                    },
                    {
                        "id": "8ff39886-ea8c-4980-b887-e3dbd67c5f58",
                        "created_at": "1683626919",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683627149",
                        "staggers": [
                            {
                                "created_at": "1683626922",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683627149"
                            }
                        ]
                    },
                    {
                        "id": "f9efde73-157b-43db-a62a-41700410d08e",
                        "created_at": "1683626909",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683627148",
                        "staggers": [
                            {
                                "created_at": "1683626919",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683627148"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "230d777e80aa127cb95527ed0dfede92184bf00d",
                "commit_title": "@W-16981727 Updating stagger to include appropraite stagger env ",
                "created_at": "1730742249",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730742453",
                "changes": [
                    {
                        "id": "939d3d96-d557-41cf-88e8-71fcfd7a86aa",
                        "created_at": "1730742249",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730742453",
                        "staggers": [
                            {
                                "created_at": "1730742251",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730742453"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4677d4b70f375822831f7541558cdf1678303cb7",
                "commit_title": "Code cleanup after comments ",
                "created_at": "1730306385",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730306590",
                "changes": [
                    {
                        "id": "197e9a49-f63e-4f08-9476-cf2e5db18e2f",
                        "created_at": "1730306385",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730306590",
                        "staggers": [
                            {
                                "created_at": "1730306387",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730306590"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4debac8590a0c057e8f9d5aab6529395c22a0f1c",
                "commit_title": "@W-12969622 Adding MDE permissions to standard PCSK roles ",
                "created_at": "1709207540",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1709300067",
                "changes": [
                    {
                        "id": "a04ede89-3715-4f29-ab21-c5e430529e2e",
                        "created_at": "1709207546",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709207955",
                        "staggers": [
                            {
                                "created_at": "1709207551",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709207955"
                            }
                        ]
                    },
                    {
                        "id": "d35a1bc2-161d-4653-8717-c92a7c390640",
                        "created_at": "1709207544",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709207754",
                        "staggers": [
                            {
                                "created_at": "1709207546",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709207754"
                            }
                        ]
                    },
                    {
                        "id": "7eff3b51-71ca-468d-9bd9-a3246e0df8ec",
                        "created_at": "1709207542",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1709300067",
                        "staggers": [
                            {
                                "created_at": "1709207544",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1709207956"
                            }
                        ]
                    },
                    {
                        "id": "e33f52cb-7f70-4567-bb54-da4c226effd0",
                        "created_at": "1709207540",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709207754",
                        "staggers": [
                            {
                                "created_at": "1709207542",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709207754"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "ee71f25d7ed70a1ed826053b5cff71efa09f75e4",
                "commit_title": "added-service-managed-stacksets ",
                "created_at": "1698920980",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1698922629",
                "changes": [
                    {
                        "id": "5c2cba94-fc8f-4379-b49a-494409d6f934",
                        "created_at": "1698920982",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeDemoStackset",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698921210",
                        "staggers": [
                            {
                                "created_at": "1698920983",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698921206"
                            }
                        ]
                    },
                    {
                        "id": "92edeafb-d3d1-4a66-951d-369b13cb9139",
                        "created_at": "1698920980",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeServiceManagedDemo",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698922627",
                        "staggers": [
                            {
                                "created_at": "1698920982",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "stage-mpa",
                                "updated_at": "1698922623"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-c2843a50-732a-11ee-860f-acde48001122",
                "commit_title": "test commit - create scopeTestThird stackset and change group config and template for scopeTestFirst,scopeTestSecond stacksets",
                "created_at": "1698233810",
                "deployment_status": "REVERTED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698234010",
                "changes": [
                    {
                        "id": "a93c9ae2-4774-4e8a-9910-9d65fc524cb2",
                        "created_at": "1698233819",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234010",
                        "staggers": [
                            {
                                "created_at": "1698233819",
                                "operation_status": "REVERTED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698234010"
                            }
                        ]
                    },
                    {
                        "id": "7a8e353e-5bce-4f80-a561-9799ea3b3b98",
                        "created_at": "1698233816",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234010",
                        "staggers": [
                            {
                                "created_at": "1698233819",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "038731646608/ou-0hcj-8jl33k6i ou_test_2",
                                "updated_at": "1698234010"
                            }
                        ]
                    },
                    {
                        "id": "cdb704a2-d0d1-4d9d-bf80-9b4db919f323",
                        "created_at": "1698233814",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234010",
                        "staggers": [
                            {
                                "created_at": "1698233888",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "non onboarded account id",
                                "updated_at": "1698234010"
                            },
                            {
                                "created_at": "1698233816",
                                "operation_status": "REVERTED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "038731646608/ou-0hcj-14a4cemi ou_test_1",
                                "updated_at": "1698234010"
                            }
                        ]
                    },
                    {
                        "id": "2e63d279-f0b0-4b46-abff-6c07651e99de",
                        "created_at": "1698233813",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234010",
                        "staggers": [
                            {
                                "created_at": "1698233814",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698234010"
                            }
                        ]
                    },
                    {
                        "id": "6cc95e31-f74b-43ff-9a3d-61e24c18bf54",
                        "created_at": "1698233812",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234010",
                        "staggers": [
                            {
                                "created_at": "1698233813",
                                "operation_status": "REVERTED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698234010"
                            }
                        ]
                    },
                    {
                        "id": "fee84aac-985a-4acb-a136-d36b3e66756e",
                        "created_at": "1698233811",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234010",
                        "staggers": [
                            {
                                "created_at": "1698233812",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698234010"
                            }
                        ]
                    },
                    {
                        "id": "d9a1a628-2fdb-4a6d-b260-bf68bd505eed",
                        "created_at": "1698233810",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "REVERTED",
                        "updated_at": "1698234010",
                        "staggers": [
                            {
                                "created_at": "1698233811",
                                "operation_status": "REVERTED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698234010"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "cb03e668b23571afe7673aab5b59f16078e4a9cb",
                "commit_title": "testing ",
                "created_at": "1731415690",
                "deployment_status": "COMPLETED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731416097",
                "changes": [
                    {
                        "id": "a47e6704-2364-40ad-8e20-72a2dc275488",
                        "created_at": "1731415690",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731416097",
                        "staggers": [
                            {
                                "created_at": "1731415694",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731416097"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "56e507361d884f0adda7c69eba8c1d43e03567c9",
                "commit_title": "test ",
                "created_at": "1728482923",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1728483330",
                "changes": [
                    {
                        "id": "62c58ee9-a5e2-4ab8-9d5b-5dcc1e1d026b",
                        "created_at": "1728482923",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728483330",
                        "staggers": [
                            {
                                "created_at": "1728482928",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728483330"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "e5ea1eaf6c47db484cfe49fa1a24f59802b337c8",
                "commit_title": "updated-rule-target ",
                "created_at": "1709726840",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1709727047",
                "changes": [
                    {
                        "id": "fabbc679-c742-4ab0-bff4-ec110161b72c",
                        "created_at": "1709726842",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeOUMovementTracker",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709727047",
                        "staggers": [
                            {
                                "created_at": "1709726844",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1709727047"
                            }
                        ]
                    },
                    {
                        "id": "a1af7739-9068-4820-88f5-c23cf988820d",
                        "created_at": "1709726840",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1709727047",
                        "staggers": [
                            {
                                "created_at": "1709726842",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1709727047"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-a33463a0-7325-11ee-bcb0-acde48001122",
                "commit_title": "test commit - go back to reverted PR - create 1 stackset and change group config for other 2 stacksets",
                "created_at": "1698231611",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698231647",
                "changes": [
                    {
                        "id": "1f2d3c2f-e26d-494c-8d47-2334a850c66c",
                        "created_at": "1698231615",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698231647",
                        "staggers": [
                            {
                                "created_at": "1698231616",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698231647"
                            }
                        ]
                    },
                    {
                        "id": "d4f27d60-470b-497d-a9a3-da8cbeae307f",
                        "created_at": "1698231614",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698231647",
                        "staggers": [
                            {
                                "created_at": "1698231615",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698231647"
                            }
                        ]
                    },
                    {
                        "id": "b647de4f-bbd2-42d5-b56a-08b91c6b27f9",
                        "created_at": "1698231613",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698231646",
                        "staggers": [
                            {
                                "created_at": "1698231614",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698231646"
                            }
                        ]
                    },
                    {
                        "id": "adc592dd-2ac3-400f-86df-74dfdd2bf7a2",
                        "created_at": "1698231612",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698231647",
                        "staggers": [
                            {
                                "created_at": "1698231613",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698231647"
                            }
                        ]
                    },
                    {
                        "id": "3021175b-7bcf-449f-9235-093021de06ca",
                        "created_at": "1698231611",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698231647",
                        "staggers": [
                            {
                                "created_at": "1698231612",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698231647"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d676105895e11103eba62a7d3dbaab41ac157807",
                "commit_title": "deployment ",
                "created_at": "1692201920",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1692202149",
                "changes": [
                    {
                        "id": "0a9b8789-b8d7-4a99-b584-b5ad85108e2b",
                        "created_at": "1692201940",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692202147",
                        "staggers": [
                            {
                                "created_at": "1692201942",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692202147"
                            }
                        ]
                    },
                    {
                        "id": "3daeff96-6c17-4706-8d68-87fee7c28f4b",
                        "created_at": "1692201938",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692202147",
                        "staggers": [
                            {
                                "created_at": "1692201940",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692202147"
                            }
                        ]
                    },
                    {
                        "id": "fd588b4b-fa47-425a-abc2-acae509a33eb",
                        "created_at": "1692201935",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692202146",
                        "staggers": [
                            {
                                "created_at": "1692201938",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692202146"
                            }
                        ]
                    },
                    {
                        "id": "81083b19-bb75-4961-b090-e084af914543",
                        "created_at": "1692201933",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692202148",
                        "staggers": [
                            {
                                "created_at": "1692201935",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692202148"
                            }
                        ]
                    },
                    {
                        "id": "ea7c13aa-fc5f-4dcd-be8e-8d1fec3c9119",
                        "created_at": "1692201931",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692202148",
                        "staggers": [
                            {
                                "created_at": "1692201933",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692202148"
                            }
                        ]
                    },
                    {
                        "id": "d3e9e8b2-0937-497c-9e1f-413a09e58136",
                        "created_at": "1692201929",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692202149",
                        "staggers": [
                            {
                                "created_at": "1692201931",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692202149"
                            }
                        ]
                    },
                    {
                        "id": "285a2fe8-86e9-432f-af02-dc36c34c3e36",
                        "created_at": "1692201927",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692202148",
                        "staggers": [
                            {
                                "created_at": "1692201929",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692202148"
                            }
                        ]
                    },
                    {
                        "id": "89d0d995-b1b9-46b2-a8c9-9d251c9e70a6",
                        "created_at": "1692201925",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692202147",
                        "staggers": [
                            {
                                "created_at": "1692201927",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692202147"
                            }
                        ]
                    },
                    {
                        "id": "76cdd52c-7548-4810-883f-2b574f2860c9",
                        "created_at": "1692201921",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692202148",
                        "staggers": [
                            {
                                "created_at": "1692201925",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692202148"
                            }
                        ]
                    },
                    {
                        "id": "ccf4295e-eaf8-4fa7-82ad-6316f0459b07",
                        "created_at": "1692201920",
                        "operation_type": "",
                        "stackset_id": "dummyTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692202149",
                        "staggers": [
                            {
                                "created_at": "1692201921",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "two dev accounts change",
                                "updated_at": "1692202149"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "36c18d57f27280d1022ea8387e8e5a472b244189",
                "commit_title": "removing resources for cleanup ",
                "created_at": "1729528024",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1729530783",
                "changes": [
                    {
                        "id": "0249232e-44f8-49ea-917a-97b3ea2c651c",
                        "created_at": "1729528038",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729530783",
                        "staggers": [
                            {
                                "created_at": "1729530581",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729530783"
                            },
                            {
                                "created_at": "1729528039",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1729528444"
                            }
                        ]
                    },
                    {
                        "id": "685d8516-a560-4771-b049-5a9c55ff80d4",
                        "created_at": "1729528036",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729528444",
                        "staggers": [
                            {
                                "created_at": "1729528038",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729528444"
                            }
                        ]
                    },
                    {
                        "id": "16c5f6c3-e5f4-475d-81da-33552170fd78",
                        "created_at": "1729528035",
                        "operation_type": "UPDATE",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729528445",
                        "staggers": [
                            {
                                "created_at": "1729528036",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729528445"
                            }
                        ]
                    },
                    {
                        "id": "2b784a6a-7526-42d9-99b2-70512a192926",
                        "created_at": "1729528030",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729528444",
                        "staggers": [
                            {
                                "created_at": "1729528035",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729528444"
                            }
                        ]
                    },
                    {
                        "id": "60fc1ab5-82ad-4d1d-9df0-2e744330aa4d",
                        "created_at": "1729528029",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729528445",
                        "staggers": [
                            {
                                "created_at": "1729528030",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729528445"
                            }
                        ]
                    },
                    {
                        "id": "59cb262c-6ce9-43b4-b1ca-c2c90944078b",
                        "created_at": "1729528026",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729528242",
                        "staggers": [
                            {
                                "created_at": "1729528029",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729528242"
                            }
                        ]
                    },
                    {
                        "id": "c9be41d5-47e7-4874-b390-9806b0f834ea",
                        "created_at": "1729528024",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729530783",
                        "staggers": [
                            {
                                "created_at": "1729530580",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729530783"
                            },
                            {
                                "created_at": "1729528026",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1729528443"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "61c6b754a93dc9d8995af16f081f80812a0627dc",
                "commit_title": "Old way for account mapping ",
                "created_at": "1727973773",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727973977",
                "changes": [
                    {
                        "id": "98401e27-01cd-49ae-8190-36b6912a98d8",
                        "created_at": "1727973773",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727973977",
                        "staggers": [
                            {
                                "created_at": "1727973775",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1727973977"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "12352c389d25dbc0b06d7a7ebb54169470428024",
                "commit_title": "- Configs and template ",
                "created_at": "1727940365",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1727940975",
                "changes": [
                    {
                        "id": "84825437-f155-453c-b203-36effc04175f",
                        "created_at": "1727940365",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTentacleRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727940975",
                        "staggers": [
                            {
                                "created_at": "1727940367",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1727940975"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "63f69a52cff2b6b46803e3f7488645b1c79e8d9e",
                "commit_title": "Update code ",
                "created_at": "1717508076",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1717508281",
                "changes": [
                    {
                        "id": "d6d378a2-8144-4361-8c48-86c16f6d0c4e",
                        "created_at": "1717508077",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717508281",
                        "staggers": [
                            {
                                "created_at": "1717508079",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717508281"
                            }
                        ]
                    },
                    {
                        "id": "c8f57d29-607c-41d9-8af5-19f4c6698e65",
                        "created_at": "1717508076",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717508281",
                        "staggers": [
                            {
                                "created_at": "1717508077",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1717508281"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-c5834d96-74af-11ee-911d-acde48001122",
                "commit_title": "test commit - remove all stacksets from grouping, and mark as delete in payload",
                "created_at": "1698400890",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698401098",
                "changes": [
                    {
                        "id": "c7be62da-cb8a-4a9a-924c-599e6b83870e",
                        "created_at": "1698400892",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698401097",
                        "staggers": [
                            {
                                "created_at": "1698400894",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698401097"
                            }
                        ]
                    },
                    {
                        "id": "75c22df6-f9f0-49a8-b955-393004f4c003",
                        "created_at": "1698400890",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698401098",
                        "staggers": [
                            {
                                "created_at": "1698400892",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698401097"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-2cf74ac0-7188-11ee-a13b-acde48001122",
                "commit_title": "test commit - create scopeTestThird stackset and change group config and template for scopeTestFirst,scopeTestSecond stacksets",
                "created_at": "1698054043",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698054177",
                "changes": [
                    {
                        "id": "16b217ee-98dc-483f-9d3b-7274d018f33d",
                        "created_at": "1698054048",
                        "operation_type": "",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698054111",
                        "staggers": [
                            {
                                "created_at": "1698054049",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698054111"
                            }
                        ]
                    },
                    {
                        "id": "ed20f832-8d6c-46e6-8b81-45e954828f3a",
                        "created_at": "1698054044",
                        "operation_type": "",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698054111",
                        "staggers": [
                            {
                                "created_at": "1698054048",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "one account id",
                                "updated_at": "1698054111"
                            },
                            {
                                "created_at": "1698054045",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698054111"
                            }
                        ]
                    },
                    {
                        "id": "d3978579-00e8-4d4a-9704-d4df939c3061",
                        "created_at": "1698054043",
                        "operation_type": "",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698054177",
                        "staggers": [
                            {
                                "created_at": "1698054115",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "second stagger group",
                                "updated_at": "1698054177"
                            },
                            {
                                "created_at": "1698054046",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698054110"
                            },
                            {
                                "created_at": "1698054044",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698054111"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "0b2182cf18e7629fa0a9f463b39f2c3b9be009a1",
                "commit_title": "updated-eksAccessRole ",
                "created_at": "1693206301",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1693206528",
                "changes": [
                    {
                        "id": "86b825e2-6e82-4529-882e-c6ec98e479eb",
                        "created_at": "1693206321",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693206527",
                        "staggers": [
                            {
                                "created_at": "1693206322",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693206527"
                            }
                        ]
                    },
                    {
                        "id": "0077e5b1-285e-446f-b215-e0908bbe565e",
                        "created_at": "1693206319",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693206527",
                        "staggers": [
                            {
                                "created_at": "1693206320",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693206527"
                            }
                        ]
                    },
                    {
                        "id": "b03e173f-405c-4c37-b6c3-db97f29a96b2",
                        "created_at": "1693206315",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693206526",
                        "staggers": [
                            {
                                "created_at": "1693206319",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693206526"
                            }
                        ]
                    },
                    {
                        "id": "8212ab0a-9c09-45c4-8d7c-dd3a842e3c6f",
                        "created_at": "1693206313",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693206528",
                        "staggers": [
                            {
                                "created_at": "1693206315",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693206528"
                            }
                        ]
                    },
                    {
                        "id": "4bd68677-5435-4900-a5ae-b92ac90f6056",
                        "created_at": "1693206311",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693206527",
                        "staggers": [
                            {
                                "created_at": "1693206313",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693206527"
                            }
                        ]
                    },
                    {
                        "id": "b7901450-a6d3-4ae3-b7d1-a7afd2ee0f14",
                        "created_at": "1693206309",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693206528",
                        "staggers": [
                            {
                                "created_at": "1693206311",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693206528"
                            }
                        ]
                    },
                    {
                        "id": "bc0b417e-8e9b-4f94-8d59-0fc462f332ee",
                        "created_at": "1693206306",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693206528",
                        "staggers": [
                            {
                                "created_at": "1693206309",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693206528"
                            }
                        ]
                    },
                    {
                        "id": "f7d35860-bd61-4a57-ac1e-8d1f2c6e65bb",
                        "created_at": "1693206305",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693206526",
                        "staggers": [
                            {
                                "created_at": "1693206306",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693206526"
                            }
                        ]
                    },
                    {
                        "id": "ec1a2e05-61bc-4d4b-8c7b-a969a7674de0",
                        "created_at": "1693206301",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1693206528",
                        "staggers": [
                            {
                                "created_at": "1693206305",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1693206528"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "34ef05e20b19ab8b5c5c17ba0bdbb321e22b0965",
                "commit_title": "Updating syntax for principal ",
                "created_at": "1730210103",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730210306",
                "changes": [
                    {
                        "id": "087e493e-1ad2-46a8-adc2-e25653c941c8",
                        "created_at": "1730210103",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730210306",
                        "staggers": [
                            {
                                "created_at": "1730210104",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730210306"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "9d8a7effb9fb9fc23a7ffdda6ddd65988c22c8fa_905418466263_e5d984cb-e829-11ee-925b-0642777e43b2",
                "commit_title": "scopeOnboardingTest stackset ",
                "created_at": "1711097718",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1711097718",
                "changes": [
                    {
                        "id": "36f44e37-d100-4ccd-a614-6036d59a177f",
                        "created_at": "1711097718",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeOnboardingTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711097921",
                        "staggers": [
                            {
                                "created_at": "1711097718",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1711097921"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "31171ed541c46d111fa8d08a9e7c14b13c8db321",
                "commit_title": "role simplification ",
                "created_at": "1700641335",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1700641541",
                "changes": [
                    {
                        "id": "4ce07d7f-e9bb-4fcf-950f-30840ae5e5d3",
                        "created_at": "1700641335",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1700641541",
                        "staggers": [
                            {
                                "created_at": "1700641337",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1700641541"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "8fd39acaad8211a854d564b5a76e28e6b0721508",
                "commit_title": "Dummy commit to trigger -2 ",
                "created_at": "1731702246",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1731702452",
                "changes": [
                    {
                        "id": "148f3671-e13d-45a8-85af-309dc39baf6c",
                        "created_at": "1731702246",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731702452",
                        "staggers": [
                            {
                                "created_at": "1731702250",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731702452"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "55c04f0c1ad24a51b03f70575c4c07b7b71ff4a7",
                "commit_title": "W-14161241 deploy wiz role to group 2 (#106) ",
                "created_at": "1705564806",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1705565011",
                "changes": [
                    {
                        "id": "637ed425-58fc-4e31-9cc8-ebbcff96be81",
                        "created_at": "1705564806",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705565011",
                        "staggers": [
                            {
                                "created_at": "1705564809",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705565011"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "8f172fea15e7397df7f456bb869e88469ad96106",
                "commit_title": "@W-13768736 | Permission Assessment and Remediation for CSIRT and STAR RO PCSK Roles ",
                "created_at": "1691578299",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1691578526",
                "changes": [
                    {
                        "id": "908b651c-8862-4a4c-abe0-434e5be73243",
                        "created_at": "1691578317",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691578525",
                        "staggers": [
                            {
                                "created_at": "1691578319",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691578525"
                            }
                        ]
                    },
                    {
                        "id": "fcaabaef-6966-483f-b471-d3fb8fb87d8f",
                        "created_at": "1691578315",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691578524",
                        "staggers": [
                            {
                                "created_at": "1691578317",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691578524"
                            }
                        ]
                    },
                    {
                        "id": "b2109bfa-15e6-45d2-9b6c-8a9cb6275e05",
                        "created_at": "1691578312",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691578526",
                        "staggers": [
                            {
                                "created_at": "1691578315",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691578526"
                            }
                        ]
                    },
                    {
                        "id": "027f014c-0cd7-466e-bb61-694f7e5fc8fc",
                        "created_at": "1691578310",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691578525",
                        "staggers": [
                            {
                                "created_at": "1691578312",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691578525"
                            }
                        ]
                    },
                    {
                        "id": "dbd3246e-9d37-43ad-a583-74b1561ff608",
                        "created_at": "1691578308",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691578526",
                        "staggers": [
                            {
                                "created_at": "1691578310",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691578526"
                            }
                        ]
                    },
                    {
                        "id": "41525be7-81dc-4ba1-9c29-c17fcd7a688d",
                        "created_at": "1691578307",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691578523",
                        "staggers": [
                            {
                                "created_at": "1691578308",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691578523"
                            }
                        ]
                    },
                    {
                        "id": "716fd13d-a945-4ee0-aacf-1844d1d1d148",
                        "created_at": "1691578305",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691578524",
                        "staggers": [
                            {
                                "created_at": "1691578307",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691578524"
                            }
                        ]
                    },
                    {
                        "id": "c48bd13d-4a16-4fe8-9335-72775d2e9382",
                        "created_at": "1691578303",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691578524",
                        "staggers": [
                            {
                                "created_at": "1691578305",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691578524"
                            }
                        ]
                    },
                    {
                        "id": "2654312a-195c-43dd-83c6-e5549260f194",
                        "created_at": "1691578299",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691578525",
                        "staggers": [
                            {
                                "created_at": "1691578303",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691578525"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "0524390d5421c73e0af83692eaff2e743a6d65d5",
                "commit_title": "@W-17130246 testing prod flow in stage ",
                "created_at": "1731541707",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1731541913",
                "changes": [
                    {
                        "id": "da028189-f4c1-4bba-87e3-a384a0427a72",
                        "created_at": "1731541707",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731541913",
                        "staggers": [
                            {
                                "created_at": "1731541711",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731541913"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b202696aa02112e43319ba47e4c5d3e7cd932a60",
                "commit_title": "template syntax fix ",
                "created_at": "1724160284",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1724163313",
                "changes": [
                    {
                        "id": "217a62cc-6d70-4814-8de8-537435a990f5",
                        "created_at": "1724160284",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSensorsControlTelemetryRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1724163313",
                        "staggers": [
                            {
                                "created_at": "1724160286",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1724163313"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "87ad11d06f0136b69d7255ebd27c4e1712ab0f08",
                "commit_title": "added-param ",
                "created_at": "1715857770",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715857770",
                "changes": [
                    {
                        "id": "f09cd9ce-7858-40de-95a1-48012a039f2e",
                        "created_at": "1715857770",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715858084",
                        "staggers": [
                            {
                                "created_at": "1715857776",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715858084"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d6dcffc17b1b7a42a2e81d0a28ab4faa71d80e96",
                "commit_title": "fixed-paramater ",
                "created_at": "1715261972",
                "deployment_status": "FAILED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715262185",
                "changes": [
                    {
                        "id": "8e7adf1c-2ece-409f-a6ad-199bdc75e8ec",
                        "created_at": "1715261972",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "FAILED",
                        "updated_at": "1715262185",
                        "staggers": [
                            {
                                "created_at": "1715261982",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715262185"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "b2de77c0e128610bcd2227975066f2a17faafb2e",
                "commit_title": "testing-serviceManaged-stackset ",
                "created_at": "1697104163",
                "deployment_status": "FAILED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1697104163",
                "changes": null,
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "60a6649850f792d3863ac4720519158c7d79606d",
                "commit_title": "Testing nf stackset ",
                "created_at": "1685092833",
                "deployment_status": "FAILED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1685093262",
                "changes": [
                    {
                        "id": "f271962d-88a7-413d-ab60-691dd950b0cc",
                        "created_at": "1685092853",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685093260",
                        "staggers": [
                            {
                                "created_at": "1685092855",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685093260"
                            }
                        ]
                    },
                    {
                        "id": "b491e928-5342-4e0c-a28a-88e3c0303d84",
                        "created_at": "1685092851",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685093260",
                        "staggers": [
                            {
                                "created_at": "1685092853",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685093260"
                            }
                        ]
                    },
                    {
                        "id": "d6054f03-b90b-4c22-b88b-a50188af88f5",
                        "created_at": "1685092848",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685093259",
                        "staggers": [
                            {
                                "created_at": "1685093053",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685093259"
                            },
                            {
                                "created_at": "1685092851",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage_group_1",
                                "updated_at": "1685092851"
                            }
                        ]
                    },
                    {
                        "id": "938a3a6e-c73d-4dd3-bb68-8906132fd184",
                        "created_at": "1685092846",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685093259",
                        "staggers": [
                            {
                                "created_at": "1685092848",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685093259"
                            }
                        ]
                    },
                    {
                        "id": "f50d1da8-3a05-4775-9e0e-e5ae2041f3b6",
                        "created_at": "1685092844",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685093260",
                        "staggers": [
                            {
                                "created_at": "1685093055",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685093259"
                            },
                            {
                                "created_at": "1685092846",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage_group_1",
                                "updated_at": "1685092846"
                            }
                        ]
                    },
                    {
                        "id": "251f8728-9bc3-4e74-aab3-0e122064f786",
                        "created_at": "1685092841",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685093258",
                        "staggers": [
                            {
                                "created_at": "1685093052",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685093258"
                            },
                            {
                                "created_at": "1685092844",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage_group_1",
                                "updated_at": "1685092844"
                            }
                        ]
                    },
                    {
                        "id": "ba65194e-f1b8-4898-a435-b7ea189ccd26",
                        "created_at": "1685092838",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685093259",
                        "staggers": [
                            {
                                "created_at": "1685092841",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685093259"
                            }
                        ]
                    },
                    {
                        "id": "9ab5bb52-1206-4f81-b309-255525da5373",
                        "created_at": "1685092835",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1685093262",
                        "staggers": [
                            {
                                "created_at": "1685092838",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685093262"
                            }
                        ]
                    },
                    {
                        "id": "45fdbdab-9e53-406d-9754-9eab7158b80b",
                        "created_at": "1685092833",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1685093260",
                        "staggers": [
                            {
                                "created_at": "1685093056",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1685093260"
                            },
                            {
                                "created_at": "1685092835",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage_group_1",
                                "updated_at": "1685092835"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "222625b524690031674b9ef741f4449640cbf9d8",
                "commit_title": "testing ",
                "created_at": "1731521406",
                "deployment_status": "FAILED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731521611",
                "changes": [
                    {
                        "id": "f91facb0-a4cb-4c08-b8b6-1e8d1b34fc03",
                        "created_at": "1731521406",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1731521611",
                        "staggers": [
                            {
                                "created_at": "1731521410",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731521611"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "80ef7eabf18255751eb6a1db1e97fca910e38d1b",
                "commit_title": "Updating file comments and README ",
                "created_at": "1729704693",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1729704896",
                "changes": [
                    {
                        "id": "eae725fc-b3c0-42b8-ba73-ab2cbce948e4",
                        "created_at": "1729704693",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729704896",
                        "staggers": [
                            {
                                "created_at": "1729704694",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1729704896"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a1de97a90c7b3510b7b001b2d91bd63a222fa2a4",
                "commit_title": "testing MRT ",
                "created_at": "1717667617",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1717668460",
                "changes": [
                    {
                        "id": "61f6780b-8143-4b54-bdd3-42a94dbe4066",
                        "created_at": "1717667617",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717668460",
                        "staggers": [
                            {
                                "created_at": "1717668259",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1717668460"
                            },
                            {
                                "created_at": "1717667619",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1717667821"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "c345c3738a36529dc2d56c47b43aadbbbfab4fd2",
                "commit_title": "Merge pull request #134 from identity-access/MDE_Permissions ",
                "created_at": "1711024361",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1711024568",
                "changes": [
                    {
                        "id": "17fa3d64-2ee0-4ed8-8725-da0f849ae5a8",
                        "created_at": "1711024363",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeOUMovementTracker",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711024568",
                        "staggers": [
                            {
                                "created_at": "1711024365",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1711024568"
                            }
                        ]
                    },
                    {
                        "id": "f9de2fcd-8b01-4e38-8cf2-5a0bdb19f2a0",
                        "created_at": "1711024361",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711024568",
                        "staggers": [
                            {
                                "created_at": "1711024363",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1711024568"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "5a04e854874d88566fc91a6240f535620f8e2c30",
                "commit_title": "Revert ME ",
                "created_at": "1683703359",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1683703628",
                "changes": [
                    {
                        "id": "62cd1064-f3a0-45f1-8bb8-1c801967f1ed",
                        "created_at": "1683703421",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683703628",
                        "staggers": [
                            {
                                "created_at": "1683703423",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683703628"
                            }
                        ]
                    },
                    {
                        "id": "c9dbfd67-b411-4369-a8b6-f04d6787c546",
                        "created_at": "1683703414",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683703627",
                        "staggers": [
                            {
                                "created_at": "1683703421",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683703627"
                            }
                        ]
                    },
                    {
                        "id": "1b74d516-2db3-48d5-992c-f7dca4453a36",
                        "created_at": "1683703409",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1683703628",
                        "staggers": [
                            {
                                "created_at": "1683703644",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683703644"
                            },
                            {
                                "created_at": "1683703414",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683703628"
                            }
                        ]
                    },
                    {
                        "id": "68dcdee6-2ef9-4e96-8a69-53caaf92afa3",
                        "created_at": "1683703407",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683703628",
                        "staggers": [
                            {
                                "created_at": "1683703409",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683703628"
                            }
                        ]
                    },
                    {
                        "id": "5e85a6d6-dc0e-494f-bc38-7d5043ba2bb1",
                        "created_at": "1683703405",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683703629",
                        "staggers": [
                            {
                                "created_at": "1683703407",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683703629"
                            }
                        ]
                    },
                    {
                        "id": "268df05f-3bb6-4305-850a-df6ad2170107",
                        "created_at": "1683703403",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683703628",
                        "staggers": [
                            {
                                "created_at": "1683703405",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683703628"
                            }
                        ]
                    },
                    {
                        "id": "ddce9afa-b6e5-4502-952a-1a9a081b87f7",
                        "created_at": "1683703401",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683703629",
                        "staggers": [
                            {
                                "created_at": "1683703403",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683703629"
                            }
                        ]
                    },
                    {
                        "id": "95e17ec6-b87a-45aa-8323-8fdc7c36c896",
                        "created_at": "1683703399",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683703629",
                        "staggers": [
                            {
                                "created_at": "1683703401",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683703629"
                            }
                        ]
                    },
                    {
                        "id": "614f753b-bf47-41cc-90f5-6b2508f68b3d",
                        "created_at": "1683703359",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683703629",
                        "staggers": [
                            {
                                "created_at": "1683703399",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683703629"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "f4ef3cd99f68bfe016f5c9a3e437d9b7baf286f8",
                "commit_title": "updating path to custom admin ",
                "created_at": "1731078651",
                "deployment_status": "FAILED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1731078855",
                "changes": [
                    {
                        "id": "5e029733-1451-4092-8bd5-1ce02c7b4b52",
                        "created_at": "1731078651",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "FAILED",
                        "updated_at": "1731078855",
                        "staggers": [
                            {
                                "created_at": "1731078653",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1731078855"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "48f1f404559c0213cbc63b37108e77c85d607ac2",
                "commit_title": "Added permissions and role for PCSKSCPV2Role ",
                "created_at": "1702938369",
                "deployment_status": "COMPLETED",
                "developer_id": "svaradarajan@salesforce.com",
                "updated_at": "1702938405",
                "changes": [
                    {
                        "id": "c5743a0f-9ea8-4827-8007-837dfcdd8d0e",
                        "created_at": "1702938369",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1702938405",
                        "staggers": [
                            {
                                "created_at": "1702938371",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1702938405"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "2818a3912d3df4ffa2ee226ab13e2f7f1f4b86b2",
                "commit_title": "trying to create test custom role ",
                "created_at": "1727985629",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727985842",
                "changes": [
                    {
                        "id": "471eab7a-e490-48f2-a190-543b471d2861",
                        "created_at": "1727985637",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727985842",
                        "staggers": [
                            {
                                "created_at": "1727985639",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1727985841"
                            }
                        ]
                    },
                    {
                        "id": "57255b33-e0fa-4cf0-a7ac-208df26f5c55",
                        "created_at": "1727985631",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727985842",
                        "staggers": [
                            {
                                "created_at": "1727985637",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1727985842"
                            }
                        ]
                    },
                    {
                        "id": "44194f49-1e6b-4ab5-8db0-a290b6490ce2",
                        "created_at": "1727985629",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727985841",
                        "staggers": [
                            {
                                "created_at": "1727985631",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1727985841"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "7273385a3d2421db72cd35717147e9c68e95b248",
                "commit_title": "@W-16822763 Adding additional permissions to ODCROperationsAccessPoli… (#201) ",
                "created_at": "1727814285",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727814496",
                "changes": [
                    {
                        "id": "64f63b5e-1322-4409-abb5-26d25c13812b",
                        "created_at": "1727814289",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727814495",
                        "staggers": [
                            {
                                "created_at": "1727814291",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1727814495"
                            }
                        ]
                    },
                    {
                        "id": "b977816e-4fe2-4902-a8b8-50f67a0ec839",
                        "created_at": "1727814285",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727814496",
                        "staggers": [
                            {
                                "created_at": "1727814289",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1727814496"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "0f024f6cd406ae3d41149bee835c094372de8f58",
                "commit_title": "dummy-change-template ",
                "created_at": "1716359982",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1716360389",
                "changes": [
                    {
                        "id": "b7292587-4f87-4849-8510-4ce26401e5d7",
                        "created_at": "1716359982",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSAIRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716360389",
                        "staggers": [
                            {
                                "created_at": "1716359984",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1716360389"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "a63445e6ae7af1764099b6f524848cb8370ca3cc",
                "commit_title": "removed from gia2h files ",
                "created_at": "1705417541",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1705417761",
                "changes": [
                    {
                        "id": "267d0a7a-23af-4c73-94e5-e8753f24b880",
                        "created_at": "1705417553",
                        "operation_type": "UPDATE",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705417760",
                        "staggers": [
                            {
                                "created_at": "1705417555",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705417760"
                            }
                        ]
                    },
                    {
                        "id": "4f7f1670-dd1f-436f-b954-53549dca331d",
                        "created_at": "1705417550",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705417760",
                        "staggers": [
                            {
                                "created_at": "1705417553",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705417760"
                            }
                        ]
                    },
                    {
                        "id": "6a449def-2006-455b-909a-e2389a935d32",
                        "created_at": "1705417545",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705417760",
                        "staggers": [
                            {
                                "created_at": "1705417550",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705417760"
                            }
                        ]
                    },
                    {
                        "id": "614b7640-aadc-46f5-b307-cc2bc48a4980",
                        "created_at": "1705417543",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705417761",
                        "staggers": [
                            {
                                "created_at": "1705417545",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705417761"
                            }
                        ]
                    },
                    {
                        "id": "0bc8a58a-e42f-46a6-bfc0-9e68f8882243",
                        "created_at": "1705417541",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705417761",
                        "staggers": [
                            {
                                "created_at": "1705417543",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705417761"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6b88573f0ce5dcdbc3bed743707b8070f48dc111",
                "commit_title": "deny changeset start ",
                "created_at": "1703172531",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1703172730",
                "changes": [
                    {
                        "id": "66245f8b-a677-4d8c-92d9-84d32ddca46b",
                        "created_at": "1703172534",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1703172730",
                        "staggers": [
                            {
                                "created_at": "1703172536",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1703172730"
                            }
                        ]
                    },
                    {
                        "id": "c5f2e14e-2afe-46a0-a467-55ddebc01e7a",
                        "created_at": "1703172531",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1703172729",
                        "staggers": [
                            {
                                "created_at": "1703172534",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1703172729"
                            }
                        ]
                    },
                    {
                        "id": "2e61aad1-0ab2-4129-86e7-c08cf36e64ce",
                        "created_at": "1703051070",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1703051240",
                        "staggers": [
                            {
                                "created_at": "1703051075",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1703051240"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-6845b58a-717e-11ee-aefe-acde48001122",
                "commit_title": "test commit - create scopeTestThird stackset and change group config and template for scopeTestFirst,scopeTestSecond stacksets",
                "created_at": "1698049846",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698050259",
                "changes": [
                    {
                        "id": "3b86c063-8206-4341-9325-e890a993056b",
                        "created_at": "1698049849",
                        "operation_type": "",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698050053",
                        "staggers": [
                            {
                                "created_at": "1698049850",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698050053"
                            }
                        ]
                    },
                    {
                        "id": "392c583b-b585-484c-9b26-128062ee42be",
                        "created_at": "1698049848",
                        "operation_type": "",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698050054",
                        "staggers": [
                            {
                                "created_at": "1698049851",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698050054"
                            },
                            {
                                "created_at": "1698049849",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "one account id",
                                "updated_at": "1698050054"
                            }
                        ]
                    },
                    {
                        "id": "ea82c57d-2c93-42fc-8c0e-fa645ede269b",
                        "created_at": "1698049846",
                        "operation_type": "",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698050259",
                        "staggers": [
                            {
                                "created_at": "1698050059",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "second stagger group",
                                "updated_at": "1698050259"
                            },
                            {
                                "created_at": "1698049852",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698050054"
                            },
                            {
                                "created_at": "1698049848",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "first stagger group",
                                "updated_at": "1698050053"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4042fefdbe1d1abc52aff619d0dd9dbc8d391468",
                "commit_title": "v3-test-ou-filter ",
                "created_at": "1695214375",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1695214797",
                "changes": [
                    {
                        "id": "279a79eb-fa67-4859-a8cb-12ebf7dbfddb",
                        "created_at": "1695214375",
                        "operation_type": "",
                        "stackset_id": "ScopeTestv3",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1695214797",
                        "staggers": [
                            {
                                "created_at": "1695214594",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "ou",
                                "updated_at": "1695214797"
                            },
                            {
                                "created_at": "1695214376",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "dev account",
                                "updated_at": "1695214580"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "5914de27817e611a165256fcac87bb0df76c982b",
                "commit_title": "change group name ",
                "created_at": "1692195201",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1692195432",
                "changes": [
                    {
                        "id": "b0ec3d43-efa9-4969-9fd2-9443f5b31dbd",
                        "created_at": "1692195223",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692195430",
                        "staggers": [
                            {
                                "created_at": "1692195225",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692195430"
                            }
                        ]
                    },
                    {
                        "id": "ea6ab95f-57f9-4598-894f-4c8b704128f5",
                        "created_at": "1692195221",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692195431",
                        "staggers": [
                            {
                                "created_at": "1692195223",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692195431"
                            }
                        ]
                    },
                    {
                        "id": "3e2017d6-72d1-4dae-b825-aeebf613e373",
                        "created_at": "1692195219",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692195432",
                        "staggers": [
                            {
                                "created_at": "1692195221",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692195432"
                            }
                        ]
                    },
                    {
                        "id": "c1b5dcc4-f6a2-413c-a42d-7107fa264726",
                        "created_at": "1692195216",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692195432",
                        "staggers": [
                            {
                                "created_at": "1692195219",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692195432"
                            }
                        ]
                    },
                    {
                        "id": "158b9b7b-a500-4f37-ac9f-dff84e9cb745",
                        "created_at": "1692195213",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692195430",
                        "staggers": [
                            {
                                "created_at": "1692195216",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692195430"
                            }
                        ]
                    },
                    {
                        "id": "cd222561-527d-4e98-a594-dc827d568de8",
                        "created_at": "1692195211",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692195430",
                        "staggers": [
                            {
                                "created_at": "1692195213",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692195430"
                            }
                        ]
                    },
                    {
                        "id": "a05605e1-d475-4c5a-aa30-18d02db5a196",
                        "created_at": "1692195209",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692195431",
                        "staggers": [
                            {
                                "created_at": "1692195211",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692195431"
                            }
                        ]
                    },
                    {
                        "id": "24ffcb75-56c5-47c3-b8aa-3f91550481c4",
                        "created_at": "1692195207",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692195431",
                        "staggers": [
                            {
                                "created_at": "1692195209",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692195431"
                            }
                        ]
                    },
                    {
                        "id": "29b91c7f-50e0-4b9e-b5d8-9dfbfbc6ced1",
                        "created_at": "1692195203",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692195429",
                        "staggers": [
                            {
                                "created_at": "1692195207",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1692195429"
                            }
                        ]
                    },
                    {
                        "id": "6dc96cf5-267f-4d98-8d49-c2cf1c33c59c",
                        "created_at": "1692195201",
                        "operation_type": "",
                        "stackset_id": "dummyTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692195431",
                        "staggers": [
                            {
                                "created_at": "1692195203",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "two dev accounts",
                                "updated_at": "1692195431"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "0ecc2ccc1e4cf84c8ad0e0eb7c63055270647179",
                "commit_title": "testing array ",
                "created_at": "1727963865",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727964013",
                "changes": [
                    {
                        "id": "8e80461b-c24e-45d0-8772-d4327dba9a64",
                        "created_at": "1727963865",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1727964013",
                        "staggers": null
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "e7f38be0c016826a9808c355850fe0690d65144a",
                "commit_title": "Adding config files ",
                "created_at": "1717665172",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1717665785",
                "changes": [
                    {
                        "id": "587d5ed8-c021-46e0-a3a0-aa84630e6d3f",
                        "created_at": "1717665179",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717665785",
                        "staggers": [
                            {
                                "created_at": "1717665181",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717665785"
                            }
                        ]
                    },
                    {
                        "id": "55fd9845-43ec-43fe-abca-9f30ad291a2d",
                        "created_at": "1717665172",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1717665784",
                        "staggers": [
                            {
                                "created_at": "1717665179",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1717665784"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "47395bd3220ad2c94cb2a1c4e6ce171fc7cb0180",
                "commit_title": "DDos role updates new ",
                "created_at": "1715687037",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1715687448",
                "changes": [
                    {
                        "id": "eaa0cd40-6843-42bb-8b2a-9d82d59d90cf",
                        "created_at": "1715687037",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715687448",
                        "staggers": [
                            {
                                "created_at": "1715687045",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715687448"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "17da82d28648d22376fc8d81fedad0157ec737d2",
                "commit_title": "delete-scopeEmptyOU ",
                "created_at": "1714991935",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714992140",
                "changes": [
                    {
                        "id": "ecac9fd8-d931-4e78-ab0e-cd05c01714db",
                        "created_at": "1714991935",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeEmptyOU",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714992140",
                        "staggers": [
                            {
                                "created_at": "1714991936",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1714992139"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "9901ea4a68b6449536942483cc11d039da2f5f81",
                "commit_title": "added-paramaters-for-prisma ",
                "created_at": "1714127989",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1714128194",
                "changes": [
                    {
                        "id": "1c56d31d-89d3-43d4-a5f6-24b3acbcefea",
                        "created_at": "1714127990",
                        "operation_type": "CREATE",
                        "stackset_id": "scopePrismaRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1714128194",
                        "staggers": [
                            {
                                "created_at": "1714127991",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1714128194"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "77bf270b04699d5548502360d8ed1575b36325ad",
                "commit_title": "fix role policy ",
                "created_at": "1709301143",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1709301143",
                "changes": null,
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "10afdfc29576cc6252dd6d609a81b75634c4ccbc",
                "commit_title": "Added permissions and role for PCSKSCPV2Role ",
                "created_at": "1703052046",
                "deployment_status": "COMPLETED",
                "developer_id": "svaradarajan@salesforce.com",
                "updated_at": "1703052464",
                "changes": [
                    {
                        "id": "6ccb39f8-0382-419b-8d07-e2d279c5afab",
                        "created_at": "1703052051",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1703052464",
                        "staggers": [
                            {
                                "created_at": "1703052056",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1703052464"
                            }
                        ]
                    },
                    {
                        "id": "02189fe3-e9bb-49a6-8381-6ab3729439a2",
                        "created_at": "1703052049",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1703052245",
                        "staggers": [
                            {
                                "created_at": "1703052051",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1703052245"
                            }
                        ]
                    },
                    {
                        "id": "d99736b8-9bed-4021-855e-05d863db15a8",
                        "created_at": "1703052046",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1703052245",
                        "staggers": [
                            {
                                "created_at": "1703052049",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1703052245"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "ba84b11642f0bbbc354f3ba4fda883fb0e3ecb67",
                "commit_title": " @W-13738330 | Cleanup of CloudHealth stackset on MPAs ",
                "created_at": "1690535879",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1690536104",
                "changes": [
                    {
                        "id": "e759a667-2af0-4dd7-9f03-1cb2c2ab2568",
                        "created_at": "1690535896",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690536102",
                        "staggers": [
                            {
                                "created_at": "1690535898",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690536102"
                            }
                        ]
                    },
                    {
                        "id": "0dd0db48-e929-4071-bf8d-8572f8738414",
                        "created_at": "1690535894",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690536103",
                        "staggers": [
                            {
                                "created_at": "1690535896",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690536103"
                            }
                        ]
                    },
                    {
                        "id": "2005dd47-8999-47df-a429-1a86b1cd49c0",
                        "created_at": "1690535892",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690536102",
                        "staggers": [
                            {
                                "created_at": "1690535894",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690536102"
                            }
                        ]
                    },
                    {
                        "id": "6334ed0b-3a80-4916-a3ff-1b0819c8d4e6",
                        "created_at": "1690535889",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690536104",
                        "staggers": [
                            {
                                "created_at": "1690535892",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690536104"
                            }
                        ]
                    },
                    {
                        "id": "c57fd734-3623-40bf-92ea-0796b8c17d2a",
                        "created_at": "1690535887",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690536103",
                        "staggers": [
                            {
                                "created_at": "1690535889",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690536103"
                            }
                        ]
                    },
                    {
                        "id": "6830bc8e-d579-404b-82fc-439416b730f3",
                        "created_at": "1690535885",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690536102",
                        "staggers": [
                            {
                                "created_at": "1690535887",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690536102"
                            }
                        ]
                    },
                    {
                        "id": "ac83760a-be8a-4cea-a659-fa5d2fa85599",
                        "created_at": "1690535883",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690536103",
                        "staggers": [
                            {
                                "created_at": "1690535885",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690536103"
                            }
                        ]
                    },
                    {
                        "id": "dfedc8dc-f183-4539-a4dd-5f67f8a47e30",
                        "created_at": "1690535881",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690536102",
                        "staggers": [
                            {
                                "created_at": "1690535883",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690536102"
                            }
                        ]
                    },
                    {
                        "id": "d22a24ba-3f3e-449a-9ad0-bd697bee9356",
                        "created_at": "1690535879",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1690536104",
                        "staggers": [
                            {
                                "created_at": "1690535881",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1690536103"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "89b7616657f1d0adb2b0a9e64b52282060679e4d",
                "commit_title": "Added AmazonDetectiveInvestigatorAccess and AmazonVPCReachabilityAnalyzerFullAccessPolicy policies to AdminAccessRole ",
                "created_at": "1689142238",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1689142462",
                "changes": [
                    {
                        "id": "4249865e-8b14-457d-9927-b20cdd4ab935",
                        "created_at": "1689142257",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689142460",
                        "staggers": [
                            {
                                "created_at": "1689142258",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689142460"
                            }
                        ]
                    },
                    {
                        "id": "9b61eb9b-4078-4597-8f06-c7896dbd62c9",
                        "created_at": "1689142255",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689142462",
                        "staggers": [
                            {
                                "created_at": "1689142257",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689142462"
                            }
                        ]
                    },
                    {
                        "id": "b0a5d858-5902-455e-b144-c43880b0c419",
                        "created_at": "1689142253",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689142462",
                        "staggers": [
                            {
                                "created_at": "1689142255",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689142462"
                            }
                        ]
                    },
                    {
                        "id": "39ed7a8a-0ccc-4b28-8378-cb23db4f96ca",
                        "created_at": "1689142249",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689142462",
                        "staggers": [
                            {
                                "created_at": "1689142253",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689142462"
                            }
                        ]
                    },
                    {
                        "id": "cc5e4f00-bc90-49e1-94bc-7e27989fec9e",
                        "created_at": "1689142247",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689142460",
                        "staggers": [
                            {
                                "created_at": "1689142249",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689142460"
                            }
                        ]
                    },
                    {
                        "id": "823d634e-8f2c-4a9b-a0dc-98ea3547ac6f",
                        "created_at": "1689142245",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689142461",
                        "staggers": [
                            {
                                "created_at": "1689142247",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689142461"
                            }
                        ]
                    },
                    {
                        "id": "9fa7f9b7-09a2-4dfd-9fe7-10a4f9dc5bbf",
                        "created_at": "1689142243",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689142461",
                        "staggers": [
                            {
                                "created_at": "1689142245",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689142461"
                            }
                        ]
                    },
                    {
                        "id": "b248d126-4a81-4258-bd3d-730ca6eeb219",
                        "created_at": "1689142240",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689142461",
                        "staggers": [
                            {
                                "created_at": "1689142243",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689142461"
                            }
                        ]
                    },
                    {
                        "id": "6636ad90-c8d0-4e51-8b75-9a3ee4e16ece",
                        "created_at": "1689142238",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1689142462",
                        "staggers": [
                            {
                                "created_at": "1689142240",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1689142462"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "46c9da56b17f62ab10449021f5661e6129791fc2",
                "commit_title": "tmp ",
                "created_at": "1683806856",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1683807335",
                "changes": [
                    {
                        "id": "d05eba52-e832-40ea-b945-01da09844a2c",
                        "created_at": "1683806911",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683807119",
                        "staggers": [
                            {
                                "created_at": "1683806913",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683807119"
                            }
                        ]
                    },
                    {
                        "id": "9a7c6040-bc42-4478-b28c-0854299029ba",
                        "created_at": "1683806909",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683807118",
                        "staggers": [
                            {
                                "created_at": "1683806911",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683807118"
                            }
                        ]
                    },
                    {
                        "id": "b9483424-eb16-4853-a61c-6f1a6191eac6",
                        "created_at": "1683806907",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683807119",
                        "staggers": [
                            {
                                "created_at": "1683806909",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683807119"
                            }
                        ]
                    },
                    {
                        "id": "c41d8b1b-bb63-4a2a-ab3c-3d6eddde0a05",
                        "created_at": "1683806901",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1683807335",
                        "staggers": [
                            {
                                "created_at": "1683807348",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683807348"
                            },
                            {
                                "created_at": "1683807132",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683807335"
                            },
                            {
                                "created_at": "1683806907",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683807117"
                            }
                        ]
                    },
                    {
                        "id": "d43dd30f-fec8-4dce-8fb8-ce592a607535",
                        "created_at": "1683806900",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683807119",
                        "staggers": [
                            {
                                "created_at": "1683806901",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683807119"
                            }
                        ]
                    },
                    {
                        "id": "32a841eb-92a7-4e14-aaa7-1596332da92e",
                        "created_at": "1683806898",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683807118",
                        "staggers": [
                            {
                                "created_at": "1683806900",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683807118"
                            }
                        ]
                    },
                    {
                        "id": "f832e7c8-c914-4395-b802-5b73063c1874",
                        "created_at": "1683806895",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683807118",
                        "staggers": [
                            {
                                "created_at": "1683806898",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683807118"
                            }
                        ]
                    },
                    {
                        "id": "dc357eb3-85e1-4b4b-ab2f-3d7b551f04d0",
                        "created_at": "1683806889",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683806889",
                        "staggers": [
                            {
                                "created_at": "1683807353",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683807353"
                            },
                            {
                                "created_at": "1683806895",
                                "operation_status": "STOPPED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683807335"
                            }
                        ]
                    },
                    {
                        "id": "f1bd0f78-4061-45b7-a4f5-3c087322946d",
                        "created_at": "1683806856",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683807118",
                        "staggers": [
                            {
                                "created_at": "1683806889",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683807118"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "1d436c139d8d9c98b20cfec631748cb5c6634a1c",
                "commit_title": "- hardcoded update ",
                "created_at": "1683613128",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1683614214",
                "changes": [
                    {
                        "id": "415994d5-f229-4c4b-9d20-a426d65890de",
                        "created_at": "1683613153",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683613561",
                        "staggers": [
                            {
                                "created_at": "1683613154",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683613561"
                            }
                        ]
                    },
                    {
                        "id": "15d70921-9488-4bb3-bb3c-9021f7eb1380",
                        "created_at": "1683613150",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683613562",
                        "staggers": [
                            {
                                "created_at": "1683613153",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683613562"
                            }
                        ]
                    },
                    {
                        "id": "79400741-15e8-48d8-8e20-66e2da48346f",
                        "created_at": "1683613148",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683613561",
                        "staggers": [
                            {
                                "created_at": "1683613150",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683613561"
                            }
                        ]
                    },
                    {
                        "id": "d2e6d3e1-7a5c-4a18-aa69-f706bc1c7ab5",
                        "created_at": "1683613146",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683613358",
                        "staggers": [
                            {
                                "created_at": "1683613148",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683613358"
                            }
                        ]
                    },
                    {
                        "id": "cabc0c3c-447a-47fe-870c-42ca904cb146",
                        "created_at": "1683613145",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683613562",
                        "staggers": [
                            {
                                "created_at": "1683613146",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683613562"
                            }
                        ]
                    },
                    {
                        "id": "161b2ed0-7dc2-4cf9-8035-b26b1a06ff19",
                        "created_at": "1683613142",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683613562",
                        "staggers": [
                            {
                                "created_at": "1683613145",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683613562"
                            }
                        ]
                    },
                    {
                        "id": "479e3f8d-a266-4507-9af7-e94f00517298",
                        "created_at": "1683613135",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683613561",
                        "staggers": [
                            {
                                "created_at": "1683613142",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683613560"
                            }
                        ]
                    },
                    {
                        "id": "cd24dbad-970e-457e-8599-83d833114577",
                        "created_at": "1683613130",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1683614214",
                        "staggers": [
                            {
                                "created_at": "1683614226",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683614226"
                            },
                            {
                                "created_at": "1683614012",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683614214"
                            },
                            {
                                "created_at": "1683613797",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683613999"
                            },
                            {
                                "created_at": "1683613582",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683613784"
                            },
                            {
                                "created_at": "1683613135",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683613561"
                            }
                        ]
                    },
                    {
                        "id": "a2993af3-d8c1-49ac-b94b-9d83580ecf6c",
                        "created_at": "1683613128",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1683613562",
                        "staggers": [
                            {
                                "created_at": "1683613130",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1683613562"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "b20b1b68347c4a5a1fd0d25da6a35189a1cc1004",
                "commit_title": "testing ",
                "created_at": "1731477132",
                "deployment_status": "FAILED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731477337",
                "changes": [
                    {
                        "id": "f7b528d8-dd7d-4f99-a374-d0046e65e17a",
                        "created_at": "1731477132",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1731477337",
                        "staggers": [
                            {
                                "created_at": "1731477136",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731477337"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "39ffead61d224c4496ae049e7bf3bd075289c5c8",
                "commit_title": "permissoin boundary allow all ",
                "created_at": "1729065060",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1729065485",
                "changes": [
                    {
                        "id": "67027ad5-ad57-4e3e-92b9-2482c1722afd",
                        "created_at": "1729065060",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1729065485",
                        "staggers": [
                            {
                                "created_at": "1729065283",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1729065485"
                            },
                            {
                                "created_at": "1729065062",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1729065266"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "bd164c347e54b4c0a2f6fa479ff21798ba8a1cef_120881508763_5900e868-2fb0-11ef-a8a4-3aea1f518f88",
                "commit_title": "@W-15755612 | Added permissions for ou-automation DELETE and UPDATE APIs ",
                "created_at": "1718961997",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1718962201",
                "changes": [
                    {
                        "id": "3aae12f4-f39b-47ba-9537-15a0f6e946b3",
                        "created_at": "1718961997",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1718962201",
                        "staggers": [
                            {
                                "created_at": "1718961998",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1718962201"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6cf2e32400a78ed2005d7f7ba52125d5e2919388",
                "commit_title": "test 12 ",
                "created_at": "1716388475",
                "deployment_status": "COMPLETED",
                "developer_id": "dnagpal@salesforce.com",
                "updated_at": "1716389145",
                "changes": [
                    {
                        "id": "41c05345-2864-421b-a40d-07bbcbb98d1d",
                        "created_at": "1716388475",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeGuardDutyDelegateAdminRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716389145",
                        "staggers": [
                            {
                                "created_at": "1716388944",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 3",
                                "updated_at": "1716389145"
                            },
                            {
                                "created_at": "1716388717",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 2",
                                "updated_at": "1716388917"
                            },
                            {
                                "created_at": "1716388477",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1716388679"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "3de5db2e582ecf637107104699e14dcf586cbba6",
                "commit_title": "adding wildcard ",
                "created_at": "1730219222",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730219426",
                "changes": [
                    {
                        "id": "dae35f0c-1fae-46aa-8f41-38de93379e57",
                        "created_at": "1730219222",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730219426",
                        "staggers": [
                            {
                                "created_at": "1730219224",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730219426"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "1d6965f9ac8a9dbc18761d2f1ecf67ae150f144e",
                "commit_title": "added-template-version ",
                "created_at": "1716359058",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1716359261",
                "changes": [
                    {
                        "id": "14a68c77-c337-4d28-b204-0a4fc3981eec",
                        "created_at": "1716359058",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSAIRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716359261",
                        "staggers": [
                            {
                                "created_at": "1716359059",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1716359261"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6b158f7702853f58143ba224b825f1050c55cafe",
                "commit_title": "testing-nested-role ",
                "created_at": "1715780612",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715780612",
                "changes": null,
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f0b0043f13c81d485bc4b1c8f04ab53f303a4be1_211125539547_36110227-e84a-11ee-b9be-da8b3f1b7720",
                "commit_title": "create vendingtest stackset ",
                "created_at": "1711111597",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1711111801",
                "changes": [
                    {
                        "id": "b2ddf723-0201-4d51-89e8-ee9be197b94f",
                        "created_at": "1711111597",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeVendingTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711111801",
                        "staggers": [
                            {
                                "created_at": "1711111598",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1711111801"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "4ecb6291f17cd6edc47099b464f42b9280a93681",
                "commit_title": "@W-12969622 MPA specific policy ",
                "created_at": "1711104570",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1711104775",
                "changes": [
                    {
                        "id": "ff926b00-6582-415d-8a42-27331e311b76",
                        "created_at": "1711104570",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711104775",
                        "staggers": [
                            {
                                "created_at": "1711104572",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1711104775"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "b535a9ce8d9d824036f0e2955fdf7aef83b8f145",
                "commit_title": "testing ",
                "created_at": "1731519532",
                "deployment_status": "FAILED",
                "developer_id": "anjani.k@salesforce.com",
                "updated_at": "1731519737",
                "changes": [
                    {
                        "id": "1113fe3e-c552-4f9b-bea8-0deb93d262a5",
                        "created_at": "1731519532",
                        "operation_type": "UPDATE",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1731519737",
                        "staggers": [
                            {
                                "created_at": "1731519536",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731519737"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "1e82ee1077b9460ecc1673809dbcb19cea829723",
                "commit_title": "delete-scptest ",
                "created_at": "1715764625",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1715764730",
                "changes": [
                    {
                        "id": "758ba525-d031-4dc9-adeb-be531f901e09",
                        "created_at": "1715764625",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeSCPSelfManagedTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715764730",
                        "staggers": [
                            {
                                "created_at": "1715764627",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1715764730"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "efa30a914125389b14fe56b8573544737a41397c",
                "commit_title": "Added scp stagger related stackset changes ",
                "created_at": "1705595795",
                "deployment_status": "COMPLETED",
                "developer_id": "svaradarajan@salesforce.com",
                "updated_at": "1705596214",
                "changes": [
                    {
                        "id": "7ff5193a-bc42-4fbe-9f22-f6e6757fc612",
                        "created_at": "1705595795",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1705596214",
                        "staggers": [
                            {
                                "created_at": "1705596012",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1705596214"
                            },
                            {
                                "created_at": "1705595797",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1705596001"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-c415df14-731f-11ee-b831-acde48001122",
                "commit_title": "test commit - remove all stacksets from grouping, and mark as delete in payload",
                "created_at": "1698229086",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698229151",
                "changes": [
                    {
                        "id": "8c318a1e-83e3-46cc-b184-ee5fab0f15f0",
                        "created_at": "1698229088",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698229151",
                        "staggers": [
                            {
                                "created_at": "1698229089",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698229151"
                            }
                        ]
                    },
                    {
                        "id": "42a15d5d-7ef8-428f-8223-fe1c7effed1b",
                        "created_at": "1698229086",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698229151",
                        "staggers": [
                            {
                                "created_at": "1698229088",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1698229150"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "79acdec3c814c2101cd423ebbacca55d0b0a064a",
                "commit_title": "delete-org-stackset ",
                "created_at": "1697110212",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1697110448",
                "changes": [
                    {
                        "id": "4cb771e8-a3f4-49a4-bca4-d5f3ae4a986a",
                        "created_at": "1697110212",
                        "operation_type": "",
                        "stackset_id": "scopeOrgTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1697110446",
                        "staggers": [
                            {
                                "created_at": "1697110218",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "stage mpa",
                                "updated_at": "1697110436"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "381a26c089dacda6a7d2df443e4fa2a7b71066d4",
                "commit_title": "Dummy commit to trigger ",
                "created_at": "1731437131",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1731438520",
                "changes": [
                    {
                        "id": "5c727c5c-569e-4f09-b692-d036ccb0b693",
                        "created_at": "1731437136",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731438520",
                        "staggers": [
                            {
                                "created_at": "1731438318",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731438520"
                            },
                            {
                                "created_at": "1731437137",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "stage MPA",
                                "updated_at": "1731437339"
                            }
                        ]
                    },
                    {
                        "id": "34b1b08a-0b5e-4d66-b1a3-eacb0c2b6eef",
                        "created_at": "1731437131",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1731437340",
                        "staggers": [
                            {
                                "created_at": "1731437136",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1731437340"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "2f8cb1a17bd544da4534472e2bb301d7a59f407a",
                "commit_title": "deny marketplace ",
                "created_at": "1703046403",
                "deployment_status": "COMPLETED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1703046579",
                "changes": [
                    {
                        "id": "0e1de3f6-6d84-448a-9911-0fe571c2c9dd",
                        "created_at": "1703046410",
                        "operation_type": "UPDATE",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1703046445",
                        "staggers": [
                            {
                                "created_at": "1703046411",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1703046445"
                            }
                        ]
                    },
                    {
                        "id": "bb8bea18-82fb-4cd7-b5d1-67bd48bb6bcc",
                        "created_at": "1703046407",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1703046579",
                        "staggers": [
                            {
                                "created_at": "1703046410",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1703046579"
                            }
                        ]
                    },
                    {
                        "id": "468f865f-4494-4260-9507-d222630a5fdf",
                        "created_at": "1703046403",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1703046579",
                        "staggers": [
                            {
                                "created_at": "1703046407",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1703046579"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "545ea212d8f6181946ca1aefd896e6fa3711357f",
                "commit_title": "test-2 ",
                "created_at": "1697104609",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1697104836",
                "changes": [
                    {
                        "id": "659d48c8-4177-4f22-a06d-06cc8b2c7031",
                        "created_at": "1697104609",
                        "operation_type": "",
                        "stackset_id": "scopetestv1",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1697104833",
                        "staggers": [
                            {
                                "created_at": "1697104611",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "scope-ou",
                                "updated_at": "1697104829"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "aa243a7a1928b91fa9484cf6bf3c9e42e888ef72",
                "commit_title": "update mpa stackset ",
                "created_at": "1694698917",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1694698980",
                "changes": [
                    {
                        "id": "9ff7ca5b-6ec5-40a8-b8ab-f7931af56d4d",
                        "created_at": "1694698917",
                        "operation_type": "",
                        "stackset_id": "ScopeTestMpa",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1694698980",
                        "staggers": [
                            {
                                "created_at": "1694698918",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "MPAs",
                                "updated_at": "1694698980"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "296fb98cb32f56d984acc03f1348341dc90fc01b",
                "commit_title": "addressing comments ",
                "created_at": "1709535241",
                "deployment_status": "OVERRIDDEN",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1709540395",
                "changes": [
                    {
                        "id": "4e0296ad-0677-451c-8867-58ae9bed8009",
                        "created_at": "1709535241",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeMRTRole",
                        "stackset_status": "OVERRIDDEN",
                        "updated_at": "1709540395",
                        "staggers": [
                            {
                                "created_at": "1709535243",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Staggered Group 1",
                                "updated_at": "1709535446"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "94f0ee84288c35e22fb5255c2ee7bf7089819f25",
                "commit_title": "@W-17130227 - upgrading mulesoft_pcd template version for latest changes ",
                "created_at": "1730935115",
                "deployment_status": "COMPLETED",
                "developer_id": "svaradarajan@salesforce.com",
                "updated_at": "1730935321",
                "changes": [
                    {
                        "id": "e1c3048a-f2db-4ce4-aaa4-cc52941384ed",
                        "created_at": "1730935115",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730935321",
                        "staggers": [
                            {
                                "created_at": "1730935116",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730935321"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "0c70505f4e56547c52a92a20ddf6fd4ed2009e79",
                "commit_title": "Updatin version ",
                "created_at": "1730147777",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1730147980",
                "changes": [
                    {
                        "id": "31d0a903-0dbd-43f6-84e0-e673febdbf90",
                        "created_at": "1730147777",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftPCDStage",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1730147980",
                        "staggers": [
                            {
                                "created_at": "1730147778",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Stagger Group stage",
                                "updated_at": "1730147980"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d55df4e2c036e1b565cbdec71a10128991192f2f",
                "commit_title": "Adding assume role trust for stage and test app group ",
                "created_at": "1728412063",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1728412267",
                "changes": [
                    {
                        "id": "e66d45f3-cabd-4dcf-aa05-48076cdc5b1f",
                        "created_at": "1728412063",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728412267",
                        "staggers": [
                            {
                                "created_at": "1728412065",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1728412267"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "2521313578c91c3a5184ea7292a3a47f384aa599",
                "commit_title": "update version for commit ",
                "created_at": "1727988549",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727988753",
                "changes": [
                    {
                        "id": "e4a9daf5-3d9b-41f9-8be5-48d3fc4d196a",
                        "created_at": "1727988549",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727988753",
                        "staggers": [
                            {
                                "created_at": "1727988551",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1727988753"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "eaf2f5a342fc2e30a313a8511aa977195b0ba567",
                "commit_title": "updated-template-version ",
                "created_at": "1716359625",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1716359829",
                "changes": [
                    {
                        "id": "99fb7e17-3fee-41c0-8440-54bd7f1e7753",
                        "created_at": "1716359625",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeSAIRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1716359829",
                        "staggers": [
                            {
                                "created_at": "1716359627",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group stage 1",
                                "updated_at": "1716359829"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "ce1bee90ec3e12f39cfdd418ef62a30c245451eb",
                "commit_title": "Test commit ",
                "created_at": "1715970374",
                "deployment_status": "COMPLETED",
                "developer_id": "deepanshu.arora@salesforce.com",
                "updated_at": "1715970374",
                "changes": [
                    {
                        "id": "4b786a54-6671-426d-aba5-294ac54c6602",
                        "created_at": "1715970378",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1715970894",
                        "staggers": [
                            {
                                "created_at": "1715970385",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1715970894"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "f0b0043f13c81d485bc4b1c8f04ab53f303a4be1_211125539547_f148a6fb-e84f-11ee-8866-0671046b5346",
                "commit_title": "create vendingtest stackset ",
                "created_at": "1711114059",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1711114264",
                "changes": [
                    {
                        "id": "de937330-a20a-4f99-aaae-80bf2ee0463a",
                        "created_at": "1711114059",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeOnboardingTest",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1711114264",
                        "staggers": [
                            {
                                "created_at": "1711114060",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "ScopeDeploymentWithoutStaggering",
                                "updated_at": "1711114264"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "0d38e21b19383c015c4e57d250615802fe4da07f",
                "commit_title": "delete resource ",
                "created_at": "1701932603",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1701933227",
                "changes": [
                    {
                        "id": "baab76bf-8bc0-448a-8f84-6c384d9c928d",
                        "created_at": "1701932603",
                        "operation_type": "DELETE",
                        "stackset_id": "scopeMRTResources",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701933227",
                        "staggers": [
                            {
                                "created_at": "1701933024",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Managed Runtime/Non-Production OU",
                                "updated_at": "1701933226"
                            },
                            {
                                "created_at": "1701932814",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "Managed Runtime/Non-Production/Managers OU",
                                "updated_at": "1701933016"
                            },
                            {
                                "created_at": "1701932604",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETE",
                                "stagger_group_name": "mrt-non-production-scope-test-2",
                                "updated_at": "1701932806"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "d5634b0636eebce3ecc8ce365b6375ee73a153ef",
                "commit_title": "@W-13768736 | Permission Assessment and Remediation for CSIRT and STAR RO PCSK Roles (#59) ",
                "created_at": "1692172537",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1692172537",
                "changes": [
                    {
                        "id": "4e938efe-fd17-4841-a302-b4d0fe4e4550",
                        "created_at": "1692172537",
                        "operation_type": "",
                        "stackset_id": "bhor-test",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1692172537",
                        "staggers": null
                    }
                ],
                "can_expand": true,
                "can_revert": true
            },
            {
                "commit_id": "93f0be0f5ba2da8a44228a0e3a49498f5b92f78e",
                "commit_title": "added-test-stackset-template ",
                "created_at": "1691756373",
                "deployment_status": "COMPLETED",
                "developer_id": "bhorbhaskar.verma@salesforce.com",
                "updated_at": "1691757656",
                "changes": [
                    {
                        "id": "0d3984b7-e6c8-45af-a1af-1d223d5fd079",
                        "created_at": "1691756393",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691757655",
                        "staggers": [
                            {
                                "created_at": "1691756395",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691757655"
                            }
                        ]
                    },
                    {
                        "id": "945d267f-8f3f-45bc-8696-2d3d3f362586",
                        "created_at": "1691756391",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691757654",
                        "staggers": [
                            {
                                "created_at": "1691756393",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691757654"
                            }
                        ]
                    },
                    {
                        "id": "7aa54d25-27b4-4237-9d9b-018ad138dfc1",
                        "created_at": "1691756389",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691757654",
                        "staggers": [
                            {
                                "created_at": "1691756391",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691757654"
                            }
                        ]
                    },
                    {
                        "id": "dedb0887-e1a6-4de1-9cc9-0039c104f572",
                        "created_at": "1691756386",
                        "operation_type": "",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691757653",
                        "staggers": [
                            {
                                "created_at": "1691756389",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691757653"
                            }
                        ]
                    },
                    {
                        "id": "770c00b4-99de-403b-b72c-805af7477eee",
                        "created_at": "1691756383",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691757655",
                        "staggers": [
                            {
                                "created_at": "1691756386",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691757655"
                            }
                        ]
                    },
                    {
                        "id": "b376e0a8-399f-45fe-82ed-4b61bcf374c8",
                        "created_at": "1691756380",
                        "operation_type": "",
                        "stackset_id": "bhor-test",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691757655",
                        "staggers": [
                            {
                                "created_at": "1691756383",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "scope-ou",
                                "updated_at": "1691757655"
                            }
                        ]
                    },
                    {
                        "id": "bdcc9cf6-bf32-4f57-ac92-ce4eb5b0e121",
                        "created_at": "1691756378",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691757656",
                        "staggers": [
                            {
                                "created_at": "1691756380",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691757656"
                            }
                        ]
                    },
                    {
                        "id": "9070d477-a64f-42dd-832d-d5090e0079ce",
                        "created_at": "1691756377",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691757653",
                        "staggers": [
                            {
                                "created_at": "1691756378",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691757653"
                            }
                        ]
                    },
                    {
                        "id": "17989fbb-4d70-42d9-beb3-be4f5df05d6e",
                        "created_at": "1691756375",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691757655",
                        "staggers": [
                            {
                                "created_at": "1691756377",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691757655"
                            }
                        ]
                    },
                    {
                        "id": "ee665e99-a58b-4b8e-9aca-4e62cb800135",
                        "created_at": "1691756373",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1691757654",
                        "staggers": [
                            {
                                "created_at": "1691756375",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1691757654"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "ff0263ddd159d3e99d7b5e398d278c39a268fe80",
                "commit_title": "@W-16822703 | Allowlisting CVW accounts in PCSKDenyHostAccessPolicy ",
                "created_at": "1728032822",
                "deployment_status": "COMPLETED",
                "developer_id": "cgosalia@salesforce.com",
                "updated_at": "1728033229",
                "changes": [
                    {
                        "id": "8ea720f8-626c-4939-a245-3172a3ad3f20",
                        "created_at": "1728032822",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1728033229",
                        "staggers": [
                            {
                                "created_at": "1728032826",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1728033229"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "7177de10fe60e9edd053b475c88ebc59f48db699",
                "commit_title": "Updating comment for a commit ",
                "created_at": "1727962202",
                "deployment_status": "COMPLETED",
                "developer_id": "p.raghavan@salesforce.com",
                "updated_at": "1727962614",
                "changes": [
                    {
                        "id": "1e36d6f9-b11a-4184-9097-8574e61b0190",
                        "created_at": "1727962209",
                        "operation_type": "UPDATE",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727962413",
                        "staggers": [
                            {
                                "created_at": "1727962211",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1727962413"
                            }
                        ]
                    },
                    {
                        "id": "9d41e4f1-36ff-401c-a56b-07c7d0d50803",
                        "created_at": "1727962207",
                        "operation_type": "UPDATE",
                        "stackset_id": "scopeMulesoftSSMRole",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727962413",
                        "staggers": [
                            {
                                "created_at": "1727962209",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "Staggered Group 1 - dev",
                                "updated_at": "1727962413"
                            }
                        ]
                    },
                    {
                        "id": "c58029d4-e949-4d84-b48c-f3514c4e1ee7",
                        "created_at": "1727962202",
                        "operation_type": "UPDATE",
                        "stackset_id": "commonIAM",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1727962614",
                        "staggers": [
                            {
                                "created_at": "1727962207",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1727962614"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "6e6c0826f39d6b37caac10682170b11aa0c2b614",
                "commit_title": "add MRT resources stackset ",
                "created_at": "1701938423",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1701959042",
                "changes": [
                    {
                        "id": "053a7a42-edbf-446b-b4bd-9f96b54c736c",
                        "created_at": "1701938423",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeMRTResources",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1701938864",
                        "staggers": [
                            {
                                "created_at": "1701938689",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Managed Runtime/Non-Production OU",
                                "updated_at": "1701938864"
                            },
                            {
                                "created_at": "1701938648",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Managed Runtime/Non-Production/Managers OU",
                                "updated_at": "1701938680"
                            },
                            {
                                "created_at": "1701938425",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "mrt-non-production-scope-test-2",
                                "updated_at": "1701938458"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "test-e58ac366-74b5-11ee-bed9-acde48001122",
                "commit_title": "test commit - revert to 2nd stage configuration,third stackset is created back",
                "created_at": "1698403520",
                "deployment_status": "COMPLETED",
                "developer_id": "shivmitra.mishra@salesforce.com",
                "updated_at": "1698405220",
                "changes": [
                    {
                        "id": "6e4603d0-c4ba-4819-b5fe-cfbe5c8f7dba",
                        "created_at": "1698403523",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698403726",
                        "staggers": [
                            {
                                "created_at": "1698403523",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698403726"
                            }
                        ]
                    },
                    {
                        "id": "0299abc6-eaa2-4518-b2d4-17ff2bf73589",
                        "created_at": "1698403523",
                        "operation_type": "ADDINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698403725",
                        "staggers": [
                            {
                                "created_at": "1698403524",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "ADDINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698403725"
                            }
                        ]
                    },
                    {
                        "id": "35bbc6ad-5fbe-4746-8f99-ac6165a3045c",
                        "created_at": "1698403522",
                        "operation_type": "CREATE",
                        "stackset_id": "scopeTestThird",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698403725",
                        "staggers": [
                            {
                                "created_at": "1698403523",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "CREATE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698403725"
                            }
                        ]
                    },
                    {
                        "id": "8f19a20a-0a78-46f2-b52e-758d19bb9aaf",
                        "created_at": "1698403521",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestSecond",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698403725",
                        "staggers": [
                            {
                                "created_at": "1698403522",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698403725"
                            }
                        ]
                    },
                    {
                        "id": "d7082369-448e-4a82-81f1-c850be733cce",
                        "created_at": "1698403520",
                        "operation_type": "DELETEINSTANCE",
                        "stackset_id": "scopeTestFirst",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1698403726",
                        "staggers": [
                            {
                                "created_at": "1698403521",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "DELETEINSTANCE",
                                "stagger_group_name": "Revert",
                                "updated_at": "1698403726"
                            }
                        ]
                    }
                ],
                "can_expand": false,
                "can_revert": true
            },
            {
                "commit_id": "97b226f974de7d28d62f5dc4697b1c9a54e1a570",
                "commit_title": "@W-13046968 | Enabled managed execution on stacksets (#38) ",
                "created_at": "1684310213",
                "deployment_status": "FAILED",
                "developer_id": "shrawan.sah@salesforce.com",
                "updated_at": "1684310440",
                "changes": [
                    {
                        "id": "adede437-f65e-4d82-926f-b63ebc6e1d2e",
                        "created_at": "1684310233",
                        "operation_type": "",
                        "stackset_id": "falconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684310440",
                        "staggers": [
                            {
                                "created_at": "1684310235",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684310440"
                            }
                        ]
                    },
                    {
                        "id": "b44992e3-61a3-4a1f-85f0-9ac9ff9bcb7f",
                        "created_at": "1684310231",
                        "operation_type": "",
                        "stackset_id": "commonIAMNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684310440",
                        "staggers": [
                            {
                                "created_at": "1684310233",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684310440"
                            }
                        ]
                    },
                    {
                        "id": "d483e22e-b61a-406f-9ba3-683d82588fb1",
                        "created_at": "1684310227",
                        "operation_type": "",
                        "stackset_id": "cloudtrail",
                        "stackset_status": "FAILED",
                        "updated_at": "1684310440",
                        "staggers": [
                            {
                                "created_at": "1684310455",
                                "operation_status": "IN_PROGRESS",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684310455"
                            },
                            {
                                "created_at": "1684310231",
                                "operation_status": "FAILED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684310440"
                            }
                        ]
                    },
                    {
                        "id": "c31758e5-3d4a-42a4-adf5-8f55675b6f06",
                        "created_at": "1684310225",
                        "operation_type": "",
                        "stackset_id": "nonFalconMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684310439",
                        "staggers": [
                            {
                                "created_at": "1684310227",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684310439"
                            }
                        ]
                    },
                    {
                        "id": "85bcb322-5f81-4904-b8ef-f673a0f2cdcd",
                        "created_at": "1684310224",
                        "operation_type": "",
                        "stackset_id": "allMPA",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684310439",
                        "staggers": [
                            {
                                "created_at": "1684310225",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684310439"
                            }
                        ]
                    },
                    {
                        "id": "503c4098-a396-44e8-a8d7-0d42b062ad70",
                        "created_at": "1684310221",
                        "operation_type": "",
                        "stackset_id": "commonIAMFalconMPAAndPCSKAccounts",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684310441",
                        "staggers": [
                            {
                                "created_at": "1684310224",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684310441"
                            }
                        ]
                    },
                    {
                        "id": "a2745bd7-7fc4-4da2-87a4-fb0524299405",
                        "created_at": "1684310215",
                        "operation_type": "",
                        "stackset_id": "cloudtrailNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684310441",
                        "staggers": [
                            {
                                "created_at": "1684310217",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684310441"
                            }
                        ]
                    },
                    {
                        "id": "d56adb63-605c-44a2-8fe2-9b132fceb2ae",
                        "created_at": "1684310213",
                        "operation_type": "",
                        "stackset_id": "herokuNonFalcon",
                        "stackset_status": "COMPLETED",
                        "updated_at": "1684310441",
                        "staggers": [
                            {
                                "created_at": "1684310215",
                                "operation_status": "SUCCEEDED",
                                "operation_type": "UPDATE",
                                "stagger_group_name": "rest",
                                "updated_at": "1684310441"
                            }
                        ]
                    }
                ],
                "can_expand": true,
                "can_revert": true
            }
        ]
    }
}

// Updated MockStackSetsDeploymentApiService class
export class MockStackSetsDeploymentApiService implements StackSetsDeploymentApiService {
    async getDeployments(): Promise<StackSetsDeployment[]> {
        const mockData = generateMockData();

        return new Promise<StackSetsDeployment[]>(resolve => {
            setTimeout(() => {
                resolve(StackSetsDeployment.fromJSON(mockData));
            }, 500);
        });
    }

    async deployCommit(changeCase: string, implStepID: string, commitID: string, commitTitle: string, force: boolean, auto: boolean, dryRun: boolean): Promise<GenericDeploymentSubmissionResponse> {
        return new Promise<GenericDeploymentSubmissionResponse>(resolve => {
            setTimeout(() => {
                resolve(new GenericDeploymentSubmissionResponse("Deployment Request Accepted!", true));
            }, 1500);
        });
    }

    async expandCommit(changeCase: string, implStepID: string, commitID: string, commitTitle: string, force: boolean, auto: boolean, dryRun: boolean): Promise<GenericDeploymentSubmissionResponse> {
        return new Promise<GenericDeploymentSubmissionResponse>(resolve => {
            setTimeout(() => {
                resolve(new GenericDeploymentSubmissionResponse("Expand Request Accepted!", true));
            }, 1500);
        });
    }

    async revertCommit(changeCase: string, implStepID: string, commitID: string, commitTitle: string, revertCommitID: string, force: boolean, dryRun: boolean): Promise<GenericDeploymentSubmissionResponse> {
        return new Promise<GenericDeploymentSubmissionResponse>(resolve => {
            setTimeout(() => {
                resolve(new GenericDeploymentSubmissionResponse("Revert Request Accepted!", true));
            }, 1500);
        });
    }
    async getSummary(): Promise<GenericServiceSummary> {
        return new Promise<any>(resolve => {
            setTimeout(() => {
                const summary = GenericServiceSummary.fromJSON({
                    "summaries": [
                        {
                            "status": "Completed",
                            "count": 5
                        },
                        {
                            "status": "In Progress",
                            "count": 5
                        }
                    ]
                })
                resolve(summary);
            }, 1000);
        });
    }
}
