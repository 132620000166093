import React, { useEffect, useState } from "react";
import {
    Card,
    Icon,
    Button,
    Accordion,
    AccordionPanel,
    Input, ButtonGroup, Dropdown
} from "@salesforce/design-system-react";
import { AddToastFunction } from "../../../../utils/types/toast";
import { CustomHeightPageSpinner } from "../../../../utils/components/spinner/page_spinner_overlay";
import { TOAST_MESSAGE } from "../../../../constants/toastMessages";
import BarChart, {
    BarChartRow,
    NoneBarChartRow,
    PrepareBarChartData
} from "../../../../utils/components/bar_chart/bar_chart";
import {SCPDeployment, SCPOperation} from "../../../../utils/types/deployments/scp/operations";
import {SCPDeploymentAPIServiceFactory} from "../../../../services/deployment/scp/factory";
import QuickLinkList, {
    AdminSCPDeploymentGuide, PCAMDashboard, PCSKAvailability, PCSKTroubleshootingGuide,
    QuickLinkItem, SCPAPIRepo, SCPStoreRepo
} from "../../../../utils/components/quick_links/QuickLinks";
import IconSpinnerOverlay from "../../../../utils/components/spinner/icon_spinner_overlay";
import ThemedBadge from "../../../../utils/components/tag/tag";
import {CONSTANT_GENERIC_DEPLOYMENTS} from "../../../../constants/themes";
import SCPOperationsCard from "./OperationsCard";
import SCPMPAsCard from "./MPAsCard";
import {extractUserIDFromEmail} from "../stacksets/Utils";


const ITEMS_PER_PAGE = 10;

type SCPDeploymentsProps = {
    addToast: AddToastFunction;
    navigationBar: React.ReactNode;
};

type FilterOption = {
    label: string;
    value: string;
};
const labelAll : FilterOption = {label: "All", value: "All"}

const PCSKAdminSCPDeploymentsPage: React.FC<SCPDeploymentsProps> = ({
                                                                      addToast,
                                                                        navigationBar
                                                                                      }) => {
    const [scpDeployments, setScpDeployments] = useState<SCPDeployment[]>([]);
    const [filteredSCPDeployments, setFilteredSCPDeployments] = useState<SCPDeployment[]>([]);
    const [isFetchingDeployments, setIsFetchingDeployments] = useState<boolean>(false);
    const [selectedDeployment, setSelectedDeployment] = useState<SCPDeployment | null>(null);
    const [selectedOperationID, setSelectedOperationID] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [statusFilterDropdownOptions, setStatusFilterDropdownOptions] = useState<FilterOption[]>([labelAll])
    const [selectedStatusDropDownFilter, setSelectedStatusDropDownFilter] = useState<FilterOption>(labelAll)
    const [deploymentsBarChartData, setDeploymentsBarChartData] = useState<BarChartRow[]>([])
    const [developerFilterOptions, setDeveloperFilterOptions] = useState<FilterOption[]>([labelAll])
    const [selectedDeveloperFilter, setSelectedDeveloperFilter] = useState<FilterOption>(labelAll)


    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, filteredSCPDeployments.length);
    const scpApiService = SCPDeploymentAPIServiceFactory.getService();

    useEffect(() => {
        fetchSCPOperations();
    }, []);

    useEffect(() => {
        filterDeployments();
    }, [searchTerm, currentPage, scpDeployments, selectedStatusDropDownFilter, selectedDeveloperFilter]);

    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm, selectedStatusDropDownFilter, selectedDeveloperFilter]);

    useEffect(() => {
        // Create a Set to store unique deployment statuses
        const uniqueStatuses = new Set(
            scpDeployments.flatMap(deployment =>
                deployment.operations.map(operation => operation.status)
            )
        );

        // Create the filter options from unique deployment statuses
        const filterByStatusOptions: FilterOption[] = Array.from(uniqueStatuses).map(status => ({
            label: status,
            value: status
        }));

        // Add "All" as an option
        filterByStatusOptions.push({ label: "All", value: "All" });
        filterByStatusOptions.sort((a, b) => a.value.localeCompare(b.value));

        setStatusFilterDropdownOptions(filterByStatusOptions);


        // Create a Set to store unique deployment statuses
        const uniqueDevelopers = new Set(
            scpDeployments.flatMap(deployment =>
                deployment.operations.map(operation => extractUserIDFromEmail(operation.author_email))
            )
        );

        // Filter out empty strings from unique developers
        const filteredDevelopers = Array.from(uniqueDevelopers).filter(developer => developer !== "");

        // Create the filter options from unique developers
        const filterByDeveloperOptions: FilterOption[] = filteredDevelopers.map(developer => ({
            label: developer,
            value: developer
        }));

        // Add "All" as an option
        filterByDeveloperOptions.push({ label: "All", value: "All" });
        filterByDeveloperOptions.sort((a, b) => a.value.localeCompare(b.value));

        setDeveloperFilterOptions(filterByDeveloperOptions)

    }, [scpDeployments]);

    useEffect(() => {
        const statusCounts = new Map<string, number>();

        // Iterate over all deployments
        filteredSCPDeployments.forEach((deployment) => {
            // Iterate over each operation in the deployment
            deployment.operations.forEach((operation) => {
                const status = operation.status || "Unknown";
                statusCounts.set(status, (statusCounts.get(status) ?? 0) + 1);
            });
        });
        setDeploymentsBarChartData(PrepareBarChartData(statusCounts));
    }, [filteredSCPDeployments]);

    const fetchSCPOperations = async () => {
        setIsFetchingDeployments(true);
        setScpDeployments([])
        resetPage()
        try {
            const response = await scpApiService.getOperations();
            setScpDeployments(response);
        } catch (error) {
            addToast("", TOAST_MESSAGE.FAILED_LOADING_STACK_SET_DEPLOYMENTS(`${error}`), "error");
        } finally {
            setIsFetchingDeployments(false);
        }
    };

    const filterDeployments = () => {
        let data = scpDeployments.filter(deployment => {
            // Check if search term matches either commit_id or any of the operation_ids in operations
            return (
                deployment.commit_metadata.commit_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                deployment.commit_metadata.commit_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                deployment.operations.some(operation =>
                    operation.operation_id.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        });

        // Filter by selected dropdown filter if necessary, checking all operations' statuses
        if (selectedStatusDropDownFilter.value !== labelAll.value) {
            data = data.filter(deployment => {
                // Check if any of the operations within the deployment match the selected status
                return deployment.operations.some(operation => operation.status === selectedStatusDropDownFilter.value);
            });
        }

        // Filter by developer dropdown filter if necessary
        if (selectedDeveloperFilter.value !== labelAll.value) {
            data = data.filter(deployment => {
                // Check if any of the operations within the deployment match the selected status
                return deployment.operations.some(operation => operation.author_email.includes(selectedDeveloperFilter.value));
            });
        }

        // Update the filtered deployments state
        setFilteredSCPDeployments(data);
    };

    // Get the current page's deployments
    const getCurrentPageDeployments = () => {
        return filteredSCPDeployments.slice(startIndex, endIndex);
    };

    const resetPage = () => {
        setSelectedOperationID("")
        setSelectedDeployment(null)
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page when searching
        resetPage()
    };

    const handleNextPage = () => {
        if ((currentPage * ITEMS_PER_PAGE) < filteredSCPDeployments.length) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const getDeploymentsBarChartSize = () => {
        return `slds-size_1-of-${deploymentsBarChartData.length <= 0 ? 1 : deploymentsBarChartData.length}`
    }

    const getDetailsLi = (key: string, value: string) => {
        return <>
            <li>
                <b className="slds-text-color--weak slds-text-body_small">{key}:</b> {value}
            </li>
        </>
    }

    const getDeploymentsCard = () => {
        const body = <>
            <Card
                heading={<>
                    <div className="slds-grid slds-grid_align-spread">
                        <h1 className={"slds-align-content-center"}>PCSK SCP Deployments</h1>
                        <IconSpinnerOverlay size={"x-small"} loading={isFetchingDeployments}
                                            onClick={fetchSCPOperations}/>
                    </div>
                </>}
                icon={<Icon category="standard" name="code_playground" size="small"/>}
                className="slds-m-horizontal--small"
                bodyClassName="slds-p-right_medium slds-p-left_small slds-p-bottom_medium"
            >
                {isFetchingDeployments || scpDeployments.length > 0 ? (
                        <>
                            {/* Bar Chart */}
                            {!isFetchingDeployments
                                &&
                                (filteredSCPDeployments.length > 0 ?
                                        <div className="slds-grid slds-wrap slds-size_1-of-1 slds-m-left_medium">
                                            <div className="slds-col">
                                                <BarChart
                                                    heading={"Operation Status Distribution"}
                                                    data={deploymentsBarChartData}
                                                    liClass={getDeploymentsBarChartSize()}
                                                    contentText={"Operation"}
                                                />
                                            </div>
                                        </div> :
                                        <div className="slds-grid slds-wrap slds-size_1-of-1">
                                            <div className="slds-col">
                                                <BarChart
                                                    heading={"Operation Status Distribution"}
                                                    data={NoneBarChartRow()}
                                                    liClass={getDeploymentsBarChartSize()}
                                                    contentText={"Operation"}
                                                />
                                            </div>
                                        </div>
                                )
                            }
                            {!isFetchingDeployments && <hr className="slds-m-top_x-small slds-m-bottom_x-small"/>}
                            <div className="slds-col slds-size_1-of-1 slds-m-bottom_medium">
                                <Card
                                    icon={<Icon category="standard" name="code_playground" size="small"/>}
                                    className=""
                                    bodyClassName={"slds-p-horizontal--small"}
                                    hasNoHeader={true}
                                    style={{minHeight: "70vh"}}
                                >
                                    {isFetchingDeployments ?
                                        <CustomHeightPageSpinner size="medium" minHeight="70vh"/> : <>
                                            <div
                                                className="slds-grid slds-wrap slds-m-bottom_small">
                                                {/* Search bar */}
                                                <div
                                                    className="slds-col slds-size_6-of-12 slds-p-right_small">
                                                    <h2> Search Operations</h2>
                                                    <Input
                                                        value={searchTerm}
                                                        onChange={handleSearchChange}
                                                        placeholder="Search by commit_id, commit_title or operation_id"
                                                        className="slds-m-bottom_small"
                                                        disabled={isFetchingDeployments}
                                                    />
                                                </div>

                                                <div className="slds-m-left_small">
                                                    <h2> Developer</h2>
                                                    <div>
                                                        <Dropdown
                                                            align="left"
                                                            iconCategory="utility"
                                                            iconName="people"
                                                            iconPosition="right"
                                                            label={selectedDeveloperFilter.label}
                                                            options={developerFilterOptions}
                                                            onSelect={(selectedFilter: FilterOption) => {
                                                                setSelectedDeveloperFilter(selectedFilter)
                                                            }}
                                                            disabled={isFetchingDeployments}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="slds-m-left_small">
                                                    <h2> Operation Status</h2>
                                                    <div className="slds-form-element__control">
                                                        <Dropdown
                                                            align="left"
                                                            iconCategory="utility"
                                                            iconName="filter"
                                                            iconPosition="right"
                                                            label={selectedStatusDropDownFilter.label}
                                                            options={statusFilterDropdownOptions}
                                                            value={""}
                                                            onSelect={(selectedFilter: FilterOption) => {
                                                                setSelectedStatusDropDownFilter(selectedFilter)
                                                            }}
                                                            disabled={isFetchingDeployments}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Pagination display */}
                                            {Math.min(endIndex, filteredSCPDeployments.length) > 0 &&
                                                <div
                                                    className="slds-grid slds-m-bottom--x-small slds-m-top_medium slds-box slds-color__background_gray-2">
                                                    <div
                                                        className="slds-col slds-size_1-of-2 slds-align-middle slds-m-left_medium slds-text-title_bold slds-text-color_success">
                                    <span>
                                        Showing {startIndex + 1} - {Math.min(endIndex, filteredSCPDeployments.length)} of total {filteredSCPDeployments.length} Commits
                                    </span>
                                                    </div>
                                                    {/* Pagination buttons */}
                                                    <div className="slds-col slds-size_1-of-2 slds-text-align--right">
                                                        <ButtonGroup className="slds-m-right_medium">
                                                            <Button
                                                                className={"slds-button_outline-brand"}
                                                                label="Previous"
                                                                variant="neutral"
                                                                onClick={handlePrevPage}
                                                                disabled={currentPage <= 1}
                                                            />
                                                            <Button
                                                                className={"slds-button_outline-brand"}
                                                                label="Next"
                                                                onClick={handleNextPage}
                                                                disabled={(currentPage * ITEMS_PER_PAGE) >= filteredSCPDeployments.length}
                                                            />
                                                        </ButtonGroup>
                                                    </div>
                                                </div>
                                            }

                                            <Accordion>
                                                {getCurrentPageDeployments().map((deployment, index) => (
                                                    <AccordionPanel
                                                        key={index}
                                                        expanded={selectedDeployment && selectedDeployment.commit_metadata.commit_id === deployment.commit_metadata.commit_id}
                                                        onTogglePanel={() => {
                                                            // If the panel is opened, set the selected deployment
                                                            if (selectedDeployment && selectedDeployment.commit_metadata.commit_id === deployment.commit_metadata.commit_id) {
                                                                setSelectedDeployment(null);  // If already selected, set it to null (close the panel)
                                                            } else {
                                                                setSelectedDeployment(deployment);  // If not selected, set the selected deployment
                                                            }
                                                        }}
                                                        summary={
                                                            <div className="slds-text-wrap"
                                                                 style={{whiteSpace: 'normal'}}>
                                                                <ThemedBadge
                                                                    className="slds-m-right_medium"
                                                                    theme={CONSTANT_GENERIC_DEPLOYMENTS.GET_STATUS_THEME(deployment.getStatus(), true)}
                                                                    text={deployment.getStatus()}
                                                                />
                                                                <span title={`${deployment.commit_metadata.author_email}`}>
                                                                    {`${deployment.commit_metadata.commit_title}`}
                                                                </span>
                                                            </div>
                                                        }
                                                    >

                                                        {selectedDeployment &&
                                                            <div className="slds-box slds-m-left--large">
                                                                <ul>
                                                                <SCPOperationsCard
                                                                    getDetailsLi={getDetailsLi}
                                                                    selectedDeployment={selectedDeployment}
                                                                    setSelectedOperationIDInParent={setSelectedOperationID}
                                                                    addToast={addToast}
                                                                />
                                                            </ul>
                                                        </div>}
                                                    </AccordionPanel>
                                                ))}
                                            </Accordion>
                                        </>}
                                </Card>
                            </div>

                        </>
                    ) :
                    <>
                        <div className="slds-size_1-of-1">
                            <div className="slds-m-top_medium slds-m-bottom_medium slds-text-align_center">
                                <div
                                    className="slds-text-title">
                                    <p className="slds-text-heading_medium slds-text-color_default">
                                        No SCP Deployments Found
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Card>
        </>

        return <>{body}</>
    }

    const getChangesCard = () => {
        return <SCPMPAsCard
            selectedDeployment={selectedDeployment}
            getDetailsLi={getDetailsLi}
            selectedOperationID={selectedOperationID}/>
    }

    // Useful links Data
    const usefulPCSKLinks: QuickLinkItem[] = [
        AdminSCPDeploymentGuide,
        SCPStoreRepo,
        SCPAPIRepo,
        PCSKTroubleshootingGuide,
        PCSKAvailability,
        PCAMDashboard,
    ];
    return <>
        {/* Render navigation bar */}
        {navigationBar}

        <QuickLinkList usefulPCSKLinks={usefulPCSKLinks}/>


        <div className={"slds-grid"}>
            <div className="slds-col slds-size_5-of-8 slds-m-bottom_large">
                {getDeploymentsCard()}
            </div>
            <div className="slds-col slds-size_3-of-8">
                {getChangesCard()}
            </div>
        </div>
    </>

};

export default PCSKAdminSCPDeploymentsPage;
